import cn from 'classnames';
import React, { useState } from 'react';

import { CButton, CFormTextarea, CLoadingButton } from '@coreui/react-pro';

import { fetchAppointment, updateAppointment } from 'api/Appointments';

import { Appointment } from 'types/Appointment';

import { renderMarkdown } from 'utils/markdown';

import SvgPencil from 'assets/images/SvgPencil';

import styles from './InternalNote.module.scss';

type InternalNoteProps = {
  appointment: Appointment;
  onNoteSaved: (appointment: Appointment) => void;
};

export function InternalNote({ appointment, onNoteSaved }: InternalNoteProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [appointmentNotes, setAppointmentNotes] = useState(appointment.internal_notes);

  const handleCancel = () => {
    setIsEditing(false);
    setAppointmentNotes(appointment.internal_notes);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    updateAppointment(
      appointment.id,
      { ...appointment, internal_notes: appointmentNotes },
      {
        onSuccess: updateSuccess,
        onError: updateError
      }
    );
  };

  const updateSuccess = (appointment: Appointment) => {
    fetchAppointment(appointment.id.toString(), () => {
      setIsSubmitting(false);
      setIsEditing(false);
      onNoteSaved(appointment);
    });
  };

  const updateError = (error: string) => {
    setIsSubmitting(false);
  };

  return (
    <div className={styles.root}>
      <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
        <h3 className={styles.header}>Internal Note</h3>
        {!isEditing && (
          <CButton
            className={styles.editButton}
            shape="rounded-pill"
            onClick={() => setIsEditing(true)}
            aria-label="edit appointment internal note button"
          >
            <SvgPencil height={17} />
          </CButton>
        )}
      </div>
      {isEditing ? (
        <div className={cn('d-flex flex-column align-items-start h-100')}>
          <CFormTextarea
            id="appointment_notes"
            rows={6}
            placeholder="Notes about the appointment are only visible to staff"
            value={appointmentNotes || undefined}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setAppointmentNotes(event.target.value)}
            className={styles.textarea}
          />

          <div className="d-flex align-items-center justify-content-end gap-2 w-100 mt-2">
            <CButton
              color="primary"
              variant="outline"
              shape="rounded-pill"
              size="sm"
              className="px-3"
              onClick={handleCancel}
            >
              Cancel
            </CButton>
            <CLoadingButton
              color="primary"
              shape="rounded-pill"
              type="submit"
              size="sm"
              className="px-3"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Save
            </CLoadingButton>
          </div>
        </div>
      ) : appointment.internal_notes ? (
        <div className={styles.appointmentNotes}>{renderMarkdown(appointment.internal_notes)}</div>
      ) : (
        <div className="h-100">
          <i>None entered</i>
        </div>
      )}
    </div>
  );
}
