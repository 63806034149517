import { DraggableProvided } from '@hello-pangea/dnd';
import cn from 'classnames';
import * as React from 'react';
import { forwardRef, Ref, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CTableDataCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchProducts } from 'api/Products';

import { EstimateItem } from 'types/EstimateItem';
import { Option } from 'types/Option';
import { Product } from 'types/Product';

import { toCurrency } from 'utils/price';
import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import SvgDrag from 'assets/images/SvgDrag';
import SvgMinusPlain from 'assets/images/SvgMinusPlain';
import SvgPlusPlain from 'assets/images/SvgPlusPlain';

import styles from './EstimateItemForm.module.scss';

import { ActionsMenu } from './ActionsMenu';
import { IconButton } from './IconButton';

type Props = {
  index: number;
  estimateItem?: EstimateItem;
  isDragging: boolean;
  handleChange: (estimateItem: EstimateItem, key: string | undefined) => void;
  removeItem: (key: string | undefined, id: number | undefined) => void;
  isOrgAdmin?: boolean;
  draggableProvided: DraggableProvided;
  addItemAfter: (key?: string, id?: number) => void;
};

export const EstimateItemForm = forwardRef(function EstimateItemForm(
  { isDragging, index, estimateItem, handleChange, removeItem, isOrgAdmin, draggableProvided, addItemAfter }: Props,
  ref: Ref<HTMLTableRowElement>
) {
  const [productList, setProductsList] = useState<Product[]>([]);

  const [selectedProductOption, setSelectedProductOption] = useState<Option | null>();

  const [discount, setDiscount] = useState<number>(
    estimateItem?.discount_fixed ? estimateItem?.discount_fixed / 100 : estimateItem?.discount_percentage || 0
  );
  const [discountType, setDiscountType] = useState<'fixed' | 'percentage'>(
    estimateItem?.discount_fixed ? 'fixed' : 'percentage'
  );

  const [quantity, setQuantity] = useState<number>(estimateItem?.quantity || 1);
  const [qtyLow, setQtyLow] = useState<number>(estimateItem?.quantity_low || 1);
  const [qtyHigh, setQtyHigh] = useState<number>(estimateItem?.quantity_high || 1);

  const [productPresentation, setProductPresentation] = useState<'button' | 'select'>(
    estimateItem?.product ? 'button' : 'select'
  );

  type QuantitySelected = 'low' | 'high';
  const [qtySelected, setQtySelected] = useState<QuantitySelected>(
    !estimateItem?.quantity_low || !estimateItem?.quantity_high
      ? 'low'
      : estimateItem?.quantity === estimateItem?.quantity_low
      ? 'low'
      : 'high'
  );

  const [canDecline, setCanDecline] = useState<boolean>(estimateItem?.customer_declinable || false);

  useEffect(() => {
    if (estimateItem?.discount_fixed) {
      setDiscountType('fixed');
      setDiscount(estimateItem.discount_fixed / 100);
    } else {
      setDiscountType('percentage');
      setDiscount(estimateItem?.discount_percentage ?? 0);
    }
  }, [discountType, estimateItem?.discount_fixed, estimateItem?.discount_percentage]);

  useEffect(() => {
    if (estimateItem?.product && estimateItem.product.id) {
      setSelectedProductOption(toOption(estimateItem.product));
    }
  }, [estimateItem?.product]);

  useEffect(() => {
    if (estimateItem?.status === 'rejected') {
      setProductPresentation('button');
    }
  }, [estimateItem]);

  const handleProductSelectChange = async (selectedOption: Option | null) => {
    if (selectedOption?.value) {
      const selectedProduct = productList.find((product) => product.id === Number(selectedOption.value));
      setSelectedProductOption(selectedProductOption);

      handleChange(
        {
          ...estimateItem,
          discount_percentage: discountType === 'percentage' ? discount : undefined,
          discount_fixed: discountType === 'fixed' && discount ? discount * 100 : undefined,
          product: selectedProduct,
          product_id: Number(selectedProduct?.id),
          quantity: estimateItem?.quantity || quantity,
          quantity_low: qtyLow,
          quantity_high: qtyHigh
        },
        estimateItem?.key
      );

      setProductPresentation('button');
    }
  };

  const updateQuantityHigh = async (newQuantity: number) => {
    setQtyHigh(newQuantity);
    setQuantity(newQuantity);
    handleChange(
      { ...estimateItem, ...(qtySelected === 'high' && { quantity: newQuantity }), quantity_high: newQuantity },
      estimateItem?.key
    );
  };

  const updateQuantityLow = async (newQuantity: number) => {
    setQtyLow(newQuantity);
    setQuantity(newQuantity);
    handleChange(
      { ...estimateItem, ...(qtySelected === 'low' && { quantity: newQuantity }), quantity_low: newQuantity },
      estimateItem?.key
    );
  };

  const updateQuantityLowOrHighSelected = (quantitySelected: QuantitySelected) => {
    setQtySelected(quantitySelected);
    if (quantitySelected === 'low') {
      setQuantity(qtyLow);
      handleChange({ ...estimateItem, quantity: qtyLow }, estimateItem?.key);
    } else {
      setQuantity(qtyHigh);
      handleChange({ ...estimateItem, quantity: qtyHigh }, estimateItem?.key);
    }
  };

  const updateDiscount = async (newDiscount: number) => {
    setDiscount(newDiscount);
    handleChange(
      {
        ...estimateItem,
        discount_percentage: discountType === 'percentage' ? newDiscount : undefined,
        discount_fixed: discountType === 'fixed' && newDiscount ? newDiscount * 100 : undefined
      },
      estimateItem?.key
    );
  };

  const updateDiscountType = async (newDiscountType: string) => {
    if (newDiscountType === 'percentage') {
      setDiscountType('percentage');
      handleChange(
        {
          ...estimateItem,
          discount_percentage: estimateItem?.discount_fixed ? estimateItem.discount_fixed / 100 : undefined,
          discount_fixed: undefined
        },
        estimateItem?.key
      );
    } else {
      setDiscountType('fixed');
      handleChange(
        {
          ...estimateItem,
          discount_percentage: undefined,
          discount_fixed: estimateItem?.discount_percentage ? estimateItem?.discount_percentage * 100 : undefined
        },
        estimateItem?.key
      );
    }
  };

  const updateStatus = () => {
    handleChange(
      {
        ...estimateItem,
        status: estimateItem?.status === 'pending' || estimateItem?.status === 'approved' ? 'rejected' : 'pending'
      },
      estimateItem?.key
    );
  };

  const updateCanDecline = () => {
    setCanDecline(!canDecline);
    handleChange(
      {
        ...estimateItem,
        status: !canDecline ? 'pending' : 'approved',
        customer_declinable: !canDecline
      },
      estimateItem?.key
    );
  };

  const handleProductClick = () => setProductPresentation('select');

  return (
    <CTableRow
      color={isDragging ? 'secondary' : 'default'}
      ref={ref}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <CTableDataCell style={{ width: '3%' }}>
        <SvgDrag height={20} className={styles.dragSvg} />
      </CTableDataCell>
      <CTableDataCell style={{ width: '30%' }}>
        {productPresentation === 'button' && estimateItem?.product && (
          <CButton
            color="light"
            shape="rounded-pill"
            className="w-100 fw-normal text-start"
            onClick={handleProductClick}
            disabled={estimateItem?.status === 'rejected'}
          >
            {estimateItem.product.name}
          </CButton>
        )}

        {productPresentation === 'select' && (
          <div contentEditable>
            <AsyncSelect<Option>
              aria-label={`Product ${index + 1}`}
              placeholder="Type to search..."
              styles={reactSelectStyles}
              value={selectedProductOption}
              onChange={handleProductSelectChange}
              loadingMessage={() => 'Loading...'}
              defaultOptions
              autoFocus
              loadOptions={(inputValue, callback) => {
                fetchProducts(inputValue, (products: Product[]) => {
                  setProductsList(products);
                  callback(products.map(toOption));
                });
              }}
              isSearchable
              isDisabled={estimateItem?.status === 'rejected'}
              required
            />
          </div>
        )}
      </CTableDataCell>

      <CTableDataCell style={{ width: '10%' }}>
        <div className="d-flex gap-1 align-items-center">
          <CFormCheck
            className="d-flex"
            type="radio"
            name={`quantity-low-check-${index}`}
            id={`quantity-low-check-${index}`}
            aria-label="quantity-low-select"
            checked={qtySelected === 'low'}
            disabled={estimateItem?.status === 'rejected'}
            onChange={() => updateQuantityLowOrHighSelected('low')}
          />
          <CFormInput
            className="d-flex"
            style={{ flex: 2 }}
            type="number"
            id={`quantity-low-${index}`}
            aria-label="Quantity Low"
            min={0}
            value={qtyLow}
            step="any"
            required
            disabled={estimateItem?.status === 'rejected'}
            onChange={(event) => updateQuantityLow(parseFloat(event.target.value))}
          />
        </div>
      </CTableDataCell>
      <CTableDataCell style={{ width: '10%' }}>
        <div className="d-flex gap-1 align-items-center">
          <CFormCheck
            className="d-flex"
            type="radio"
            name={`quantity-high-check-${index}`}
            aria-label="quantity-high-select"
            id={`quantity-high-check-${index}`}
            checked={qtySelected === 'high'}
            disabled={estimateItem?.status === 'rejected'}
            onChange={() => updateQuantityLowOrHighSelected('high')}
          />
          <CFormInput
            className="d-flex"
            style={{ flex: 2 }}
            type="number"
            id={`quantity-high-${index}`}
            aria-label="Quantity High"
            min={0}
            value={qtyHigh}
            step="any"
            required
            disabled={estimateItem?.status === 'rejected'}
            onChange={(event) => updateQuantityHigh(parseFloat(event.target.value))}
          />
        </div>
      </CTableDataCell>
      <CTableDataCell
        style={{ width: '8%' }}
        className={cn({ [styles.rejectedText]: estimateItem?.status === 'rejected' })}
      >
        {estimateItem?.price ? toCurrency(estimateItem?.price) : null}
      </CTableDataCell>
      <CTableDataCell style={{ width: '12%' }}>
        {isOrgAdmin ? (
          <CInputGroup className="flex-nowrap">
            <CFormInput
              className="w-50"
              type="number"
              id={`discount-${index}`}
              aria-label="Discount"
              min={0}
              max={discountType === 'percentage' ? 100 : undefined}
              value={discount}
              step={0.01}
              disabled={estimateItem?.status === 'rejected'}
              onChange={(event) => updateDiscount(parseFloat(event.target.value))}
            />
            <CFormSelect
              style={{ paddingRight: '1.75rem', paddingLeft: '0.25rem' }}
              id={`discount_type-${index}`}
              name="discount_type"
              aria-label="Discount type"
              value={discountType}
              options={[
                { value: 'percentage', label: '%' },
                { value: 'fixed', label: '$' }
              ]}
              disabled={estimateItem?.status === 'rejected'}
              onChange={(event) => updateDiscountType(event.target.value)}
            />
          </CInputGroup>
        ) : (
          <div>{discountType === 'percentage' ? `${discount}%` : `$${discount}`}</div>
        )}
      </CTableDataCell>
      <CTableDataCell style={{ width: '10%' }}>
        <div className="d-flex flex-column align-items-center gap-1">
          {estimateItem?.price_before_tax_low && (
            <div
              className={cn(styles.price, {
                [styles.selectedPrice]: estimateItem?.status !== 'rejected' && qtySelected === 'low',
                [styles.rejectedText]: estimateItem?.status === 'rejected'
              })}
            >
              {toCurrency(estimateItem?.price_before_tax_low)}
            </div>
          )}
          {estimateItem?.price_before_tax_high && (
            <div
              className={cn(styles.price, {
                [styles.selectedPrice]: estimateItem?.status !== 'rejected' && qtySelected === 'high',
                [styles.rejectedText]: estimateItem?.status === 'rejected'
              })}
            >
              {toCurrency(estimateItem?.price_before_tax_high)}
            </div>
          )}
        </div>
      </CTableDataCell>
      <CTableDataCell style={{ width: '2%' }}>
        <CFormCheck
          className="mb-2"
          aria-label={`Customer can decline ${estimateItem?.product?.name}`}
          checked={canDecline}
          id={`can-decline-${index}`}
          disabled={estimateItem?.status === 'rejected'}
          onChange={updateCanDecline}
        />
      </CTableDataCell>
      <CTableDataCell style={{ width: '4%' }}>
        <div className="d-flex align-items-center gap-2">
          <ActionsMenu
            items={[{ label: estimateItem?.status === 'rejected' ? 'Accept' : 'Decline', onClick: updateStatus }]}
          />
          <div className="d-flex flex-column gap-1" style={{ width: 'fit-content' }}>
            <IconButton
              onClick={() => removeItem(estimateItem?.key, estimateItem?.id)}
              icon={SvgMinusPlain}
              title="Remove item"
              style={{ padding: '5px', width: '20px', height: '20px' }}
            />
            <IconButton
              onClick={() => addItemAfter(estimateItem?.key, estimateItem?.id)}
              icon={SvgPlusPlain}
              title="Add item"
              style={{ padding: '5px', width: '20px', height: '20px' }}
            />
          </div>
        </div>
      </CTableDataCell>
    </CTableRow>
  );
});
