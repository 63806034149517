import cn from 'classnames';
import { useRef, useState } from 'react';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { CButton, CCol, CContainer, CForm, CFormInput, CRow, CSpinner } from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';

import { Animal } from 'types/Animal';

import { ageDisplay, desexedDisplay } from 'utils/animal';
import { uploadAnimalPhoto } from 'utils/cloudinary';
import { compactDateDisplay } from 'utils/dates';
import { renderMarkdown } from 'utils/markdown';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as GenderIcon } from 'assets/images/gender.svg';
import { ReactComponent as PawIcon } from 'assets/images/paw.svg';
import { ReactComponent as ScaleIcon } from 'assets/images/scale.svg';
import SvgDrawPicture from 'assets/images/SvgDrawPicture';

import { AnimalPhoto } from 'components/AnimalPhoto';

import styles from './Signalment.module.scss';

import { NewPatientBadge } from './NewPatientBadge';

type Props = {
  animal: Animal;
  showPatientNotes?: boolean;
  onUpdateSuccess?: () => void;
  condensed?: boolean;
};

export const Signalment = ({ animal, showPatientNotes = false, onUpdateSuccess, condensed = false }: Props) => {
  const [imageUploading, setImageUploading] = useState(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    uploadRef.current?.click();
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;

    try {
      setImageUploading(true);
      const response = await uploadAnimalPhoto(file);
      updateAnimal(animal.id, { photo_provider_id: response.public_id }, { onSuccess: handleImageUploadSuccess });
    } catch {
      setImageUploading(false);
      toast.error('Image upload failed!');
    }
  };

  const handleImageUploadSuccess = (animal: Animal) => {
    setImageUploading(false);
    onUpdateSuccess?.();
    toast.success('Image uploaded!');
  };

  const isWeightComplete = (animal: Animal) => {
    return [animal.weight, animal.weight_unit].every((attr) => attr);
  };

  return (
    <div>
      {!condensed && (
        <h2 className={styles.header}>
          Patient <NewPatientBadge animal={animal} />
        </h2>
      )}

      <CContainer className={styles.container}>
        <CRow style={{ paddingBottom: '15px' }}>
          <CCol xs="auto">
            <div className={styles.photoNameContainer}>
              <div className={styles.photoContainer}>
                <Link
                  aria-label={animal.name || 'Patient details'}
                  to={generatePath(paths.animalDetails, { id: animal.id })}
                >
                  <AnimalPhoto className="d-block" animal={animal} height={80} width={80} />
                </Link>
                <CForm>
                  <CButton
                    shape="rounded-circle"
                    className={styles.uploadButton}
                    onClick={handleUploadClick}
                    disabled={imageUploading}
                    aria-label="Upload patient photo"
                  >
                    {imageUploading ? (
                      <CSpinner className={styles.spinner} size="sm" />
                    ) : (
                      <SvgDrawPicture height={12} />
                    )}
                  </CButton>
                  <CFormInput accept="image/*" hidden onChange={handleImageChange} ref={uploadRef} type="file" />
                </CForm>
              </div>
              <Link className={styles.name} to={generatePath(paths.animalDetails, { id: animal.id })}>
                {animal.name}
              </Link>
            </div>
          </CCol>

          <CCol className="d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Species
              </div>
              <div className={styles.value}>{animal.species.name}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <GenderIcon className={cn(styles.icon, styles.unstroked)} />
                Sex
              </div>
              <div className={styles.value}>{desexedDisplay(animal)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Breed
              </div>
              {animal.breed && <div className={styles.value}>{animal.breed.name}</div>}
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Demeanor
              </div>
              {animal.demeanor && <div className={styles.value}>{animal.demeanor}</div>}
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                FAS Score
              </div>
              {animal.fas_score && <div className={styles.value}>{animal.fas_score}</div>}
            </div>
          </CCol>

          <CCol className="d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between">
              <div>
                <ScaleIcon className={styles.icon} />
                Weight
              </div>
              {isWeightComplete(animal) && (
                <div className={styles.value}>
                  {animal.weight} {animal.weight_unit}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Age
              </div>
              {animal.date_of_birth && (
                <div className={cn('text-capitalize', styles.value)}>{ageDisplay(animal.date_of_birth)}</div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <CalendarIcon className={styles.icon} />
                DOB
              </div>
              {animal.date_of_birth && <div className={styles.value}>{compactDateDisplay(animal.date_of_birth)}</div>}
            </div>
            {!condensed && (
              <div className="d-flex justify-content-between">
                <div>
                  <PawIcon className={styles.icon} />
                  Personality
                </div>
                {animal.personality_trait_list && (
                  <div className={cn('text-capitalize', styles.value)}>{animal.personality_trait_list.join(', ')}</div>
                )}
              </div>
            )}
          </CCol>
        </CRow>

        {showPatientNotes && animal.internal_notes && (
          <CRow className={styles.patientNotes}>
            <div className="fw-semibold">Patient Notes</div>
            <div className={styles.notesContent}>{renderMarkdown(animal.internal_notes)}</div>
          </CRow>
        )}
      </CContainer>
    </div>
  );
};
