import { Clinic } from './Clinic';
import { Employee } from './Employee';
import { Organization } from './Organization';
import { Product } from './Product';

export type Price = { subtotal: number; tax: number; total: number; price: number };
export type CalculatedPrices = {
  low: Price;
  high: Price;
  selected: Price;
};

export type ProductPrice = {
  id: number;
  cost: number | null;
  created_at: string;
  created_by_employee: Employee;
  calculated_prices: CalculatedPrices;
  dispense_fee: number | null;
  markup_based_pricing: boolean;
  markup_percentage: number | null;
  owner?: Organization | Clinic;
  owner_id: number;
  owner_type: 'Clinic' | 'Organization';
  urgent_care_price: number | null;
  price: number | null;
  product: Product;
  product_prices_attributes?: ProductPricesAttributes;
  status: 'active' | 'disabled';
  tax_rate: number | null;
  updated_at: string;
  updated_by_employee: Employee;

  /* Front End Fields */
  key?: string; // Not an API field, used for iteration
};

export type Owner = {
  owner_name: string;
  owner_id: number;
  owner_type: 'Clinic' | 'Organization';
};

export type ProductPricesAttributes = {
  id?: number;
  price?: number | null;
  status?: 'active' | 'disabled';
  owner_id?: number | null;
  owner_type?: 'Clinic' | 'Organization';
  cost?: number | null;
  dispense_fee?: number | null;
  markup_based_pricing?: boolean;
  markup_percentage?: number | null;
  tax_rate?: number | null;
};

export const priceToAttributes = (price: Partial<ProductPrice>): ProductPricesAttributes => {
  return {
    id: price.id,
    price: price.price,
    status: price.status,
    owner_id: price.owner_id,
    owner_type: price.owner_type,
    cost: price.cost,
    dispense_fee: price.dispense_fee,
    markup_based_pricing: price.markup_based_pricing,
    markup_percentage: price.markup_percentage,
    tax_rate: price.tax_rate
  };
};
