import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16.478 13.748c0 .27-.06.547-.188.817-.127.27-.292.525-.51.765a3.4 3.4 0 0 1-1.23.885q-.674.284-1.463.285c-.764 0-1.582-.18-2.444-.547a13.2 13.2 0 0 1-2.58-1.485 21.6 21.6 0 0 1-2.46-2.1A21 21 0 0 1 3.51 9.915c-.615-.855-1.11-1.71-1.47-2.557Q1.5 6.075 1.5 4.905c0-.51.09-.997.27-1.448a3.5 3.5 0 0 1 .862-1.252c.48-.473 1.006-.705 1.56-.705.21 0 .42.045.608.135.195.09.368.225.503.42l1.74 2.453c.135.187.232.36.3.524.067.158.104.316.104.458 0 .18-.052.36-.157.532q-.149.261-.42.533l-.57.593a.4.4 0 0 0-.12.3c0 .06.008.112.022.172.023.06.045.105.06.15q.204.373.698.96c.338.39.697.787 1.087 1.185.406.398.795.765 1.193 1.103.39.33.713.554.967.69.038.014.083.037.135.06.06.022.12.03.188.03a.4.4 0 0 0 .308-.128l.57-.562q.28-.284.54-.42a1 1 0 0 1 .532-.158q.213-.002.457.098a3 3 0 0 1 .526.292l2.482 1.762c.195.136.33.293.413.48.075.188.12.375.12.585Z"
    />
  </svg>
);
export default SvgCall;
