import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CCol, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchEmployeesByQuery } from 'api/Employees';

import { AppointmentType } from 'types/AppointmentType';
import { Employee } from 'types/Employee';
import { ForwardBooking } from 'types/ForwardBooking';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { appointmentTypeToOption, employeeToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgPerson from 'assets/images/SvgPerson';
import SvgStack from 'assets/images/SvgStack';

import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './ForwardBookingForm.module.scss';

type Props = {
  appointmentTypes: AppointmentType[];
  employee: Employee | null;
  loading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  hideForm: () => void;
  forwardBooking?: ForwardBooking;
};

const MIN_QUERY_LENGTH = 3;

export const ForwardBookingForm = ({
  forwardBooking,
  hideForm,
  appointmentTypes,
  onSubmit,
  loading,
  employee
}: Props) => {
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(
    forwardBooking ? employeeToOption(forwardBooking.employee) : employee ? employeeToOption(employee) : null
  );

  const [selectedAppointmentTypeOption, setSelectedAppointmentTypeOption] = useState<Option | null>(
    forwardBooking ? appointmentTypeToOption(forwardBooking.appointment_type) : null
  );
  const appointmentTypeOptions = [
    { label: 'Select an appointment type', value: '' },
    ...appointmentTypes.map(appointmentTypeToOption)
  ];

  const [notes, setNotes] = useState(forwardBooking?.notes ?? '');
  const [dueDateDescription, setDueDateDescription] = useState(forwardBooking?.due_date_description ?? '');

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);
  };

  const handleAppointmentTypeSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = appointmentTypeOptions.find((option: Option) => option.value === event.target.value);

    if (selected) {
      setSelectedAppointmentTypeOption(selected);
    }
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{forwardBooking ? 'Edit' : 'New'} Forward Booking</h2>

      <CRow className="mb-3">
        <CCol xs>
          <CFormInput hidden id="employee_id" name="employee_id" value={selectedEmployeeOption?.value ?? ''} />
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="employee">
              <IconLabel icon={SvgPerson} label="Employee" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <AsyncSelect<Option>
            id="employee"
            aria-label="Employee"
            placeholder="Type to search..."
            value={selectedEmployeeOption}
            styles={reactSelectStyles}
            autoFocus={!forwardBooking}
            onChange={handleEmployeeSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={loadEmployeeOptions}
            isClearable
            isSearchable
            required
          />
        </CCol>

        <CCol sm>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="due_date_description">
              <IconLabel icon={SvgCalendar} label="Due Date Description" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <CFormInput
            type="text"
            value={dueDateDescription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDueDateDescription(e.target.value)}
            id="due_date_description"
            name="due_date_description"
            placeholder={'Two weeks from now'}
            required
          />
        </CCol>

        <CCol sm>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="appointment_type_id">
              <IconLabel icon={SvgStack} label="Appointment Type" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <select
            className="form-select"
            id="appointment_type_id"
            name="appointment_type_id"
            onChange={handleAppointmentTypeSelectChange}
            required
            value={selectedAppointmentTypeOption?.value}
          >
            {appointmentTypeOptions.map((option: Option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </CCol>
      </CRow>

      <RichTextEditor value={notes} onChange={setNotes} name="notes" label="Notes" id="notes" />

      <div className="d-flex align-items-center justify-content-between">
        {forwardBooking && <FormAuditData item={forwardBooking} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton className={styles.button} shape="rounded-pill" type="submit" loading={loading}>
            {forwardBooking ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
