import flatMap from 'lodash/flatMap';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';
import { ExternalDispensePrescriptions } from 'views/prescriptions/ExternalDispensePrescriptions';

import { CNav, CNavItem, CNavLink } from '@coreui/react-pro';

import { fetchDispenseRecord, updateDispenseRecord } from 'api/DispenseRecords';
import {
  ensureDispenseRecords,
  fetchPrescriptionItemsForConsult,
  fetchPrescriptionLabel,
  updatePrescriptionItem
} from 'api/PrescriptionItems';
import { createPrescription, fetchPrescription, updatePrescription } from 'api/Prescriptions';
import { createVaccination, fetchVaccinationsForConsult, updateVaccination } from 'api/Vaccinations';

import { Consult } from 'types/Consult';
import { DispenseRecord } from 'types/DispenseRecord';
import { Prescription } from 'types/Prescription';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { PrescriptionLoadState } from 'types/PrescriptionLoadState';
import { Vaccination } from 'types/Vaccination';

import { useAuth } from 'hooks/useAuth';
import { usePoll } from 'hooks/usePoll';

import { compactDateDisplay, compactDateTimeDisplay } from 'utils/dates';
import { isAwaitingLogs } from 'utils/dispenseRecords';
import { fulfillmentStatus } from 'utils/prescriptionItems';
import { prescriptionItemToOption, vaccinationToOption } from 'utils/selectOptions';
import { statusAction } from 'utils/status';
import { createNewTab } from 'utils/tabs';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { IconButton } from 'components/IconButton';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { NewDispenseLogsModal } from 'components/NewDispenseLogsModal';
import { Pill } from 'components/Pill';
import { PrescriptionForm } from 'components/PrescriptionForm';
import { PrescriptionItemLabelPrint } from 'components/print_labels/PrescriptionItemLabelPrint';
import { TableAuditData } from 'components/TableAuditData';
import { VaccinationForm } from 'components/VaccinationForm';

type Form =
  | 'edit-prescription'
  | 'edit-vaccination'
  | 'external-dispense-medication'
  | 'new-dispense-logs'
  | 'new-prescription'
  | 'new-vaccination'
  | 'new-dispense-log-single';

const tables = ['medications', 'vaccinations'] as const;
export type Table = (typeof tables)[number];

type LoadState = 'creating-updating-vaccination' | PrescriptionLoadState;

type Props = {
  consult: Consult;
  scrollUp: () => void;
};

export const MedicationsVaccinationsStep = ({ consult, scrollUp }: Props) => {
  const [prescriptionItems, setPrescriptionItems] = useState<PrescriptionItem[]>([]);
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);

  const [activeTable, setActiveTable] = useState<Table>(tables[0]);
  const [editingItem, setEditingItem] = useState<Prescription | Vaccination | DispenseRecord>();

  const [enqueuedDispenseRecords, setEnqueuedDispenseRecords] = useState<DispenseRecord[]>();

  const [loadState, setLoadState] = useState<LoadState | undefined>(undefined);
  const [formKey, setFormKey] = useState(0);
  const [visibleForm, setVisibleForm] = useState<Form>();

  const auth = useAuth();

  const fetchVaccinations = useCallback(
    () =>
      fetchVaccinationsForConsult(consult.id, (vaccinations) => {
        if (activeTable === 'vaccinations' && vaccinations.length === 0) {
          setVisibleForm('new-vaccination');
        }

        setVaccinations(vaccinations);
      }),
    [consult, activeTable]
  );
  usePoll(fetchVaccinations);

  const fetchPrescriptionItems = useCallback(
    () =>
      fetchPrescriptionItemsForConsult(consult.id, {
        onSuccess: (prescriptionItems) => {
          if (activeTable === 'medications' && prescriptionItems.length === 0) {
            setVisibleForm('new-prescription');
          }

          setPrescriptionItems(prescriptionItems);
        }
      }),
    [consult, activeTable]
  );
  usePoll(fetchPrescriptionItems);

  const hideForm = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  const remountForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleNewMedicationClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-prescription');
  };

  const handleNewVaccinationClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-vaccination');
  };

  const handleEditPrescriptionItemClick = (item: PrescriptionItem) => () => {
    fetchPrescription(item.prescription.id, {
      onSuccess: (prescription) => {
        remountForm();
        scrollUp();
        setEditingItem(prescription);
        setVisibleForm('edit-prescription');
      }
    });
  };

  const handlePrintPrescriptionItemClick = (item: PrescriptionItem) => () => {
    ensureDispenseRecords(item.id, (prescriptionItem) => {
      const loggableDispenseRecords = prescriptionItem.dispense_records?.filter(isAwaitingLogs) ?? [];

      if (loggableDispenseRecords.length === 0) {
        fetchPrescriptionLabel(item.id, {
          onSuccess: (label) => {
            createNewTab(<PrescriptionItemLabelPrint prescriptionLabel={label} />);
          }
        });
      } else {
        setLoadState(undefined);
        setEditingItem(loggableDispenseRecords[0]);
        setVisibleForm('new-dispense-log-single');
        remountForm();
      }
    });
  };

  const handleEditVaccinationClick = (item: Vaccination) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-vaccination');
  };

  const handleSuccess = (message: string) => {
    fetchVaccinations();
    fetchPrescriptionItems();

    toast.success(message);
    hideForm();
    setLoadState(undefined);
  };

  const handleExternalDispense = (prescription: Prescription) => {
    fetchPrescriptionItems();
    setLoadState(undefined);
    setEditingItem(prescription);
    setVisibleForm('external-dispense-medication');
  };

  const handleError = () => {
    setLoadState(undefined);
  };

  // Note: our domain says this should always be true, but checking for
  // it feels like a good idea.
  const everyPrescriptionDispensed = (prescription: Prescription) => {
    return prescription.prescription_items.every(
      (item) => item?.dispense_records && item?.dispense_records?.length > 0
    );
  };

  const extractLoggableDispenseRecords = (prescription: Prescription) => {
    return flatMap(
      prescription.prescription_items,
      (item: PrescriptionItem) => item.dispense_records?.filter(isAwaitingLogs) || []
    );
  };

  // Note: we need the full serialized dispense record and its associated records to load the dispense logs form. The
  // record returned with the prescription does not have enough data, nor should it.
  const loadDispenseRecord = (dispenseRecordId: number) => {
    fetchDispenseRecord(dispenseRecordId, {
      onSuccess: (dispenseRecord) => {
        setLoadState(undefined);
        setEditingItem(dispenseRecord);
        setVisibleForm('new-dispense-logs');
        remountForm();
      }
    });
  };

  const handleEnterPrintProcess = (prescription: Prescription) => {
    if (!everyPrescriptionDispensed(prescription)) {
      toast.error('All medications must be dispensed.');
      return;
    }

    const loggableDispenseRecords = extractLoggableDispenseRecords(prescription);
    setEnqueuedDispenseRecords(loggableDispenseRecords);

    if (loggableDispenseRecords.length === 0) {
      printPrescription(prescription);
    } else {
      loadDispenseRecord(loggableDispenseRecords[0].id);
    }
  };

  const handleReenterPrintProcess = (dispenseRecord: DispenseRecord) => {
    if (!enqueuedDispenseRecords) return;

    const remainingLoggables = enqueuedDispenseRecords.slice(1);
    setEnqueuedDispenseRecords(remainingLoggables);

    if (remainingLoggables.length === 0) {
      setEditingItem(undefined);
      setVisibleForm(undefined);

      fetchPrescription(dispenseRecord.prescription_item.prescription.id, { onSuccess: printPrescription });
    } else {
      loadDispenseRecord(remainingLoggables[0].id);
    }
  };

  const printPrescription = (prescription: Prescription) => {
    prescription.prescription_items.forEach((prescriptionItem) => {
      fetchPrescriptionLabel(prescriptionItem.id, {
        onSuccess: (label) => {
          handleSuccess('Prescription label printed!');
          createNewTab(<PrescriptionItemLabelPrint prescriptionLabel={label} />);
        },
        onError: handleError
      });
    });
  };

  const handleTogglePrescriptionItemCurrentness = (item: PrescriptionItem) => {
    const newCurrent = !item.current;
    const newDescriptor = newCurrent ? 'current' : 'stopped';

    updatePrescriptionItem(
      item.id,
      { current: newCurrent },
      {
        onSuccess: () => handleSuccess(`Medication ${newDescriptor}!`),
        onError: handleError
      }
    );
  };

  const getPrescriptionItemsAttributes = (formData: FormData) => {
    const nestedName = (index: number, name: string) => `prescription_items_attributes[${index}][${name}]`;
    const nestedDateName = (index: number, name: string) => `${nestedName(index, name)}-date`;

    const result = [];
    let index = 0;

    while (formData.has(nestedName(index, 'product_id'))) {
      const chronic = formData.get(nestedName(index, 'chronic'));
      const current = formData.get(nestedName(index, 'current'));
      const dispense = formData.get(nestedName(index, 'dispense'));
      const dispenseInstructions = formData.get(nestedName(index, 'dispense_instructions'));
      const id = formData.get(nestedName(index, 'id'));
      const instructions = formData.get(nestedName(index, 'instructions'));
      const maxRefills = formData.get(nestedName(index, 'max_refills'));
      const productId = formData.get(nestedName(index, 'product_id'));
      const quantity = formData.get(nestedName(index, 'quantity'));
      const quantityPerRefill = formData.get(nestedName(index, 'quantity_per_refill'));
      const quantityPerUse = formData.get(nestedName(index, 'quantity_per_use'));
      const refillExpiresAt = formData.get(nestedDateName(index, 'refill_expires_at')?.toString());
      const refillTimePeriod = formData.get(nestedName(index, 'refill_time_period'));
      const refillTimeUnit = formData.get(nestedName(index, 'refill_time_unit'));
      const takenWithFood = formData.get(nestedName(index, 'taken_with_food'));
      const timeIntervalInHours = formData.get(nestedName(index, 'time_interval_in_hours'));
      const expiresAt = formData.get(nestedDateName(index, 'expires_at')?.toString());

      const attributes = {
        chronic: Boolean(chronic),
        current: Boolean(current),
        dispense: dispense || null,
        dispense_instructions: dispenseInstructions || null,
        expires_at: expiresAt,
        id: id ? Number(id) : null,
        instructions: instructions,
        max_refills: Number(maxRefills),
        product_id: Number(productId),
        quantity: quantity,
        quantity_per_refill: quantityPerRefill,
        quantity_per_use: quantityPerUse || null,
        refill_expires_at: refillExpiresAt,
        refill_time_period: refillTimePeriod,
        refill_time_unit: Number(refillTimeUnit),
        taken_with_food: Boolean(takenWithFood),
        time_interval_in_hours: timeIntervalInHours ? Number(timeIntervalInHours) : null
      };

      result.push(attributes);

      index++;
    }

    return result;
  };

  const evaluateSubmitValue = (event: React.FormEvent<HTMLFormElement>) => {
    const isPrinting = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'print';
    const isDispensing = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'enqueue';
    const isExternalDispensing = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'external';

    return { isPrinting, isDispensing, isExternalDispensing };
  };

  const handleCreatePrescription = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);

    const { isPrinting, isDispensing, isExternalDispensing } = evaluateSubmitValue(event);
    let loadState = 'creating-updating-prescription';
    if (isPrinting) {
      loadState = 'printing-prescription';
    } else if (isDispensing) {
      loadState = 'dispensing-prescription';
    } else if (isExternalDispensing) {
      loadState = 'external-dispensing-prescription';
    }

    setLoadState(loadState as LoadState);

    const prescriptionItemsAttributes = getPrescriptionItemsAttributes(formData).map((attributes) => ({
      ...attributes,
      ...(isExternalDispensing ? { external_prescription: true } : {})
    }));

    const formJson = {
      consult_id: consult.id,
      animal_id: consult.animal_id,
      employee_id: Number(formData.get('employee_id')),
      date_of_prescription: formData.get('date_of_prescription-date')?.toString(),
      prescription_items_attributes: prescriptionItemsAttributes
    };

    createPrescription(
      formJson,
      { enqueue: isPrinting || isDispensing },
      {
        onSuccess: (prescription) => {
          if (isPrinting) {
            handleEnterPrintProcess(prescription);
          } else if (isExternalDispensing) {
            handleExternalDispense(prescription);
          } else {
            const action = isDispensing ? 'enqueued for dispense' : 'created';
            const message = `Medications ${action}!`;
            handleSuccess(message);
          }
        },
        onError: handleError
      }
    );
  };

  const handleUpdatePrescription = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;

    const form = event.currentTarget;
    const formData = new FormData(form);

    const { isPrinting, isDispensing, isExternalDispensing } = evaluateSubmitValue(event);
    let loadState = 'creating-updating-prescription';
    if (isPrinting) {
      loadState = 'printing-prescription';
    } else if (isDispensing) {
      loadState = 'dispensing-prescription';
    } else if (isExternalDispensing) {
      loadState = 'external-dispensing-prescription';
    }

    setLoadState(loadState as LoadState);

    const prescriptionItemsAttributes = getPrescriptionItemsAttributes(formData).map((attributes) => ({
      ...attributes,
      ...(isExternalDispensing ? { external_prescription: true } : {})
    }));

    const formJson = {
      employee_id: Number(formData.get('employee_id')),
      date_of_prescription: formData.get('date_of_prescription-date')?.toString(),
      prescription_items_attributes: prescriptionItemsAttributes
    };

    updatePrescription(
      editingItem.id,
      formJson,
      { enqueue: isPrinting || isDispensing },
      {
        onSuccess: (prescription) => {
          if (isPrinting) {
            handleEnterPrintProcess(prescription);
          } else if (isExternalDispensing) {
            handleExternalDispense(prescription);
          } else {
            const action = isDispensing ? 'enqueued for dispense' : 'updated';
            const message = `Medications ${action}!`;
            handleSuccess(message);
          }
        },
        onError: handleError
      }
    );
  };

  const handleCreateVaccination = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadState('creating-updating-vaccination');

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      consult_id: consult.id,
      product_id: Number(formData.get('product_id')),
      date_of_administration: formData.get('date_of_administration')?.toString(),
      date_of_next_administration: formData.get('date_of_next_administration')?.toString(),
      historical: formData.get('historical') === 'on',
      quantity: Number(formData.get('quantity')),
      serial_number: formData.get('serial_number')?.toString(),
      expiration_date: formData.get('expiration_date-date')?.toString()
    };

    createVaccination(formJson, {
      onSuccess: () => handleSuccess('Vaccination created!'),
      onError: handleError
    });
  };

  const handleUpdateVaccination = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoadState('creating-updating-vaccination');

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      product_id: Number(formData.get('product_id')),
      date_of_administration: formData.get('date_of_administration')?.toString(),
      date_of_next_administration: formData.get('date_of_next_administration')?.toString(),
      historical: formData.get('historical') ? formData.get('historical') === 'on' : false,
      quantity: Number(formData.get('quantity')),
      serial_number: formData.get('serial_number')?.toString(),
      expiration_date: formData.get('expiration_date-date')?.toString()
    };

    updateVaccination(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Vaccination updated!'),
      onError: handleError
    });
  };

  const getDispenseLogsAttributes = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    const nestedName = (index: number, name: string) => `dispense_logs[${index}][${name}]`;

    const result = [];
    let index = 0;

    while (formData.has(nestedName(index, 'expiration_date'))) {
      const id = formData.get(nestedName(index, 'id'));
      const lotNumber = formData.get(nestedName(index, 'lot_number'));
      const expirationDate = formData.get(nestedName(index, 'expiration_date'));

      result.push({
        id: id ? Number(id) : undefined,
        lot_number: lotNumber ? String(lotNumber) : null,
        expiration_date: expirationDate ? String(expirationDate) : null
      });

      index++;
    }

    return result;
  };

  const handleCreateDispenseLogs =
    (onSuccess: (dispenseRecord: DispenseRecord) => void) => (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!editingItem) return;
      setLoadState('creating-dispense-logs');

      const dispense_logs_attributes = getDispenseLogsAttributes(event);

      updateDispenseRecord(
        editingItem.id,
        { dispense_logs_attributes },
        {
          onSuccess,
          onError: handleError
        }
      );
    };

  const hideModal = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  type Editable = Prescription | Vaccination | DispenseRecord;
  const isPrescription = (item?: Editable): item is Prescription => !!item;
  const isVaccination = (item?: Editable): item is Vaccination => !!item;
  const isDispenseRecord = (item?: Editable): item is DispenseRecord => !!item;

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'new-prescription':
        if (consult) {
          return (
            <PrescriptionForm
              appointment={consult.appointment}
              key={formKey}
              animal={consult.animal}
              employee={auth?.employee}
              hideForm={hideForm}
              loadState={loadState as PrescriptionLoadState}
              onSubmit={handleCreatePrescription}
            />
          );
        }
        break;
      case 'edit-prescription':
        if (consult && isPrescription(editingItem)) {
          return (
            <PrescriptionForm
              appointment={consult.appointment}
              key={formKey}
              animal={consult.animal}
              hideForm={hideForm}
              loadState={loadState as PrescriptionLoadState}
              onSubmit={handleUpdatePrescription}
              prescription={editingItem}
            />
          );
        }
        break;
      case 'external-dispense-medication':
        if (consult && isPrescription(editingItem)) {
          return (
            <ExternalDispensePrescriptions animal={consult.animal} hideModal={hideForm} prescription={editingItem} />
          );
        }
        break;
      case 'new-dispense-logs':
        if (isDispenseRecord(editingItem)) {
          return (
            <NewDispenseLogsModal
              hideModal={hideModal}
              loading={loadState === 'creating-dispense-logs'}
              key={formKey}
              onSubmit={handleCreateDispenseLogs(handleReenterPrintProcess)}
              dispenseRecord={editingItem}
            />
          );
        }
        break;
      case 'new-dispense-log-single':
        if (isDispenseRecord(editingItem)) {
          return (
            <NewDispenseLogsModal
              hideModal={hideModal}
              loading={loadState === 'creating-dispense-logs'}
              key={formKey}
              onSubmit={handleCreateDispenseLogs((dispenseRecord) => {
                fetchPrescriptionLabel(dispenseRecord.prescription_item.id, {
                  onSuccess: (label) => {
                    setLoadState(undefined);
                    setVisibleForm(undefined);
                    createNewTab(<PrescriptionItemLabelPrint prescriptionLabel={label} />);
                  }
                });
              })}
              dispenseRecord={editingItem}
            />
          );
        }
        break;
      case 'new-vaccination':
        return (
          <VaccinationForm
            key={formKey}
            hideForm={hideForm}
            loading={loadState === 'creating-updating-vaccination'}
            onSubmit={handleCreateVaccination}
          />
        );
      case 'edit-vaccination':
        if (isVaccination(editingItem)) {
          return (
            <VaccinationForm
              key={formKey}
              vaccination={editingItem}
              hideForm={hideForm}
              loading={loadState === 'creating-updating-vaccination'}
              onSubmit={handleUpdateVaccination}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  const itemsCount = {
    medications: prescriptionItems.length,
    vaccinations: vaccinations.length
  };

  const handleNavClick = (table: Table) => () => {
    hideForm();
    setActiveTable(table);
  };

  const TableControl = () => (
    <CNav role="list" variant="underline">
      {tables.map((table) => (
        <CNavItem key={table}>
          <CNavLink
            className="text-capitalize"
            active={table === activeTable}
            role="button"
            onClick={handleNavClick(table)}
          >
            {table.replace('-', ' ')} ({itemsCount[table]})
          </CNavLink>
        </CNavItem>
      ))}
    </CNav>
  );

  return (
    <>
      <MedicalHistoryTableV2
        name="Medications"
        visible={activeTable === 'medications'}
        form={renderForm(visibleForm)}
        items={prescriptionItems}
        tableControl={<TableControl />}
        columns={[
          'updated',
          { key: 'date_of_prescription', label: 'Date of Prescription' },
          { key: 'fulfillment_status' },
          { key: 'current' },
          { key: 'chronic' },
          { key: 'name' },
          { key: 'quantity' },
          { key: 'instructions' },
          { key: 'actions' }
        ]}
        scopedColumns={{
          updated: (item: PrescriptionItem) => (
            <TableAuditData item={item.prescription} handleClick={handleEditPrescriptionItemClick(item)} />
          ),
          date_of_prescription: (item: PrescriptionItem) => (
            <td>{compactDateDisplay(item.prescription.date_of_prescription, { timezone: 'UTC' })}</td>
          ),
          fulfillment_status: (item: PrescriptionItem) => (
            <td>
              <Pill label={fulfillmentStatus(item)} />
            </td>
          ),
          name: (item: PrescriptionItem) => <td>{item.product?.name}</td>,
          instructions: (item: PrescriptionItem) => <td dangerouslySetInnerHTML={{ __html: item.instructions }} />,
          current: (item: PrescriptionItem) => <td>{item.current ? 'Yes' : 'No'}</td>,
          chronic: (item: PrescriptionItem) => <td>{item.chronic ? 'Yes' : 'No'}</td>,
          actions: (item: PrescriptionItem) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditPrescriptionItemClick(item)
                  },
                  {
                    label: item.current ? 'Mark Not Taking' : 'Mark Taking',
                    onClick: () => handleTogglePrescriptionItemCurrentness(item)
                  },
                  statusAction({
                    item,
                    name: 'Medication',
                    updater: updatePrescriptionItem,
                    handleError,
                    handleSuccess
                  }),
                  {
                    label: 'Print',
                    onClick: handlePrintPrescriptionItemClick(item)
                  }
                ]}
              />
            </td>
          )
        }}
        newButton={<IconButton icon={SvgPlus} label="New Medication" onClick={handleNewMedicationClick} />}
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={prescriptionItems
              .filter((item) => item.status !== 'disabled')
              .map(prescriptionItemToOption)}
            contextType={
              prescriptionItems.filter((prescriptionItem) => prescriptionItem.status !== 'disabled').length > 0
                ? 'PrescriptionItem'
                : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />

      <MedicalHistoryTableV2
        name="Vaccinations"
        items={vaccinations}
        form={renderForm(visibleForm)}
        visible={activeTable === 'vaccinations'}
        tableControl={<TableControl />}
        columns={[
          'updated',
          { key: 'date_of_administration', label: 'Date of Admin' },
          { key: 'date_of_next_administration', label: 'Date of Next Admin' },
          { key: 'administration_status', label: 'Admin Status' },
          'product_name',
          'quantity',
          'historical',
          'serial_number',
          { key: 'expiration_date', label: 'Date of Expiration' },
          'actions'
        ]}
        scopedColumns={{
          updated: (item: Vaccination) => <TableAuditData item={item} handleClick={handleEditVaccinationClick(item)} />,
          status: (item: Vaccination) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          date_of_administration: (item: Vaccination) => (
            <td>{item.date_of_administration && compactDateTimeDisplay(item.date_of_administration)}</td>
          ),
          date_of_next_administration: (item: Vaccination) => (
            <td>{item.date_of_next_administration && compactDateTimeDisplay(item.date_of_next_administration)}</td>
          ),
          product_name: (item: Vaccination) => <td>{item.product?.name}</td>,
          administration_status: (item: Vaccination) => (
            <td>
              <Pill label={item.administration_status} />
            </td>
          ),
          historical: (item: Vaccination) => <td>{item.historical ? 'True' : 'False'}</td>,
          quantity: (item: Vaccination) => <td>{item.quantity && item.quantity}</td>,
          expiration_date: (item: Vaccination) => (
            <td>{item.expiration_date && compactDateDisplay(item.expiration_date)}</td>
          ),
          actions: (item: Vaccination) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditVaccinationClick(item)
                  },
                  statusAction({
                    item,
                    name: 'Vaccination',
                    updater: updateVaccination,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={<IconButton icon={SvgPlus} label="New Vaccination" onClick={handleNewVaccinationClick} />}
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={vaccinations
              .filter((vaccination) => vaccination.status !== 'disabled')
              .map(vaccinationToOption)}
            contextType={
              vaccinations.filter((vaccination) => vaccination.status !== 'disabled').length > 0
                ? 'Vaccination'
                : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />
    </>
  );
};
