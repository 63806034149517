import * as React from 'react';

import { Animal } from 'types/Animal';
import { Customer } from 'types/Customer';
import { Estimate } from 'types/Estimate';

import { compactDateDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import styles from './EstimateDocument.module.scss';

type Props = {
  estimate: Estimate;
  customer: Customer;
  animal: Animal;
};

export const EstimateDocument = ({ estimate, customer, animal }: Props): JSX.Element => {
  return (
    <>
      <h2>{estimate.name}</h2>
      <table className={styles.estimateDocumentTable}>
        <thead>
          <tr>
            <th>Client</th>
            <th>Patient</th>
            <th>Client Phone</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {customer.first_name} {customer.last_name}
            </td>
            <td>{animal.name}</td>
            <td>{customer.phone}</td>
            <td>{estimate.effective_date && compactDateDisplay(estimate.effective_date)}</td>
          </tr>
        </tbody>
      </table>
      <table className={styles.estimateDocumentTable}>
        <thead className={styles.tableHeader}>
          <tr>
            <th style={{ width: '50%' }}>Item</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {estimate.estimate_items?.map((item) => {
            return item.status !== 'disabled' && item.status !== 'rejected' ? (
              <tr key={item.id}>
                <td style={{ width: '50%' }}>
                  <div>{item.product?.name}</div>
                  <div style={{ fontSize: '14px', color: 'gray', paddingRight: '1rem' }}>
                    {item.product?.description}
                  </div>
                </td>
                <td>
                  <span className={item.quantity_low === item.quantity ? 'fw-bold' : ''}>{item.quantity_low}</span>-
                  <span
                    className={
                      item.quantity_low !== item.quantity && item.quantity_high === item.quantity ? 'fw-bold' : ''
                    }
                  >
                    {item.quantity_high}
                  </span>
                </td>
                <td>{item.price && toCurrency(item.price)}</td>
                <td>
                  {item.discount_fixed
                    ? toCurrency(item.discount_fixed)
                    : item.discount_percentage
                    ? `${item.discount_percentage}%`
                    : '$0'}
                </td>
                <td>
                  <span className={item.total_low === item.total ? 'fw-bold' : ''}>
                    {item.total_low && toCurrency(item.total_low)}
                  </span>
                  -
                  <span className={item.total_low !== item.total && item.total_high === item.total ? 'fw-bold' : ''}>
                    {item.total_high && toCurrency(item.total_high)}
                  </span>
                </td>
              </tr>
            ) : null;
          })}

          <tr style={{ borderTop: '1px solid black', height: '30px' }}>
            <td colSpan={3} />
            <td>Subtotal</td>
            <td>{estimate.subtotal && toCurrency(estimate.subtotal)}</td>
          </tr>

          <tr>
            <td colSpan={3} />
            <td>Tax</td>
            <td>{estimate.tax && toCurrency(estimate.tax)}</td>
          </tr>

          <tr>
            <td colSpan={3} />
            <td>Total</td>
            <td className="fw-bold">
              {estimate.total_low && toCurrency(estimate.total_low)}-
              {estimate.total_high && toCurrency(estimate.total_high)}
            </td>
          </tr>
        </tbody>
      </table>
      <div>Owner Signature</div>
      #SIGNATURE#
    </>
  );
};
