import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from 'routes';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createPresentingProblem } from 'api/PresentingProblems';

import { PresentingProblem } from 'types/PresentingProblem';

import Form from './Form';

const PresentingProblemNew = (): JSX.Element => {
  const navigate = useNavigate();
  const [presentingProblem, setPresentingProblem] = useState<PresentingProblem>({
    status: 'active'
  } as PresentingProblem);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setPresentingProblem({
        ...presentingProblem,
        [event.target.id]: 'checked' in event.target ? (event.target.checked ? 'active' : 'disabled') : null
      });
    } else {
      setPresentingProblem({ ...presentingProblem, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => {
    createPresentingProblem(presentingProblem, updateSuccess, errorCallback);
  };

  const updateSuccess = (presentingProblem: PresentingProblem) => {
    navigate(generatePath(paths.presentingProblemDetails, { id: presentingProblem.id }));
  };

  const handleCancel = () => {
    navigate(`/presenting_problems`);
  };

  return (
    <CCard style={{ width: '35rem' }}>
      <CCardHeader>
        <h3>New Presenting Problem</h3>
      </CCardHeader>
      <CCardBody>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          presentingProblem={presentingProblem}
        />
      </CCardBody>
    </CCard>
  );
};

export default PresentingProblemNew;
