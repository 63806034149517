import { DateSelectArg, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin from '@fullcalendar/luxon2';
import FullCalendar from '@fullcalendar/react';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cilChevronLeft, cilChevronRight } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CCollapse, CContainer, CDatePicker, CFormCheck, CFormLabel, CRow } from '@coreui/react-pro';

import { createAppointment, fetchAppointment, rescheduleAppointment } from 'api/Appointments';
import { fetchAppointmentStatuses } from 'api/AppointmentStatuses';
import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { fetchClinic } from 'api/Clinics';
import { fetchEvents } from 'api/Events';
import { fetchPimResources } from 'api/PimResources';
import { fetchRooms } from 'api/Rooms';

import { Appointment } from 'types/Appointment';
import { AppointmentStatus } from 'types/AppointmentStatus';
import { AppointmentType } from 'types/AppointmentType';
import { CalendarEvent } from 'types/CalendarEvent';
import { Clinic } from 'types/Clinic';
import { PimResource } from 'types/PimResource';
import { Room } from 'types/Room';

import { useAuth } from 'hooks/useAuth';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { useCable } from 'components/Cable';
import EmployeePhoto from 'components/EmployeePhoto';

import { AppointmentSidebar } from './AppointmentSidebar';
import { NewAppointmentModal } from './NewAppointmentModal';

type Modals = 'new-appointment';
type Sidebars = 'edit-appointment';
type Actions = 'create-appointment';

const Calendar = (): JSX.Element => {
  type Resource = {
    id: string;
    title: string;
  };

  const [appointmentStatuses, setAppointmentStatuses] = useState<AppointmentStatus[]>([]);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [currentClinic, setCurrentClinic] = useState<Clinic | undefined>(undefined);
  const [editingItem, setEditingItem] = useState<Appointment | undefined>(undefined);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [networkAction, setNetworkAction] = useState<Actions>();
  const [pimResources, setPimResources] = useState<PimResource[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
  const [selectedEnd, setSelectedEnd] = useState(new Date());
  const [selectedResource, setSelectedResource] = useState({ id: '', title: '' });
  const [selectedStart, setSelectedStart] = useState(new Date());
  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [visibleSidebar, setVisibleSidebar] = useState<Sidebars>();

  const [isDatePickerCollapsed, setIsDatePickerCollapsed] = useState(false);

  const calendarRef = React.useRef<FullCalendar>(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const focusedApppointmentId = searchParams.get('focused_appointment_id');
  const dateParam = searchParams.get('date');

  useDocumentTitle('Appointments');

  /* How it works:
   * 1. Fetch appointment types, appointment statuses, and rooms
   * 2. Set active clinic to the clinic context
   * 3. Fetch all the PIM resources for the selected clinic
   * 4. Set the current date to today
   * 5. Fetch all the planning guides for the current date
   * 6. Fetch all the appointments for the current date
   */

  useEffect(() => {
    fetchAppointmentTypes(setAppointmentTypes);
    fetchAppointmentStatuses(setAppointmentStatuses);
  }, []);

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    if (clinicContext) fetchClinic(Number(clinicContext), setCurrentClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (focusedApppointmentId)
      fetchAppointment(focusedApppointmentId, (appointment) => {
        setEditingItem(appointment);
        setVisibleSidebar('edit-appointment');
        searchParams.delete('focused_appointment_id');
        setSearchParams(searchParams);

        if (currentClinic) {
          const date = DateTime.fromISO(appointment.client_start_time, { zone: currentClinic.time_zone });
          setSelectedDay(date.startOf('day').toJSDate().toDateString());
        }
      });
    else if (dateParam) setSelectedDay(dateParam);
  }, [currentClinic, focusedApppointmentId, dateParam, searchParams, setSearchParams]);

  useEffect(() => {
    if (selectedDay && currentClinic) {
      const dtSelectedDay = DateTime.fromFormat(selectedDay, 'EEE MMM dd yyyy', { zone: currentClinic.time_zone });
      const startOfDay = dtSelectedDay.startOf('day').toJSDate();
      const endOfDay = dtSelectedDay.endOf('day').toJSDate();
      fetchEvents(startOfDay, endOfDay, currentClinic.id, (data) => {
        setEvents(data);
      });
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(dtSelectedDay.toJSDate());
      }
    }
  }, [selectedDay, currentClinic]);

  useEffect(() => {
    if (!selectedDay && !dateParam && pimResources.length && currentClinic) {
      const today = DateTime.local({ zone: currentClinic.time_zone }).startOf('day').toJSDate();
      setSelectedDay(today.toDateString());
    }
  }, [selectedDay, pimResources, currentClinic, dateParam]);

  useEffect(() => {
    if (currentClinic) {
      fetchPimResources(currentClinic.id, setPimResources);
      fetchRooms(setRooms);
    }
  }, [currentClinic]);

  const handleResourceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const resourceId = event.target.value;
    const checked = event.target.checked;
    if (checked) {
      const planningResource = pimResources.find((pimResource: PimResource) => {
        return pimResource.id.toString() === resourceId;
      });
      if (planningResource) {
        const newResource = {
          id: planningResource.id.toString(),
          title: planningResource.display_name
        };
        setResources([...resources, newResource]);
      }
    } else {
      setResources(resources.filter((resource: Resource) => resource.id !== resourceId));
    }
  };

  const appointmentToEvent = (appointment: Appointment) => {
    const title = `"${appointment.animal.name}" ${appointment.customer?.last_name}`;
    return {
      id: appointment.id.toString(),
      title: title,
      backgroundColor: appointment.appointment_type.color,
      start: appointment.start_time,
      end: appointment.end_time,
      resourceId: appointment.pim_resource_id.toString(),
      display: 'auto'
    };
  };

  useEffect(() => {
    const resourceIds = new Set();
    events.forEach((event: CalendarEvent) => {
      resourceIds.add(event.resourceId);
    });
    const activeResources = pimResources.filter((pimResource: PimResource) => {
      return resourceIds.has(pimResource.id.toString());
    });
    const calendarResources = activeResources.map((pimResource: PimResource) => {
      return {
        id: pimResource.id.toString(),
        title: pimResource.display_name
      };
    });
    setResources(calendarResources);
  }, [events, pimResources]);

  useEffect(() => {
    if (selectedDay) {
      searchParams.set('date', selectedDay);
      setSearchParams(searchParams);
    }
  }, [searchParams, selectedDay, setSearchParams]);

  const handleEventClick = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    if (!event.id.startsWith('planningGuide')) {
      closeSidebar();
      fetchAppointment(event.id, (appointment) => {
        setEditingItem(appointment);
        setVisibleSidebar('edit-appointment');
      });
    }
  };

  const closeSidebar = () => {
    setEditingItem(undefined);
    setVisibleSidebar(undefined);
  };

  const handleSelect = (event: DateSelectArg) => {
    if (event.resource) {
      setSelectedStart(new Date(event.startStr));
      setSelectedEnd(new Date(event.endStr));
      setSelectedResource({ id: event.resource.id, title: event.resource.title });

      setEditingItem(undefined);
      setVisibleModal('new-appointment');
    }
  };

  const handleCreateError = () => {
    setNetworkAction(undefined);
  };

  const handleCreateSuccess = (appointment: Appointment) => {
    addEvent(appointment);
    setNetworkAction(undefined);
    hideModal();
    toast.success('Appointment created!');
  };

  const isCreatingAppointment = networkAction === 'create-appointment';
  const handleCreateAppointment = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNetworkAction('create-appointment');

    const form = event.currentTarget;
    const formData = new FormData(form);

    const appointment: Partial<Appointment> = {
      start_time: formData.get('start_time')?.toString(),
      end_time: formData.get('end_time')?.toString(),
      appointment_type_id: Number(formData.get('appointment_type_id')),
      status: String(formData.get('appointment_status')),
      pim_resource_id: Number(formData.get('pim_resource_id')),
      animal_id: Number(formData.get('animal_id')),
      notes: String(formData.get('notes')),
      internal_notes: String(formData.get('internal_notes')),
      room_id: formData.get('room_id') ? Number(formData.get('room_id')) : null,
      auto_selected_resource: false
    };

    createAppointment(appointment, { onSuccess: handleCreateSuccess, onError: handleCreateError });
  };

  const addEvent = (appointment: Appointment) => {
    const event = appointmentToEvent(appointment);
    setEvents(events.concat([event]));
  };

  const updateEvent = (appointment: Appointment) => {
    const event = appointmentToEvent(appointment);
    setEvents(events.map((e: CalendarEvent) => (e.id === event.id ? event : e)));
  };

  type ChannelPayload = {
    action: string;
    event_id?: number;
    event?: CalendarEvent;
  };
  const handleReceived = useCallback((payload: object) => {
    const { action, event_id, event } = payload as ChannelPayload;
    switch (action) {
      case 'event_created':
        if (event) {
          setEvents((list) => list.concat([event]));
        }
        break;
      case 'event_updated':
        if (event) {
          setEvents((list) => list.map((e: CalendarEvent) => (e.id === event.id ? event : e)));
        }
        break;
      case 'event_deleted':
        if (event_id) {
          setEvents((list) => list.filter((e: CalendarEvent) => e.id !== event_id.toString()));
        }
        break;
    }
  }, []);

  const auth = useAuth();
  const { cable } = useCable();
  useEffect(() => {
    if (cable && currentClinic) {
      const channel = cable.subscriptions.create(
        { channel: 'PimAppointmentsChannel', clinic_id: currentClinic.id, employee_id: auth.employee?.id },
        {
          received: handleReceived
        }
      );

      return () => {
        channel.unsubscribe();
      };
    }
  }, [auth.employee?.id, cable, currentClinic, handleReceived]);

  const handleChangeEvent = (event: EventChangeArg) => {
    const eventObj = event.event.toPlainObject();
    const resourceId = event.event.getResources()[0].id;

    rescheduleAppointment(eventObj.id, eventObj.start, eventObj.end, Number(resourceId), updateEvent, event.revert);
  };

  const hideModal = () => {
    setVisibleModal(undefined);
  };

  const newAppointmentFormData =
    currentClinic && [rooms, appointmentTypes, appointmentStatuses].every((selectData) => selectData.length > 0);

  // Wait for CCollapse to timeout (300ms) before resizing calendar
  const resizeCalendar = () => {
    setTimeout(() => {
      const calendar = calendarRef.current;
      if (calendar) {
        calendar.getApi().updateSize();
      }
    }, 500);
  };

  const applyStyles = (event: CalendarEvent) => {
    return {
      ...event,
      borderColor: editingItem && event.id === editingItem.id.toString() ? '#f00' : '#000000'
    };
  };

  return (
    <>
      {visibleModal === 'new-appointment' && newAppointmentFormData && (
        <NewAppointmentModal
          hideModal={hideModal}
          loading={isCreatingAppointment}
          onSubmit={handleCreateAppointment}
          selectedStart={selectedStart}
          selectedEnd={selectedEnd}
          clinic={currentClinic}
          selectedResource={selectedResource}
          appointmentTypes={appointmentTypes}
          appointmentStatuses={appointmentStatuses}
          rooms={rooms}
        />
      )}

      {editingItem && rooms && (
        <AppointmentSidebar
          appointment={editingItem}
          onClose={closeSidebar}
          isVisible={visibleSidebar === 'edit-appointment'}
          rooms={rooms}
        />
      )}

      <CContainer className="mw-100">
        <CRow>
          <CCol xs="auto" className="d-flex flex-column">
            <CButton
              className="mb-2 align-self-end"
              color="link"
              onClick={() => setIsDatePickerCollapsed(!isDatePickerCollapsed)}
            >
              <CIcon
                icon={isDatePickerCollapsed ? cilChevronRight : cilChevronLeft}
                title={isDatePickerCollapsed ? 'Show date picker' : 'Hide date picker'}
              />
            </CButton>

            <CCollapse horizontal visible={!isDatePickerCollapsed} onShow={resizeCalendar} onHide={resizeCalendar}>
              <CButton
                className="mb-3 align-self-start"
                color="primary"
                onClick={() => {
                  navigate('/appointments/new');
                }}
              >
                New Appointment
              </CButton>

              {currentClinic ? (
                <CDatePicker
                  date={selectedDay}
                  locale="en-US"
                  visible
                  closeOnSelect={false}
                  cleaner={false}
                  container={'inline'}
                  firstDayOfWeek={0}
                  inputReadOnly
                  onDateChange={(date: Date | null) => {
                    if (date) {
                      setSelectedDay(date.toDateString());
                    }
                  }}
                  onHide={() => {
                    return false;
                  }}
                  size={'sm'}
                  weekdayFormat={'short'}
                />
              ) : null}
              {pimResources.map((pimResource: PimResource) => {
                return (
                  <div key={pimResource.employee_id} className="mb-3 d-flex align-items-center">
                    <CFormCheck
                      id={pimResource.id.toString()}
                      style={{ marginTop: 0 }} // className="mt-0" doesn't work
                      name={pimResource.display_name}
                      value={pimResource.id}
                      onChange={handleResourceChange}
                      checked={resources.map((r) => r.id).includes(pimResource.id.toString())}
                    />
                    <CFormLabel className="mb-0 d-flex align-items-center" htmlFor={pimResource.id.toString()}>
                      <span className="mx-2">
                        <EmployeePhoto employee={pimResource.employee} />
                      </span>
                      <span className="mb-0">{pimResource.display_name}</span>
                    </CFormLabel>
                  </div>
                );
              })}
            </CCollapse>
          </CCol>
          <CCol>
            <FullCalendar
              schedulerLicenseKey="0956875066-fcs-1680552874"
              plugins={[interactionPlugin, resourceTimeGridPlugin, resourceDayGridPlugin, luxon2Plugin]}
              ref={calendarRef}
              initialDate={selectedDay}
              customButtons={{
                huddle: {
                  text: 'Huddle',
                  click: () => {
                    if (selectedDay) navigate(`/huddle?clinic=${currentClinic?.id}&date=${selectedDay}`);
                  }
                },
                customPrev: {
                  text: 'Previous day',
                  icon: 'chevron-left',
                  click: () => {
                    if (calendarRef.current) {
                      calendarRef.current.getApi().prev();
                      setSelectedDay(calendarRef.current.getApi().getDate().toDateString());
                    }
                  }
                },
                customNext: {
                  text: 'Next day',
                  icon: 'chevron-right',
                  click: () => {
                    if (calendarRef.current) {
                      calendarRef.current.getApi().next();
                      setSelectedDay(calendarRef.current.getApi().getDate().toDateString());
                    }
                  }
                },
                customToday: {
                  text: 'Today',
                  click: () => {
                    if (calendarRef.current) {
                      calendarRef.current.getApi().today();
                      setSelectedDay(calendarRef.current.getApi().getDate().toDateString());
                    }
                  }
                }
              }}
              headerToolbar={{ start: 'customPrev,customNext customToday', center: 'title', end: 'huddle' }}
              buttonText={{}}
              initialView="resourceTimeGridDay"
              titleFormat={{ month: 'long', year: 'numeric', day: 'numeric', weekday: 'long' }}
              nowIndicator={true}
              editable={true}
              selectable={true}
              eventChange={handleChangeEvent}
              navLinks={true}
              events={events.map(applyStyles)}
              eventTextColor="#000000"
              resources={resources}
              timeZone={currentClinic?.time_zone}
              allDaySlot={false}
              height={'auto'}
              slotDuration={'00:15:00'}
              slotMinTime={'07:30:00'}
              slotMaxTime={'18:30:00'}
              businessHours={{
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                startTime: '08:30',
                endTime: '17:30'
              }}
              eventClick={handleEventClick}
              select={handleSelect}
            />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Calendar;
