import axios from 'axios';

import { EmployeeTask } from 'types/EmployeeTask';
import { Pagination } from 'types/Pagination';

type EmployeeTasksFilterParams = {
  animal_id?: string;
  clinic_id?: string;
  consult_id?: string;
  employee_id?: string;
  type?: string;
  status?: string;
  due_date_start?: string;
  due_date_end?: string;
};

type EmployeeTaskParams = {
  employee_task: Partial<EmployeeTask>;
};

function createEmployeeTask(
  params: EmployeeTaskParams,
  { onSuccess, onError }: { onSuccess: (task: EmployeeTask) => void; onError?: (error: string) => void }
) {
  const url = `/pim/employee_tasks`;
  axios
    .post<EmployeeTask>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

const fetchEmployeeTasks = ({
  onSuccess,
  setPagination,
  page,
  filterParams
}: {
  onSuccess: (tasks: EmployeeTask[]) => void;
  setPagination: (pagination: Pagination) => void;
  page?: number;
  filterParams?: EmployeeTasksFilterParams;
}) => {
  const url = `/pim/employee_tasks`;

  axios
    .get<EmployeeTask[]>(url, {
      params: {
        ...filterParams,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
};

function updateEmployeeTask(
  taskId: number,
  params: EmployeeTaskParams,
  { onSuccess, onError }: { onSuccess: (task: EmployeeTask) => void; onError?: (error: string) => void }
) {
  const url = `/pim/employee_tasks/${taskId}`;
  return axios
    .put<EmployeeTask>(url, params)
    .then((data) => {
      onSuccess(data.data.record);
    })
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

export { createEmployeeTask, fetchEmployeeTasks, updateEmployeeTask };
