import axios from 'axios';

import { Customer, CustomerListItem } from 'types/Customer';
import { HomeAddress } from 'types/HomeAddress';

function fetchCustomers(queryText: string, onSuccess: (customers: CustomerListItem[]) => void) {
  const url = '/pim/customers';
  axios
    .get<CustomerListItem[]>(url, {
      params: {
        query: queryText
      }
    })
    .then((data) => onSuccess(data.data.record));
}

const fetchCustomersByQuery = (query: string, { members = false }) => {
  const url = `/pim/customers/`;

  const params = members ? { query: query, members: members } : { query: query };

  return axios
    .get<Customer[]>(url, { params: params })
    .then((response) => response.data.record)
    .catch((error) => []);
};

const fetchCustomer = (customerId: string | number, onSuccess: (customer: Customer) => void) => {
  const url = `/pim/customers/${customerId}`;
  axios.get<Customer>(url).then((data) => onSuccess(data.data.record));
};

const updateCustomer = (
  customerId: string | number,
  params: Partial<Customer> & { shipping_address_attributes?: HomeAddress },
  { onSuccess, onError }: { onSuccess: (customer: Customer) => void; onError: (error: string) => void }
) => {
  const url = `/pim/customers/${customerId}`;
  axios
    .put<Customer>(url, { customer: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.message));
};

const triggerResetPasswordEmail = (customerId: string, onSuccess: () => void) => {
  const url = `/pim/customers/${customerId}/reset_password`;
  axios.get(url).then(() => onSuccess());
};

type BecomeCustomerResponse = { become_customer_token: string };
const createLoginToken = (customerId: string, onSuccess: (token: string) => void) => {
  const url = `/pim/customers/${customerId}/become_customer_token`;
  axios.post<BecomeCustomerResponse>(url, {}).then((data) => onSuccess(data.data.record.become_customer_token));
};

const resyncCustomer = (customerId: string, onSuccess: (customer: Customer, message: string) => void) => {
  const url = `/pim/customers/${customerId}/resync`;
  axios.put<Customer>(url, {}).then((data) => onSuccess(data.data.record, data.data.message || ''));
};

export {
  fetchCustomers,
  fetchCustomersByQuery,
  fetchCustomer,
  updateCustomer,
  triggerResetPasswordEmail,
  createLoginToken,
  resyncCustomer
};
