import cn from 'classnames';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { CCol, CFormCheck, CFormInput, CRow } from '@coreui/react-pro';

import { DiagnosticResultItem } from 'types/DiagnosticResultItem';

import SvgMinusPlain from 'assets/images/SvgMinusPlain';

import { IconButton } from 'components/IconButton';

import styles from './DiagnosticResultItemForm.module.scss';

type Props = {
  index: number;
  resultItem?: DiagnosticResultItem;
};

export const DiagnosticResultItemForm = ({ index, resultItem }: Props) => {
  const [itemName, setItemName] = useState(resultItem?.name ?? '');
  const [unit, setUnit] = useState(resultItem?.unit ?? '');
  const [itemValue, setItemValue] = useState(resultItem?.value ?? '');
  const [qualifier, setQualifier] = useState(resultItem?.qualifier ?? '');
  const [rangeLow, setRangeLow] = useState(resultItem?.range_low ?? '');
  const [rangeHigh, setRangeHigh] = useState(resultItem?.range_high ?? '');
  const [isDestroying, setIsDestroying] = useState(false);

  const nestedName = (name: string) => `diagnostic_result_items_attributes[${index}][${name}]`;

  const styleForValue = useMemo(() => {
    const isLower = rangeLow && Number(itemValue) < Number(rangeLow);
    const isHigher = rangeHigh && Number(itemValue) > Number(rangeHigh);
    return isLower || isHigher ? 'bg-danger' : '';
  }, [rangeLow, rangeHigh, itemValue]);

  return (
    <CRow className={cn('mb-2', 'table-danger', { 'd-none': isDestroying })}>
      <CFormInput hidden id="id" name={nestedName('id')} value={resultItem?.id} />
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`name-${index}`}
          label="Name"
          name={nestedName('name')}
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
      </CCol>
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`value-${index}`}
          label="Value"
          name={nestedName('value')}
          value={itemValue}
          onChange={(e) => setItemValue(e.target.value)}
        />
      </CCol>
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`unit-${index}`}
          label="Unit"
          name={nestedName('unit')}
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
        />
      </CCol>
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`qualifier-${index}`}
          name={nestedName('qualifier')}
          label="Qualifier"
          value={qualifier}
          onChange={(e) => setQualifier(e.target.value)}
        />
      </CCol>
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`range_low-${index}`}
          label="Range Low"
          name={nestedName('range_low')}
          value={rangeLow}
          onChange={(e) => setRangeLow(e.target.value)}
        />
      </CCol>
      <CCol className={cn('pb-1', styleForValue)}>
        <CFormInput
          id={`range_high-${index}`}
          label="Range High"
          name={nestedName('range_high')}
          value={rangeHigh}
          onChange={(e) => setRangeHigh(e.target.value)}
        />
      </CCol>

      <CCol lg={1} className="d-flex flex-column align-items-center justify-content-end w-auto">
        <CFormCheck hidden readOnly id={`destroy-${index}`} name={nestedName('_destroy')} checked={isDestroying} />

        <IconButton
          onClick={() => setIsDestroying(true)}
          icon={SvgMinusPlain}
          title="Remove item"
          className={styles.button}
        />
      </CCol>
    </CRow>
  );
};
