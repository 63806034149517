import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { CNav, CNavItem, CNavLink } from '@coreui/react-pro';

import {
  createAnimalMasterProblem,
  fetchAnimalMasterProblemsByAnimal,
  updateAnimalMasterProblem
} from 'api/AnimalMasterProblems';

import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { Consult } from 'types/Consult';

import { usePoll } from 'hooks/usePoll';

import SvgPlus from 'assets/images/SvgPlus';

import { IconButton } from 'components/IconButton';

import { EditAnimalMasterProblemModal } from './EditAnimalMasterProblemModal';
import { MasterProblemItem } from './MasterProblemItem';
import { NewAnimalMasterProblemModal } from './NewAnimalMasterProblemModal';

type Modals = 'new-animal-master-problem' | 'edit-animal-master-problem';

const statuses = ['active', 'disabled'] as const;
type Status = (typeof statuses)[number];

type Props = {
  consult: Consult;
};

export const MasterProblemsList = ({ consult }: Props) => {
  const [animalMasterProblems, setAnimalMasterProblems] = useState<AnimalMasterProblem[]>([]);

  const [statusFilter, setStatusFilter] = useState<Status>(statuses[0]);
  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [editingItem, setEditingItem] = useState<AnimalMasterProblem>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    () => fetchAnimalMasterProblemsByAnimal(consult.animal_id, setAnimalMasterProblems),
    [consult]
  );

  usePoll(fetch);

  const hideModal = () => {
    setVisibleModal(undefined);
    setEditingItem(undefined);
  };

  const handleSuccess = (message: string) => {
    fetch();

    toast.success(message);
    hideModal();
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleFlagClick = (animalMasterProblem: AnimalMasterProblem) => {
    updateAnimalMasterProblem(
      animalMasterProblem.id,
      { important: !animalMasterProblem.important },
      {
        onSuccess: (updated: AnimalMasterProblem) =>
          handleSuccess(updated.important ? 'Master problem flagged!' : 'Master problem unflagged!'),
        onError: handleError
      }
    );
  };

  const handleCreateAnimalMasterProblem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      consult_id: consult.id,
      started_on: formData.get('started-date')?.toString(),
      important: formData.get('important') === 'true' ? true : false,
      ...Object.fromEntries(formData.entries())
    };

    createAnimalMasterProblem(formJson, {
      onSuccess: () => handleSuccess('Master problem created!'),
      onError: handleError
    });
  };

  const handleUpdateAnimalMasterProblem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      started_on: formData.get('started-date')?.toString(),
      ...Object.fromEntries(formData.entries())
    };

    updateAnimalMasterProblem(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Master problem updated!'),
      onError: handleError
    });
  };

  const filterByStatus = (items: AnimalMasterProblem[]) => {
    return items.filter((item) => item.status === statusFilter);
  };

  const renderModal = (visibleModal?: Modals) => {
    switch (visibleModal) {
      case 'new-animal-master-problem':
        return (
          <NewAnimalMasterProblemModal
            hideModal={hideModal}
            loading={loading}
            animalMasterProblems={animalMasterProblems}
            onSubmit={handleCreateAnimalMasterProblem}
          />
        );
      case 'edit-animal-master-problem':
        if (editingItem) {
          return (
            <EditAnimalMasterProblemModal
              animalMasterProblem={editingItem}
              hideModal={hideModal}
              loading={loading}
              onSubmit={handleUpdateAnimalMasterProblem}
              animalMasterProblems={animalMasterProblems}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {renderModal(visibleModal)}

      <div className="d-flex align-items-center justify-content-between mb-3">
        <CNav role="list" variant="underline" className="flex-nowrap">
          {statuses.map((status) => (
            <CNavItem key={status}>
              <CNavLink
                className="text-capitalize"
                active={status === statusFilter}
                role="button"
                onClick={() => setStatusFilter(status)}
              >
                {status}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>

        <IconButton icon={SvgPlus} onClick={() => setVisibleModal('new-animal-master-problem')} label="New" />
      </div>

      {filterByStatus(animalMasterProblems).map((item) => (
        <MasterProblemItem
          key={item.id}
          item={item}
          handleFlagClick={() => handleFlagClick(item)}
          handleStatusSuccess={handleSuccess}
          handleStatusError={handleError}
          handleEditClick={() => {
            setEditingItem(item);
            setVisibleModal('edit-animal-master-problem');
          }}
        />
      ))}
    </>
  );
};
