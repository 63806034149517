import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { paths } from 'routes';

import { CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react-pro';

import { fetchPresentingProblem, updatePresentingProblem } from 'api/PresentingProblems';

import { PresentingProblem } from 'types/PresentingProblem';

import Form from './Form';

const Edit = (): JSX.Element => {
  type EditParams = {
    id: string;
  };
  const { id } = useParams<keyof EditParams>() as EditParams;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [presentingProblem, setPresentingProblem] = useState<PresentingProblem>({} as PresentingProblem);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setPresentingProblem({
        ...presentingProblem,
        [event.target.id]: 'checked' in event.target ? (event.target.checked ? 'active' : 'disabled') : null
      });
    } else {
      setPresentingProblem({ ...presentingProblem, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => {
    event.preventDefault();
    updatePresentingProblem(id, presentingProblem, updateSuccess, errorCallback);
  };

  function handlePresentingProblemLoaded(presentingProblem: PresentingProblem) {
    setPresentingProblem(presentingProblem);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPresentingProblem(id, handlePresentingProblemLoaded);
  }, [id]);

  const updateSuccess = (presentingProblem: PresentingProblem) => {
    navigate(generatePath(paths.presentingProblemDetails, { id: presentingProblem.id }));
  };

  const handleCancel = () => {
    navigate(generatePath(paths.presentingProblemDetails, { id: presentingProblem.id }));
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <CCard style={{ width: '35rem' }}>
        <CCardHeader>
          <h3>Edit Master Problem</h3>
        </CCardHeader>
        <CCardBody>
          <Form
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleChange={handleChange}
            presentingProblem={presentingProblem}
          />
        </CCardBody>
      </CCard>
    );
  }

  return isLoading ? loadingState() : loadedState();
};

export default Edit;
