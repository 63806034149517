import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPrint = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.64 6.417h8.709V4.583c0-1.833-.687-2.75-2.75-2.75H9.391c-2.063 0-2.75.917-2.75 2.75zM14.662 13.75v3.667q0 2.75-2.75 2.75h-1.834q-2.75 0-2.75-2.75V13.75z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M19.25 9.167v4.583q0 2.75-2.75 2.75h-1.833v-2.75H7.333v2.75H5.5q-2.75 0-2.75-2.75V9.167q0-2.75 2.75-2.75h11q2.75 0 2.75 2.75M15.589 13.75H6.422M6.422 10.083h2.75"
    />
  </svg>
);
export default SvgPrint;
