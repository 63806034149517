import { addMinutes, differenceInMinutes } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchAnimal, fetchAnimalsByQuery } from 'api/Animals';
import { fetchCustomer, fetchCustomersByQuery } from 'api/Customers';

import { Animal } from 'types/Animal';
import { AppointmentStatus } from 'types/AppointmentStatus';
import { AppointmentType } from 'types/AppointmentType';
import { Clinic } from 'types/Clinic';
import { Customer } from 'types/Customer';
import { Room } from 'types/Room';

import { getPaddedTime } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import {
  animalToOption,
  appointmentStatusToOption,
  appointmentTypeToOption,
  customerToOption,
  toOption
} from 'utils/selectOptions';

type Option = {
  value?: string;
  label?: string;
};

type Props = {
  appointmentStatuses: AppointmentStatus[];
  appointmentTypes: AppointmentType[];
  clinic: Clinic;
  hideModal: () => void;
  loading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  rooms: Room[];
  selectedEnd: Date;
  selectedResource: { id: string; title: string };
  selectedStart: Date;
};

const MIN_QUERY_LENGTH = 3;

export const NewAppointmentForm = ({
  appointmentStatuses,
  appointmentTypes,
  clinic,
  hideModal,
  loading,
  onSubmit,
  rooms,
  selectedEnd,
  selectedResource,
  selectedStart
}: Props) => {
  const clinicTimezone = clinic.time_zone;

  const [appointmentTypeOptions] = useState<Option[]>([
    { label: 'Select an appointment type', value: '' },
    ...appointmentTypes.map(appointmentTypeToOption)
  ]);
  const [selectedAppointmentTypeOption, setSelectedAppointmentTypeOption] = useState<Option | null>();

  const initialAppointmentStatus = appointmentStatusToOption(
    appointmentStatuses.find((appointmentStatus) => appointmentStatus.name === 'Unconfirmed') || appointmentStatuses[0]
  );
  const [appointmentStatusOptions] = useState<string[]>(appointmentStatuses.map(appointmentStatusToOption));
  const [selectedAppointmentStatusOption, setSelectedAppointmentStatusOption] = useState<string | null>(
    initialAppointmentStatus
  );

  const initialStartDate = utcToZonedTime(selectedStart, clinicTimezone);
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);

  const initialStartTime = getPaddedTime(initialStartDate);

  const [startTime, setStartTime] = useState<string>(initialStartTime);

  const initialEndDate = utcToZonedTime(selectedEnd, clinicTimezone);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const initialEndTime = getPaddedTime(initialEndDate);
  const [endTime, setEndTime] = useState<string>(initialEndTime);

  const [selectedAnimalOption, setSelectedAnimalOption] = useState<Option | null>(null);
  const [animalOptions, setAnimalOptions] = useState<Option[]>([]);

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<Option | null>(null);
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const [selectedRoomOption, setSelectedRoomOption] = useState<Option | null | undefined>(null);
  const [roomOptions] = useState<Option[]>([{ label: 'Select a room', value: '' }, ...rooms.map(toOption)]);

  const [notes, setNotes] = useState('');
  const [internalNotes, setInternalNotes] = useState('');

  const [isStandardDuration, setIsStandardDuration] = useState<boolean>(true);

  useEffect(() => {
    let newIsStandardDuration = true;
    if (selectedAppointmentTypeOption && startDate && endDate) {
      const appointmentTypeDuration = appointmentTypes.find(
        (appointmentType) => String(appointmentType.id) === selectedAppointmentTypeOption.value
      )?.duration;

      const selectedDuration = differenceInMinutes(endDate, startDate);

      if (appointmentTypeDuration && selectedDuration !== appointmentTypeDuration) {
        newIsStandardDuration = false;
      }
    }

    setIsStandardDuration(newIsStandardDuration);
  }, [endDate, startDate, endTime, startTime, selectedAppointmentTypeOption, appointmentTypes]);

  const handleAnimalSelectChange = (option: Option | null) => {
    setSelectedAnimalOption(option);

    if (option?.value) {
      fetchAnimal(option.value, (animal: Animal) => {
        if (animal?.customer) {
          const customerOption = customerToOption(animal.customer);
          setSelectedCustomerOption(customerOption);
          setCustomerOptions([customerOption]);
          setSelectedCustomer(animal.customer);
          fetchCustomer(animal.customer.id, setSelectedCustomer);
        }
      });
    } else {
      setSelectedCustomerOption(null);
      setSelectedCustomer(null);
    }
  };

  const handleCustomerSelectChange = (option: Option | null) => {
    setSelectedCustomerOption(option);

    if (option?.value) {
      fetchCustomer(option.value, (customer: Customer) => {
        setSelectedCustomer(customer);
        if (customer?.animals) {
          const options = customer.animals.map(animalToOption);
          setAnimalOptions(options);
          setSelectedAnimalOption(options[0]);
        }
      });
    } else {
      setSelectedAnimalOption(null);
      setSelectedCustomer(null);
    }
  };

  const handleAppointmentTypeSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = appointmentTypeOptions.find((option: Option) => option.value === event.target.value);

    if (selected) {
      setSelectedAppointmentTypeOption(selected);
      offsetEndTime(startDate, selected);
    }
  };

  const handleAppointmentStatusSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedAppointmentStatusOption(selected);
  };

  const handleRoomSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = roomOptions.find((option: Option) => option.value === event.target.value);
    setSelectedRoomOption(selected);
  };

  const handleStartDateChange = (start: Date | null) => {
    if (!start) return;
    const [hours, minutes] = startTime.split(':');

    start.setHours(Number(hours));
    start.setMinutes(Number(minutes));

    setStartDate(start);
    offsetEndTime(start, selectedAppointmentTypeOption);
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedStartTime = event.target.value;

    if (changedStartTime) {
      setStartTime(changedStartTime);
      const [hours, minutes] = changedStartTime.split(':');

      const start = startDate;

      start?.setHours(Number(hours));
      start?.setMinutes(Number(minutes));

      offsetEndTime(start, selectedAppointmentTypeOption);
    }
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedEndTime = event.target.value;

    if (changedEndTime) {
      const [hours, minutes] = changedEndTime.split(':');

      const end = endDate;

      end?.setHours(Number(hours));
      end?.setMinutes(Number(minutes));

      setEndDate(end);

      setEndTime(`${hours}:${minutes}`);
    }
  };

  const offsetEndTime = (start: Date | null, appointmentTypeOption: Option | null | undefined) => {
    if (start && appointmentTypeOption) {
      const appointmentTypeDuration = appointmentTypes.find(
        (appointmentType) => String(appointmentType.id) === appointmentTypeOption.value
      )?.duration;

      if (appointmentTypeDuration) {
        const updatedEndDate = addMinutes(start, appointmentTypeDuration);
        const updatedEndTime = getPaddedTime(updatedEndDate);

        setEndDate(updatedEndDate);
        setEndTime(updatedEndTime);
      }
    }
  };

  const loadAnimalOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchAnimalsByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(animalToOption));
    });
  };

  const loadCustomerOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchCustomersByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(customerToOption));
    });
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const zonedToUtc = (date: Date | null) => {
    if (date) {
      return zonedTimeToUtc(date, clinicTimezone).toISOString();
    }
  };

  return (
    <CForm className="g-3" onSubmit={onSubmit}>
      <CRow className="mb-3">
        <CCol sm>
          <label htmlFor="appointment_type_id" className="form-label">
            Appointment Type
          </label>
          <select
            className="form-select"
            id="appointment_type_id"
            name="appointment_type_id"
            onChange={handleAppointmentTypeSelectChange}
            required
            value={selectedAppointmentTypeOption?.value}
          >
            {appointmentTypeOptions.map((option: Option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="form-text">Required</div>
        </CCol>

        <CCol sm>
          <CFormSelect
            id="appointment_status"
            label="Appointment Status"
            name="appointment_status"
            options={appointmentStatusOptions}
            value={selectedAppointmentStatusOption ?? ''}
            onChange={handleAppointmentStatusSelectChange}
            required
          />
          <div className="form-text">Required</div>
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol sm>
          <CFormInput hidden name="start_time" value={zonedToUtc(startDate)} />
          <CDatePicker
            label="Appointment Date"
            date={startDate}
            locale="en-US"
            firstDayOfWeek={0}
            format="MMM dd, yyyy"
            onDateChange={handleStartDateChange}
            cleaner={false}
          />
          <div className="form-text">Required</div>
        </CCol>
        <CCol sm>
          <CFormInput label="Start Time" type="time" onChange={handleStartTimeChange} value={startTime} />
          <div className="form-text">Required</div>
        </CCol>
        <CCol sm>
          <CFormInput hidden name="end_time" value={zonedToUtc(endDate)} />
          <CFormInput
            label={
              <>
                End Time
                {!isStandardDuration && <CIcon className="ms-2 text-warning" icon={cilWarning} aria-label="warning" />}
              </>
            }
            type="time"
            onChange={handleEndTimeChange}
            value={endTime}
          />
          <div className="form-text">
            {isStandardDuration ? (
              'Required'
            ) : (
              <span className="text-warning">Warning! End time doesn&apos;t match appointment type</span>
            )}
          </div>
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol sm>
          <CFormInput hidden name="pim_resource_id" value={selectedResource.id} />
          <CFormInput
            disabled
            label="Resource"
            id="pim_resource"
            name="pim_resource"
            value={selectedResource.title}
            readOnly
          />
        </CCol>

        <CCol sm>
          <label htmlFor="room_id" className="form-label">
            Room
          </label>
          <select
            className="form-select"
            id="room_id"
            name="room_id"
            onChange={handleRoomSelectChange}
            value={selectedRoomOption?.value}
          >
            {roomOptions.map((option: Option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol sm>
          <CFormInput hidden id="animal_id" name="animal_id" value={selectedAnimalOption?.value ?? ''} />
          <label htmlFor="animal" className="form-label">
            Patient
          </label>
          <AsyncSelect<Option>
            id="animal"
            aria-label="Patient"
            placeholder="Type to search..."
            styles={reactSelectStyles}
            value={selectedAnimalOption}
            onChange={handleAnimalSelectChange}
            loadingMessage={loadingMessage}
            defaultOptions={animalOptions}
            loadOptions={loadAnimalOptions}
            isClearable
            isSearchable
            required
          />
          <div className="form-text">Required</div>
        </CCol>
        <CCol sm>
          <label htmlFor="client" className="form-label">
            Client
          </label>
          {selectedCustomer?.has_unpaid_invoices ? (
            <CBadge className="ms-2" color="danger">
              Unpaid Invoices
            </CBadge>
          ) : null}
          {selectedCustomer?.membership ? (
            <CBadge className="ms-2" color="brand">
              {selectedCustomer.membership.plan.name}
            </CBadge>
          ) : null}
          <AsyncSelect<Option>
            id="client"
            aria-label="Client"
            placeholder="Type to search..."
            styles={reactSelectStyles}
            value={selectedCustomerOption}
            onChange={handleCustomerSelectChange}
            loadingMessage={loadingMessage}
            defaultOptions={customerOptions}
            loadOptions={loadCustomerOptions}
            isClearable
            isSearchable
          />
        </CCol>
      </CRow>

      <div className="mb-3">
        <CFormTextarea
          id="notes"
          label="Notes"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          placeholder="Notes..."
          name="notes"
          rows={1}
        />
      </div>

      <div className="mb-3">
        <CFormTextarea
          id="internal_notes"
          label="Internal Notes"
          value={internalNotes}
          onChange={(event) => setInternalNotes(event.target.value)}
          placeholder="Internal Notes will never be shared with the customer"
          name="internal_notes"
          rows={1}
        />
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" type="submit">
          Create
        </CLoadingButton>
        <CButton color="secondary" type="button" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
