import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import { CButton, CCol, CForm, CFormInput, CModal, CModalBody, CModalHeader, CRow, CSpinner } from '@coreui/react-pro';

import { fetchClinics } from 'api/Clinics';
import { updateCustomer } from 'api/Customers';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Customer } from 'types/Customer';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { employeeToOption, toOption } from 'utils/selectOptions';

import SvgCall from 'assets/images/SvgCall';
import SvgHospital from 'assets/images/SvgHospital';
import SvgLocation from 'assets/images/SvgLocation';
import SvgSms from 'assets/images/SvgMail';
import SvgPerson from 'assets/images/SvgPerson';
import SvgStethoscope from 'assets/images/SvgStethoscope';

import { IconLabel } from 'components/IconLabel';

type EditCustomerProps = {
  customer: Customer;
  setCustomer: (customer: Customer) => void;
  onSuccess: (customer: Customer) => void;
  onCancel: () => void;
};

const MIN_QUERY_LENGTH = 3;

const EditCustomerModal = ({ customer, setCustomer, onSuccess, onCancel }: EditCustomerProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedPreferredEmployeeOption, setSelectedPreferredEmployeeOption] = useState<Option | null>(null);
  const [selectedPreferredClinicOption, setSelectedPreferredClinicOption] = useState<Option | null>(null);
  const [clinicOptions, setClinicOptions] = useState<Option[]>([]);

  useEffect(() => {
    fetchClinics((clinics) => setClinicOptions(clinics.map(toOption)));
  }, []);

  useEffect(() => {
    if (customer.preferred_employee) {
      setSelectedPreferredEmployeeOption(employeeToOption(customer.preferred_employee));
    }
    if (customer.clinic) {
      setSelectedPreferredClinicOption(toOption(customer.clinic));
    }
  }, [customer.preferred_employee, customer.clinic]);

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue, { vets_only: true }).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox' && 'checked' in event.target) {
      setCustomer({ ...customer, [event.target.id]: event.target.checked });
    } else {
      const address = customer.shipping_address || { street1: '', city: '', state: '', zip_code: '' };
      if (event.target.id === 'street1')
        setCustomer({ ...customer, shipping_address: { ...address, street1: event.target.value } });
      else if (event.target.id === 'street2')
        setCustomer({ ...customer, shipping_address: { ...address, street2: event.target.value } });
      else if (event.target.id === 'city')
        setCustomer({ ...customer, shipping_address: { ...address, city: event.target.value } });
      else if (event.target.id === 'state')
        setCustomer({ ...customer, shipping_address: { ...address, state: event.target.value } });
      else if (event.target.id === 'zip_code')
        setCustomer({ ...customer, shipping_address: { ...address, zip_code: event.target.value } });
      else setCustomer({ ...customer, [event.target.id]: event.target.value });
    }
  };

  const handlePreferredEmployeeSelectChange = (option: Option | null) => {
    const newPreferredEmployeeId = option?.value ?? '';
    setCustomer({ ...customer, preferred_employee_id: newPreferredEmployeeId });
    setSelectedPreferredEmployeeOption(option);
  };

  const handlePreferredClinicSelectChange = (option: Option | null) => {
    const newPreferredClinicId = option?.value ?? '';
    setCustomer({ ...customer, clinic_id: Number(newPreferredClinicId) });
    setSelectedPreferredClinicOption(option);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    updateCustomer(
      customer.id,
      { ...customer, shipping_address_attributes: customer.shipping_address },
      {
        onSuccess: updateSuccess,
        onError: updateError
      }
    );
  };

  const updateSuccess = (customer: Customer) => {
    setIsSubmitting(false);
    onSuccess(customer);
  };

  const updateError = (error: string) => {
    setIsSubmitting(false);
    toast.error(error);
  };

  const handleCancel = () => {
    setIsSubmitting(false);
    onCancel();
  };

  return (
    <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={onCancel}>
      <CModalHeader>
        <h3 className="mb-0">Edit Customer</h3>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CRow className="mb-3">
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="first_name"
                name="first_name"
                label={<IconLabel icon={SvgPerson} label="First Name" />}
                value={customer.first_name || ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="last_name"
                name="last_name"
                label={<IconLabel icon={SvgPerson} label="Last Name" />}
                value={customer.last_name || ''}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol xs={12}>
              <CFormInput
                type="text"
                id="email"
                name="email"
                label={<IconLabel icon={SvgSms} label="Email" />}
                value={customer.email || ''}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol xs={12}>
              <CFormInput
                id="phone"
                required
                label={<IconLabel icon={SvgCall} label="Phone" />}
                value={customer.phone || ''}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="street1"
                name="street1"
                label={<IconLabel icon={SvgLocation} label="Shipping Address" />}
                value={customer.shipping_address?.street1 || ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="street2"
                name="street2"
                label={<IconLabel icon={SvgLocation} label="Address Line 2" />}
                value={customer.shipping_address?.street2 || ''}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol xs={4}>
              <CFormInput
                type="text"
                id="city"
                name="city"
                label={<IconLabel icon={SvgLocation} label="City" />}
                value={customer.shipping_address?.city || ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs={4}>
              <CFormInput
                type="text"
                id="state"
                name="state"
                label={<IconLabel icon={SvgLocation} label="State" />}
                value={customer.shipping_address?.state || ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs={4}>
              <CFormInput
                type="text"
                id="zip_code"
                name="zip_code"
                label={<IconLabel icon={SvgLocation} label="Zip Code" />}
                value={customer.shipping_address?.zip_code || ''}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol xs={6}>
              <IconLabel icon={SvgHospital} label="Preferred Clinic" />
              <Select<Option>
                id="preferred_clinic_id"
                placeholder="Type to search..."
                aria-label="Preferred Clinic"
                className="mt-2"
                value={selectedPreferredClinicOption}
                onChange={handlePreferredClinicSelectChange}
                loadingMessage={loadingMessage}
                options={clinicOptions}
                styles={reactSelectStyles}
                isClearable
              />
            </CCol>
            <CCol xs={6}>
              <IconLabel icon={SvgStethoscope} label="Preferred Vet" />
              <AsyncSelect<Option>
                id="preferred_employee_id"
                placeholder="Type to search..."
                aria-label="Preferred Vet"
                className="mt-2"
                value={selectedPreferredEmployeeOption}
                onChange={handlePreferredEmployeeSelectChange}
                loadingMessage={loadingMessage}
                loadOptions={loadEmployeeOptions}
                styles={reactSelectStyles}
                isClearable
                isSearchable
              />
            </CCol>
          </CRow>

          <div className="d-grid gap-4 d-sm-flex justify-content-sm-end mt-3">
            <CButton
              color="primary"
              variant="outline"
              shape="rounded-pill"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </CButton>
            <CButton color="primary" shape="rounded-pill" type="submit" disabled={isSubmitting}>
              {isSubmitting && <CSpinner size="sm" />} Save
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    </CModal>
  );
};

export default EditCustomerModal;
