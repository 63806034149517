import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPersonPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.334 14h-2.667M12 15.333v-2.666M9.107 7.743a1.2 1.2 0 0 0-.22 0A2.947 2.947 0 0 1 6.04 4.79a2.95 2.95 0 0 1 2.953-2.96 2.96 2.96 0 0 1 2.96 2.96c0 1.6-1.266 2.9-2.846 2.953M8.993 15.54c-1.213 0-2.42-.307-3.34-.92-1.613-1.08-1.613-2.84 0-3.913 1.834-1.227 4.84-1.227 6.674 0"
    />
  </svg>
);
export default SvgPersonPlus;
