import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { PrescriptionItem } from 'types/PrescriptionItem';
import { PrescriptionLabel } from 'types/PrescriptionLabel';

const fetchPrescriptionItem = (prescriptionItem: string, onSuccess: (prescriptionItem: PrescriptionItem) => void) => {
  const url = `/pim/prescription_items/${prescriptionItem}`;
  axios.get<PrescriptionItem>(url).then((data) => onSuccess(data.data.record));
};

const fetchPrescriptionItemsByAnimal = (
  animalId: string | number,
  onSuccess: (prescriptionItem: PrescriptionItem[]) => void
) => {
  const url = `/pim/prescription_items?animal_id=${animalId}`;
  axios.get<PrescriptionItem[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchPrescriptionItemsForConsult = (
  consultId: number | string,
  {
    onSuccess,
    params
  }: { onSuccess: (prescriptionItems: PrescriptionItem[]) => void; params?: { without_injectables: boolean } }
) => {
  const url = `/pim/prescription_items`;
  axios
    .get<PrescriptionItem[]>(url, { params: { consult_id: consultId, ...params } })
    .then((data) => onSuccess(data.data.record));
};

const updatePrescriptionItem = (
  prescriptionItemId: number,
  params: Partial<PrescriptionItem>,
  { onSuccess, onError }: { onSuccess: (prescriptionItem: PrescriptionItem) => void; onError: (error: string) => void }
) => {
  const url = `/pim/prescription_items/${prescriptionItemId}`;

  axios
    .put<PrescriptionItem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<PrescriptionItem>>) => onError(error.message));
};

const fetchPrescriptionLabel = (
  prescriptionItemId: number,
  { onSuccess, onError }: { onSuccess: (label: PrescriptionLabel) => void; onError?: (error: string) => void }
) => {
  const url = `/pim/prescription_items/${prescriptionItemId}/label_information`;
  axios
    .get<PrescriptionLabel>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<PrescriptionLabel>>) => onError?.(error.message));
};

const ensureDispenseRecords = (prescriptionItemId: number, onSuccess: (prescriptionItem: PrescriptionItem) => void) => {
  const url = `/pim/prescription_items/${prescriptionItemId}/dispense_records`;
  axios.put<PrescriptionItem>(url).then((data) => onSuccess(data.data.record));
};

export {
  ensureDispenseRecords,
  fetchPrescriptionItemsForConsult,
  fetchPrescriptionLabel,
  fetchPrescriptionItem,
  fetchPrescriptionItemsByAnimal,
  updatePrescriptionItem
};
