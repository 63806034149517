import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { createProductSupplier } from 'api/ProductSuppliers';

import { ProductSupplier } from 'types/ProductSupplier';

import Form from './Form';

const New = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productSupplier, setProductSupplier] = useState<ProductSupplier>({ status: 'active' } as ProductSupplier);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setProductSupplier({ ...productSupplier, [event.target.id]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    createProductSupplier(productSupplier, updateSuccess, updateError);
  };

  const updateError = () => {
    setIsLoading(false);
    toast.error('Error creating product supplier');
  };

  const updateSuccess = (supplier: ProductSupplier) => {
    setIsLoading(false);
    toast.success('Product supplier created!');
    navigate(generatePath(paths.productSupplierEdit, { id: supplier.id }));
  };

  const handleCancel = () => {
    navigate(generatePath(paths.productSupplierList));
  };

  return (
    <div style={{ borderRadius: '20px', boxShadow: '0px 4px 30px 0px rgba(26, 41, 60, 0.1)', padding: '21px' }}>
      <h1 className="mb-3">New Product Supplier</h1>
      <Form
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleChange={handleChange}
        supplier={productSupplier}
        isLoading={isLoading}
      />
    </div>
  );
};

export default New;
