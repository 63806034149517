import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path stroke="currentColor" strokeWidth={1.5} d="M9 10.073a2.34 2.34 0 1 0 0-4.68 2.34 2.34 0 0 0 0 4.68Z" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2.715 6.368c1.478-6.495 11.1-6.488 12.57.007.863 3.81-1.507 7.035-3.585 9.03a3.895 3.895 0 0 1-5.407 0c-2.07-1.995-4.44-5.227-3.578-9.037Z"
    />
  </svg>
);
export default SvgLocation;
