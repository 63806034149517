import * as React from 'react';
import { Link } from 'react-router-dom';

import { Animal, compactAge } from 'types/Animal';
import { HospitalSheet } from 'types/HospitalSheet';

import { Pill } from 'components/Pill';
import { SpacedItems } from 'components/SpacedItems';

import styles from './ResourceOverviewCell.module.scss';

type Props = {
  animal: Animal;
  hospitalSheet: HospitalSheet;
};

export const ResourceOverviewCell = ({ animal, hospitalSheet }: Props) => {
  return (
    <div className={styles.resourceCell}>
      <div className={styles.headerRow}>
        <span className={styles.nameSection}>
          <Link to={`/hospital_sheet/${hospitalSheet.id}`}>{animal.name}</Link>
          {animal.resuscitate ? <Pill label="CPR" /> : <Pill label="DNR" />}
          {animal.fas_score && <Pill label={`FAS ${animal.fas_score}`} />}
          {hospitalSheet.discharged_at && <Pill label={'discharged'} />}
        </span>
      </div>
      <div className={styles.infoRow}>
        <SpacedItems items={[animal.species?.name, animal.breed?.name]} />
      </div>
      <div className={styles.infoRow}>
        <SpacedItems items={[animal.sex?.name, `${animal.weight} ${animal.weight_unit}`, compactAge(animal)]} />
      </div>

      {hospitalSheet.case_owner && (
        <div className={styles.infoRow}>{hospitalSheet.case_owner.full_name_with_title}</div>
      )}

      {hospitalSheet.assigned_nurse && (
        <div className={styles.infoRow}>{hospitalSheet.assigned_nurse.full_name_with_title}</div>
      )}
      <div className={styles.infoRow}>Status: {hospitalSheet.appointment.status}</div>
    </div>
  );
};
