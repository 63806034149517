import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cilOptions, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CListGroup,
  CListGroupItem,
  CRow
} from '@coreui/react-pro';

import { PresentingProblem } from 'types/PresentingProblem';

import { Pill } from 'components/Pill';

import { fetchPresentingProblem } from '../../api/PresentingProblems';
import { compactDateTimeDisplay } from '../../utils/dates';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [presentingProblem, setPresentingProblem] = useState<PresentingProblem>();

  useEffect(() => {
    fetchPresentingProblem(id, setPresentingProblem);
  }, [id]);

  return (
    <>
      {presentingProblem && (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <strong style={{ fontSize: '30px' }}>{presentingProblem.name}</strong>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  role="button"
                  className="align-items-center"
                  href={`/presenting_problems/${presentingProblem.id}/edit`}
                >
                  <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
                  Edit
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <CRow className="mb-5">
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Status</CCol>
                    <CCol>
                      <Pill label={presentingProblem.status} />
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Created On</CCol>
                    <CCol>{compactDateTimeDisplay(presentingProblem.created_at)}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Updated On</CCol>
                    <CCol>{compactDateTimeDisplay(presentingProblem.updated_at)}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Last Updated By</CCol>
                    <CCol>{presentingProblem.updated_by_employee?.full_name_with_title}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>ID</CCol>
                    <CCol>{presentingProblem.id}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CRow>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};
export default Details;
