import cn from 'classnames';
import * as React from 'react';
import { Fragment, useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CButtonGroup, CCol, CDatePicker, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';
import { Clinic } from 'types/Clinic';
import { Employee } from 'types/Employee';
import { Option } from 'types/Option';
import { Prescription } from 'types/Prescription';
import { PrescriptionLoadState } from 'types/PrescriptionLoadState';

import { ClinicContext } from 'contexts/ClinicContext';

import { compactDateDisplay } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import { employeeToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgStethoscope from 'assets/images/SvgStethoscope';

import { FormAuditData } from 'components/FormAuditData';
import { FormDivider } from 'components/FormDivider';
import { IconLabel } from 'components/IconLabel';

import styles from './PrescriptionForm.module.scss';

import { PrescriptionItemForm } from './PrescriptionItemForm';

type Props = {
  prescription?: Prescription;
  animal: Animal;
  appointment?: Appointment;
  employee?: Employee | null;
  hideForm: () => void;
  loadState: PrescriptionLoadState;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

const MIN_QUERY_LENGTH = 3;

export const PrescriptionForm = ({
  prescription,
  animal,
  appointment,
  employee,
  hideForm,
  loadState,
  onSubmit
}: Props) => {
  const initialEmployeeOption = () => {
    const initial =
      prescription?.employee || (appointment?.case_owner?.vet && appointment.case_owner) || (employee?.vet && employee);

    return initial ? employeeToOption(initial) : null;
  };

  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(initialEmployeeOption);

  const [dateOfPrescription, setDateOfPrescription] = useState<Date | string | null>();

  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();

  useEffect(() => {
    if (clinicContext) fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (clinic) {
      setDateOfPrescription(
        prescription?.date_of_prescription
          ? compactDateDisplay(prescription.date_of_prescription, { timezone: 'UTC' })
          : new Date().toISOString()
      );
    }
  }, [clinic, prescription?.date_of_prescription]);

  const [prescriptionItemFormCount, setPrescriptionItemFormCount] = useState(1);

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue, { vets_only: true }).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);
  };

  const handleDateOfPrescriptionChange = (date: Date | null) => {
    setDateOfPrescription(date);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const incrementPrescriptionItemForms = () => {
    setPrescriptionItemFormCount(prescriptionItemFormCount + 1);
  };

  const decrementPrescriptionItemForms = () => {
    setPrescriptionItemFormCount(prescriptionItemFormCount - 1);
  };

  const prescriptionItemsOrForms =
    prescription?.prescription_items || Array.from({ length: prescriptionItemFormCount });

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{prescription ? 'Edit' : 'New'} Medication</h2>

      <CRow className="mb-4">
        <CCol>
          <CFormInput hidden id="employee_id" name="employee_id" value={selectedEmployeeOption?.value ?? ''} />

          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="date_of_administration">
              <IconLabel icon={SvgStethoscope} label="Prescriber" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <AsyncSelect<Option>
            aria-label="Prescriber"
            placeholder="Type to search..."
            value={selectedEmployeeOption}
            styles={reactSelectStyles}
            onChange={handleEmployeeSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={loadEmployeeOptions}
            isClearable
            isSearchable
            required
          />
        </CCol>

        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="date_of_administration">
              <IconLabel icon={SvgCalendar} label="Date of Prescription" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <CDatePicker
            id="date_of_prescription"
            date={dateOfPrescription}
            locale="en-US"
            firstDayOfWeek={0}
            format="MMM dd, yyyy"
            onDateChange={handleDateOfPrescriptionChange}
            cleaner={false}
          />
        </CCol>
      </CRow>

      <CRow>
        <div className="d-flex justify-content-between mb-3">
          <h3>Medication Items</h3>

          {!prescription && (
            <CButtonGroup className={styles.buttonGroup} role="group" aria-label="Add or remove prescription items">
              <CButton size="sm" onClick={incrementPrescriptionItemForms}>
                Add
              </CButton>
              <CButton size="sm" onClick={decrementPrescriptionItemForms} disabled={prescriptionItemFormCount === 1}>
                Remove
              </CButton>
            </CButtonGroup>
          )}
        </div>

        {prescriptionItemsOrForms.map((item, index) => {
          const lastItem = index === prescriptionItemsOrForms.length - 1;

          return (
            <Fragment key={index}>
              <PrescriptionItemForm index={index} prescriptionItem={item} />
              {!lastItem && <FormDivider />}
            </Fragment>
          );
        })}
      </CRow>

      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
        {prescription && <FormAuditData item={prescription} />}
        <div className={cn('d-flex ms-auto', styles.buttons)}>
          <CLoadingButton
            className={styles.button}
            shape="rounded-pill"
            loading={loadState === 'creating-updating-prescription'}
            color="primary"
            type="submit"
          >
            {prescription ? 'Update' : 'Create'}
          </CLoadingButton>
          <CLoadingButton
            loading={loadState === 'dispensing-prescription'}
            className={styles.button}
            shape="rounded-pill"
            color="primary"
            type="submit"
            value="enqueue"
          >
            Dispense
          </CLoadingButton>
          <CLoadingButton
            loading={loadState === 'external-dispensing-prescription'}
            className={styles.button}
            shape="rounded-pill"
            color="primary"
            type="submit"
            value="external"
          >
            External Dispense
          </CLoadingButton>
          <CLoadingButton
            className={styles.button}
            shape="rounded-pill"
            loading={loadState === 'printing-prescription'}
            color="primary"
            type="submit"
            value="print"
          >
            Print
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
