import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHospital = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1.5 16.5h15m-3.75-15h-7.5c-2.25 0-3 1.342-3 3v12h13.5v-12c0-1.658-.75-3-3-3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.545 11.25H7.447a.71.71 0 0 0-.705.705V16.5h4.5v-4.545a.693.693 0 0 0-.697-.705M9 4.5v3.75M7.125 6.375h3.75"
    />
  </svg>
);
export default SvgHospital;
