import * as React from 'react';
import { useEffect } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { paths } from 'routes';

import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchInvoiceChangeLog } from 'api/ChangeLog';
import { fetchCustomer } from 'api/Customers';
import { fetchInvoice, updateInvoice } from 'api/Invoices';

import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';
import { LogEvent } from 'types/LogEvent';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { dateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';
import { generatePaymentLink } from 'utils/stripePayments';

import { Changelog } from 'components/Changelog';
import { PaymentInvoiceModal } from 'components/PaymentInvoiceModal';
import { Pill } from 'components/Pill';

const InvoiceDetails = () => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [invoice, setInvoice] = React.useState<Invoice>();
  const [changelog, setChangelog] = React.useState<LogEvent[]>([]);
  const [chargingInvoice, setChargingInvoice] = React.useState<Invoice | null>(null);
  const [customer, setCustomer] = React.useState<Customer>();

  useDocumentTitle('Invoice', invoice?.name);

  useEffect(() => {
    if (invoice?.customer_id) fetchCustomer(invoice?.customer_id.toString(), setCustomer);
  }, [invoice]);

  const confirmPayModal = () => {
    if (invoice && customer) {
      return (
        <PaymentInvoiceModal
          invoice={invoice}
          customer={customer}
          isVisible={!!chargingInvoice}
          onClose={() => setChargingInvoice(null)}
          onConfirm={paymentSuccessful}
        />
      );
    }
  };

  const approveInvoice = () => {
    if (invoice?.id)
      updateInvoice(
        invoice.id.toString(),
        { ...invoice, status: 'approved', invoice_items_attributes: invoice.invoice_items },
        { onSuccess: setInvoice }
      );
  };

  const paymentSuccessful = (invoice: Invoice) => {
    setChargingInvoice(null);
    setInvoice(invoice);
  };

  useEffect(() => {
    fetchInvoice(id, setInvoice);
    fetchInvoiceChangeLog(id, setChangelog);
  }, [id]);

  if (!invoice) return <CSpinner color="primary" />;

  return (
    <CContainer fluid>
      <h1 className="mb-4">{['Invoice Details', invoice.name].filter((item) => item).join(' - ')}</h1>

      <div>
        <CRow className="mt-3">
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Client</div>
            </CRow>
            <CRow className="ps-1">
              {invoice.customer && (
                <Link to={`/customers/${invoice.customer.id}`}>
                  {invoice.customer.first_name} {invoice.customer.last_name}
                </Link>
              )}
            </CRow>
          </CCol>
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Patient</div>
            </CRow>
            <CRow className="ps-1">
              <Link to={`/animals/${invoice.animal_id}`}>{invoice.animal_name}</Link>
            </CRow>
          </CCol>
          <CCol></CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Status</div>
            </CRow>
            <CRow className="ps-3">
              <Pill label={invoice.status} />
            </CRow>
          </CCol>
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Actions</div>
            </CRow>
            <CRow className="ps-3">
              {invoice && invoice.status === 'draft' && (
                <CButton
                  shape="rounded-pill"
                  onClick={() => approveInvoice()}
                  color="success"
                  size="sm"
                  className="w-auto me-2"
                >
                  Approve
                </CButton>
              )}
              {invoice && !invoice.fully_paid && invoice.status === 'approved' && (
                <CButton
                  disabled={!!chargingInvoice}
                  onClick={() => setChargingInvoice(invoice)}
                  shape="rounded-pill"
                  size="sm"
                  className="w-auto"
                >
                  {chargingInvoice && chargingInvoice.id === invoice.id && <CSpinner className="me-3" size="sm" />}
                  Pay
                </CButton>
              )}
            </CRow>
          </CCol>
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Approved By</div>
            </CRow>
            <CRow className="ps-1">
              <Link to={`/employees/${invoice.approved_by_employee_id}`}>
                {`${invoice.approved_by_employee?.first_name || ''} ${invoice.approved_by_employee?.last_name || ''}`}
              </Link>
            </CRow>
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Total</div>
            </CRow>
            <CRow className="ps-3">{toCurrency(invoice.total || 0)}</CRow>
          </CCol>
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Amount Paid</div>
            </CRow>
            <CRow className="ps-3">{toCurrency(invoice.total_paid || 0)}</CRow>
          </CCol>
          <CCol>
            <CRow className="ps-1">
              <div className="fw-bold">Amount Due</div>
            </CRow>
            <CRow className="ps-3">{toCurrency(invoice.unpaid_total || 0)}</CRow>
          </CCol>
        </CRow>

        <CTable small align="top" className="mt-5">
          <CTableHead color="dark">
            <CTableRow>
              <CTableHeaderCell>Item</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Qty</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Price</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Discount</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Total</CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {invoice.invoice_items?.map((item, index) => {
              return (
                <CTableRow key={index}>
                  <CTableDataCell style={{ border: 'none' }}>
                    <Link
                      to={generatePath(paths.productEdit, { id: item.product_id })}
                      aria-label={`product-${item.product_id}-link`}
                    >
                      {item.product?.name}
                    </Link>
                    <div style={{ fontSize: '14px', color: 'gray' }}>{item.product?.description}</div>
                  </CTableDataCell>
                  <CTableDataCell style={{ border: 'none' }}>{item.quantity}</CTableDataCell>
                  <CTableDataCell style={{ border: 'none' }}>{item.price && toCurrency(item.price)}</CTableDataCell>
                  <CTableDataCell style={{ border: 'none' }}>
                    {item.discount_fixed
                      ? toCurrency(item.discount_fixed)
                      : item.discount_percentage
                      ? `${item.discount_percentage}%`
                      : '$0'}
                  </CTableDataCell>
                  <CTableDataCell style={{ border: 'none' }}>
                    {item.subtotal && toCurrency(item.subtotal)}
                  </CTableDataCell>
                </CTableRow>
              );
            })}

            <CTableRow style={{ border: 'none', borderTop: '2px solid black' }}>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>Subtotal</CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>
                {invoice.subtotal && toCurrency(invoice.subtotal)}
              </CTableDataCell>
            </CTableRow>
            <CTableRow style={{ border: 'none', borderTop: '0px' }}>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>Tax</CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>{invoice.tax && toCurrency(invoice.tax)}</CTableDataCell>
            </CTableRow>
            <CTableRow style={{ border: 'none', borderTop: '0px' }}>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}></CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>Total</CTableDataCell>
              <CTableDataCell style={{ border: 'none' }}>{invoice.total && toCurrency(invoice.total)}</CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>

        <h5 className="mb-2">Payments</h5>
        <CCol xs={8}>
          <CTable className="mb-5">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                <CTableHeaderCell scope="col">Payment Provider</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody key={invoice.id}>
              {invoice.payments.map((payment) => (
                <CTableRow key={payment.id}>
                  <CTableDataCell>{dateTimeDisplay(payment.created_at)}</CTableDataCell>
                  <CTableDataCell>{toCurrency(payment.amount || 0)}</CTableDataCell>
                  <CTableDataCell>
                    {payment.payment_provider_name === 'Stripe' ? (
                      <Link to={generatePaymentLink(payment)}>{payment.payment_provider_name}</Link>
                    ) : (
                      payment.payment_provider_name
                    )}
                    {payment.payment_provider_id && ` - ${payment.payment_provider_id}`}
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCol>
        <CRow className="mb-4">
          <Changelog events={changelog} title="Invoice Change Log" />
        </CRow>
        {confirmPayModal()}
      </div>
    </CContainer>
  );
};

export default InvoiceDetails;
