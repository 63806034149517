import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CSmartTable
} from '@coreui/react-pro';

import { fetchConsultTemplates } from 'api/ConsultTemplates';

import { cleanConsultTemplateType, ConsultTemplate } from 'types/ConsultTemplate';
import { calculatePages, Pagination } from 'types/Pagination';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateTimeDisplay } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { Pill } from 'components/Pill';

const List = (): JSX.Element => {
  const [templatesList, setTemplatesList] = useState<ConsultTemplate[]>();
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  useDocumentTitle('Consult Templates');

  useEffect(() => {
    if (searchText.length === 0) {
      fetchConsultTemplates({ onSuccess: setTemplatesList, setPagination });
    }
  }, [searchText]);

  const updateTemplatesList = useCallback((templates: ConsultTemplate[]) => {
    setIsLoading(false);
    setTemplatesList(templates);
  }, []);

  const updatePage = useCallback(
    (page: number) => {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      fetchConsultTemplates({ queryText: searchText, onSuccess: updateTemplatesList, setPagination, page: page });
    },
    [searchText, updateTemplatesList]
  );

  const fetchConsultTemplatesList = useCallback(() => {
    setIsLoading(true);
    fetchConsultTemplates({ queryText: searchText, onSuccess: updateTemplatesList, setPagination });
  }, [searchText, updateTemplatesList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchConsultTemplatesList();
  };

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <CForm className="row g-3" onSubmit={handleSubmit}>
          <CCol>
            <CFormInput
              type="text"
              id="searchInput"
              label="Search Consult Templates"
              placeholder="Search by name"
              onChange={(event) => setSearchText(event.target.value)}
            />
          </CCol>
          <CCol className="align-self-end" xs={2}>
            <CLoadingButton
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={!searchText}
              shape="rounded-pill"
            >
              Search
            </CLoadingButton>
          </CCol>
          <CCol className="align-self-end text-end" xs={2}>
            <CButton color="primary" href={'/consult_templates/new'} shape="rounded-pill">
              New
            </CButton>
          </CCol>
        </CForm>
      </CCardHeader>
      <CFormLabel className="ms-3 mt-2 text-body-secondary" color="light">
        {pagination.total} {pluralize('template', pagination.total)}{' '}
      </CFormLabel>
      {templatesList && templatesList.length > 0 && (
        <CCardBody role="listitem">
          <CSmartTable
            items={templatesList}
            itemsPerPage={25}
            pagination={{
              external: true
            }}
            paginationProps={{
              activePage: pagination.page,
              pages: calculatePages(pagination),
              align: 'center'
            }}
            onActivePageChange={(activePage) => updatePage(activePage)}
            columns={['name', 'type', 'status', 'description']}
            scopedColumns={{
              name: (template: ConsultTemplate) => {
                return (
                  <td>
                    <Link to={`/consult_templates/${template.id}`}>{template.name}</Link>
                  </td>
                );
              },
              type: (template: ConsultTemplate) => {
                return <td>{cleanConsultTemplateType(template.type)}</td>;
              },
              status: (template: ConsultTemplate) => {
                return (
                  <td>
                    <Pill label={template.status} />
                  </td>
                );
              },
              last_updated: (template: ConsultTemplate) => {
                return <td>{template.updated_at && compactDateTimeDisplay(template.updated_at)}</td>;
              }
            }}
          ></CSmartTable>
        </CCardBody>
      )}
    </CCard>
  );
};

export default List;
