import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { CNav, CNavItem, CNavLink } from '@coreui/react-pro';

import {
  createAssessment,
  fetchAssessmentsByAnimal,
  fetchAssessmentsForConsult,
  updateAssessment
} from 'api/Assessments';
import { createConsultPlan, fetchConsultPlansForConsult, updateConsultPlan } from 'api/ConsultPlans';
import {
  createConsultPresentingProblem,
  fetchConsultPresentingProblemsForConsult,
  updateConsultPresentingProblem
} from 'api/ConsultPresentingProblems';
import { createHistory, fetchHistoriesForConsult, updateHistory } from 'api/Histories';
import {
  createPhysicalExam,
  fetchPhysicalExamsByAnimal,
  fetchPhysicalExamsForConsult,
  updatePhysicalExam
} from 'api/PhysicalExams';

import { Assessment } from 'types/Assessment';
import { Consult } from 'types/Consult';
import { ConsultPlan } from 'types/ConsultPlan';
import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';
import { History } from 'types/History';
import { MedicalHistoryItem } from 'types/MedicalHistoryItem';
import { PhysicalExam } from 'types/PhysicalExam';

import { usePoll } from 'hooks/usePoll';

import {
  assessmentToOption,
  consultPlanToOption,
  consultPresentingProblemToOption,
  historyToOption,
  physicalExamToOption
} from 'utils/selectOptions';
import { statusAction } from 'utils/status';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { IconButton } from 'components/IconButton';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

import { AssessmentForm } from './AssessmentForm';
import { ConsultPlanForm } from './ConsultPlanForm';
import { ConsultPresentingProblemForm } from './ConsultPresentingProblemForm';
import { HistoryForm } from './HistoryForm';
import { PhysicalExamForm } from './PhysicalExamForm';

type Form =
  | 'new-consult-presenting-problem'
  | 'edit-consult-presenting-problem'
  | 'new-history'
  | 'edit-history'
  | 'new-physical-exam'
  | 'edit-physical-exam'
  | 'new-assessment'
  | 'edit-assessment'
  | 'new-consult-plan'
  | 'edit-consult-plan';

type Props = {
  consult: Consult;
  scrollUp: () => void;
};

const tables = ['presenting-problems', 'histories', 'physical-exams', 'assessments', 'consult-plans'] as const;
export type Table = (typeof tables)[number];

export const SOAPStep = ({ consult, scrollUp }: Props): JSX.Element => {
  const [consultPresentingProblems, setConsultPresentingProblems] = useState<ConsultPresentingProblem[]>([]);
  const [histories, setHistories] = useState<History[]>([]);
  const [physicalExams, setPhysicalExams] = useState<PhysicalExam[]>([]);
  const [pastPhysicalExams, setPastPhysicalExams] = useState<PhysicalExam[]>([]);
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [pastAssessments, setPastAssessments] = useState<Assessment[]>([]);
  const [consultPlans, setConsultPlans] = useState<ConsultPlan[]>([]);

  const [activeTable, setActiveTable] = useState<Table>(tables[0]);
  const [visibleForm, setVisibleForm] = useState<Form>();
  const [editingItem, setEditingItem] = useState<MedicalHistoryItem>();
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssessmentsByAnimal(consult.animal_id, setPastAssessments);
    fetchPhysicalExamsByAnimal(consult.animal_id, setPastPhysicalExams);
  }, [consult.animal_id]);

  const fetchConsultPresentingProblems = useCallback(
    () =>
      fetchConsultPresentingProblemsForConsult(consult.id, (consultPresentingProblems) => {
        if (activeTable === 'presenting-problems' && consultPresentingProblems.length === 0) {
          setVisibleForm('new-consult-presenting-problem');
        }

        setConsultPresentingProblems(consultPresentingProblems);
      }),
    [consult, activeTable]
  );

  const fetchHistories = useCallback(
    () =>
      fetchHistoriesForConsult(consult.id, (histories) => {
        if (activeTable === 'histories' && histories.length === 0) {
          setVisibleForm('new-history');
        }

        setHistories(histories);
      }),
    [consult, activeTable]
  );

  const fetchPhysicalExams = useCallback(
    () =>
      fetchPhysicalExamsForConsult(consult.id, (physicalExams) => {
        if (activeTable === 'physical-exams' && physicalExams.length === 0) {
          setVisibleForm('new-physical-exam');
        }

        setPhysicalExams(physicalExams);
      }),
    [consult, activeTable]
  );

  const fetchAssessments = useCallback(
    () =>
      fetchAssessmentsForConsult(consult.id, (assessments) => {
        if (activeTable === 'assessments' && assessments.length === 0) {
          setVisibleForm('new-assessment');
        }

        setAssessments(assessments);
      }),
    [consult, activeTable]
  );

  const fetchConsultPlans = useCallback(
    () =>
      fetchConsultPlansForConsult(consult.id, (consultPlans) => {
        if (activeTable === 'consult-plans' && consultPlans.length === 0) {
          setVisibleForm('new-consult-plan');
        }

        setConsultPlans(consultPlans);
      }),
    [consult, activeTable]
  );

  usePoll(fetchConsultPresentingProblems);
  usePoll(fetchHistories);
  usePoll(fetchPhysicalExams);
  usePoll(fetchAssessments);
  usePoll(fetchConsultPlans);

  const hideForm = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  const remountForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleNewClick = (form: Form) => () => {
    remountForm();
    scrollUp();
    setVisibleForm(form);
  };

  const handleEditClick = (item: MedicalHistoryItem) => (form: Form) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm(form);
  };

  const handleSuccess = (message: string, closeForm = true) => {
    fetchConsultPresentingProblems();
    fetchHistories();
    fetchPhysicalExams();
    fetchAssessments();
    fetchConsultPlans();

    toast.success(message);
    if (closeForm) hideForm();
    remountForm();
    setLoading(false);
  };

  const handleCreateHistorySuccess = (item: History) => {
    toast.success('History created!');
    fetchHistories();

    remountForm();
    setEditingItem(item);
    setVisibleForm('edit-history');

    setLoading(false);
  };

  const handleCreatePhysicalExamSuccess = (item: PhysicalExam) => {
    toast.success('Physical exam created!');
    fetchPhysicalExams();

    remountForm();
    setEditingItem(item);
    setVisibleForm('edit-physical-exam');

    setLoading(false);
  };

  const handleCreateAssessmentSuccess = (item: Assessment) => {
    toast.success('Assessment created!');
    fetchAssessments();

    remountForm();
    setEditingItem(item);
    setVisibleForm('edit-assessment');

    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleCreateConsultPresentingProblem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      consult_id: consult.id,
      presenting_problem_id: Number(formData.get('presenting_problem_id')),
      specifics: formData.get('specifics') as string
    };

    createConsultPresentingProblem(formJson, {
      onSuccess: () => handleSuccess('Presenting problem created!', false),
      onError: handleError
    });
  };

  const handleUpdateConsultPresentingProblem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      presenting_problem_id: Number(formData.get('presenting_problem_id')),
      specifics: formData.get('specifics') as string
    };

    updateConsultPresentingProblem(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Presenting problem updated!', false),
      onError: handleError
    });
  };

  const handleCreateHistory = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { consult_id: consult.id, ...Object.fromEntries(formData.entries()) };

    createHistory(formJson, {
      onSuccess: handleCreateHistorySuccess,
      onError: handleError
    });
  };

  const handleUpdateHistory = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;

    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updateHistory(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('History updated!'),
      onError: handleError
    });
  };

  const handleCreatePhysicalExam = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { consult_id: consult.id, ...Object.fromEntries(formData.entries()) };

    createPhysicalExam(formJson, {
      onSuccess: handleCreatePhysicalExamSuccess,
      onError: handleError
    });
  };

  const handleUpdatePhysicalExam = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updatePhysicalExam(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Physical exam updated!'),
      onError: handleError
    });
  };

  const handleCreateAssessment = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { consult_id: consult.id, ...Object.fromEntries(formData.entries()) };

    createAssessment(formJson, {
      onSuccess: handleCreateAssessmentSuccess,
      onError: handleError
    });
  };

  const handleUpdateAssessment = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;

    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updateAssessment(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Assessment updated!'),
      onError: handleError
    });
  };

  const handleCreateConsultPlan = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { consult_id: consult.id, ...Object.fromEntries(formData.entries()) };

    createConsultPlan(formJson, {
      onSuccess: () => handleSuccess('Consult plan created!'),
      onError: handleError
    });
  };

  const handleUpdateConsultPlan = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updateConsultPlan(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Consult plan updated!'),
      onError: handleError
    });
  };

  const isConsultPresentingProblem = (item?: MedicalHistoryItem): item is ConsultPresentingProblem => !!item;
  const isHistory = (item?: MedicalHistoryItem): item is History => !!item;
  const isPhysicalExam = (item?: MedicalHistoryItem): item is PhysicalExam => !!item;
  const isAssessment = (item?: MedicalHistoryItem): item is Assessment => !!item;
  const isConsultPlan = (item?: MedicalHistoryItem): item is ConsultPlan => !!item;

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'new-consult-presenting-problem':
        return (
          <ConsultPresentingProblemForm
            key={formKey}
            hideForm={hideForm}
            loading={loading}
            onSubmit={handleCreateConsultPresentingProblem}
          />
        );
      case 'edit-consult-presenting-problem':
        if (isConsultPresentingProblem(editingItem)) {
          return (
            <ConsultPresentingProblemForm
              key={formKey}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateConsultPresentingProblem}
              consultPresentingProblem={editingItem}
            />
          );
        }
        break;
      case 'new-history':
        return <HistoryForm key={formKey} hideForm={hideForm} loading={loading} onSubmit={handleCreateHistory} />;
      case 'edit-history':
        if (isHistory(editingItem)) {
          return (
            <HistoryForm
              key={formKey}
              history={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateHistory}
            />
          );
        }
        break;
      case 'new-physical-exam':
        return (
          <PhysicalExamForm
            key={formKey}
            pastPhysicalExams={pastPhysicalExams}
            hideForm={hideForm}
            loading={loading}
            onSubmit={handleCreatePhysicalExam}
          />
        );
      case 'edit-physical-exam':
        if (isPhysicalExam(editingItem)) {
          return (
            <PhysicalExamForm
              key={formKey}
              physicalExam={editingItem}
              pastPhysicalExams={pastPhysicalExams}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdatePhysicalExam}
            />
          );
        }
        break;
      case 'new-assessment':
        return (
          <AssessmentForm
            key={formKey}
            pastAssessments={pastAssessments}
            hideForm={hideForm}
            loading={loading}
            onSubmit={handleCreateAssessment}
          />
        );
      case 'edit-assessment':
        if (isAssessment(editingItem)) {
          return (
            <AssessmentForm
              key={formKey}
              assessment={editingItem}
              pastAssessments={pastAssessments}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateAssessment}
            />
          );
        }
        break;
      case 'new-consult-plan':
        return (
          <ConsultPlanForm key={formKey} hideForm={hideForm} loading={loading} onSubmit={handleCreateConsultPlan} />
        );
      case 'edit-consult-plan':
        if (isConsultPlan(editingItem)) {
          return (
            <ConsultPlanForm
              key={formKey}
              consultPlan={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateConsultPlan}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  const handleNavClick = (table: Table) => () => {
    hideForm();
    setActiveTable(table);
  };

  const itemsCount = {
    'presenting-problems': consultPresentingProblems.length,
    histories: histories.length,
    'physical-exams': physicalExams.length,
    assessments: assessments.length,
    'consult-plans': consultPlans.length
  };

  const TableControl = () => (
    <CNav role="list" variant="underline">
      {tables.map((table) => (
        <CNavItem key={table}>
          <CNavLink
            className="text-capitalize"
            active={table === activeTable}
            role="button"
            onClick={handleNavClick(table)}
          >
            {table.replace('-', ' ')} ({itemsCount[table]})
          </CNavLink>
        </CNavItem>
      ))}
    </CNav>
  );

  return (
    <>
      <MedicalHistoryTableV2
        name="Presenting Problems"
        items={consultPresentingProblems}
        form={renderForm(visibleForm)}
        visible={activeTable === 'presenting-problems'}
        tableControl={<TableControl />}
        columns={['updated', { key: 'status' }, { key: 'name', label: 'Problem' }, { key: 'actions' }]}
        scopedColumns={{
          updated: (item: ConsultPresentingProblem) => (
            <TableAuditData item={item} handleClick={handleEditClick(item)('edit-consult-presenting-problem')} />
          ),
          status: (item: ConsultPresentingProblem) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          name: (item: ConsultPresentingProblem) => (
            <td>
              <span style={{ fontWeight: 'bold' }}>{item.presenting_problem.name}</span>{' '}
              {item.specifics && <span dangerouslySetInnerHTML={{ __html: item.specifics }}></span>}
            </td>
          ),
          actions: (item: ConsultPresentingProblem) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditClick(item)('edit-consult-presenting-problem')
                  },
                  statusAction({
                    item,
                    name: 'Presenting problem',
                    updater: updateConsultPresentingProblem,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={
          <IconButton
            icon={SvgPlus}
            label="New Presenting Problem"
            onClick={handleNewClick('new-consult-presenting-problem')}
          />
        }
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={consultPresentingProblems
              .filter((problem) => problem.status !== 'disabled')
              .map(consultPresentingProblemToOption)}
            contextType={
              consultPresentingProblems.filter((problem) => problem.status !== 'disabled').length > 0
                ? 'ConsultPresentingProblem'
                : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />

      <MedicalHistoryTableV2
        name="Histories"
        items={histories}
        form={renderForm(visibleForm)}
        visible={activeTable === 'histories'}
        tableControl={<TableControl />}
        columns={['updated', { key: 'status' }, { key: 'history' }, { key: 'actions' }]}
        scopedColumns={{
          updated: (item: History) => (
            <TableAuditData item={item} handleClick={handleEditClick(item)('edit-history')} />
          ),
          history: (item: History) => <td dangerouslySetInnerHTML={{ __html: item.history ?? '' }} />,
          status: (item: History) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          actions: (item: History) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditClick(item)('edit-history')
                  },
                  statusAction({
                    item,
                    name: 'History',
                    updater: updateHistory,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={
          histories.every((history) => history.status === 'disabled') && (
            <IconButton icon={SvgPlus} label="New History" onClick={handleNewClick('new-history')} />
          )
        }
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={histories.filter((history) => history.status !== 'disabled').map(historyToOption)}
            contextType={
              histories.filter((history) => history.status !== 'disabled').length > 0 ? 'History' : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />

      <MedicalHistoryTableV2
        name="Physical Exams"
        items={physicalExams}
        form={renderForm(visibleForm)}
        visible={activeTable === 'physical-exams'}
        tableControl={<TableControl />}
        columns={['updated', { key: 'status' }, { key: 'exam' }, { key: 'actions' }]}
        scopedColumns={{
          updated: (item: PhysicalExam) => (
            <TableAuditData item={item} handleClick={handleEditClick(item)('edit-physical-exam')} />
          ),
          status: (item: PhysicalExam) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          exam: (item: PhysicalExam) => <td dangerouslySetInnerHTML={{ __html: item.exam ?? '' }} />,
          actions: (item: PhysicalExam) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditClick(item)('edit-physical-exam')
                  },
                  statusAction({
                    item,
                    name: 'Physical exam',
                    updater: updatePhysicalExam,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={
          <IconButton icon={SvgPlus} label="New Physical Exam" onClick={handleNewClick('new-physical-exam')} />
        }
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={physicalExams.filter((exam) => exam.status !== 'disabled').map(physicalExamToOption)}
            contextType={
              physicalExams.filter((exam) => exam.status !== 'disabled').length > 0 ? 'PhysicalExam' : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />

      <MedicalHistoryTableV2
        name="Assessments"
        visible={activeTable === 'assessments'}
        items={assessments}
        form={renderForm(visibleForm)}
        tableControl={<TableControl />}
        columns={['updated', { key: 'status' }, { key: 'evaluation' }, { key: 'actions' }]}
        scopedColumns={{
          updated: (item: Assessment) => (
            <TableAuditData item={item} handleClick={handleEditClick(item)('edit-assessment')} />
          ),
          status: (item: Assessment) => <td>{<Pill label={item.status} />}</td>,
          evaluation: (item: Assessment) => <td dangerouslySetInnerHTML={{ __html: item.evaluation }} />,
          actions: (item: Assessment) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditClick(item)('edit-assessment')
                  },
                  statusAction({
                    item,
                    name: 'Assessment',
                    updater: updateAssessment,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={<IconButton icon={SvgPlus} label="New Assessment" onClick={handleNewClick('new-assessment')} />}
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={assessments
              .filter((assessment) => assessment.status !== 'disabled')
              .map(assessmentToOption)}
            contextType={
              assessments.filter((assessment) => assessment.status !== 'disabled').length > 0 ? 'Assessment' : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />

      <MedicalHistoryTableV2
        name="Consult Plans"
        items={consultPlans}
        form={renderForm(visibleForm)}
        visible={activeTable === 'consult-plans'}
        tableControl={<TableControl />}
        columns={['updated', { key: 'status' }, { key: 'plan' }, { key: 'customer_discussion' }, { key: 'actions' }]}
        scopedColumns={{
          updated: (item: ConsultPlan) => (
            <TableAuditData item={item} handleClick={handleEditClick(item)('edit-consult-plan')} />
          ),
          status: (item: ConsultPlan) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          plan: (item: ConsultPlan) => <td dangerouslySetInnerHTML={{ __html: item.plan ?? '' }} />,
          customer_discussion: (item: ConsultPlan) => (
            <td dangerouslySetInnerHTML={{ __html: item.customer_discussion ?? '' }} />
          ),
          actions: (item: ConsultPlan) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditClick(item)('edit-consult-plan')
                  },
                  statusAction({
                    item,
                    name: 'Consult plan',
                    updater: updateConsultPlan,
                    handleSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
        newButton={<IconButton icon={SvgPlus} label="New Consult Plan" onClick={handleNewClick('new-consult-plan')} />}
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={consultPlans.filter((plan) => plan.status !== 'disabled').map(consultPlanToOption)}
            contextType={
              consultPlans.filter((plan) => plan.status !== 'disabled').length > 0 ? 'ConsultPlan' : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />
    </>
  );
};
