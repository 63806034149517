import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import { DocumentsList } from 'views/documents/DocumentsList';
import { NewDocumentModal } from 'views/documents/NewDocumentModal';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import {
  CButton,
  CLoadingButton,
  CNav,
  CNavItem,
  CNavLink,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { fetchCustomer } from 'api/Customers';
import { fetchDocumentsByConsult } from 'api/Documents';
import { createForwardBooking, fetchForwardBookings, updateForwardBooking } from 'api/ForwardBookings';
import { createInvoice, fetchInvoice, fetchInvoicesForConsult, updateInvoice } from 'api/Invoices';
import { fetchVisitSummaryForConsult, updateVisitSummary } from 'api/VisitSummaries';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { AppointmentType } from 'types/AppointmentType';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Document } from 'types/Document';
import { DocumentContextObjects } from 'types/DocumentContextObjects';
import { ForwardBooking } from 'types/ForwardBooking';
import { Invoice, InvoiceStatus } from 'types/Invoice';
import { Pagination } from 'types/Pagination';
import { VisitSummary } from 'types/VisitSummary';

import { useKey } from 'hooks/useKey';
import { usePoll } from 'hooks/usePoll';

import { compactDateDisplay, compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';
import { toOption } from 'utils/selectOptions';
import { statusAction } from 'utils/status';

import SvgPencil from 'assets/images/SvgPencil';
import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { IconButton } from 'components/IconButton';
import { InvoiceForm } from 'components/InvoiceForm';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { PaymentInvoiceModal } from 'components/PaymentInvoiceModal';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

import styles from './SummaryStep.module.scss';

import { ForwardBookingForm } from './ForwardBookingForm';
import { VisitSummaryForm } from './VisitSummaryForm';

const tables = ['forward-bookings', 'documents', 'invoices', 'summary'] as const;
export type Table = (typeof tables)[number];

type Form = 'edit-visit-summary' | 'new-forward-booking' | 'edit-forward-booking' | 'new-invoice' | 'edit-invoice';
type LoadState = 'general' | 'regenerating-summary' | 'creating-invoice' | 'updating-invoice';

const modals = ['new-document'] as const;
export type Modal = (typeof modals)[number];

type Props = {
  consult: Consult;
  contextObjects: DocumentContextObjects;
  scrollUp: () => void;
};

export const SummaryStep = ({ consult, contextObjects, scrollUp }: Props) => {
  const [visitSummary, setVisitSummary] = useState<VisitSummary | null>(null);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>();
  const [forwardBookings, setForwardBookings] = useState<ForwardBooking[]>([]);
  const [editingItem, setEditingItem] = useState<ForwardBooking | VisitSummary>();

  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentsPagination, setDocumentsPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [modalVisible, setModalVisible] = useState<Modal>();

  const [loadState, setLoadState] = useState<LoadState>();

  const [activeTable, setActiveTable] = useState<Table>(tables[0]);
  const [visibleForm, setVisibleForm] = useState<Form>();

  const [customer, setCustomer] = useState<Customer>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoiceToPay, setInvoiceToPay] = useState<Invoice>();
  const [invoice, setInvoice] = useState<Partial<Invoice>>();

  const [formKey, remountForm] = useKey();

  const fetchSummary = useCallback(
    () => fetchVisitSummaryForConsult(consult.id, { onSuccess: setVisitSummary }),
    [consult]
  );
  usePoll(fetchSummary);

  const initializeInvoice = useCallback(() => {
    if (customer) {
      const initializedInvoice = {
        active: true,
        customer_id: customer.id,
        animal_id: consult.animal_id,
        consult_id: consult.id,
        customer: customer,
        fully_paid: false,
        payments: [],
        subtotal: 0,
        total: 0,
        tax: 0,
        total_paid: 0,
        unpaid_total: 0,
        status: 'draft'
      } as Partial<Invoice>;

      setInvoice(initializedInvoice);
    }
  }, [consult, customer]);

  const fetchInvoices = useCallback(
    () =>
      fetchInvoicesForConsult(consult.id, (invoices) => {
        if (activeTable === 'invoices' && invoices.length === 0) {
          if (!invoice) initializeInvoice();
          setVisibleForm('new-invoice');
        }

        setInvoices(invoices);
      }),
    [consult.id, activeTable, invoice, initializeInvoice]
  );
  usePoll(fetchInvoices);

  const fetchDocuments = useCallback(
    () =>
      fetchDocumentsByConsult(consult.id, {
        onSuccess: setDocuments,
        setPagination: setDocumentsPagination,
        page: documentsPagination?.page
      }),
    [consult, documentsPagination.page]
  );
  usePoll(fetchDocuments);

  const fetchBookings = useCallback(
    () =>
      fetchForwardBookings(consult.id, (forwardBookings) => {
        if (activeTable === 'forward-bookings' && forwardBookings.length === 0) {
          setVisibleForm('new-forward-booking');
        }

        setForwardBookings(forwardBookings);
      }),
    [consult, activeTable]
  );
  usePoll(fetchBookings);

  useEffect(() => {
    fetchAppointmentTypes(setAppointmentTypes);
  }, []);

  useEffect(() => {
    fetchCustomer(consult.customer_id, setCustomer);
  }, [consult]);

  const handleCreateInvoiceSuccess = () => {
    toast.success('Invoice created!');
    setLoadState(undefined);
    hideForm();
    fetchInvoices();
  };

  const handleUpdateInvoiceSuccess = () => {
    toast.success('Invoice created!');
    setLoadState(undefined);
    hideForm();
    fetchInvoices();
  };

  const handlePaymentSuccess = (invoice: Invoice) => {
    setInvoiceToPay(undefined);
    fetchInvoices();
  };

  const handleInvoiceError = (message: string) => {
    setLoadState(undefined);
    toast.error(message);
  };

  const handleCreateInvoice = async () => {
    if (!invoice) return;
    setLoadState('creating-invoice');

    return createInvoice(invoice, handleCreateInvoiceSuccess, handleInvoiceError);
  };

  const handleUpdateInvoice = async () => {
    if (!invoice?.id) return;
    setLoadState('updating-invoice');

    return updateInvoice(
      invoice.id,
      { ...invoice, invoice_items_attributes: invoice.invoice_items },
      { onSuccess: handleUpdateInvoiceSuccess, onError: handleInvoiceError }
    );
  };

  const hideForm = () => {
    setVisibleForm(undefined);
  };

  const handleNewInvoiceClick = () => {
    remountForm();
    scrollUp();

    initializeInvoice();
    setVisibleForm('new-invoice');
  };

  const handleEditInvoiceClick = (item: Invoice) => () => {
    remountForm();
    scrollUp();

    fetchInvoice(item.id, (fullInvoice) => {
      setInvoice(fullInvoice);
      setVisibleForm('edit-invoice');
    });
  };

  const handleUpdateInvoiceStatus = ({ item, status }: { item: Invoice; status: InvoiceStatus }) => {
    setLoadState('updating-invoice');

    updateInvoice(
      item.id,
      { status: status },
      {
        onSuccess: handleUpdateInvoiceSuccess,
        onError: handleInvoiceError
      }
    );
  };

  const handleEditSummaryClick = () => {
    remountForm();
    scrollUp();

    setVisibleForm('edit-visit-summary');
  };

  const handleEditForwardBookingClick = (item: ForwardBooking) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-forward-booking');
  };

  const handleNewForwardBookingClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-forward-booking');
  };

  const handleVisitSummarySuccess = (message: string) => {
    toast.success(message);
    fetchSummary();
    hideForm();
    setLoadState(undefined);
  };

  const handleForwardBookingSuccess = (message: string) => {
    toast.success(message);
    fetchBookings();
    hideForm();
    setLoadState(undefined);
  };

  const handleError = () => {
    setLoadState(undefined);
  };

  const handleRegenerateSummaryClick = () => {
    setLoadState('regenerating-summary');

    fetchVisitSummaryForConsult(consult.id, {
      params: { generate: true },
      onSuccess: () => handleVisitSummarySuccess('Visit summary regenerated!')
    });
  };

  const handleUpdateVisitSummary = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!visitSummary) return;
    setLoadState('general');

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updateVisitSummary(visitSummary.id, formJson, {
      onSuccess: () => handleVisitSummarySuccess('Visit summary updated!'),
      onError: handleError
    });
  };

  const handleCreateForwardBooking = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadState('general');

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      consult_id: consult.id,
      animal_id: consult.animal_id,
      ...Object.fromEntries(formData.entries())
    };

    createForwardBooking(formJson, {
      onSuccess: () => handleForwardBookingSuccess('Forward booking created!'),
      onError: handleError
    });
  };

  const handleUpdateForwardBooking = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadState('general');

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    if (!editingItem) return;

    updateForwardBooking(editingItem.id, formJson, {
      onSuccess: () => handleForwardBookingSuccess('Forward booking updated!'),
      onError: handleError
    });
  };

  const handleDocumentSuccess = (document: Document) => {
    toast.success('Document created!');
    setModalVisible(undefined);
  };

  const handleNavClick = (table: Table) => () => {
    hideForm();
    setActiveTable(table);
  };

  const TableControl = () => (
    <CNav role="list" variant="underline">
      {tables.map((table) => (
        <CNavItem key={table}>
          <CNavLink
            className="text-capitalize"
            active={table === activeTable}
            role="button"
            onClick={handleNavClick(table)}
          >
            {table.replace('-', ' ')}
          </CNavLink>
        </CNavItem>
      ))}
    </CNav>
  );

  const invoicesActionsMenuItems = (item: Invoice) => {
    return [
      !item.fully_paid && {
        label: 'Edit',
        onClick: handleEditInvoiceClick(item)
      },
      {
        label: 'View',
        href: generatePath(paths.invoiceDetails, { id: item.id })
      },
      item.status === 'approved' &&
        !item.fully_paid && {
          label: 'Pay Now',
          onClick: () => setInvoiceToPay(item)
        },
      item.status !== 'approved' && {
        label: 'Approve',
        onClick: () => handleUpdateInvoiceStatus({ item, status: 'approved' })
      },
      item.status !== 'draft' &&
        !item.fully_paid && {
          label: 'Make Draft',
          onClick: () => handleUpdateInvoiceStatus({ item, status: 'draft' })
        },
      item.status !== 'disabled' &&
        !item.fully_paid && {
          label: 'Disable',
          onClick: () => handleUpdateInvoiceStatus({ item, status: 'disabled' })
        }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  const isForwardBooking = (item?: ForwardBooking | VisitSummary): item is ForwardBooking => !!item;

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'edit-visit-summary':
        if (visitSummary) {
          return (
            <VisitSummaryForm
              visitSummary={visitSummary}
              hideForm={hideForm}
              key={formKey}
              loading={loadState === 'general'}
              onSubmit={handleUpdateVisitSummary}
            />
          );
        }
        break;
      case 'new-forward-booking':
        if (appointmentTypes) {
          return (
            <ForwardBookingForm
              appointmentTypes={appointmentTypes}
              hideForm={hideForm}
              employee={consult.employee}
              key={formKey}
              loading={loadState === 'general'}
              onSubmit={handleCreateForwardBooking}
            />
          );
        }
        break;
      case 'edit-forward-booking':
        if (appointmentTypes && isForwardBooking(editingItem)) {
          return (
            <ForwardBookingForm
              forwardBooking={editingItem}
              appointmentTypes={appointmentTypes}
              hideForm={hideForm}
              employee={consult.employee}
              key={formKey}
              loading={loadState === 'general'}
              onSubmit={handleUpdateForwardBooking}
            />
          );
        }
        break;
      case 'new-invoice':
        if (!invoice) return null;
        if (!customer) return null;

        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            handleSubmit={handleCreateInvoice}
            handleCancel={hideForm}
            setInvoice={setInvoice}
            isLoading={loadState === 'creating-invoice'}
          />
        );
      case 'edit-invoice':
        if (!invoice) return null;
        if (!customer) return null;

        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            handleSubmit={handleUpdateInvoice}
            handleCancel={hideForm}
            setInvoice={setInvoice}
            isLoading={loadState === 'updating-invoice'}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <MedicalHistoryTableV2
        name="Forward Bookings"
        items={forwardBookings}
        form={renderForm(visibleForm)}
        tableControl={<TableControl />}
        visible={activeTable === 'forward-bookings'}
        newButton={<IconButton icon={SvgPlus} onClick={handleNewForwardBookingClick} label="New Forward Booking" />}
        columns={[
          'updated',
          'status',
          'employee',
          'due_date_description',
          'due_date',
          'appointment_type',
          'notes',
          'actions'
        ]}
        scopedColumns={{
          updated: (item: ForwardBooking) => (
            <TableAuditData item={item} handleClick={handleEditForwardBookingClick(item)} />
          ),
          notes: (item: ForwardBooking) => <td dangerouslySetInnerHTML={{ __html: item.notes }} />,
          due_date_description: (item: ForwardBooking) => <td>{item.due_date_description}</td>,
          due_date: (item: ForwardBooking) => <td>{compactDateDisplay(item.due_date)}</td>,
          status: (item: ForwardBooking) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          appointment_type: (item: ForwardBooking) => (
            <td>
              <Link to={`/appointments/types/${item.appointment_type_id}`}>{item.appointment_type.name_in_pim}</Link>
            </td>
          ),
          employee: (item: ForwardBooking) => (
            <td>
              <Link to={`/employees/${item.employee_id}`}>{item.employee.full_name_with_title}</Link>
            </td>
          ),
          actions: (item: ForwardBooking) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'Edit',
                    onClick: handleEditForwardBookingClick(item)
                  },
                  statusAction({
                    item,
                    name: 'Forward booking',
                    updater: updateForwardBooking,
                    handleSuccess: handleForwardBookingSuccess,
                    handleError
                  })
                ]}
              />
            </td>
          )
        }}
      />

      {activeTable === 'documents' && (
        <>
          <TableControl />

          <DocumentsList
            documents={documents}
            pagination={documentsPagination}
            updatePage={(page) => setDocumentsPagination({ ...documentsPagination, page })}
            visible={activeTable === 'documents'}
            handleCreateNewDocument={() => setModalVisible('new-document')}
          />

          <NewDocumentModal
            onClose={() => setModalVisible(undefined)}
            docContextObjects={contextObjects}
            onSuccess={handleDocumentSuccess}
            isVisible={modalVisible === 'new-document'}
          />
        </>
      )}

      <MedicalHistoryTableV2
        name="Invoices"
        items={invoices}
        form={renderForm(visibleForm)}
        visible={activeTable === 'invoices'}
        tableControl={<TableControl />}
        columns={[
          'updated',
          'status',
          'name',
          'total',
          { key: 'total_paid', label: 'Amount Paid' },
          { key: 'unpaid_total', label: 'Amount Due' },
          'payment_status',
          'actions'
        ]}
        scopedColumns={{
          updated: (item: Invoice) => <TableAuditData item={item} handleClick={handleEditInvoiceClick(item)} />,
          created_at: (item: Invoice) => <td>{item.created_at && compactDateTimeDisplay(item.created_at)}</td>,
          total: (item: Invoice) => <td>{toCurrency(item.total)}</td>,
          total_paid: (item: Invoice) => <td>{toCurrency(item.total_paid)}</td>,
          unpaid_total: (item: Invoice) => <td>{toCurrency(item.unpaid_total)}</td>,
          status: (item: Invoice) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          payment_status: (item: Invoice) => (
            <td>{item.status === 'approved' && <Pill label={item.fully_paid ? 'Paid' : 'Unpaid'} />}</td>
          ),
          actions: (item: Invoice) => (
            <td>
              <ActionsMenu
                isLoading={loadState === 'creating-invoice' || loadState === 'updating-invoice'}
                items={invoicesActionsMenuItems(item)}
              />
            </td>
          )
        }}
        newButton={<IconButton onClick={handleNewInvoiceClick} icon={SvgPlus} label="New Invoice" />}
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={invoices.filter((invoice) => invoice.status !== 'disabled').map(toOption)}
            contextType={invoices.filter((invoice) => invoice.status !== 'disabled').length > 0 ? 'Invoice' : undefined}
            consult_id={consult.id}
            animal_id={consult.animal_id}
            initialReason="FinancialTask"
            disablePresets
          />
        }
      />

      {invoiceToPay && customer && (
        <PaymentInvoiceModal
          invoice={invoiceToPay}
          customer={customer}
          isVisible={!!invoiceToPay}
          onClose={() => setInvoiceToPay(undefined)}
          onConfirm={handlePaymentSuccess}
        />
      )}

      {activeTable === 'summary' && (
        <div>
          <div className="mb-2">
            <TableControl />

            <div className="d-flex justify-content-end align-items-center gap-3 mt-2 mb-4">
              <CLoadingButton
                loading={loadState === 'regenerating-summary'}
                shape="rounded-pill"
                disabledOnLoading
                onClick={handleRegenerateSummaryClick}
              >
                Regenerate Summary
              </CLoadingButton>
            </div>
          </div>

          {renderForm(visibleForm)}

          <CTable align="middle">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell colSpan={2} className="d-flex justify-content-between align-items-center">
                  Summary
                  {visitSummary && (
                    <div className="me-2">Updated: {compactDateTimeDisplay(visitSummary?.updated_at)}</div>
                  )}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>
                  {visitSummary ? (
                    <div dangerouslySetInnerHTML={{ __html: visitSummary.summary }} />
                  ) : (
                    <div>{"We'll generate your summary here after the appointment ends."}</div>
                  )}
                </CTableDataCell>
                <CTableDataCell>
                  {visitSummary?.summary && (
                    <CButton
                      className={styles.editButton}
                      variant="outline"
                      shape="rounded-pill"
                      onClick={handleEditSummaryClick}
                    >
                      <SvgPencil height={16} aria-label="Edit" className="primaryColor" />
                    </CButton>
                  )}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>

          <CTable align="middle">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell>AI Summary</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>
                  {visitSummary ? (
                    <div dangerouslySetInnerHTML={{ __html: visitSummary.ai_summary }} />
                  ) : (
                    <div>{"We'll generate your summary here after the appointment ends."}</div>
                  )}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </div>
      )}
    </>
  );
};
