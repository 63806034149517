import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrowIntoCurve = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m8.758 10.965 1.92-1.92-1.92-1.92M3 9.045h7.627"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9 3c3.315 0 6 2.25 6 6s-2.685 6-6 6"
    />
  </svg>
);
export default SvgArrowIntoCurve;
