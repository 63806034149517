import cn from 'classnames';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { Animal } from 'types/Animal';

import { compactDateDisplay, dateDisplay } from 'utils/dates';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

import { AnimalPhoto } from 'components/AnimalPhoto';
import { Pill } from 'components/Pill';

import styles from './PatientCard.module.scss';

type Props = {
  animal: Animal;
};

export const PatientCard = ({ animal }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Link to={generatePath(paths.animalDetails, { id: animal.id })} aria-label={animal.name || 'Patient details'}>
          <AnimalPhoto height={80} width={80} animal={animal} className={cn('rounded-4', styles.patientImage)} />
        </Link>

        <div className={styles.column}>
          <h3 className={styles.patientName}>
            <Link to={generatePath(paths.animalDetails, { id: animal.id })}>{animal.name}</Link>
            {animal.membership?.status && <Pill label={animal.membership.status}></Pill>}
          </h3>
          <div className={styles.row}>
            {animal.species.name}
            {animal.species.name && animal.sex?.name && <DotIcon />}
            {animal.sex?.name}
          </div>
          <div className={styles.row}>{animal.breed?.name}</div>
        </div>
      </div>

      <div className={styles.dateSection}>
        <div className={styles.row}>
          <div className={styles.left}>Birthdate</div>
          <div className={styles.right}>{animal.date_of_birth && dateDisplay(animal.date_of_birth)}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>Created On</div>
          <div className={styles.right}>{animal.created_at && dateDisplay(animal.created_at)}</div>
        </div>
      </div>

      <div className={styles.appointmentsSection}>
        <div className={styles.row}>
          <div className={styles.left}>Next Appointment</div>
          <div className={styles.right}>
            {animal.next_appointment_json?.start_time ? (
              <Link to={`/appointments/${animal.next_appointment_json.id}`}>
                {compactDateDisplay(animal.next_appointment_json.start_time)}
              </Link>
            ) : (
              'None'
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>Last Appointment</div>
          <div className={styles.right}>
            {animal.last_appointment_json?.start_time ? (
              <Link to={`/appointments/${animal.last_appointment_json.id}`}>
                {compactDateDisplay(animal.last_appointment_json.start_time)}
              </Link>
            ) : (
              'None'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
