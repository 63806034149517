type CoreUIColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
type CustomColor = 'brand';

export type Color = CoreUIColor | CustomColor;

export const colorMap = (key: string): Color => {
  const color = {
    'due soon': 'warning',
    'fas 1': 'success',
    'fas 2': 'warning',
    'fas 3': 'warning',
    'fas 4': 'danger',
    'fas 5': 'danger',
    'non-core': 'success',
    'past due': 'danger',
    active: 'success',
    approved: 'success',
    complete: 'success',
    completed: 'success',
    confirmed: 'success',
    core: 'warning',
    cpr: 'success',
    disabled: 'danger',
    draft: 'warning',
    dnr: 'danger',
    due: 'success',
    due_now: 'warning',
    expired: 'danger',
    external: 'warning',
    fulfilled: 'success',
    future: 'warning',
    high: 'danger',
    important: 'danger',
    low: 'success',
    medium: 'warning',
    new_patient: 'brand-subtle',
    overdue: 'danger',
    paid: 'success',
    past_due: 'danger',
    pending: 'warning',
    rejected: 'danger',
    released: 'info',
    required: 'danger',
    signed: 'success',
    upcoming: 'success',
    unpaid: 'danger',
    unsigned: 'danger'
  }[key.toLowerCase()];

  return (color || 'secondary') as Color;
};
