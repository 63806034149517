import { differenceInMonths, differenceInYears, parse } from 'date-fns';

import { AnimalPreviousClinic } from './AnimalPreviousClinic';
import { AbbreviatedAppointment, Appointment } from './Appointment';
import { Breed } from './Breed';
import { Clinic } from './Clinic';
import { Customer } from './Customer';
import { Employee } from './Employee';
import { Membership } from './Membership';
import { PetSex } from './PetSex';
import { PetSpecies } from './PetSpecies';
import { PrescriptionItem } from './PrescriptionItem';
import { Vaccination } from './Vaccination';

export type Animal = {
  animal_previous_clinics: AnimalPreviousClinic[];
  appointments: Appointment[];
  archived_at: string | null;
  breed: Breed | null;
  breed_id: number | null;
  customer: Customer;
  customer_id: number;
  created_at: string;
  date_of_birth: string | null;
  date_of_rabies_vaccination: string | null;
  date_of_death: string | null;
  date_of_desex: string | null;
  demeanor: string | null;
  desexed: boolean | null;
  dead: boolean | null;
  death_reason: string | null;
  fas_score: string | null;
  favorite_treat: string | null;
  hostile: boolean | null;
  id: number;
  insurance_provider_name?: string;
  internal_notes: string | null;
  last_appointment_json?: Partial<AbbreviatedAppointment>;
  membership?: Membership;
  microchip_number?: string;
  name: string | null;
  needs_sedation: boolean | null;
  next_appointment_json?: Partial<AbbreviatedAppointment>;
  no_past_records: boolean;
  past_records_uploaded?: boolean;
  personality_trait_list?: string[];
  pet_peeve_list?: string[];
  pim_id: number;
  photo_provider_id: string | null;
  preferred_clinic?: Clinic;
  preferred_employee?: Employee;
  prescription_items?: PrescriptionItem[];
  previous_clinics_processed: boolean;
  previous_clinics_requested: boolean;
  rabies_next_due_date?: string;
  rabies_number?: string;
  resuscitate: boolean | null;
  sex?: PetSex;
  sex_id?: number;
  siblings: { id: number; name: string | null }[]; // Animal subset
  species: PetSpecies;
  species_id: number;
  updated_at: string;
  vaccinations: Vaccination[];
  weight?: number | null;
  weight_unit?: string;
};

export type AnimalListItem = {
  archived_at: string | null;
  breed: Breed | null;
  customer: { first_name: string; last_name: string; id: number; clinic?: Clinic };
  desexed: boolean | null;
  id: number;
  membership?: Membership;
  name: string | null;
  sex?: PetSex;
  species: PetSpecies;
};

export function age(animal: Animal): string {
  if (!animal.date_of_birth) {
    return '';
  }
  const date = parse(animal.date_of_birth, 'yyyy-MM-dd', new Date());
  const age = differenceInYears(new Date(), date);
  const ageString = age.toString();
  if (age === 1) {
    return '1 year';
  }

  const formattedAgeString = `${ageString} years`;
  return formattedAgeString;
}

export function compactAge(animal: Animal): string {
  if (!animal.date_of_birth) {
    return '';
  }
  const date = parse(animal.date_of_birth, 'yyyy-MM-dd', new Date());
  const years = differenceInYears(new Date(), date);
  const months = differenceInMonths(new Date(), date) % 12;

  return `${years}y ${months}m`;
}
