import * as React from 'react';

import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSpinner } from '@coreui/react-pro';

import { ActionsMenuItem } from 'types/ActionsMenuItem';

import { iconMap } from 'utils/icons';

import { ReactComponent as MoreIcon } from 'assets/images/more.svg';

import styles from './ActionsMenu.module.scss';

type Props = {
  items: ActionsMenuItem[];
  isLoading?: boolean;
};

export const ActionsMenu = ({ items, isLoading = false }: Props) => {
  return (
    <CDropdown alignment="end">
      <CDropdownToggle
        shape="rounded-circle"
        className={styles.toggle}
        color="transparent"
        caret={false}
        aria-label="Actions menu"
      >
        {isLoading ? <CSpinner size="sm" color="primary" /> : <MoreIcon className={styles.moreIcon} />}
      </CDropdownToggle>
      <CDropdownMenu>
        {items.map((item) => (
          <CDropdownItem
            className={styles.dropdownItem}
            href={item.href}
            key={item.label}
            onClick={item.onClick}
            role="button"
            disabled={item.disabled}
          >
            <div className="me-2" style={{ width: '15px', height: '15px' }}>
              {iconMap(item.label)}
            </div>
            <span>{item.label}</span>
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};
