import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CRow, CSmartTable } from '@coreui/react-pro';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Document } from 'types/Document';
import { ListFilter } from 'types/ListFilter';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgClipboard from 'assets/images/SvgClipboard';

import { ActionsMenu } from 'components/ActionsMenu';
import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';
import { Pill } from 'components/Pill';

import { EditDocumentModal } from './EditDocumentModal';
import { EmailDocumentModal } from './EmailDocumentModal';

interface Props {
  documents?: Document[];
  pagination: Pagination;
  handleCreateNewDocument?: () => void;
  updatePage: (page: number) => void;
  handleFilterChange?: (filters: ListFilter[]) => void;
  filters?: ListFilter[];
  tableControl?: React.ReactNode;
  visible?: boolean;
}

export const DocumentsList: React.FC<Props> = ({
  documents,
  pagination,
  handleCreateNewDocument,
  updatePage,
  handleFilterChange,
  filters,
  tableControl,
  visible = true
}) => {
  const [documentToEmail, setDocumentToEmail] = useState<Document>();
  const [documentToEdit, setDocumentToEdit] = useState<Document>();

  if (!visible) {
    return null;
  }

  const actionsMenuItems = (document: Document) => {
    return [
      !document.signed && {
        label: 'Edit',
        onClick: () => setDocumentToEdit(document)
      },
      {
        label: 'View',
        href: generatePath(paths.documentDetails, { id: document.id })
      },
      document.attachment && {
        label: 'Download PDF',
        href: document.attachment.private_url
      },
      document.attachment && {
        label: 'Email Document',
        onClick: () => setDocumentToEmail(document)
      }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  return (
    <CRow className="mb-3">
      <div className="d-flex align-items-center justify-content-between w-100 mb-4">
        {tableControl}
        <div className="d-flex justify-content-end w-100 mt-2">
          {filters && handleFilterChange && <Filters setFilters={handleFilterChange} filters={filters} useRange />}
          <IconButton className="ms-2" onClick={handleCreateNewDocument} label="New Document" icon={SvgClipboard} />
        </div>
      </div>

      <CSmartTable
        pagination={{
          external: true
        }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center',
          'aria-label': 'Documents pagination'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        items={documents}
        tableProps={{
          'aria-label': 'Documents'
        }}
        columns={['document_name', 'created_at', 'status', 'patient', 'consult', 'actions']}
        scopedColumns={{
          created_at: (document: Document) => {
            return <td className="py-2">{document.created_at ? compactDateTimeDisplay(document.created_at) : null}</td>;
          },
          document_name: (document: Document) => {
            return <td className="py-2">{document.name}</td>;
          },
          status: (document: Document) => {
            return (
              <td className="py-2">
                {document.requires_signature && <Pill label={document.signed ? 'signed' : 'unsigned'} />}
              </td>
            );
          },
          patient: (document: Document) => (
            <td className="py-2">
              {document.animal && <Link to={`/animals/${document.animal.id}`}>{document.animal.name}</Link>}
            </td>
          ),
          consult: (document: Document) => (
            <td className="py-2">
              {document.consult && (
                <Link to={`/animals/${document.consult.id}`}>{compactDateTimeDisplay(document.consult.date)}</Link>
              )}
            </td>
          ),
          actions: (document: Document) => (
            <td>
              <ActionsMenu items={actionsMenuItems(document)} />
            </td>
          )
        }}
      />
      {documentToEmail && (
        <EmailDocumentModal
          document={documentToEmail}
          onClose={() => setDocumentToEmail(undefined)}
          onSuccess={() => setDocumentToEmail(undefined)}
        />
      )}
      {documentToEdit && (
        <EditDocumentModal
          document={documentToEdit}
          onClose={() => setDocumentToEdit(undefined)}
          onSuccess={() => setDocumentToEdit(undefined)}
        />
      )}
    </CRow>
  );
};
