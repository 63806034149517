import * as React from 'react';
import { Fragment } from 'react';

import { CButton, CCard, CCardBody, CCardHeader, CSmartTable } from '@coreui/react-pro';

import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DiagnosticRequestItem } from 'types/DiagnosticRequestItem';
import { DiagnosticResult } from 'types/DiagnosticResult';
import { DiagnosticResultItem } from 'types/DiagnosticResultItem';

import { compactDateTimeDisplay } from 'utils/dates';

import { DiagnosticResultAttachments } from 'components/DiagnosticResultAttachments';
import { List } from 'components/List';
import { Pill } from 'components/Pill';

const applyResultStyles = (item: DiagnosticResultItem) => {
  const isLower = item.range_low && Number(item.value) < Number(item.range_low);
  const isHigher = item.range_high && Number(item.value) > Number(item.range_high);

  return { ...item, ...((isLower || isHigher) && { _props: { color: 'danger' } }) };
};

interface DiagnosticRequestDetailsProps {
  item: DiagnosticRequest;
  handleEditDiagnosticResult?: (item: DiagnosticResult) => void;
  handleSendOrder?: (item: DiagnosticRequest) => void;
}

export const DiagnosticRequestDetails: React.FC<DiagnosticRequestDetailsProps> = ({
  item,
  handleEditDiagnosticResult,
  handleSendOrder
}) => {
  const formatAttributes = (data: DiagnosticRequestItem) => {
    return {
      name: `${data.diagnostic.name} (${data.diagnostic.code})`,
      accession_number: item.reference_number,
      requested_at: compactDateTimeDisplay(item.requested_at),
      updated_at: compactDateTimeDisplay(item.updated_at),
      updated_by: item.updated_by_employee?.full_name_with_title
    };
  };

  const resultTitle = (result: DiagnosticResult): string => {
    const title = result.display_name ? result.display_name : result.diagnostic_name;
    return title || 'Result Summary';
  };

  return (
    <>
      {item.diagnostic_request_items.map((requestItem: DiagnosticRequestItem) => (
        <Fragment key={requestItem.id}>
          <div className="d-flex align-items-center">
            <span className="me-2 fw-semibold">Request Summary</span>
            <Pill label={item.status} />
            {handleSendOrder && (
              <CButton
                type="button"
                className="ms-2"
                size="sm"
                shape="rounded-pill"
                onClick={() => handleSendOrder(item)}
              >
                Send Order
              </CButton>
            )}
          </div>
          <List key={requestItem.id} data={formatAttributes(requestItem)} />
        </Fragment>
      ))}

      {item.diagnostic_results?.map((result, index) => (
        <Fragment key={index}>
          <CCard className="mb-2">
            <CCardHeader>
              <div className="d-flex align-items-center">
                <div className="me-2 fw-semibold">{resultTitle(result)}</div>
                <Pill label={result.status} />
                {result.results_url && (
                  <div>
                    <CButton className="ms-2" size="sm" target="_blank" shape="rounded-pill" href={result.results_url}>
                      View Result
                    </CButton>
                  </div>
                )}
                {handleEditDiagnosticResult && (
                  <div>
                    <CButton
                      type="button"
                      className="ms-2"
                      size="sm"
                      target="_blank"
                      shape="rounded-pill"
                      onClick={() => handleEditDiagnosticResult(result)}
                    >
                      Edit
                    </CButton>
                  </div>
                )}
              </div>
            </CCardHeader>
            <CCardBody>
              {result.reference_number ? <div>Result Accession Number: {result.reference_number}</div> : null}
              {result.attachments && <DiagnosticResultAttachments attachments={result.attachments} />}
              <CSmartTable
                columns={['name', 'value', 'unit', 'range_low', 'range_high', 'qualifier']}
                items={result.diagnostic_result_items.map(applyResultStyles)}
                tableHeadProps={{ color: 'dark' }}
                tableProps={{ className: 'mb-0', caption: 'Diagnostic results, with out-of-range values in red' }}
                itemsPerPage={result.diagnostic_result_items.length}
              />
              <div>
                <span className="fw-semibold">Outcome: </span>
                <span dangerouslySetInnerHTML={{ __html: result.outcome ?? '' }} />
              </div>
            </CCardBody>
          </CCard>
        </Fragment>
      ))}
    </>
  );
};
