import { identity } from 'utils/identity';
import { formatWithBreaks } from 'utils/strings';

export type ConsultTemplate = {
  id: number;
  created_at?: string;
  created_by_employee_id: number;
  name: string;
  description: string;
  external_prescription: boolean;
  content: string;
  type: ConsultTemplateType;
  status: ConsultTemplateStatus;
  updated_at: string;
  updated_by_employee_id: number;
};

export type ConsultTemplateType =
  | 'HistoryTemplate'
  | 'AssessmentTemplate'
  | 'PhysicalExamTemplate'
  | 'ProcedureTemplate'
  | 'DocumentTemplate'
  | 'ConsultPlanPlanTemplate'
  | 'ConsultPlanCustomerDiscussionTemplate';

export type ConsultTemplateStatus = 'active' | 'disabled';

export const cleanConsultTemplateType = (type: string): string => {
  const types: { [key: string]: string } = {
    HistoryTemplate: 'History',
    AssessmentTemplate: 'Assessment',
    PhysicalExamTemplate: 'Physical Exam',
    ProcedureTemplate: 'Procedure',
    DocumentTemplate: 'Document'
  };
  return types[type];
};

export type FormLineOption = {
  type: 'text' | 'select' | 'label' | 'textarea';
  label: string;
  options?: string[]; // used for select
  selectedValue?: string;
  tabIndex?: number;
};

export type FormLine = {
  label: string;
  options: FormLineOption[];
};

export const consultTemplateInstructions = [
  '- Each line is a form line. A line may have multiple inputs. Defaults are optional.',
  "- Text format: `Label #INPUT#'Default'`. Example: `Steps #INPUT#'1. Check for existing microchip...'`",
  "- Large text format: `Label #TEXTAREA#'Default'`. Example: `Description #TEXTAREA#'The microchip is inserted...'`",
  "- Radio format: `Label #INPUT#[Choice A/Choice B]'Default'`. Example: `Has Microchip? #INPUT#[Yes/No]'No'`"
].join('\n');

/* The formatting rules expectations:
    - Each line is a form line, separated by a \n
    - Anything before the first #INPUT# is the line label
    - A line may have multiple inputs; anything between the previous label and the next #INPUT# is a label for the input
    - An input may be a radio select, options are separated by a /
    - Radio options cannot have a nested #INPUT#
*/
export const consultTemplateForm = (template: Partial<ConsultTemplate>): FormLine[] => {
  if (template.content === undefined) return [];
  const lines = template.content.split('\n');
  let index = 1;

  const form: FormLine[] = lines.map((line) => {
    const inputLine = line.indexOf('#INPUT#') > -1;
    const textAreaLine = line.indexOf('#TEXTAREA#') > -1;
    if (!inputLine && !textAreaLine) return { label: line, options: [{ label: line, type: 'label' }] };

    const regex = /([^#]+)(?:#INPUT#(?:\[((?:[^\]]*\|?[^\]]*\/?)*)\])?|#TEXTAREA#|#INPUT#)(?:'([^']*)')?/g;

    let match;
    const inputs: FormLineOption[] = [];
    while ((match = regex.exec(line)) !== null) {
      let options;
      if (match[2]) {
        options = match[2]
          .split('/')
          .map((option) => option.trim())
          .filter((option) => option !== '');
      }
      inputs.push({
        label: match[1].trim(),
        type: options ? 'select' : textAreaLine ? 'textarea' : 'text',
        options: options,
        selectedValue: match[3],
        tabIndex: index++
      });
    }
    return {
      label: line.slice(0, line.indexOf('#INPUT#')),
      options: inputs
    };
  });
  return form;
};

export const compileConsultTemplate = (formLines: FormLine[]): string => {
  return formLines
    .map((line) =>
      line.options
        .filter((option): option is FormLineOption & { selectedValue: string } => !!option.selectedValue)
        .map((option) => `<b>${option.label}</b> ${formatWithBreaks(option.selectedValue)}`)
        .join(' ')
    )
    .filter(identity)
    .join('<br>');
};

export const documentTemplateInstructions =
  '- Text entry format: `#INPUT#`\n' +
  '- Signature entry format: `#SIGNATURE#`, and must be on its own line\n' +
  '- Large text entry format: `#TEXTAREA#`\n' +
  '- Select format: `#INPUT#[Choice A/Choice B]`\n' +
  '- Enter variables like: `{{animal.name}}`. Expected data can be entered using the first button in the toolbar.\n' +
  "- Today's date: `{{today}}`";
