import cn from 'classnames';
import * as React from 'react';
import { ReactNode, useState } from 'react';

import { CFormCheck, CSmartTable } from '@coreui/react-pro';

import { MedicalHistoryItem } from 'types/MedicalHistoryItem';
import { PrescriptionItem } from 'types/PrescriptionItem';

import { toKebabCase } from 'utils/strings';

import styles from './MedicalHistoryTableV2.module.scss';

type Column =
  | {
      key: string;
      label?: string;
      _style?: { width: string };
    }
  | string;

type Props = {
  caption?: string;
  columns: Column[];
  items: MedicalHistoryItem[];
  name: string;
  scopedColumns: object;
  title?: string;
  visible?: boolean;

  newButton: ReactNode; // Trigger new form of this item
  secondaryButton?: ReactNode; // Secondary action, such as tasks
  tableControl?: ReactNode; // Control this table's visibility among others
  form: ReactNode; // Form to create or edit an item
};

export const MedicalHistoryTableV2 = ({
  caption,
  columns,
  form,
  items,
  title,
  name,
  newButton,
  scopedColumns,
  secondaryButton,
  tableControl,
  visible = true,
  ...rest
}: Props) => {
  const [hideItems, setHideItems] = useState(true);

  const toggleHideItems = () => setHideItems(!hideItems);

  const canBeCurrent = items.length && Object.keys(items[0]).includes('current');
  const toggleLabel = canBeCurrent ? 'Hide not currently taking' : 'Hide disabled';

  const itemFilter = (item: MedicalHistoryItem) => {
    let perItemFilter;
    if (canBeCurrent) {
      perItemFilter = (item as PrescriptionItem).current;
    } else {
      perItemFilter = item.status !== 'disabled';
    }

    return !hideItems || perItemFilter;
  };

  if (!visible) return null;

  return (
    <div>
      <div className="mb-2">
        {tableControl}

        <div className="d-flex justify-content-between align-items-center gap-3 mt-2 mb-4">
          {title && <h2>{title}</h2>}
          <div className="d-flex align-items-center ms-auto gap-3">
            {items.length > 0 && (
              <CFormCheck
                className={cn('mb-0', styles.checkbox)}
                id={`${toKebabCase(name)}-checkbox`}
                label={toggleLabel}
                onChange={toggleHideItems}
                checked={hideItems}
              />
            )}
            {secondaryButton}
            {newButton}
          </div>
        </div>
      </div>
      {form}

      {items.length > 0 && (
        <CSmartTable
          columns={columns}
          items={items.filter(itemFilter)}
          itemsPerPage={20}
          pagination={true}
          scopedColumns={scopedColumns}
          paginationProps={{
            pages: Math.ceil(items.filter(itemFilter).length / 20) || 1,
            align: 'center'
          }}
          tableHeadProps={{ color: 'dark' }}
          tableProps={{ className: 'mb-5', 'aria-label': name, caption }}
          {...rest}
        />
      )}
    </div>
  );
};
