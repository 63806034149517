import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOutlineCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 16.5c4.125 0 7.5-3.375 7.5-7.5S13.125 1.5 9 1.5 1.5 4.875 1.5 9s3.375 7.5 7.5 7.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m5.813 9 2.122 2.123 4.253-4.246"
    />
  </svg>
);
export default SvgOutlineCheck;
