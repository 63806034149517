import { ErrorBoundary, Provider } from '@rollbar/react';
import axios from 'axios';
import { rollbarToken } from 'parsedEnv';
import { ProtectedRoute } from 'ProtectedRoute';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Rollbar from 'rollbar';
import HospitalSheetDetails from 'views/whiteboard/HospitalSheetDetails';
import WhiteboardOverview from 'views/whiteboard/WhiteboardOverview';

import { onRequestInterceptor, onResponseErrorInterceptor } from 'api/config/service';

import { AuthContext, AuthProvider } from 'hooks/useAuth';

import { ClinicProvider } from 'contexts/ClinicContext';

import './scss/style.scss';

import AppLogout from './components/AppLogout';
import { CableProvider } from './components/Cable';
import AnimalDetails from './views/animals/Details';
import AnimalList from './views/animals/List';
import AnimalMedicalHistory from './views/animals/MedicalHistory';
import AppointmentTypeDetails from './views/appointment_types/Details';
import AppointmentTypeEdit from './views/appointment_types/Edit';
import AppointmentTypeList from './views/appointment_types/List';
import AppointmentCalendar from './views/appointments/Calendar';
import AppointmentDetails from './views/appointments/Details';
import GuidedForm from './views/appointments/GuidedForm';
import AppointmentCheckout from './views/checkout/AppointmentCheckout';
import ConsultTemplatesDetails from './views/consult_templates/Details';
import ConsultTemplatesEdit from './views/consult_templates/Edit';
import ConsultTemplatesList from './views/consult_templates/List';
import ConsultTemplatesNew from './views/consult_templates/New';
import ConsultDetails from './views/consults/Details';
import CustomerDetails from './views/customers/Details';
import Dashboard from './views/dashboard/Dashboard';
import DiagnosticsQueue from './views/diagnostics/DiagnosticsQueue';
import DocumentTemplatesDetails from './views/document_templates/Details';
import DocumentTemplatesEdit from './views/document_templates/Edit';
import DocumentTemplatesList from './views/document_templates/List';
import DocumentTemplatesNew from './views/document_templates/New';
import DocumentDetails from './views/documents/Details';
import EmployeeDetail from './views/employees/Details';
import EmployeeEdit from './views/employees/Edit';
import EmployeesList from './views/employees/List';
import EmployeeNew from './views/employees/New';
import HealthPlansAddProduct from './views/health_plans/AddProduct';
import HealthPlansAddTrigger from './views/health_plans/AddTrigger';
import HealthPlansDetail from './views/health_plans/Details';
import HealthPlansEdit from './views/health_plans/Edit';
import HealthPlansEditProduct from './views/health_plans/EditProduct';
import HealthPlansEditTrigger from './views/health_plans/EditTrigger';
import HealthPlansList from './views/health_plans/List';
import HealthPlansNew from './views/health_plans/New';
import Huddle from './views/huddle/HuddleList';
import InvoiceDetails from './views/invoices/Details';
import ForgotPassword from './views/login/ForgotPassword';
import Login from './views/login/Login';
import ResetPassword from './views/login/ResetPassword';
import MasterProblemsDetail from './views/master_problems/Details';
import MasterProblemsEdit from './views/master_problems/Edit';
import MasterProblemsList from './views/master_problems/List';
import MasterProblemsNew from './views/master_problems/New';
import MemosInbox from './views/memos/Inbox';
import PrescriptionDetails from './views/prescriptions/Details';
import PresentingProblemsDetail from './views/presenting_problems/Details';
import PresentingProblemsEdit from './views/presenting_problems/Edit';
import PresentingProblemsList from './views/presenting_problems/List';
import PresentingProblemsNew from './views/presenting_problems/New';
import PreviousClinicDetails from './views/previous_clinics/Details';
import PreviousClinicsQueue from './views/previous_clinics_queue/Queue';
import ProductSuppliersEdit from './views/product_suppliers/Edit';
import ProductSuppliersList from './views/product_suppliers/List';
import ProductSuppliersNew from './views/product_suppliers/New';
import ProductsEdit from './views/products/Edit';
import ProductsList from './views/products/List';
import ProductsNew from './views/products/New';
import RefillsQueue from './views/refills/Queue';
import Schedule from './views/schedule/Schedule';
import SchedulingCategoryEdit from './views/scheduling_categories/Edit';
import SchedulingCategoryList from './views/scheduling_categories/List';
import SchedulingCategoryNew from './views/scheduling_categories/New';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const rollbarConfig = {
  accessToken: rollbarToken,
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.NODE_ENV
};
export const rollbar = new Rollbar(rollbarConfig);

axios.defaults.withCredentials = true;
axios.interceptors.request.use(onRequestInterceptor);
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return onResponseErrorInterceptor(error);
  }
);

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <AuthProvider>
          <AppLogout>
            <CableProvider>
              <ClinicProvider>
                <ToastContainer
                  position="top-right"
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  transition={Slide}
                />
                <AuthContext.Consumer>
                  {({ employee }) => {
                    return (
                      <Routes>
                        <Route path="forgot_password" element={<ForgotPassword />} />
                        <Route path="reset_password" element={<ResetPassword />} />
                        <Route path="/*" element={!employee ? <Login /> : <DefaultLayout />}>
                          <Route path="*" element={<ProtectedRoute />}>
                            <Route path="*" element={<Dashboard />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="customers" element={<Dashboard />} />
                            <Route path="customers/:id" element={<CustomerDetails />} />
                            <Route path="consults/:id" element={<ConsultDetails />} />
                            <Route path="animals" element={<AnimalList />} />
                            <Route path="animals/:id" element={<AnimalDetails />} />
                            <Route path="animals/:id/medical_history" element={<AnimalMedicalHistory />} />
                            <Route path="appointments" element={<AppointmentCalendar />} />
                            <Route path="appointments/:id" element={<AppointmentDetails />} />
                            <Route path="appointments/:id/checkout" element={<AppointmentCheckout />} />
                            <Route path="appointments/calendar" element={<AppointmentCalendar />} />
                            <Route path="appointments/new" element={<GuidedForm />} />
                            <Route path="appointments/types" element={<AppointmentTypeList />} />
                            <Route path="appointments/types/:id" element={<AppointmentTypeDetails />} />
                            <Route path="appointments/types/:id/edit" element={<AppointmentTypeEdit />} />
                            <Route path="consult_templates" element={<ConsultTemplatesList />} />
                            <Route path="consult_templates/:id" element={<ConsultTemplatesDetails />} />
                            <Route path="consult_templates/:id/edit" element={<ConsultTemplatesEdit />} />
                            <Route path="consult_templates/new" element={<ConsultTemplatesNew />} />
                            <Route path="diagnostics" element={<DiagnosticsQueue />} />
                            <Route path="documents/:id" element={<DocumentDetails />} />
                            <Route path="document_templates" element={<DocumentTemplatesList />} />
                            <Route path="document_templates/:id" element={<DocumentTemplatesDetails />} />
                            <Route path="document_templates/:id/edit" element={<DocumentTemplatesEdit />} />
                            <Route path="document_templates/new" element={<DocumentTemplatesNew />} />
                            <Route path="employees" element={<EmployeesList />} />
                            <Route path="employees/:id" element={<EmployeeDetail />} />
                            <Route path="employees/:id/edit" element={<EmployeeEdit />} />
                            <Route path="employees/add" element={<EmployeeNew />} />
                            <Route path="health_plans" element={<HealthPlansList />} />
                            <Route path="health_plans/new" element={<HealthPlansNew />} />
                            <Route path="health_plans/:id/edit" element={<HealthPlansEdit />} />
                            <Route path="health_plans/:id" element={<HealthPlansDetail />} />
                            <Route path="health_plans/:id/products/new" element={<HealthPlansAddProduct />} />
                            <Route
                              path="health_plans/:planId/products/:productId/edit"
                              element={<HealthPlansEditProduct />}
                            />
                            <Route path="health_plans/:id/triggers/new" element={<HealthPlansAddTrigger />} />
                            <Route
                              path="health_plans/:planId/triggers/:triggerId/edit"
                              element={<HealthPlansEditTrigger />}
                            />
                            <Route path="hospital_sheet/:id" element={<HospitalSheetDetails />} />
                            <Route path="huddle" element={<Huddle />} />
                            <Route path="invoices/:id" element={<InvoiceDetails />} />
                            <Route path="master_problems" element={<MasterProblemsList />} />
                            <Route path="master_problems/:id" element={<MasterProblemsDetail />} />
                            <Route path="master_problems/new" element={<MasterProblemsNew />} />
                            <Route path="master_problems/:id/edit" element={<MasterProblemsEdit />} />
                            <Route path="memos" element={<MemosInbox />} />
                            <Route path="prescriptions/:id" element={<PrescriptionDetails />} />
                            <Route path="presenting_problems" element={<PresentingProblemsList />} />
                            <Route path="presenting_problems/:id" element={<PresentingProblemsDetail />} />
                            <Route path="presenting_problems/new" element={<PresentingProblemsNew />} />
                            <Route path="presenting_problems/:id/edit" element={<PresentingProblemsEdit />} />
                            <Route path="previous_clinics" element={<PreviousClinicsQueue />} />
                            <Route path="previous_clinics/:id" element={<PreviousClinicDetails />} />
                            <Route path="products" element={<ProductsList />} />
                            <Route path="products/:id/edit" element={<ProductsEdit />} />
                            <Route path="products/new" element={<ProductsNew />} />
                            <Route path="product_suppliers" element={<ProductSuppliersList />} />
                            <Route path="product_suppliers/:id/edit" element={<ProductSuppliersEdit />} />
                            <Route path="product_suppliers/new" element={<ProductSuppliersNew />} />
                            <Route path="previous_clinics/:id/edit" element={<PreviousClinicDetails />} />
                            <Route path="refills" element={<RefillsQueue />} />
                            <Route path="schedule" element={<Schedule />} />
                            <Route path="scheduling_categories" element={<SchedulingCategoryList />} />
                            <Route path="scheduling_categories/new" element={<SchedulingCategoryNew />} />
                            <Route path="scheduling_categories/:id/edit" element={<SchedulingCategoryEdit />} />
                            <Route path="whiteboard" element={<WhiteboardOverview />} />
                          </Route>
                        </Route>
                      </Routes>
                    );
                  }}
                </AuthContext.Consumer>
              </ClinicProvider>
            </CableProvider>
          </AppLogout>
        </AuthProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
