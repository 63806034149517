import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { createHealthStatus, fetchHealthStatusesForConsult, updateHealthStatus } from 'api/HealthStatuses';

import { Consult } from 'types/Consult';
import { HealthStatus } from 'types/HealthStatus';

import { usePoll } from 'hooks/usePoll';

import { compactDateTimeDisplay } from 'utils/dates';
import { filterMetadata } from 'utils/healthStatuses';
import { healthStatusToOption } from 'utils/selectOptions';
import { statusAction } from 'utils/status';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { IconButton } from 'components/IconButton';
import { List } from 'components/List';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

import { HealthStatusForm } from './HealthStatusForm';

type Form = 'new-health-status' | 'edit-health-status';

type Props = {
  consult: Consult;
  scrollUp: () => void;
  refetchAnimal: () => void;
};

export const HealthStatusesStep = ({ consult, scrollUp, refetchAnimal }: Props) => {
  const [healthStatuses, setHealthStatuses] = useState<HealthStatus[]>([]);

  const [visibleForm, setVisibleForm] = useState<Form>();
  const [editingItem, setEditingItem] = useState<HealthStatus>();
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    fetchHealthStatusesForConsult(consult.id, (healthStatuses) => {
      if (healthStatuses.length === 0) {
        setVisibleForm('new-health-status');
      }

      setHealthStatuses(healthStatuses);
    });
  }, [consult]);
  usePoll(fetch);

  const hideForm = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  const remountForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleNewClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-health-status');
  };

  const handleEditClick = (item: HealthStatus) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-health-status');
  };

  const handleSuccess = (message: string) => {
    hideForm();
    toast.success(message);
    fetch();
    refetchAnimal();

    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleCreateHealthStatus = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      consult_id: consult.id,
      ...Object.fromEntries(formData.entries()),
      max_dental_score: formData.get('dental_score') ? Number(formData.get('max_dental_score')) : null,
      max_pain_score: formData.get('pain_score') ? Number(formData.get('max_pain_score')) : null,
      weight_unit: 'kg'
    };

    createHealthStatus(formJson, {
      onSuccess: () => handleSuccess('Health Status created!'),
      onError: handleError
    });
  };

  const handleUpdateHealthStatus = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      ...Object.fromEntries(formData.entries()),
      max_dental_score: formData.get('dental_score') ? Number(formData.get('max_dental_score')) : null,
      max_pain_score: formData.get('pain_score') ? Number(formData.get('max_pain_score')) : null,
      weight_unit: 'kg'
    };

    updateHealthStatus(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Health Status updated!'),
      onError: handleError
    });
  };

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'new-health-status':
        return (
          <HealthStatusForm key={formKey} hideForm={hideForm} loading={loading} onSubmit={handleCreateHealthStatus} />
        );
      case 'edit-health-status':
        if (editingItem) {
          return (
            <HealthStatusForm
              key={formKey}
              healthStatus={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateHealthStatus}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <MedicalHistoryTableV2
      name="Health Statuses"
      items={healthStatuses}
      form={renderForm(visibleForm)}
      columns={[{ key: 'updated', label: 'Updated' }, { key: 'status' }, { key: 'statuses' }, { key: 'actions' }]}
      scopedColumns={{
        updated: (item: HealthStatus) => <TableAuditData item={item} handleClick={handleEditClick(item)} />,
        statuses: (item: HealthStatus) => (
          <td>
            <List data={filterMetadata(item)} />
          </td>
        ),
        status: (item: HealthStatus) => (
          <td>
            <Pill label={item.status} />
          </td>
        ),
        updated_at: (item: HealthStatus) => (
          <td>
            {compactDateTimeDisplay(item.updated_at)}
            {item.updated_by_employee && ` (${item.updated_by_employee.last_name})`}
          </td>
        ),
        actions: (item: HealthStatus) => (
          <td>
            <ActionsMenu
              items={[
                {
                  label: 'Edit',
                  onClick: handleEditClick(item)
                },
                statusAction({
                  item,
                  handleSuccess,
                  handleError,
                  name: 'Health status',
                  updater: updateHealthStatus
                })
              ]}
            />
          </td>
        )
      }}
      newButton={<IconButton icon={SvgPlus} onClick={handleNewClick} label="New Health Status" />}
      secondaryButton={
        <NewTaskButton
          buttonText="New Task"
          contextOptions={healthStatuses.filter((status) => status.status !== 'disabled').map(healthStatusToOption)}
          contextType={
            healthStatuses.filter((healthStatus) => healthStatus.status !== 'disabled').length > 0
              ? 'HealthStatus'
              : undefined
          }
          consult_id={consult.id}
          animal_id={consult.animal_id}
          disablePresets
        />
      }
    />
  );
};
