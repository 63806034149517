import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { paths } from 'routes';

import { CButton, CCol, CFormLabel, CRow, CSmartTable } from '@coreui/react-pro';

import { fetchProductSuppliers } from 'api/ProductSuppliers';

import { calculatePages, Pagination } from 'types/Pagination';
import { ProductSupplier } from 'types/ProductSupplier';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { pluralize } from 'utils/strings';

const List = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [productSuppliersList, setProductSuppliersList] = useState<ProductSupplier[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  useDocumentTitle('Products List');

  const updateProductSupplierList = useCallback((products: ProductSupplier[]) => {
    setIsLoading(false);
    setProductSuppliersList(products);
  }, []);

  useEffect(() => {
    const page = searchParams.get('page');
    if (page) setPagination((prev) => ({ ...prev, page: parseInt(page) }));

    fetchProductSuppliers(setProductSuppliersList, setPagination, page ? parseInt(page) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePage = (page: number) => {
    if (pagination.page !== page) {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
      fetchProductSuppliers(updateProductSupplierList, setPagination, page);
    }
  };
  return (
    <div>
      <CRow>
        <CCol xs={11}>
          <h1 className="mb-0">Product Suppliers</h1>
        </CCol>
        <CCol xs={1}>
          <CButton color="primary" href={generatePath(paths.productSupplierNew)} shape="rounded-pill">
            New
          </CButton>
        </CCol>
      </CRow>
      {!isLoading && (
        <CFormLabel className="mt-2 text-body-secondary" color="light">
          {pagination.total} {pluralize('product supplier', pagination.total)} found
        </CFormLabel>
      )}
      {productSuppliersList && productSuppliersList.length > 0 && (
        <CSmartTable
          items={productSuppliersList}
          itemsPerPage={25}
          pagination={{
            external: true
          }}
          paginationProps={{
            activePage: pagination.page,
            pages: calculatePages(pagination),
            align: 'center'
          }}
          onActivePageChange={(activePage) => updatePage(activePage)}
          columns={['name', 'status']}
          tableHeadProps={{ color: 'dark' }}
          scopedColumns={{
            name: (supplier: ProductSupplier) => {
              return (
                <td className="py-2">
                  <Link to={generatePath(paths.productSupplierEdit, { id: supplier.id })}>{supplier.name}</Link>
                </td>
              );
            },
            status: (supplier: ProductSupplier) => {
              return (
                <td className="py-2" style={{ textTransform: 'capitalize' }}>
                  {supplier.status}
                </td>
              );
            }
          }}
        ></CSmartTable>
      )}
    </div>
  );
};

export default List;
