import * as React from 'react';
import { createRef, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentsList } from 'views/documents/DocumentsList';
import { NewDocumentModal } from 'views/documents/NewDocumentModal';
import { TasksListTable } from 'views/employee_tasks/TasksListTable';

import { cilClipboard, cilExitToApp, cilFolder, cilFolderOpen, cilPrint } from '@coreui/icons';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';

import { fetchAnimalHealthPlans } from 'api/AnimalHealthPlans';
import { fetchAnimal, updateAnimal } from 'api/Animals';
import { fetchAppointmentsByAnimal } from 'api/Appointments';
import { createAttachmentsV2, fetchAttachmentsForAnimal } from 'api/Attachments';
import { fetchAttachmentTypes } from 'api/AttachmentTypes';
import { downloadSoapRecords } from 'api/ClinicalRecords';
import { fetchClinic } from 'api/Clinics';
import { fetchCommunicationLogsByCustomer } from 'api/CommunicationLogs';
import { fetchContextsForAnimal } from 'api/Contexts';
import { fetchCustomer } from 'api/Customers';
import { fetchDocumentsByAnimal } from 'api/Documents';
import { fetchEmployeeTasks } from 'api/EmployeeTasks';
import { fetchEstimatesForAnimal } from 'api/Estimates';
import { fetchInvoicesForAnimal } from 'api/Invoices';
import { fetchVaccinationsByAnimal } from 'api/Vaccinations';

import { Animal } from 'types/Animal';
import { AnimalHealthPlan, healthPlanStatuses } from 'types/AnimalHealthPlan';
import { Appointment } from 'types/Appointment';
import { Attachment } from 'types/Attachment';
import { AttachmentType } from 'types/AttachmentType';
import { Clinic } from 'types/Clinic';
import { CommunicationLog } from 'types/CommunicationLog';
import { Context } from 'types/Context';
import { Customer } from 'types/Customer';
import { Document } from 'types/Document';
import { EmployeeTask } from 'types/EmployeeTask';
import { Estimate, estimateStatuses } from 'types/Estimate';
import { Invoice, invoiceStatuses } from 'types/Invoice';
import { ListFilter, transformFilters } from 'types/ListFilter';
import { Pagination } from 'types/Pagination';
import { topics } from 'types/Topic';
import { Vaccination } from 'types/Vaccination';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { pluralize } from 'utils/strings';

import { CommunicationLogList } from 'components/CommunicationLogList';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { PrintLabelSelectorModal } from 'components/print_labels/PrintLabelSelectorModal';

import styles from './Details.module.scss';

import { AccountDetails } from './AccountDetails';
import { AnimalHealthPlansList } from './AnimalHealthPlansList';
import { AppointmentsList } from './AppointmentsList';
import { AttachmentsList } from './AttachmentsList';
import { EstimatesList } from './EstimatesList';
import { HealthPlansList } from './HealthPlansList';
import { InfoCard } from './InfoCard';
import { InternalNote } from './InternalNote';
import { InvoicesList } from './InvoicesList';
import { MasterProblemsList } from './MasterProblemsList';
import { NewAttachmentModal } from './NewAttachmentModal';
import { OwnerInfoCard } from './OwnerInfoCard';
import { UpcomingAppointmentsCard } from './UpcomingAppointmentsCard';

export const sidebarSteps = ['master-problems', 'health-plans'] as const;

export type SidebarStep = (typeof sidebarSteps)[number];

const animalTabs = [
  'health-plans',
  'appointments',
  'documents',
  'invoices',
  'treatment-plans',
  'tasks',
  'communications'
] as const;

type Tab = (typeof animalTabs)[number];

const documentsTables = ['documents', 'attachments'] as const;
export type Table = (typeof documentsTables)[number];

type LoadState = 'creating-attachment' | 'general';
type Modals = 'new-attachment' | 'new-document' | 'archive' | 'print-label';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const navigate = useNavigate();
  const [animal, setAnimal] = useState<Animal>();
  const [customer, setCustomer] = useState<Customer>();

  const scrollRef = createRef<HTMLDivElement>();
  const scrollUp = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();

  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [loadState, setLoadState] = useState<LoadState>();
  const [sidebarStep, setSidebarStep] = useState<SidebarStep>(sidebarSteps[0]);

  const [appointments, setAppointments] = useState<Appointment[]>();
  const [appointmentsPagination, setAppointmentsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [appointmentsFilters, setAppointmentsFilters] = useState<ListFilter[]>([]);

  const [healthPlans, setHealthPlans] = useState<AnimalHealthPlan[]>();
  const [healthPlansFilters, setHealthPlansFilters] = useState<ListFilter[]>([
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', ...healthPlanStatuses] }
  ]);

  const [invoices, setInvoices] = useState<Invoice[] | undefined>();
  const [invoicesPagination, setInvoicesPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [invoicesFilters, setInvoicesFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', ...invoiceStatuses] },
    { name: 'Payment Status', key: 'payment_status', value: undefined, type: 'select', options: ['', 'paid', 'unpaid'] }
  ]);

  const [estimates, setEstimates] = useState<Estimate[]>();
  const [estimatesPagination, setEstimatesPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [estimatesFilters, setEstimatesFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', ...estimateStatuses] }
  ]);

  const [contexts, setContexts] = useState<Context[]>();
  const communicationLogListRef = React.useRef<HTMLDivElement>(null);
  const [communicationLogs, setCommunicationLogs] = useState<CommunicationLog[]>();
  const [communicationLogsPagination, setCommunicationLogsPagination] = useState<Pagination>({
    page: 1,
    perPage: 12,
    total: 1
  });
  const [communicationsFilters, setCommunicationsFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Topic', key: 'topic', value: undefined, type: 'select', options: ['', ...topics] }
  ]);

  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);

  // Documents and Attachments
  const [documents, setDocuments] = useState<Document[]>();
  const [documentsPagination, setDocumentsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [attachments, setAttachments] = useState<Attachment[]>();
  const [attachmentsPagination, setAttachmentsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [activeDocumentsTable, setActiveDocumentsTable] = useState<Table>(documentsTables[0]);
  const [documentsFilters, setDocumentsFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', 'signed', 'unsigned'] },
    { name: 'Name', key: 'name', value: undefined, type: 'input' }
  ]);

  const [employeeTasks, setEmployeeTasks] = useState<EmployeeTask[]>([]);
  const [tasksPagination, setTasksPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<Tab>((searchParams.get('tab') as Tab) ?? animalTabs[0]);
  const [attachmentTypes, setAttachmentTypes] = useState<AttachmentType[]>();

  const isArchived = useMemo(() => Boolean(animal?.archived_at), [animal]);

  useEffect(() => {
    clinicContext && fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useDocumentTitle(animal && `${animal.name} ${animal.customer.last_name}`);

  useEffect(() => {
    fetchAnimal(id, (animal: Animal) => {
      setAnimal(animal);

      fetchCommunicationLogsByCustomer(
        animal.customer_id,
        { animal_id: id },
        setCommunicationLogs,
        setCommunicationLogsPagination
      );

      fetchCustomer(animal.customer_id, setCustomer);
    });

    fetchEmployeeTasks({
      onSuccess: setEmployeeTasks,
      setPagination: setTasksPagination,
      filterParams: {
        animal_id: id
      }
    });

    fetchContextsForAnimal(id, setContexts);
    fetchAppointmentsByAnimal({
      params: { animal_id: id },
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
    fetchAnimalHealthPlans({ animal_id: id }, setHealthPlans);
    fetchInvoicesForAnimal({ animal_id: id }, setInvoices, setInvoicesPagination);
    fetchEstimatesForAnimal(id, {}, setEstimates, setEstimatesPagination);
    fetchDocumentsByAnimal({
      params: { animal_id: id },
      onSuccess: setDocuments,
      setPagination: setDocumentsPagination
    });
    fetchAttachmentsForAnimal(id, { onSuccess: setAttachments, setPagination: setAppointmentsPagination });
    fetchVaccinationsByAnimal(id, setVaccinations);
    fetchAttachmentTypes({ onSuccess: setAttachmentTypes });
  }, [id]);

  useEffect(() => {
    let tabFromParams = searchParams.get('tab');

    if (!tabFromParams || !animalTabs.includes(tabFromParams as Tab)) {
      tabFromParams = animalTabs[0];
    }

    setCurrentTab(tabFromParams as Tab);
  }, [searchParams, setSearchParams]);

  const handleTabClick = (tab: Tab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  const handleOpenInEzyVet = () => {
    if (animal) {
      window.open(`https://drtreat.usw2.ezyvet.com/?recordclass=Animal&recordid=${animal.pim_id}`, '_blank');
    }
  };

  const handleUpdateCommunicationLogPage = (page: number) => {
    if (animal?.customer_id) {
      setCommunicationLogsPagination((prev) => ({ ...prev, page }));
      fetchCommunicationLogsByCustomer(
        animal.customer_id,
        transformFilters(communicationsFilters, { animal_id: id, page: page }),
        setCommunicationLogs,
        setCommunicationLogsPagination
      );

      if (communicationLogListRef?.current) {
        communicationLogListRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleFetchCommunicationLogs = () => {
    if (animal?.customer_id) {
      setCommunicationLogsPagination((prev) => ({ ...prev, page: 1 }));
      fetchCommunicationLogsByCustomer(
        animal.customer_id,
        transformFilters(communicationsFilters, { animal_id: id, page: 1 }),
        setCommunicationLogs,
        setCommunicationLogsPagination
      );
    }
  };

  const handleHealthPlansFilterChange = (filters: ListFilter[]) => {
    setHealthPlansFilters(filters);
    fetchAnimalHealthPlans(transformFilters(filters, { animal_id: id }), setHealthPlans);
  };

  const handleCommunicationsFilterChange = (filters: ListFilter[]) => {
    setCommunicationsFilters(filters);
    if (animal?.customer_id) {
      fetchCommunicationLogsByCustomer(
        animal.customer_id,
        transformFilters(filters, { animal_id: id }),
        setCommunicationLogs,
        setCommunicationLogsPagination
      );
    }
  };

  const handleAppointmentsFilterChange = (filters: ListFilter[]) => {
    setAppointmentsFilters(filters);
    fetchAppointmentsByAnimal({
      params: transformFilters(filters, { animal_id: id }),
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
  };

  const handleDocumentsFilterChange = (filters: ListFilter[]) => {
    setDocumentsFilters(filters);
    const signedFilter = filters.find((filter) => filter.key === 'status');
    let initial;
    if (signedFilter?.value !== '') {
      initial = { animal_id: id, signed: signedFilter?.value === 'signed' };
    } else {
      initial = { animal_id: id };
    }
    fetchDocumentsByAnimal({
      params: transformFilters(filters, initial),
      onSuccess: setDocuments,
      setPagination: setDocumentsPagination
    });
  };

  const handleEstimatesFilterChange = (filters: ListFilter[]) => {
    setEstimatesFilters(filters);
    fetchEstimatesForAnimal(id, transformFilters(filters, { animal_id: id }), setEstimates, setEstimatesPagination);
  };

  const handleInvoicesFilterChange = (filters: ListFilter[]) => {
    setInvoicesFilters(filters);
    const paidFilter = filters.find((filter) => filter.key === 'payment_status');
    let initial;
    if (paidFilter?.value !== '' && paidFilter?.value !== undefined) {
      initial = { animal_id: id, fully_paid: paidFilter?.value === 'paid' };
    } else {
      initial = { animal_id: id };
    }
    fetchInvoicesForAnimal(transformFilters(filters, initial), setInvoices, setInvoicesPagination);
  };

  const fetchSoapRecords = () => {
    if (animal) {
      downloadSoapRecords(animal.id);
    }
  };

  const fetchVaccinationRecords = () => {
    if (animal) {
      downloadSoapRecords(animal.id, 'vaccinations');
    }
  };

  const fetchDiagnosticRecords = () => {
    if (animal) {
      downloadSoapRecords(animal.id, 'diagnostics');
    }
  };

  const handleArchiveSuccess = (animal: Animal) => {
    setLoadState(undefined);
    hideModal();
    toast.success(`Animal ${animal.archived_at ? 'archived' : 'unarchived'}!`);

    setAnimal(animal);
  };

  const handleNewAttachmentClick = () => {
    setVisibleModal('new-attachment');
  };

  const handleArchiveClick = () => {
    setVisibleModal('archive');
  };

  const handleCreateNewDocumentClick = () => {
    setVisibleModal('new-document');
  };

  const handlePrintLabelClick = () => {
    setVisibleModal('print-label');
  };

  const hideModal = () => {
    setVisibleModal(undefined);
  };

  const handleAddHealthPlanSuccess = () => {
    fetchAnimalHealthPlans(transformFilters(healthPlansFilters, { animal_id: id }), setHealthPlans);
  };

  const handleCreateNewAppointment = () => {
    navigate(`/appointments/new?animal_id=${animal?.id}`);
  };

  const handleAppointmentsPageChange = (page: number) => {
    setAppointmentsPagination({ ...appointmentsPagination, page });
    fetchAppointmentsByAnimal({
      params: transformFilters(appointmentsFilters, { animal_id: id, page: page }),
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
  };

  const handleDocumentsPageChange = (page: number) => {
    setDocumentsPagination((prev) => ({ ...prev, page }));
    const signedFilter = documentsFilters.find((filter) => filter.key === 'status');
    let initial;
    if (signedFilter?.value !== '') {
      initial = { animal_id: id, signed: signedFilter?.value === 'signed', page: page };
    } else {
      initial = { animal_id: id, page: page };
    }
    fetchDocumentsByAnimal({
      params: transformFilters(documentsFilters, initial),
      onSuccess: setDocuments,
      setPagination: setDocumentsPagination
    });
  };

  const handleAttachmentsPageChange = (page: number) => {
    setAttachmentsPagination((prev) => ({ ...prev, page }));
    fetchAttachmentsForAnimal(id, { onSuccess: setAttachments, setPagination: setAttachmentsPagination });
  };

  const handleInvoicesPageChange = (page: number) => {
    setInvoicesPagination((prev) => ({ ...prev, page }));
    const paidFilter = invoicesFilters.find((filter) => filter.key === 'payment_status');
    let initial;
    if (paidFilter?.value !== '' && paidFilter?.value !== undefined) {
      initial = { animal_id: id, fully_paid: paidFilter?.value === 'paid', page: page };
    } else {
      initial = { animal_id: id, page: page };
    }
    fetchInvoicesForAnimal(transformFilters(invoicesFilters, initial), setInvoices, setInvoicesPagination);
  };

  const handleEstimatesPageChange = (page: number) => {
    setEstimatesPagination((prev) => ({ ...prev, page }));
    fetchEstimatesForAnimal(
      id,
      transformFilters(estimatesFilters, { animal_id: id, page: page }),
      setEstimates,
      setEstimatesPagination
    );
  };

  const toggleAnimalArchived = () => {
    if (animal) {
      setLoadState('general');
      updateAnimal(
        Number(animal.id),
        { archived: !isArchived },
        {
          onSuccess: handleArchiveSuccess,
          onError: hideModal
        }
      );
    }
  };

  const handleDocumentSuccess = (document: Document) => {
    hideModal();
    navigate(`/documents/${document.id}`);
  };

  const actionsMenuItems = () => {
    return [
      {
        label: 'Open in EzyVet',
        icon: cilExitToApp,
        onClick: handleOpenInEzyVet
      },
      {
        label: isArchived ? 'Unarchive Animal' : 'Archive Animal',
        icon: isArchived ? cilFolderOpen : cilFolder,
        onClick: handleArchiveClick
      },
      {
        label: 'Download SOAP Records',
        icon: cilClipboard,
        onClick: fetchSoapRecords
      },
      {
        label: 'Download Vaccination SOAP Records',
        icon: cilClipboard,
        onClick: fetchVaccinationRecords
      },
      {
        label: 'Download Diagnostic SOAP Records',
        icon: cilClipboard,
        onClick: fetchDiagnosticRecords
      },
      {
        label: 'Print Label',
        icon: cilPrint,
        onClick: handlePrintLabelClick
      }
    ];
  };

  const itemsCount = {
    attachments: attachments?.length ?? 0,
    documents: documents?.length ?? 0
  };

  const handleDocumentsNavClick = (table: Table) => () => {
    setActiveDocumentsTable(table);
  };

  const TableControl = () => (
    <CNav role="list" variant="underline" className="w-100">
      {documentsTables.map((table) => (
        <CNavItem key={table}>
          <CNavLink
            className="text-capitalize"
            active={table === activeDocumentsTable}
            role="button"
            onClick={handleDocumentsNavClick(table)}
          >
            {table} ({itemsCount[table]})
          </CNavLink>
        </CNavItem>
      ))}
    </CNav>
  );

  const renderTable = (tab: Tab) => {
    switch (tab) {
      case 'health-plans':
        if (animal) {
          return (
            <CTabPane role="tabpanel" aria-label="health-plans-tab" visible>
              <AnimalHealthPlansList
                animal={animal}
                healthPlans={healthPlans}
                filters={healthPlansFilters}
                handleFilterChange={handleHealthPlansFilterChange}
                onSuccess={handleAddHealthPlanSuccess}
              />
            </CTabPane>
          );
        }
        break;
      case 'appointments':
        return (
          <CTabPane role="tabpanel" aria-label="appointments-tab" visible>
            <AppointmentsList
              appointments={appointments}
              filters={appointmentsFilters}
              handleFilterChange={handleAppointmentsFilterChange}
              handleCreateNewAppointment={handleCreateNewAppointment}
              handleActivePageChange={handleAppointmentsPageChange}
              pagination={appointmentsPagination}
            />
          </CTabPane>
        );
      case 'documents':
        return (
          <CTabPane role="tabpanel" aria-label="documents-tab" visible>
            <DocumentsList
              tableControl={<TableControl />}
              documents={documents}
              pagination={documentsPagination}
              visible={activeDocumentsTable === 'documents'}
              handleCreateNewDocument={handleCreateNewDocumentClick}
              updatePage={handleDocumentsPageChange}
              filters={documentsFilters}
              handleFilterChange={handleDocumentsFilterChange}
            />

            <AttachmentsList
              tableControl={<TableControl />}
              attachments={attachments}
              handleNewAttachmentClick={handleNewAttachmentClick}
              pagination={attachmentsPagination}
              visible={activeDocumentsTable === 'attachments'}
              updatePage={handleAttachmentsPageChange}
            />
          </CTabPane>
        );
      case 'invoices':
        if (animal?.customer) {
          return (
            <CTabPane role="tabpanel" aria-label="invoices-tab" visible>
              <InvoicesList
                invoices={invoices}
                customer={animal.customer}
                animal={animal}
                fetchInvoices={() => fetchInvoicesForAnimal({ animal_id: id }, setInvoices, setInvoicesPagination)}
                isLoading={loadState === 'general'}
                pagination={invoicesPagination}
                updatePage={handleInvoicesPageChange}
                filters={invoicesFilters}
                handleFilterChange={handleInvoicesFilterChange}
                scrollUp={scrollUp}
              />
            </CTabPane>
          );
        }
        break;
      case 'treatment-plans':
        if (animal?.customer) {
          return (
            <CTabPane role="tabpanel" aria-label="treatment-plans-tab" visible>
              <EstimatesList
                estimates={estimates}
                animal={animal}
                fetchEstimates={() => fetchEstimatesForAnimal(id, {}, setEstimates, setEstimatesPagination)}
                customer={animal.customer}
                pagination={estimatesPagination}
                updatePage={handleEstimatesPageChange}
                filters={estimatesFilters}
                handleFilterChange={handleEstimatesFilterChange}
                scrollUp={scrollUp}
              />
            </CTabPane>
          );
        }
        break;
      case 'tasks':
        return (
          <CTabPane role="tabpanel" aria-label="tasks-tab" visible>
            <TasksListTable
              employeeTasks={employeeTasks}
              pagination={tasksPagination}
              isLoading={loadState === 'general'}
              setPagination={setTasksPagination}
              refetchTasks={() =>
                fetchEmployeeTasks({
                  onSuccess: setEmployeeTasks,
                  setPagination: setTasksPagination,
                  filterParams: {
                    animal_id: id
                  }
                })
              }
              animal={animal}
            />
          </CTabPane>
        );
      case 'communications':
        if (animal && customer) {
          return (
            <CTabPane role="tabpanel" aria-label="communications-tab" visible>
              <CommunicationLogList
                customer={customer}
                contexts={contexts}
                communicationLogs={communicationLogs}
                animal={animal}
                filters={communicationsFilters}
                fetchCommunicationLogs={handleFetchCommunicationLogs}
                handleFilterChange={handleCommunicationsFilterChange}
                pagination={communicationLogsPagination}
                ref={communicationLogListRef}
                updatePage={handleUpdateCommunicationLogPage}
              />
            </CTabPane>
          );
        }
        break;
      default:
        return null;
    }
  };

  const handleAttachmentCreateSuccess = (attachments: Attachment[]) => {
    setLoadState(undefined);
    const message = pluralize('Attachment', attachments.length) + ' uploaded!';
    toast.success(message);

    fetchAttachmentsForAnimal(id, { onSuccess: setAttachments, setPagination: setAppointmentsPagination });
    hideModal();
  };

  const handleAttachmentCreateError = () => {
    setLoadState(undefined);
  };

  const handleUploadAttachments = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!animal) return;
    setLoadState('creating-attachment');

    const form = event.currentTarget;
    const formData = new FormData(form);
    formData.append('record_type', 'Animal');
    formData.append('record_id', String(animal.id));

    createAttachmentsV2(formData, {
      onSuccess: handleAttachmentCreateSuccess,
      onError: handleAttachmentCreateError
    });
  };

  const onUpdateSidebar = () => {
    fetchAnimalHealthPlans({ animal_id: id }, setHealthPlans);
  };

  const renderSidebar = (step: SidebarStep) => {
    if (!animal) return;

    return {
      'master-problems': <MasterProblemsList animal={animal} />,
      'health-plans': <HealthPlansList animal={animal} onUpdateSidebar={onUpdateSidebar} />
    }[step];
  };

  const cardBody = (
    <CRow>
      <CCol>
        <CNav variant="pills" style={{ marginBottom: '19px' }} ref={scrollRef}>
          {[
            animalTabs.map((tab) => (
              <CNavItem key={tab}>
                <CNavLink
                  role="button"
                  className="text-capitalize"
                  onClick={() => handleTabClick(tab)}
                  active={tab === currentTab}
                >
                  {tab.split('-').join(' ')}
                </CNavLink>
              </CNavItem>
            ))
          ]}
        </CNav>

        <CTabContent>{renderTable(currentTab)}</CTabContent>
      </CCol>
    </CRow>
  );

  const loading = !animal;
  if (loading) {
    return <CSpinner color="primary" />;
  }

  return (
    <div className="d-flex" style={{ gap: '30px' }}>
      <div className={styles.mainPage}>
        <CRow className="mb-3">
          <CCol xxl={9}>
            <InfoCard actionsMenuItems={actionsMenuItems} animal={animal} setAnimal={setAnimal} />
          </CCol>
          <CCol className="d-flex flex-column justify-content-between gap-2">
            <InternalNote animal={animal} onNoteSaved={setAnimal} />
            {(animal.next_appointment_json?.start_time || animal.last_appointment_json?.start_time) && (
              <UpcomingAppointmentsCard
                futureAppointment={animal.next_appointment_json}
                pastAppointment={animal.last_appointment_json}
              />
            )}
          </CCol>
        </CRow>

        <div className="mt-3">{cardBody}</div>
      </div>

      <CCol className={styles.sidePanel}>
        <div className={styles.panelMainContent}>
          <OwnerInfoCard customer={animal?.customer} />
          <CNav
            role="list"
            className="d-flex justify-content-between flex-nowrap"
            variant="pills"
            style={{ marginBottom: '15px' }}
          >
            {sidebarSteps.map((step) => (
              <CNavItem key={step}>
                <CNavLink
                  className="text-capitalize text-nowrap"
                  role="button"
                  active={step === sidebarStep}
                  onClick={() => setSidebarStep(step)}
                >
                  {step.split('-').join(' ')}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>

          {renderSidebar(sidebarStep)}
        </div>
        <CAccordion className={styles.accountAccordion}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <h2 className="m-0">Account Details</h2>
            </CAccordionHeader>
            <CAccordionBody className="p-0">
              <AccountDetails animal={animal} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCol>

      <ConfirmationModal
        isVisible={visibleModal === 'archive'}
        onClose={hideModal}
        onConfirm={toggleAnimalArchived}
        modalBody={isArchived ? 'Unarchive this animal?' : 'Archive this animal?'}
        confirmButtonLabel={isArchived ? 'Unarchive' : 'Archive'}
        modalHeader={isArchived ? 'Unarchive Animal' : 'Archive Animal'}
      />

      {animal && customer && (
        <NewDocumentModal
          isVisible={visibleModal === 'new-document'}
          onClose={hideModal}
          docContextObjects={{ animal: animal, customer: customer, clinic: clinic, vaccinations: vaccinations }}
          onSuccess={handleDocumentSuccess}
        />
      )}

      {visibleModal === 'new-attachment' && attachmentTypes && (
        <NewAttachmentModal
          hideModal={hideModal}
          attachmentTypes={attachmentTypes}
          onSubmit={handleUploadAttachments}
          loading={loadState === 'creating-attachment'}
        />
      )}

      {visibleModal === 'print-label' && <PrintLabelSelectorModal hideModal={hideModal} animal={animal} />}
    </div>
  );
};

export default Details;
