import { Animal } from './Animal';
import { AnimalHealthPlan } from './AnimalHealthPlan';
import { AnimalMasterProblem } from './AnimalMasterProblem';
import { Assessment } from './Assessment';
import { Clinic } from './Clinic';
import { CommunicationLog } from './CommunicationLog';
import { ConsultPlan } from './ConsultPlan';
import { ConsultPresentingProblem } from './ConsultPresentingProblem';
import { ContextType } from './ContextType';
import { DiagnosticRequest } from './DiagnosticRequest';
import { DiagnosticResult } from './DiagnosticResult';
import { Employee } from './Employee';
import { Estimate } from './Estimate';
import { HealthStatus } from './HealthStatus';
import { History } from './History';
import { Invoice } from './Invoice';
import { PhysicalExam } from './PhysicalExam';
import { Prescription } from './Prescription';
import { PrescriptionItem } from './PrescriptionItem';
import { Procedure } from './Procedure';
import { Vaccination } from './Vaccination';

export type EmployeeTask = {
  id?: number;
  animal_id?: number;
  animal?: Animal;
  body?: string;
  clinic_id?: number;
  clinic?: Clinic;
  communication_logs?: CommunicationLog[];
  consult_id?: number;
  completed_at?: string;
  context_id?: number;
  context_type?: ContextType;
  context?: ContextItem;
  created_by_employee_id?: number;
  created_by_employee?: Employee;
  csr?: string | boolean;
  due_at?: string;
  employee_ids?: number[];
  assignees?: Employee[];
  status?: EmployeeTaskStatus;
  type?: EmployeeTaskType;
  vet?: string | boolean;
  vet_tech?: string | boolean;
  created_at?: string;
  updated_at?: string;
  updated_by_employee?: Employee | null;
};

export type EmployeeTaskStatus = 'pending' | 'completed' | 'disabled';

export type EmployeeTaskType =
  | 'CallbackTask'
  | 'AppointmentTask'
  | 'FinancialTask'
  | 'MemoTask'
  | 'DiagnosticResultTask'
  | 'PrescriptionRequestTask'
  | 'OtherTask';

export const employeeTaskTypeOptions: { label: string; value: EmployeeTaskType }[] = [
  { label: 'Callback', value: 'CallbackTask' },
  { label: 'Appointment', value: 'AppointmentTask' },
  { label: 'Financial', value: 'FinancialTask' },
  { label: 'Memo', value: 'MemoTask' },
  { label: 'Diagnostic Result', value: 'DiagnosticResultTask' },
  { label: 'Prescription Request', value: 'PrescriptionRequestTask' },
  { label: 'Other', value: 'OtherTask' }
];

export const employeeTaskPresentingMap: { [key in EmployeeTaskType]: string } = {
  CallbackTask: 'Callback',
  AppointmentTask: 'Appointment',
  FinancialTask: 'Financial',
  MemoTask: 'Memo',
  DiagnosticResultTask: 'Diagnostic Result',
  PrescriptionRequestTask: 'Prescription Request',
  OtherTask: 'Other'
};

export type ContextItem =
  | AnimalHealthPlan
  | AnimalMasterProblem
  | Assessment
  | ConsultPlan
  | ConsultPresentingProblem
  | DiagnosticRequest
  | DiagnosticResult
  | Estimate
  | HealthStatus
  | History
  | Invoice
  | PhysicalExam
  | PrescriptionItem
  | Procedure
  | Prescription
  | Vaccination;

export const isOverdue = (task: EmployeeTask) => {
  return task.due_at && new Date(task.due_at) < new Date();
};

export const getLinkUrlToContext = (task: EmployeeTask) => {
  // Items with their own detail page
  if (task.context_type === 'Estimate') return `/estimates/${task.context_id}`;
  if (task.context_type === 'Invoice') return `/invoices/${task.context_id}`;
  if (task.context_type === 'PrescriptionItem' && task.context)
    return `/prescriptions/${(task.context as PrescriptionItem).prescription_id}`;

  // Items that only live on the consult detail page
  let url = '';
  if (task.consult_id) {
    url += `/consults/${task.consult_id}`;

    if (task.context_type === 'Assessment') return `${url}?step=assessments`;
    if (task.context_type === 'AnimalMasterProblem') return `${url}?step=master-problems`;
    if (task.context_type === 'AnimalHealthPlan') return `${url}?step=health-plans`;
    if (task.context_type === 'HealthStatus') return `${url}?step=health-statuses`;
    if (task.context_type === 'ConsultPresentingProblem') return `${url}?step=presenting-problems`;
    if (task.context_type === 'History') return `${url}?step=history`;
    if (task.context_type === 'PhysicalExam') return `${url}?step=physical-exams`;
    if (task.context_type === 'ConsultPlan') return `${url}?step=consult-plans`;
    if (task.context_type === 'Vaccination') return `${url}?step=vaccinations`;
    if (task.context_type === 'DiagnosticRequest' || task.context_type === 'DiagnosticResult')
      return `${url}?step=diagnostics`;
    if (task.context_type === 'Procedure') return `${url}?step=procedures`;

    return url;
  }

  // Items that don't have their own pages, nor have a consult
  return `/animals/${task.animal_id}/medical_history`;
};

export const getLinkNameForContext = (task: EmployeeTask) => {
  if (task.context && 'name' in task.context) return task.context.name;
  if (task.context_type) return task.context_type;
};
