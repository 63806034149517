export const medicalHistoryTabs = [
  'master-problems',
  'health-plans',
  'health-statuses',
  'histories',
  'physical-exams',
  'assessments',
  'consult-plans',
  'vaccinations',
  'medications',
  'diagnostics',
  'procedures'
] as const;

export type MedicalHistoryTab = (typeof medicalHistoryTabs)[number];
