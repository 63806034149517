import * as React from 'react';
import { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { CButton, CLoadingButton, CRow, CSmartTable } from '@coreui/react-pro';

import { fetchFinancialConsults } from 'api/Consults';
import { createInvoice, fetchInvoice, updateInvoice } from 'api/Invoices';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';
import { ListFilter } from 'types/ListFilter';
import { calculatePages, Pagination } from 'types/Pagination';

import { useKey } from 'hooks/useKey';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import SvgPlus from 'assets/images/SvgPlus';
import SvgRefresh from 'assets/images/SvgRefresh';

import { ActionsMenu } from 'components/ActionsMenu';
import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';
import { InvoiceForm } from 'components/InvoiceForm';
import { PaymentInvoiceModal } from 'components/PaymentInvoiceModal';
import { Pill } from 'components/Pill';

type Form = 'new' | 'edit';

type Props = {
  invoices?: Invoice[];
  customer: Customer;
  handleResyncInvoices?: () => void;
  isLoading?: boolean;
  pagination: Pagination;
  updatePage: (page: number) => void;
  fetchInvoices: () => void;
  filters: ListFilter[];
  handleFilterChange: (filters: ListFilter[]) => void;
  scrollUp: () => void;
};

export const InvoicesList = ({
  invoices,
  customer,
  handleResyncInvoices,
  isLoading,
  pagination,
  updatePage,
  fetchInvoices,
  filters,
  handleFilterChange,
  scrollUp
}: Props) => {
  const [visibleForm, setVisibleForm] = useState<Form>();
  const [invoice, setInvoice] = useState<Partial<Invoice>>();
  const [invoiceToPay, setInvoiceToPay] = useState<Invoice>();
  const [consults, setConsults] = useState<Consult[]>();

  const [formKey, remountForm] = useKey();

  useEffect(() => {
    fetchFinancialConsults({ params: { customer_id: customer.id }, onSuccess: setConsults });
  }, [customer]);

  const initializeInvoice = () => {
    const initializedInvoice: Partial<Invoice> = {
      active: true,
      animal_id: customer.animals?.length === 1 ? customer.animals[0].id : undefined,
      animal: customer.animals?.length === 1 ? customer.animals[0] : undefined,
      customer_id: customer.id,
      customer: customer,
      fully_paid: false,
      payments: [],
      subtotal: 0,
      total: 0,
      tax: 0,
      total_paid: 0,
      unpaid_total: 0,
      status: 'draft'
    };

    setInvoice(initializedInvoice);
  };

  const handleNewInvoiceClick = () => {
    remountForm();
    scrollUp();

    initializeInvoice();
    setVisibleForm('new');
  };

  const handleEditInvoiceClick = (item: Invoice) => () => {
    remountForm();
    scrollUp();

    fetchInvoice(item.id, (fullInvoice) => {
      setInvoice(fullInvoice);
      setVisibleForm('edit');
    });
  };

  const hideForm = () => {
    setVisibleForm(undefined);
  };

  const handleCreateInvoiceSuccess = (invoice: Partial<Invoice>) => {
    hideForm();
    toast.success('Invoice created!');
    fetchInvoices();
  };

  const handleUpdateInvoiceSuccess = (invoice: Partial<Invoice>) => {
    hideForm();
    toast.success('Invoice updated!');
    fetchInvoices();
  };

  const handleCreateInvoice = async () => {
    if (!invoice) return;
    return createInvoice(invoice, handleCreateInvoiceSuccess);
  };

  const handleUpdateInvoice = async () => {
    if (!invoice?.id) return;

    return updateInvoice(
      invoice.id,
      { ...invoice, invoice_items_attributes: invoice.invoice_items },
      { onSuccess: handleUpdateInvoiceSuccess }
    );
  };

  const handlePaymentSuccess = (invoice: Invoice) => {
    setInvoiceToPay(undefined);
    fetchInvoices();
  };

  const actionsMenuItems = (invoice: Invoice) => {
    return [
      !invoice.fully_paid && {
        label: 'Edit',
        onClick: handleEditInvoiceClick(invoice)
      },
      {
        label: 'View',
        href: generatePath(paths.invoiceDetails, { id: invoice.id })
      },
      invoice.status === 'approved' &&
        !invoice.fully_paid && {
          label: 'Pay Now',
          onClick: () => setInvoiceToPay(invoice)
        },
      invoice.consult_id && {
        label: 'View Consult',
        href: `/consults/${invoice.consult_id}?step=finances`
      },
      invoice.appointment_id && {
        label: 'View Appointment',
        href: `/appointments/${invoice.appointment_id}`
      }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  const renderForm = (form?: Form) => {
    if (!customer || !invoice || !consults) return null;

    switch (form) {
      case 'new':
        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            setInvoice={setInvoice}
            consults={consults}
            animals={customer.animals?.length && customer.animals?.length > 1 ? customer.animals : undefined}
            animal_id={customer.animals?.length === 1 ? customer.animals[0].id : undefined}
            handleSubmit={handleCreateInvoice}
            handleCancel={hideForm}
          />
        );
      case 'edit':
        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            setInvoice={setInvoice}
            consults={consults}
            animals={customer.animals?.length && customer.animals?.length > 1 ? customer.animals : undefined}
            animal_id={customer.animals?.length === 1 ? customer.animals[0].id : undefined}
            handleSubmit={handleUpdateInvoice}
            handleCancel={hideForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CRow className="mb-3">
      <div className="d-flex align-items-center justify-content-end mb-3">
        <CLoadingButton
          className="me-2"
          disabledOnLoading={true}
          variant="outline"
          shape="rounded-pill"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleResyncInvoices}
        >
          <SvgRefresh height={16} />
          <span className="ms-1">Resync</span>
        </CLoadingButton>
        <Filters setFilters={handleFilterChange} filters={filters} useRange />
        <IconButton onClick={handleNewInvoiceClick} icon={SvgPlus} label="New Invoice" className="ms-2" />
      </div>

      {renderForm(visibleForm)}

      <CSmartTable
        pagination={{ external: true }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        items={invoices ?? []}
        tableProps={{ 'aria-label': 'Invoices' }}
        columns={[
          { key: 'id', label: 'ID' },
          { key: 'created_at', label: 'Effective Date' },
          'status',
          'name',
          { key: 'animal_name', label: 'Patient' },
          { key: 'total' },
          { key: 'total_paid', label: 'Amount Paid' },
          { key: 'unpaid_total', label: 'Amount Due' },
          { key: 'payment_status' },
          { key: 'actions' }
        ]}
        scopedColumns={{
          id: (invoice: Invoice) => (
            <td>
              <CButton color="link" className="p-0" onClick={handleEditInvoiceClick(invoice)}>
                #{invoice.id}
              </CButton>
            </td>
          ),
          created_at: (invoice: Invoice) => <td>{invoice.created_at && compactDateTimeDisplay(invoice.created_at)}</td>,
          animal_name: (invoice: Invoice) => (
            <td>{invoice.animal_id && <Link to={`/animals/${invoice.animal_id}`}>{invoice.animal_name}</Link>}</td>
          ),
          status: (invoice: Invoice) => (
            <td>
              <Pill label={invoice.status} />
            </td>
          ),
          total: (invoice: Invoice) => <td>{toCurrency(invoice.total)}</td>,
          total_paid: (invoice: Invoice) => <td>{toCurrency(invoice.total_paid)}</td>,
          unpaid_total: (invoice: Invoice) => <td>{toCurrency(invoice.unpaid_total)}</td>,
          payment_status: (invoice: Invoice) => (
            <td>{invoice.status === 'approved' && <Pill label={invoice.fully_paid ? 'Paid' : 'Unpaid'} />}</td>
          ),
          actions: (invoice: Invoice) => (
            <td>
              <ActionsMenu items={actionsMenuItems(invoice)} />
            </td>
          )
        }}
      />

      {invoiceToPay && (
        <PaymentInvoiceModal
          invoice={invoiceToPay}
          customer={customer}
          isVisible={!!invoiceToPay}
          onClose={() => setInvoiceToPay(undefined)}
          onConfirm={handlePaymentSuccess}
        />
      )}
    </CRow>
  );
};
