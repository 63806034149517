import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CSpinner } from '@coreui/react-pro';

import { fetchProductPrices } from 'api/ProductPrices';
import { fetchProduct, updateProduct } from 'api/Products';

import { Product } from 'types/Product';
import { priceToAttributes, ProductPrice } from 'types/ProductPrice';
import { ProductType } from 'types/ProductType';

import Form from './Form';

const ProductEdit = (): JSX.Element => {
  type ProductEditParams = {
    id: string;
  };
  const { id } = useParams<keyof ProductEditParams>() as ProductEditParams;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(true);
  const [product, setProduct] = useState<Product>({} as Product);
  const [productPrices, setProductPrices] = useState<Partial<ProductPrice>[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox') {
      setProduct({ ...product, [event.target.id]: 'checked' in event.target ? event.target.checked : false });
    } else if (event.target.id === 'product_type' && event.target.value !== 'Medication') {
      setProduct({ ...product, product_type: event.target.value as ProductType, default_instructions: null });
    } else {
      setProduct({ ...product, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const productPricesAttributes = productPrices.map((price) => {
      return priceToAttributes(price);
    });

    updateProduct(id, { ...product, product_prices_attributes: productPricesAttributes }, updateSuccess, updateError);
  };

  useEffect(() => {
    fetchProduct(id, (product: Product) => {
      setProduct(product);
      setIsLoadingProduct(false);
    });

    fetchProductPrices(id, setProductPrices);
  }, [id]);

  const updateError = () => {
    setIsLoading(false);
    toast.error('Error updating product');
  };

  const updateSuccess = (product: Product) => {
    toast.success('Product updated!');
    setIsLoading(false);
    handleGoBack();
  };

  const handleCancel = () => {
    handleGoBack();
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back if there's history
    } else {
      // Handle the case where there's no previous page
      navigate('/products');
    }
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <div style={{ borderRadius: '20px', boxShadow: '0px 4px 30px 0px rgba(26, 41, 60, 0.1)', padding: '21px' }}>
        <h1 className="mb-3">Edit Product</h1>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          product={product}
          productPrices={productPrices}
          setProductPrices={setProductPrices}
          isLoading={isLoading}
        />
      </div>
    );
  }

  return isLoadingProduct ? loadingState() : loadedState();
};

export default ProductEdit;
