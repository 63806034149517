import cn from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { cilStar } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCollapse,
  CContainer,
  CDatePicker,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchDueAnimalHealthPlans } from 'api/AnimalHealthPlans';
import { fetchAnimal } from 'api/Animals';
import { createAppointment } from 'api/Appointments';
import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { AvailableTime, fetchAvailableDays, fetchAvailablePimResources, fetchAvailableTimes } from 'api/Availability';
import { fetchClinics } from 'api/Clinics';
import { fetchCustomer } from 'api/Customers';
import { fetchForwardBooking } from 'api/ForwardBookings';

import { Animal } from 'types/Animal';
import { AnimalHealthPlan, isPastDue } from 'types/AnimalHealthPlan';
import { Appointment } from 'types/Appointment';
import { AppointmentType } from 'types/AppointmentType';
import { Clinic } from 'types/Clinic';
import { Customer } from 'types/Customer';
import { ForwardBooking } from 'types/ForwardBooking';
import { Option } from 'types/Option';
import { PimResource } from 'types/PimResource';

import { useQuery } from 'hooks/useQuery';

import { compactDateDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';
import { reactSelectStyles } from 'utils/reactSelect';
import { appointmentTypeToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgChecklist from 'assets/images/SvgChecklist';
import SvgClipboard from 'assets/images/SvgClipboard';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPawCross from 'assets/images/SvgPawCross';

import { AnimalPhoto } from 'components/AnimalPhoto';
import EmployeePhoto from 'components/EmployeePhoto';
import { InternalNotes } from 'components/InternalNotes';
import PatientChooser from 'components/PatientChooser';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './GuidedForm.module.scss';

enum Step {
  Animal, // 0
  Clinic, // 1
  AppointmentType, // 2
  HealthPlans, // 3
  DateTime, // 4
  Complete // 5
}

const GuidedForm = (): JSX.Element => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<Step>(Step.Animal);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [clinic, setClinic] = useState<Clinic>();
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [appointmentType, setAppointmentType] = useState<AppointmentType>();
  const [pimResources, setPimResources] = useState<PimResource[]>([]);
  const [pimResource, setPimResource] = useState<PimResource>();
  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [timeSlot, setTimeSlot] = useState<AvailableTime>();
  const [customer, setCustomer] = useState<Customer>();
  const [notes, setNotes] = useState<string>('');
  const [internalNotes, setInternalNotes] = useState<string>();
  const [animal, setAnimal] = useState<Animal>();
  const [dueHealthPlans, setDueHealthPlans] = useState<AnimalHealthPlan[]>();
  const [selectedHealthPlans, setSelectedHealthPlans] = useState<AnimalHealthPlan[]>();
  const isResourceAutoSelected = useRef<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const [loadingPimResources, setLoadingPimResources] = useState(false);
  const [loadingDays, setLoadingDays] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);

  const [forwardBooking, setForwardBooking] = useState<ForwardBooking>();

  const query = useQuery();
  const animalId = query.get('animal_id');
  const forwardBookingId = query.get('forward_booking');

  useEffect(() => {
    fetchClinics(setClinics);
    fetchAppointmentTypes(setAppointmentTypes);
  }, []);

  useEffect(() => {
    if (forwardBookingId) {
      fetchForwardBooking(forwardBookingId, (forwardBooking) => {
        setForwardBooking(forwardBooking);
        presetAnimal(forwardBooking.animal_id, false);
        setAppointmentType(forwardBooking.appointment_type);
        setClinic(forwardBooking.clinic);
        setCurrentStep(Step.HealthPlans);
        setInternalNotes(forwardBooking.notes);
        if (forwardBooking.due_date) {
          setSelectedDate(DateTime.fromISO(forwardBooking.due_date).toJSDate());
        }
      });
    }
  }, [forwardBookingId]);

  useEffect(() => {
    if (animalId) {
      presetAnimal(animalId);
    }
  }, [animalId]);

  const presetAnimal = (id: number | string, updateStep = true) => {
    fetchAnimal(id, (animal) => {
      setAnimal(animal);

      fetchCustomer(animal.customer.id, (customer) => {
        setCustomer(customer);
        if (updateStep) setCurrentStep(Step.Clinic);
      });

      fetchDueAnimalHealthPlans(animal.id, (plans) => {
        setDueHealthPlans(plans);
      });
    });
  };

  useEffect(() => {
    if (animal?.customer?.clinic_id) {
      const clinic = clinics.find((c) => c.id === animal.customer.clinic_id);
      if (clinic) {
        setClinic(clinic);
        if (clinics.length === 1) {
          setCurrentStep(Step.AppointmentType);
        }
      }
    }

    if (animal) {
      fetchDueAnimalHealthPlans(animal.id, (plans) => {
        setDueHealthPlans(plans);
      });
    }
  }, [animal, clinics]);

  const handleSelectResource = (selectedResource: PimResource) => {
    if (pimResource?.id !== selectedResource.id) {
      setPimResource(selectedResource);
      isResourceAutoSelected.current = false;
    } else {
      setPimResource(undefined);
      isResourceAutoSelected.current = true;
    }
    setTimeSlot(undefined);
    setAvailableDays([]);
    setAvailableTimes([]);
  };

  useEffect(() => {
    if (clinic && animal && appointmentType) {
      setLoadingPimResources(true);
      fetchAvailablePimResources(animal.id, appointmentType.id, clinic.id, (resources) => {
        setPimResources(resources);
        setLoadingPimResources(false);

        if (forwardBooking) {
          const resource = resources.find((r) => r.employee_id === forwardBooking.employee_id);
          if (resource) {
            handleSelectResource(resource);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animal, appointmentType, clinic, forwardBooking]);

  useEffect(() => {
    if (appointmentType && clinic) {
      setLoadingDays(true);
      fetchAvailableDays(
        appointmentType.id,
        pimResource?.id,
        clinic.id,
        new Date(),
        DateTime.local({ zone: clinic?.time_zone }).plus({ months: 6 }).toJSDate(),
        (days) => {
          setAvailableDays(days);
          setLoadingDays(false);
        }
      );
    }
  }, [appointmentType, pimResource, clinic]);

  useEffect(() => {
    if (appointmentType && selectedDate && clinic) {
      setLoadingTimes(true);
      fetchAvailableTimes(appointmentType.id, pimResource?.id, clinic.id, selectedDate, (times) => {
        setAvailableTimes(times);
        setLoadingTimes(false);
      });
    }
  }, [appointmentType, pimResource, selectedDate, clinic]);

  const handleCreateSuccess = (data: Appointment) => {
    setSubmitting(false);
    toast.success('Appointment created!');
    navigate(`/appointments/${data.id}`);
  };

  const handleCreateError = () => {
    setSubmitting(false);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const appointment: Partial<Appointment> = {
      start_time: timeSlot?.start_at,
      end_time: timeSlot?.end_at,
      animal_health_plan_ids: selectedHealthPlans?.map((hp) => hp.id),
      appointment_type_id: appointmentType?.id,
      pim_resource_id: pimResource?.id,
      animal_id: animal?.id,
      notes: notes,
      internal_notes: internalNotes,
      auto_selected_resource: isResourceAutoSelected.current
    };
    createAppointment(appointment, { onSuccess: handleCreateSuccess, onError: handleCreateError });
  };

  const handleDayChange = (date: Date | null) => {
    if (date) {
      setTimeSlot(undefined);
      setAvailableTimes([]);
      setSelectedDate(date);
      setCurrentStep(Step.DateTime);
    }
  };

  const disabledDates: Date[] = useMemo(() => {
    const today = DateTime.local({ zone: clinic?.time_zone });
    const endDate = today.plus({ months: 6 });
    const dates = [];

    for (let day = today; day <= endDate; day = day.plus({ days: 1 })) {
      dates.push(day.toISODate());
    }
    const disabledDates = dates.filter((date) => !availableDays.includes(date));
    return disabledDates.map((date) => DateTime.fromISO(date, { zone: clinic?.time_zone }).toJSDate());
  }, [clinic, availableDays]);

  useEffect(() => {
    if (animal !== undefined && customer !== undefined && currentStep === Step.Animal) {
      setCurrentStep(Step.Clinic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animal, customer]);

  const animalStep = () => {
    return (
      <CInputGroup>
        <PatientChooser setAnimal={setAnimal} setCustomer={setCustomer} />
      </CInputGroup>
    );
  };

  const clinicStep = () => {
    return (
      <>
        <CRow>
          <CFormLabel htmlFor="clinic" className="d-flex justify-content-between">
            Select Clinic
            <div className="text-muted">
              <CIcon icon={cilStar} className="me-1" color="primary" size="sm" aria-label="Preferred star" />
              Preferred
            </div>
          </CFormLabel>
        </CRow>
        <CRow className="d-flex flex-direction-row gap-2">
          {clinics.map((c) => (
            <CButton
              key={c.id}
              variant="outline"
              shape="rounded-pill"
              className="w-auto"
              onClick={() => {
                setClinic(c);
                setCurrentStep(Step.AppointmentType);
              }}
              active={c.id === clinic?.id}
            >
              {c.id === customer?.clinic_id && <CIcon icon={cilStar} aria-label="Preferred star" className="me-2" />}
              {c.name}
            </CButton>
          ))}
        </CRow>
      </>
    );
  };

  const appointmentTypeStep = () => {
    return (
      <>
        <CFormLabel htmlFor="appointment_type" className="form-label">
          Select Appointment Type
        </CFormLabel>
        <Select<Option>
          id="appointment_type"
          aria-label="Select Appointment Type"
          styles={reactSelectStyles}
          options={appointmentTypes.map(appointmentTypeToOption)}
          onChange={(selected) => {
            if (selected) {
              setAppointmentType(appointmentTypes.find((at) => at.id === Number(selected.value)));
              setCurrentStep(Step.HealthPlans);
            }
          }}
          value={appointmentType && appointmentTypeToOption(appointmentType)}
        />
      </>
    );
  };

  const healthPlansStep = () => {
    return (
      <>
        <CFormLabel htmlFor="health_plans" className="form-label">
          {dueHealthPlans && dueHealthPlans.length > 0 ? 'Select Health Plans' : 'No Health Plans Due'}
        </CFormLabel>
        <CRow className="d-flex flex-direction-row gap-2 justify-content-between">
          {dueHealthPlans &&
            dueHealthPlans.map((p) => (
              <div
                key={p.id}
                className={cn(styles.healthPlanButton, { [styles.active]: selectedHealthPlans?.includes(p) })}
                onClick={() => {
                  if (selectedHealthPlans?.includes(p)) {
                    setSelectedHealthPlans(selectedHealthPlans.filter((hp) => hp.id !== p.id));
                  } else {
                    setSelectedHealthPlans([...(selectedHealthPlans || []), p]);
                  }
                }}
              >
                <span>{p.health_plan.name}</span>
                <div className={styles.dueDatePill}>
                  {isPastDue(p) ? 'Overdue: ' : 'Next due: '}
                  {p.next_due_date && compactDateDisplay(p.next_due_date)}
                </div>
              </div>
            ))}
        </CRow>
        <CRow className="d-flex justify-content-end mt-2">
          <CButton className="w-auto" onClick={() => setCurrentStep(Step.DateTime)}>
            Next
          </CButton>
        </CRow>
      </>
    );
  };

  const dateTimeStep = () => {
    return (
      <>
        {!loadingPimResources && pimResources.length === 0 ? (
          <p>No availability exists for this appointment type.</p>
        ) : (
          <>
            {forwardBooking && (
              <div className={styles.forwardBookingDetails}>
                <div className="w-50">
                  <div className={styles.forwardBookingTitle}>Forward Booking Details</div>
                  <div>
                    <b>Employee: </b>
                    {forwardBooking?.employee.full_name_with_title}
                  </div>
                  <div>
                    <b>Timeframe: </b>
                    {forwardBooking?.due_date}
                  </div>
                </div>
                <div className="w-50">
                  <b>Notes: </b>
                  {forwardBooking?.notes ? forwardBooking.notes : 'None provided'}
                </div>
              </div>
            )}

            <CFormLabel htmlFor="resource" className="m-0 mb-2">
              Filter by Resource
            </CFormLabel>
            {loadingPimResources && (
              <div>
                <CSpinner color="primary" />
              </div>
            )}
            <div className="d-flex gap-2 mb-4">
              {pimResources.map((pr) => (
                <CButton
                  key={pr.id}
                  color="primary"
                  shape="rounded"
                  variant="outline"
                  style={{ padding: '10px', borderRadius: '15px' }}
                  onClick={() => handleSelectResource(pr)}
                  active={pr.id === pimResource?.id}
                >
                  <EmployeePhoto employee={pr.employee} height={30} width={30} className="me-2" />
                  {pr.employee.full_name_with_title}
                </CButton>
              ))}
            </div>

            <div className="d-flex justify-content-between gap-3">
              <div>
                <CFormLabel htmlFor="resource" className="m-0 mb-2">
                  Select Date
                </CFormLabel>
                {loadingDays && (
                  <div>
                    <CSpinner color="primary" />
                  </div>
                )}
                {availableDays.length ? (
                  <CDatePicker
                    className={styles.datePicker}
                    locale="en-US"
                    visible
                    closeOnSelect={false}
                    cleaner={false}
                    container={'inline'}
                    firstDayOfWeek={0}
                    inputReadOnly
                    onDateChange={handleDayChange}
                    date={selectedDate}
                    onHide={() => {
                      return false;
                    }}
                    size={'lg'}
                    weekdayFormat={'short'}
                    disabledDates={disabledDates}
                    minDate={DateTime.local({ zone: clinic?.time_zone }).toISODate()}
                    maxDate={DateTime.local({ zone: clinic?.time_zone }).plus({ months: 6 }).toISODate()}
                  />
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2 w-50">
                <CFormLabel htmlFor="resource" className="m-0 w-100">
                  Select Time
                </CFormLabel>
                <div className="d-flex flex-column gap-2 flex-wrap" style={{ height: '384px' }}>
                  {availableTimes.map((at) => (
                    <CButton
                      key={at.start_at}
                      color="primary"
                      variant="outline"
                      style={{ minHeight: '2.5rem', maxHeight: '2.5rem', width: '43%' }}
                      onClick={() => {
                        setTimeSlot(at);
                        setPimResource(pimResources.find((pr) => pr.id === at.pim_resource_id));
                      }}
                      active={at.start_at === timeSlot?.start_at}
                    >
                      {new Date(at.start_at).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}
                    </CButton>
                  ))}
                  {loadingTimes && (
                    <div>
                      <CSpinner color="primary" />
                    </div>
                  )}
                  {!selectedDate && availableTimes.length === 0 ? (
                    <p className="text-muted">Select a date first</p>
                  ) : null}
                  {!loadingTimes && selectedDate && availableTimes.length === 0 ? (
                    <p className="text-muted">
                      No appointments available {pimResource ? `for ${pimResource.employee.full_name_with_title}` : ''}{' '}
                      today
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <CButton
                onClick={() => {
                  setCurrentStep(Step.Complete);
                }}
                shape="rounded-pill"
                disabled={!timeSlot}
              >
                Confirm Details
              </CButton>
            </div>
          </>
        )}
      </>
    );
  };

  const completeStep = () => {
    return (
      <CContainer sm className="m-0">
        <div className={styles.notesSection}>
          <RichTextEditor label="Notes" value={notes} onChange={(value) => setNotes(value)} id={'notes'} name="notes" />
          <InternalNotes value={internalNotes || ''} onChange={(value) => setInternalNotes(value)} />
        </div>

        <div className="d-flex flex-column align-items-end gap-2 mt-3">
          <p className={styles.fees}>
            Booking Fee:{' '}
            {animal?.membership?.plan.appointment_booking_fee ? (
              <span style={{ color: 'var(--primary-pink)' }}>
                {toCurrency(animal?.membership?.plan.appointment_booking_fee)}
              </span>
            ) : (
              'None'
            )}
          </p>

          <p className={styles.fees}>
            Deposit:{' '}
            {appointmentType?.deposit_amount ? (
              <span style={{ color: 'var(--primary-pink)' }}>{toCurrency(appointmentType.deposit_amount)}</span>
            ) : (
              'None'
            )}
          </p>

          <CLoadingButton onClick={handleSubmit} loading={submitting} shape="rounded-pill" className="mt-2">
            Create Appointment
          </CLoadingButton>
        </div>
      </CContainer>
    );
  };

  return (
    <>
      <CContainer className={styles.container}>
        <div>
          <h1 className="m-0">Create New Appointment</h1>
        </div>

        <div
          className={cn(styles.accordionToggle, { [styles.active]: currentStep === Step.Animal })}
          onClick={() => setCurrentStep(Step.Animal)}
        >
          <h2 className={styles.accordionToggleText}>
            <SvgPaw />
            Patient
          </h2>
          <div className={styles.animalPreview}>
            {animal && (
              <>
                <AnimalPhoto animal={animal} width={25} height={25} />
                <div className={styles.animalDetail}>
                  {`"${animal.name}" ${animal.customer.last_name}`}
                  {customer?.membership && <CBadge color="brand">{customer.membership.plan.name}</CBadge>}
                  {customer?.has_unpaid_invoices ? <CBadge color="danger">Unpaid Invoices</CBadge> : null}
                </div>
              </>
            )}
          </div>
        </div>
        <CCollapse visible={currentStep === Step.Animal} className="px-4">
          {animalStep()}
        </CCollapse>

        <div
          className={cn(styles.accordionToggle, { [styles.active]: currentStep === Step.Clinic })}
          onClick={() => setCurrentStep(Step.Clinic)}
        >
          <h2 className={styles.accordionToggleText}>
            <SvgPawCross />
            Clinic
          </h2>
          <div>{clinic?.name}</div>
        </div>
        <CCollapse visible={currentStep === Step.Clinic} className="px-4">
          {clinicStep()}
        </CCollapse>

        <div
          className={cn(styles.accordionToggle, { [styles.active]: currentStep === Step.AppointmentType })}
          onClick={() => setCurrentStep(Step.AppointmentType)}
        >
          <h2 className={styles.accordionToggleText}>
            <SvgClipboard />
            Appointment Type
          </h2>
          <div>{appointmentType?.name_in_pim}</div>
        </div>
        <CCollapse visible={currentStep === Step.AppointmentType} className="px-4">
          {appointmentTypeStep()}
        </CCollapse>

        <div
          className={cn(styles.accordionToggle, { [styles.active]: currentStep === Step.HealthPlans })}
          onClick={() => setCurrentStep(Step.HealthPlans)}
        >
          <h2 className={styles.accordionToggleText}>
            <SvgChecklist />
            Health Plans
          </h2>
          <div className="d-flex flex-row gap-1">
            {selectedHealthPlans?.map((plan) => (
              <CBadge key={plan.id} color="secondary">
                {plan.health_plan.name}
              </CBadge>
            ))}
          </div>
        </div>
        <CCollapse visible={currentStep === Step.HealthPlans} className="px-4">
          {healthPlansStep()}
        </CCollapse>

        <div
          className={cn(styles.accordionToggle, { [styles.active]: currentStep === Step.DateTime })}
          onClick={() => setCurrentStep(Step.DateTime)}
        >
          <h2 className={styles.accordionToggleText}>
            <SvgCalendar />
            Date & Time
          </h2>
          <div className={styles.dateTimeDoctorText}>
            {selectedDate ? selectedDate.toLocaleDateString() : null}
            {timeSlot
              ? ` at ${new Date(timeSlot.start_at).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}`
              : null}
            {pimResource?.employee && (
              <div>
                with
                {pimResource ? <EmployeePhoto employee={pimResource.employee} width={25} height={25} /> : null}
                {pimResource?.employee.full_name_with_title}
              </div>
            )}
          </div>
        </div>
        <CCollapse visible={currentStep === Step.DateTime} className="px-4">
          {dateTimeStep()}
        </CCollapse>

        <CCollapse visible={currentStep === Step.Complete} className="px-4">
          {completeStep()}
        </CCollapse>
      </CContainer>
    </>
  );
};

export default GuidedForm;
