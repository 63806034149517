import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow } from '@coreui/react-pro';

import { Customer } from 'types/Customer';

import { formatPhoneNumber } from 'utils/phoneNumbers';

import { ReactComponent as CallIcon } from 'assets/images/call.svg';
import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import { ReactComponent as DotIcon } from 'assets/images/dot.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit_white.svg';
import { ReactComponent as LocationIcon } from 'assets/images/location.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more.svg';
import { ReactComponent as PulseBG } from 'assets/images/pulse.svg';
import { ReactComponent as EmailIcon } from 'assets/images/sms.svg';

import { MembershipModal } from 'components/MembershipModal';

import styles from './InfoCard.module.scss';

import EditCustomerModal from './EditCustomerModal';

type Props = {
  customer: Customer;
  setCustomer: (customer: Customer) => void;
  actionsMenuItems: { label: string; icon?: string[]; onClick: () => void; disabled?: boolean }[];
  updateCustomer: () => void;
};

export const InfoCard = ({ customer, actionsMenuItems, updateCustomer, setCustomer }: Props) => {
  const [showMembershipPopup, setShowMembershipPopup] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleEditSuccess = (customer: Customer) => {
    updateCustomer();
    setShowEditModal(false);
    toast.success('Customer updated!');
  };

  return (
    <div className={styles.root}>
      <PulseBG className={styles.pulse} />
      <CRow className={styles.row}>
        <h1 className={styles.header}>
          {customer.first_name} {customer.last_name}
        </h1>
        {customer.has_unpaid_invoices && <CBadge className={styles.badge}>Unpaid Invoices</CBadge>}
      </CRow>

      <CRow className={styles.row}>
        <div className={styles.column}>
          <LocationIcon />
          <p className={styles.lineText}>
            {customer.shipping_address?.street1} {customer.shipping_address?.street2} {customer.shipping_address?.city},{' '}
            {customer.shipping_address?.state} {customer.shipping_address?.zip_code}
          </p>
        </div>
        <div className={styles.column}>
          <CrownIcon />
          <p className={styles.blueText}>{customer.membership?.plan.name}</p>
          <CButton
            className={styles.moreButton}
            variant="outline"
            shape="rounded-pill"
            onClick={() => setShowMembershipPopup(true)}
            aria-label="Membership Details"
          >
            <MoreIcon />
          </CButton>
          <DotIcon />
          <p className={styles.lineText} style={{ textTransform: 'capitalize' }}>
            {customer.membership?.status}
          </p>
        </div>
      </CRow>

      <CRow className={styles.row}>
        <div className={styles.column}>
          <EmailIcon />
          <p className={styles.lineText}>{customer.email}</p>
        </div>
        <div className={styles.column}>
          {customer.preferred_employee && (
            <Link to={`/employees/${customer.preferred_employee.id}`} className={styles.link}>
              {customer.preferred_employee.full_name_with_title}
            </Link>
          )}
          {customer.preferred_employee && customer.membership?.preferred_clinic ? <DotIcon /> : null}
          <p className={styles.lineText} style={{ textTransform: 'capitalize' }}>
            {customer.membership?.preferred_clinic?.name}
          </p>
        </div>
      </CRow>

      <CRow className={styles.row}>
        <div className={styles.column}>
          <CallIcon />
          <p className={styles.lineText}>{formatPhoneNumber(customer.phone)}</p>
        </div>
        <div className={styles.column}>
          <CButton className={styles.editButton} shape="rounded-pill" onClick={() => setShowEditModal(true)}>
            <EditIcon />
          </CButton>

          <CDropdown alignment="end">
            <CDropdownToggle
              className={styles.moreButton}
              shape="rounded-pill"
              variant="outline"
              caret={false}
              aria-label="Actions menu"
            >
              <MoreIcon className={styles.moreIcon} />
            </CDropdownToggle>

            <CDropdownMenu>
              {actionsMenuItems.map((item, index) => (
                <CDropdownItem
                  key={index}
                  role="button"
                  className="align-items-center"
                  onClick={item.onClick}
                  disabled={item.disabled}
                >
                  {item.icon ? <CIcon icon={item.icon} aria-label="Icon" className="me-2 text-high-emphasis" /> : null}
                  {item.label}
                </CDropdownItem>
              ))}
            </CDropdownMenu>
          </CDropdown>
        </div>
      </CRow>

      {showMembershipPopup && (
        <MembershipModal
          customer={customer}
          hideModal={() => setShowMembershipPopup(false)}
          updateCustomer={updateCustomer}
        />
      )}

      {showEditModal && (
        <EditCustomerModal
          customer={customer}
          setCustomer={setCustomer}
          onSuccess={handleEditSuccess}
          onCancel={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
};
