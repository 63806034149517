import cn from 'classnames';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CCallout,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormSwitch,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchVaccineProducts } from 'api/Products';

import { Clinic } from 'types/Clinic';
import { Vaccination } from 'types/Vaccination';

import { ClinicContext } from 'contexts/ClinicContext';

import { utcToZonedDate, zonedToUtcDate } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import { DescribableOption, productToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgCart from 'assets/images/SvgCart';
import SvgClipboard from 'assets/images/SvgClipboard';

import { DatePicker } from 'components/DatePicker';
import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';

import styles from './VaccinationForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  vaccination?: Vaccination;
  formDefaults?: { historical?: boolean };
};

const MIN_QUERY_LENGTH = 3;

export const VaccinationForm = ({
  loading,
  hideForm,
  onSubmit,
  vaccination,
  formDefaults: { historical = false } = {}
}: Props) => {
  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();

  useEffect(() => {
    if (clinicContext) fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  const [selectedOption, setSelectedOption] = useState<DescribableOption | null>(
    vaccination ? productToOption(vaccination.product) : null
  );

  const [isHistorical, setIsHistorical] = useState(vaccination?.historical ?? historical);
  const [quantity, setQuantity] = useState(vaccination?.quantity ?? '1');
  const [serialNumber, setSerialNumber] = useState(vaccination?.serial_number ?? '');
  const [expirationDate, setExpirationDate] = useState<Date | undefined | null>(
    vaccination?.expiration_date ? utcToZonedDate(vaccination.expiration_date, clinic) : undefined
  );

  useEffect(() => {
    if (clinic && vaccination) {
      setExpirationDate(vaccination?.expiration_date ? zonedToUtcDate(vaccination.expiration_date, clinic) : null);
    }
  }, [clinic, vaccination]);

  const handleHistoricalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsHistorical(checked);
  };

  const handleSelectChange = (selectedOption: DescribableOption | null) => {
    setSelectedOption(selectedOption);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const parseExpirationDate = (date: Date | null) => {
    if (date) {
      setExpirationDate(zonedToUtcDate(date, clinic));
    }
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{vaccination ? 'Edit' : 'New'} Vaccination</h2>

      <CRow className="mb-3">
        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="date_of_administration">
              <IconLabel icon={SvgCalendar} label="Date of Adminstration" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <DatePicker
            date={vaccination?.date_of_administration || new Date().toISOString()}
            id="date_of_administration"
            name="date_of_administration"
            required
          />
        </CCol>
        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="date_of_next_administration">
              <IconLabel icon={SvgCalendar} label="Date of Next Adminstration" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <DatePicker
            date={vaccination?.date_of_next_administration || null}
            id="date_of_next_administration"
            name="date_of_next_administration"
            required
          />
        </CCol>
      </CRow>

      <CCol className="mb-3">
        <CFormSwitch
          name="historical"
          label="Is Historical?"
          id="historical"
          checked={isHistorical}
          onChange={handleHistoricalChange}
        />
      </CCol>

      <CRow className="mb-3">
        <CCol>
          <CFormInput hidden id="product_id" name="product_id" value={selectedOption?.value ?? ''} />

          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="product">
              <IconLabel icon={SvgCart} label="Product" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <AsyncSelect<DescribableOption>
            id="product"
            aria-label="Product"
            value={selectedOption}
            placeholder="Type to search..."
            onChange={handleSelectChange}
            loadingMessage={loadingMessage}
            styles={reactSelectStyles}
            loadOptions={(inputValue, callback) => {
              if (inputValue.length < MIN_QUERY_LENGTH) return;

              fetchVaccineProducts(inputValue).then((options) => {
                callback(options.map(productToOption));
              });
            }}
            isClearable
            isSearchable
            required
          />
        </CCol>

        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="quantity">
              <IconLabel icon={SvgClipboard} label="Quantity" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <CFormInput
            id="quantity"
            name="quantity"
            value={quantity}
            type="number"
            min={1}
            step="any"
            onChange={(event) => setQuantity(event.target.value)}
            required
          />
        </CCol>
      </CRow>

      {selectedOption?.description && (
        <CCol className="mb-3">
          <CCallout color="info">{selectedOption.description}</CCallout>
        </CCol>
      )}

      <CRow className="mb-3">
        <CCol>
          <CFormInput
            id="serial_number"
            name="serial_number"
            label={<IconLabel icon={SvgClipboard} label="Serial Number" />}
            value={serialNumber}
            onChange={(event) => setSerialNumber(event.target.value)}
          />
        </CCol>

        <CCol>
          <CDatePicker
            cleaner
            date={expirationDate}
            firstDayOfWeek={0}
            label={<IconLabel icon={SvgCalendar} label="Date of Expiration" />}
            minDate={new Date()}
            onDateChange={parseExpirationDate}
            format="yyyy-MM-dd"
            id="expiration_date"
          />
        </CCol>
      </CRow>

      <div className="d-flex align-items-center justify-content-between">
        {vaccination && <FormAuditData item={vaccination} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            className={styles.button}
            shape="rounded-pill"
            loading={loading}
            color="primary"
            type="submit"
          >
            {vaccination ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
