import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import * as React from 'react';

import { PrescriptionLabel } from 'types/PrescriptionLabel';

import { styles } from 'utils/printLabel';
import { stripHTMLtags } from 'utils/strings';

type Props = {
  prescriptionLabel: PrescriptionLabel;
};

export const PrescriptionItemLabelPrint = ({ prescriptionLabel }: Props): JSX.Element => {
  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`${prescriptionLabel.animal_name}'s Medication Label`}>
        <Page size={{ width: 572, height: 318 }} style={styles.page}>
          <View style={styles.address}>
            <Text>Dr. Treat</Text>
            <Text>{prescriptionLabel.clinic_address}</Text>
            <Text>📞 {prescriptionLabel.clinic_phone}</Text>
          </View>

          <View style={styles.metaData}>
            <View>
              <Text>
                Patient: {prescriptionLabel.animal_name} ({prescriptionLabel.animal_species})
              </Text>
              <Text>Owner: {prescriptionLabel.customer_name}</Text>
              <Text>Prescriber: {prescriptionLabel.prescriber_name}</Text>
            </View>
            <View>
              <Text>Date: {prescriptionLabel.prescribed_at}</Text>
              <Text>Prescription Number: {prescriptionLabel.prescription_item_id}</Text>
            </View>
          </View>

          <View style={styles.divider} />

          <View>
            <Text style={styles.bold}>{prescriptionLabel.product_name}</Text>
            <Text>Expires: {prescriptionLabel.expiration_date}</Text>
            <Text>Quantity: {prescriptionLabel.dispensed_quantity}</Text>
            <Text>Instructions: {stripHTMLtags(prescriptionLabel.instructions)}</Text>
          </View>

          <View>
            <Text>INT: _______ / _______</Text>
            <Text style={styles.em}>For veterinary use only</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
