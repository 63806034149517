import React, { useEffect, useMemo, useState } from 'react';

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';

import { AppointmentType } from 'types/AppointmentType';
import { HealthPlan } from 'types/HealthPlan';
import { Option } from 'types/Option';

import { appointmentTypeToOption } from 'utils/selectOptions';

type HealthPlanFormProps = {
  handleSubmit: () => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  healthPlan: HealthPlan;
  isSubmitting?: boolean;
};

const Form = ({
  handleSubmit,
  handleCancel,
  healthPlan,
  handleChange,
  isSubmitting
}: HealthPlanFormProps): JSX.Element => {
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState<Option[]>();

  useMemo(() => {
    if (appointmentTypes.length === 0) {
      fetchAppointmentTypes(setAppointmentTypes);
    }
  }, [appointmentTypes]);

  useEffect(() => {
    if (appointmentTypes.length > 0) {
      setAppointmentTypeOptions([{ label: '', value: '' }, ...appointmentTypes.map(appointmentTypeToOption)]);
    }
  }, [appointmentTypes]);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmit();
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CFormLabel htmlFor="name" className="col-sm-3 col-form-label">
          Name
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="name" value={healthPlan.name || ''} required onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="type" className="col-sm-3 col-form-label">
          Type
        </CFormLabel>
        <CCol sm={9}>
          <CFormSelect
            aria-label="health plan type selection"
            id="type"
            value={healthPlan.type}
            options={[
              { label: '', value: '' },
              { label: 'Appointment Plan', value: 'AppointmentPlan' },
              { label: 'Diagnostic Plan', value: 'DiagnosticPlan' },
              { label: 'Medication Plan', value: 'MedicationPlan' },
              { label: 'Procedure Plan', value: 'ProcedurePlan' },
              { label: 'Vaccine Plan', value: 'VaccinePlan' }
            ]}
            onChange={handleChange}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="importance" className="col-sm-3 col-form-label">
          Importance
        </CFormLabel>
        <CCol sm={9}>
          <CFormSelect
            aria-label="health plan importance selection"
            id="importance"
            value={healthPlan.importance || ''}
            options={['', 'non-core', 'core', 'required']}
            onChange={handleChange}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="minimum_age_in_months" className="col-sm-3 col-form-label">
          Min Age (months)
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="number"
            id="minimum_age_in_months"
            value={healthPlan.minimum_age_in_months || ''}
            onChange={handleChange}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="recurring" className="col-sm-3 col-form-label">
          Is Recurring
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch
            id="recurring"
            aria-label="Is Recurring"
            checked={healthPlan.recurring ?? false}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      {!healthPlan.id && (
        <CRow className="mb-3">
          <CFormLabel htmlFor="status" className="col-sm-3 col-form-label">
            Status
          </CFormLabel>
          <CCol sm={9}>
            <CFormSelect
              aria-label="health plan status selection"
              id="status"
              value={healthPlan.status}
              options={[
                { label: '', value: '' },
                { label: 'Draft', value: 'draft' },
                { label: 'Active', value: 'active' }
              ]}
              onChange={handleChange}
              text={
                healthPlan.status === 'draft'
                  ? "Health plans created in draft mode won't enroll animals until the plan is activated. "
                  : null
              }
              required
            />
          </CCol>
        </CRow>
      )}
      <CRow className="mb-3">
        <CFormLabel htmlFor="appointment_type_id" className="col-sm-3 col-form-label">
          Appointment Type
        </CFormLabel>
        <CCol sm={9}>
          <CFormSelect
            aria-label="health plan appointment type selection"
            id="appointment_type_id"
            value={String(healthPlan.appointment_type_id) || ''}
            options={appointmentTypeOptions}
            onChange={handleChange}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="details"
            label="Details"
            rows={3}
            placeholder="What happens during this health plan? What does it accomplish?"
            value={healthPlan.details || undefined}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="why_recommended"
            label="Why it's recommended"
            rows={3}
            placeholder="How does this health plan benefit the health of the animal? How does it fit into our values?"
            value={healthPlan.why_recommended || undefined}
            onChange={handleChange}
          />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
