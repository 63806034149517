export const productTypes = [
  'Procedure',
  'Medication',
  'Diagnostic',
  'Standard',
  'Fee',
  'Discount',
  'Vaccination'
] as const;
export type ProductType = (typeof productTypes)[number];
