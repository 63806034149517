import axios from 'axios';

import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { Pagination } from 'types/Pagination';

const fetchDiagnosticRequests = (
  params: object,
  {
    onSuccess,
    page,
    setPagination
  }: {
    onSuccess: (diagnosticRequests: DiagnosticRequest[]) => void;
    page?: number;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = '/pim/diagnostic_requests';
  axios
    .get<DiagnosticRequest[]>(url, {
      params: {
        ...params,
        page: page || 1
      }
    })
    .then((data) => {
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
      onSuccess(data.data.record);
    });
};

const fetchDiagnosticRequestsByAnimal = (
  animalId: string,
  onSuccess: (diagnosticRequest: DiagnosticRequest[]) => void
) => {
  const url = `/pim/diagnostic_requests?animal_id=${animalId}`;
  axios.get<DiagnosticRequest[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchDiagnosticRequestsForConsult = (
  consultId: number | string,
  onSuccess: (diagnosticRequest: DiagnosticRequest[]) => void
) => {
  const url = `/pim/diagnostic_requests`;
  axios
    .get<DiagnosticRequest[]>(url, { params: { consult_id: consultId } })
    .then((data) => onSuccess(data.data.record));
};

const createDiagnosticRequest = (
  params: Partial<DiagnosticRequest>,
  onSuccess: (diagnosticRequests: DiagnosticRequest[]) => void,
  onError?: (error: string) => void
) => {
  const url = `/pim/diagnostic_requests`;
  return axios
    .post<DiagnosticRequest[]>(url, params)
    .then((response) => onSuccess(response.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const updateDiagnosticRequest = (
  diagnosticRequestId: number,
  params: Partial<DiagnosticRequest>,
  {
    onSuccess,
    onError
  }: { onSuccess: (diagnosticRequest: DiagnosticRequest) => void; onError?: (error: string) => void }
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}`;
  axios
    .put<DiagnosticRequest>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const refreshDiagnosticRequest = (
  diagnosticRequestId: number,
  onSuccess: (diagnosticRequest: DiagnosticRequest) => void
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}/refresh`;
  axios.put<DiagnosticRequest>(url).then((data) => onSuccess(data.data.record));
};

const sendDiagnosticRequestOrder = (
  diagnosticRequestId: number,
  onSuccess: (diagnosticRequest: DiagnosticRequest) => void
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}/send_order`;
  axios.put<DiagnosticRequest>(url).then((data) => onSuccess(data.data.record));
};

export {
  fetchDiagnosticRequests,
  fetchDiagnosticRequestsForConsult,
  fetchDiagnosticRequestsByAnimal,
  createDiagnosticRequest,
  updateDiagnosticRequest,
  refreshDiagnosticRequest,
  sendDiagnosticRequestOrder
};
