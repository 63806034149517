import { differenceInYears } from 'date-fns';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { paths } from 'routes';

import { CCard } from '@coreui/react-pro';

import { fetchHuddleAppointments } from 'api/Appointments';
import { fetchClinic } from 'api/Clinics';

import { Appointment } from 'types/Appointment';
import { Clinic } from 'types/Clinic';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { timeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { AnimalPhoto } from 'components/AnimalPhoto';
import { PossibleParvoBadge } from 'components/PossibleParvoBadge';

import styles from './HuddleList.module.scss';

const HuddleList = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date') || '';
  const [appointments, setAppointments] = useState<Appointment[]>();

  const [currentClinic, setCurrentClinic] = useState<Clinic>();
  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    if (clinicContext) fetchClinic(clinicContext, setCurrentClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (date && currentClinic) {
      const dtSelectedDay = DateTime.fromFormat(date, 'EEE MMM dd yyyy', { zone: currentClinic.time_zone });
      const startOfDay = dtSelectedDay.startOf('day').toJSDate();
      const endOfDay = dtSelectedDay.endOf('day').toJSDate();

      fetchHuddleAppointments(startOfDay, endOfDay, currentClinic.id, (appts) =>
        setAppointments(appts.sort((a, b) => a.client_start_time.localeCompare(b.client_start_time)))
      );
    }
  }, [date, currentClinic]);

  useDocumentTitle('Huddle');

  return (
    <>
      <h1>Huddle - {date}</h1>
      <div className={styles.appointments}>
        {appointments &&
          appointments.map((appointment) => (
            <CCard key={appointment.id} className={styles.card}>
              <Link
                to={generatePath(paths.animalDetails, { id: appointment.animal.id })}
                aria-label={appointment.animal.name || 'Patient details'}
              >
                <AnimalPhoto animal={appointment.animal} />
              </Link>
              <div className="w-100">
                <div className={styles.animal}>
                  <div className={styles.left}>
                    <Link className={styles.name} to={generatePath(paths.animalDetails, { id: appointment.animal.id })}>
                      {appointment.animal.name}
                    </Link>
                    <PossibleParvoBadge animal={appointment.animal} appointment={appointment} />
                  </div>
                  <Link to={`/appointments/${appointment.id}`}>
                    {timeDisplay(appointment.client_start_time)} - {appointment.pim_type}
                  </Link>
                </div>

                <ul className={styles.reasons}>
                  {/* Are any patients less than 1 year old, who have symptoms of diarrhea and vomiting? */}
                  {appointment.animal.date_of_birth &&
                    differenceInYears(new Date(), new Date(appointment.animal.date_of_birth)) < 1 &&
                    appointment.animal.date_of_birth &&
                    appointment.visit_reason_list.some((reason) =>
                      ['vomiting', 'diarrhea or stool change'].includes(reason.toLowerCase())
                    ) && (
                      <li>
                        Less than a year old,{' '}
                        {appointment.visit_reason_list
                          .filter(
                            (reason) => ['vomiting', 'diarrhea or stool change'].indexOf(reason.toLowerCase()) >= 0
                          )
                          .join(', ')}
                      </li>
                    )}

                  {/* Do any patients have symptoms of coughing, or sneezing? */}
                  {appointment.visit_reason_list.indexOf('Coughing or Sneezing') > -1 && <li>Coughing or Sneezing</li>}

                  {/* Which patients do not have a FAS score on file? */}
                  {!appointment.animal.fas_score && <li>FAS Score: Unknown</li>}

                  {/* Which patients (pets) have an extreme demeanor/temperament (FAS 3/4)? */}
                  {Number(appointment.animal.fas_score) > 2 && <li>FAS Score: {appointment.animal.fas_score}</li>}

                  {/* Are any pets PAST DUE on their rabies vaccine? */}
                  {appointment.animal.rabies_next_due_date &&
                    new Date() < new Date(appointment.animal.rabies_next_due_date) && <li>Rabies past due</li>}

                  {/* Who is missing medical records? */}
                  {!appointment.animal.past_records_uploaded && <li>Missing Medical Records</li>}

                  {/* Who has a UNPAID invoice or balance? */}
                  {appointment.customer?.has_unpaid_invoices && appointment.customer.unpaid_balance > 0 && (
                    <li>Unpaid Balance: {toCurrency(appointment.customer.unpaid_balance)}</li>
                  )}

                  {/* Who is coming in for their first visit (One membership)? */}
                  {!appointment.animal.last_appointment_json?.start_time && <li>First Visit</li>}

                  {/* Who needs prior sedation? */}
                  {appointment.animal.needs_sedation && <li>Requires Sedation</li>}
                </ul>
              </div>
            </CCard>
          ))}
      </div>
    </>
  );
};

export default HuddleList;
