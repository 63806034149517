import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable
} from '@coreui/react-pro';

import { PresentingProblem } from 'types/PresentingProblem';

import { compactDateTimeDisplay } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { Pill } from 'components/Pill';

import { fetchPresentingProblems } from '../../api/PresentingProblems';

const List = (): JSX.Element => {
  const [presentingProblemsList, setPresentingProblemsList] = useState<PresentingProblem[]>();
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateProblemList = useCallback((problems: PresentingProblem[]) => {
    setIsLoading(false);
    setPresentingProblemsList(problems);
  }, []);

  useEffect(() => {
    if (searchText.length === 0) {
      fetchPresentingProblems('', updateProblemList);
    }
  }, [searchText, updateProblemList]);

  useEffect(() => {
    setIsLoading(true);
    fetchPresentingProblems('', updateProblemList);
  }, [updateProblemList]);

  const fetchProblemList = useCallback(() => {
    setIsLoading(true);
    fetchPresentingProblems(searchText, updateProblemList);
  }, [searchText, updateProblemList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchProblemList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <CForm className="row g-3" onSubmit={handleSubmit}>
              <CCol xs={8}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Presenting Problems"
                  placeholder="Search by name"
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end" xs={2}>
                <CLoadingButton
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  disabled={!searchText}
                  shape="rounded-pill"
                >
                  Search
                </CLoadingButton>
              </CCol>
              <CCol className="align-self-end text-end" xs={2}>
                <CButton color="primary" href={'/presenting_problems/new'} shape="rounded-pill">
                  New
                </CButton>
              </CCol>
            </CForm>
          </CCardHeader>
          <CCardBody>
            {!isLoading && (
              <CFormLabel className="ms-3 mt-2 text-body-secondary" color="light">
                {presentingProblemsList?.length} {pluralize('problem', presentingProblemsList?.length || 0)} found
              </CFormLabel>
            )}
            {presentingProblemsList && presentingProblemsList.length > 0 && (
              <CSmartTable
                items={presentingProblemsList}
                itemsPerPage={25}
                pagination
                paginationProps={{
                  align: 'center',
                  pages: presentingProblemsList.length
                }}
                columns={[
                  {
                    key: 'name',
                    label: 'Name'
                  },
                  {
                    key: 'status',
                    label: 'Status'
                  },
                  {
                    key: 'created_at',
                    label: 'Created At'
                  },
                  {
                    key: 'updated_at',
                    label: 'Updated At'
                  }
                ]}
                scopedColumns={{
                  name: (presentingProblem: PresentingProblem) => {
                    return (
                      <td>
                        <Link to={generatePath(paths.presentingProblemDetails, { id: presentingProblem.id })}>
                          {presentingProblem.name}
                        </Link>
                      </td>
                    );
                  },
                  status: (presentingProblem: PresentingProblem) => {
                    return (
                      <td>
                        <Pill label={presentingProblem.status} />
                      </td>
                    );
                  },
                  created_at: (presentingProblem: PresentingProblem) => {
                    return <td>{compactDateTimeDisplay(presentingProblem.created_at)}</td>;
                  },
                  updated_at: (presentingProblem: PresentingProblem) => {
                    return (
                      <td>
                        {compactDateTimeDisplay(presentingProblem.updated_at)} (
                        {presentingProblem.updated_by_employee.last_name})
                      </td>
                    );
                  }
                }}
              ></CSmartTable>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
