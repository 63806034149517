import cn from 'classnames';
import * as React from 'react';
import { forwardRef, Ref, useState } from 'react';
import { toast } from 'react-toastify';

import { CRow, CSmartPagination } from '@coreui/react-pro';

import { createCommunicationLog, updateCommunicationLog } from 'api/CommunicationLogs';

import { Animal } from 'types/Animal';
import { CommunicationLog } from 'types/CommunicationLog';
import { CommunicationType } from 'types/CommunicationType';
import { Context } from 'types/Context';
import { Customer } from 'types/Customer';
import { ListFilter } from 'types/ListFilter';
import { calculatePages, Pagination } from 'types/Pagination';

import SvgMessages from 'assets/images/SvgMessages';
import SvgOutgoing from 'assets/images/SvgOutgoing';

import { CommunicationLogItem } from 'components/CommunicationLogItem';
import { IconButton } from 'components/IconButton';
import { NewLogModal } from 'components/NewLogModal';
import { NewMessageModal } from 'components/NewMessageModal';

import styles from './CommunicationLogList.module.scss';

import { EditLogModal } from './EditLogModal';
import Filters from './Filters';

type Modals = 'new-log' | 'new-message' | 'edit-log';

type Props = {
  customer: Customer;
  contexts?: Context[];
  communicationLogs?: CommunicationLog[];
  animal?: Animal;
  filters: ListFilter[];
  handleFilterChange: (filters: ListFilter[]) => void;
  fetchCommunicationLogs: () => void;
  pagination: Pagination;
  updatePage: (page: number) => void;
};

export const CommunicationLogList = forwardRef(function CommunicationLogList(
  {
    customer,
    communicationLogs,
    contexts,
    animal,
    filters,
    fetchCommunicationLogs,
    handleFilterChange,
    pagination,
    updatePage
  }: Props,
  ref: Ref<HTMLDivElement>
) {
  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [loading, setLoading] = useState(false);
  const [editCommunicationLog, setEditCommunicationLog] = useState<CommunicationLog>();

  const hideModal = () => setVisibleModal(undefined);

  const handleSuccess = (message: string) => {
    setLoading(false);
    hideModal();
    toast.success(message);
    fetchCommunicationLogs();
  };

  const handleError = () => setLoading(false);

  const handleCreateMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const animalIds = formData.get('animal_ids');
    const formJson = {
      communication_type: 'message' as CommunicationType,
      customer_id: customer.id,
      ...Object.fromEntries(formData.entries()),
      animal_ids: animalIds?.toString().split(',') ?? []
    };

    createCommunicationLog(formJson, { onSuccess: () => handleSuccess('Message sent!'), onError: handleError });
  };

  const handleCreateLog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const animalIds = formData.get('animal_ids');
    const formJson = {
      customer_id: customer.id,
      ...Object.fromEntries(formData.entries()),
      animal_ids: animalIds?.toString().split(',') ?? []
    };

    createCommunicationLog(formJson, { onSuccess: () => handleSuccess('Log created!'), onError: handleError });
  };

  const handleUpdateLog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const animalIds = formData.get('animal_ids');
    const formJson = {
      customer_id: customer.id,
      ...Object.fromEntries(formData.entries()),
      animal_ids: animalIds?.toString().split(',') ?? []
    };

    editCommunicationLog &&
      updateCommunicationLog(editCommunicationLog.id, formJson, {
        onSuccess: () => handleSuccess('Log updated!'),
        onError: handleError
      });
  };

  const renderModal = (modal?: Modals) => {
    switch (modal) {
      case 'new-message':
        if (contexts) {
          return (
            <NewMessageModal
              animals={customer.animals}
              subjectAnimal={animal}
              contexts={contexts}
              hideModal={hideModal}
              loading={loading}
              onSubmit={handleCreateMessage}
            />
          );
        }
        break;
      case 'new-log':
        if (contexts) {
          return (
            <NewLogModal
              animals={customer.animals}
              subjectAnimal={animal}
              contexts={contexts}
              hideModal={hideModal}
              loading={loading}
              onSubmit={handleCreateLog}
            />
          );
        }
        break;
      case 'edit-log':
        if (contexts && editCommunicationLog) {
          return (
            <EditLogModal
              animals={customer.animals}
              hideModal={() => setEditCommunicationLog(undefined)}
              loading={loading}
              onSubmit={handleUpdateLog}
              communicationLog={editCommunicationLog}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  const handleEditLog = (communicationLog: CommunicationLog) => {
    setEditCommunicationLog(communicationLog);
    setVisibleModal('edit-log');
  };

  return (
    <>
      {renderModal(visibleModal)}

      <CRow className="mb-3" ref={ref}>
        {(animal || (customer.animals && customer.animals.length > 0)) && (
          <div className="d-flex align-items-center justify-content-end gap-2 mb-3">
            <Filters setFilters={handleFilterChange} filters={filters} useRange />
            <IconButton onClick={() => setVisibleModal('new-log')} label="New Log" icon={SvgOutgoing} />
            <IconButton onClick={() => setVisibleModal('new-message')} label="New Message" icon={SvgMessages} />
          </div>
        )}

        <div className="position-relative">
          {communicationLogs?.length ? (
            <>
              <div className={cn(styles.card)} role="list" aria-label="Communications">
                {communicationLogs?.map((communicationLog) => (
                  <CommunicationLogItem
                    key={communicationLog.id}
                    communicationLog={communicationLog}
                    handleEditLog={handleEditLog}
                  />
                ))}
              </div>

              <CSmartPagination
                activePage={pagination.page}
                pages={calculatePages(pagination)}
                onActivePageChange={updatePage}
                align="center"
              />
            </>
          ) : (
            'Communications will appear here.'
          )}
        </div>
      </CRow>
    </>
  );
});
