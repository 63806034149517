import * as React from 'react';
import type { SVGProps } from 'react';

const SvgClipboardX = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m10.501 12.12-2.97-2.97M10.47 9.18 7.5 12.15M7.5 4.5h3C12 4.5 12 3.75 12 3c0-1.5-.75-1.5-1.5-1.5h-3C6.75 1.5 6 1.5 6 3s.75 1.5 1.5 1.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 3.015c2.498.135 3.75 1.058 3.75 4.485V12c0 3-.75 4.5-4.5 4.5h-4.5C3 16.5 2.25 15 2.25 12V7.5c0-3.42 1.253-4.35 3.75-4.485"
    />
  </svg>
);
export default SvgClipboardX;
