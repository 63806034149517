import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import { CSpinner } from '@coreui/react-pro';

import { updateAppointment } from 'api/Appointments';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Appointment } from 'types/Appointment';
import { Option } from 'types/Option';

import { reactSmallSelectStyles } from 'utils/reactSelect';
import { employeeToOption } from 'utils/selectOptions';

type Props = {
  appointment: Appointment;
};

export const AppointmentCaseOwnerSelect = ({ appointment }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(
    appointment.case_owner ? employeeToOption(appointment.case_owner) : null
  );

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);

    setIsUpdating(true);
    updateAppointment(
      appointment.id,
      { case_owner_id: option ? Number(option.value) : null },
      { onSuccess: handleUpdateSuccess, onError: handleUpdateError }
    );
  };

  const handleUpdateSuccess = () => {
    setIsUpdating(false);
    toast.success('Case owner updated!');
  };

  const handleUpdateError = (error: string) => {
    setIsUpdating(false);
    toast.error(error);
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < 3) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  return (
    <div className="d-flex align-items-center gap-2 w-100" style={{ position: 'relative' }}>
      <AsyncSelect<Option>
        id="case_owner"
        classNamePrefix="case-owner"
        aria-label="Case Owner"
        placeholder="Type to search..."
        value={selectedEmployeeOption}
        styles={reactSmallSelectStyles}
        onChange={handleEmployeeSelectChange}
        loadOptions={loadEmployeeOptions}
        isDisabled={isUpdating}
        isSearchable
      />
      {isUpdating && (
        <CSpinner size="sm" color="primary" style={{ background: 'white', position: 'absolute', right: '13px' }} />
      )}
    </div>
  );
};
