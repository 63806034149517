import axios from 'axios';

import { PresentingProblem } from 'types/PresentingProblem';

const fetchPresentingProblems = (query: string, onSuccess: (presentingProblems: PresentingProblem[]) => void) => {
  const url = `/pim/presenting_problems`;
  axios
    .get<PresentingProblem[]>(url, {
      params: {
        query: query
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
    });
};

function fetchPresentingProblem(problemId: string, onSuccess: (problem: PresentingProblem) => void) {
  const url = `/pim/presenting_problems/${problemId}`;
  axios.get<PresentingProblem>(url).then((data) => onSuccess(data.data.record));
}

function updatePresentingProblem(
  presentingProblemId: string,
  params: PresentingProblem,
  onSuccess: (presentingProblem: PresentingProblem) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/presenting_problems/${presentingProblemId}`;
  axios
    .put<PresentingProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function createPresentingProblem(
  params: PresentingProblem,
  onSuccess: (presentingProblem: PresentingProblem) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/presenting_problems`;
  axios
    .post<PresentingProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

export { fetchPresentingProblems, fetchPresentingProblem, updatePresentingProblem, createPresentingProblem };
