import * as React from 'react';
import type { SVGProps } from 'react';

const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19" {...props}>
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.896 14.896c0 .848.693 1.541 1.541 1.541s1.542-.693 1.542-1.541-.694-1.542-1.542-1.542-1.541.694-1.541 1.542Zm0-10.792c0 .848.693 1.542 1.541 1.542s1.542-.694 1.542-1.542-.694-1.541-1.542-1.541-1.541.693-1.541 1.541Zm0 5.396c0 .848.693 1.541 1.541 1.541s1.542-.693 1.542-1.541-.694-1.542-1.542-1.542-1.541.694-1.541 1.542Z"
    />
  </svg>
);
export default SvgMore;
