import * as React from 'react';
import { createRef, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { OwnerInfoCard } from 'views/appointments/OwnerInfoCard';

import { CButton, CCol, CNav, CNavItem, CNavLink, CRow, CSpinner } from '@coreui/react-pro';

import { fetchAnimal } from 'api/Animals';
import { fetchClinic } from 'api/Clinics';
import { fetchConsult } from 'api/Consults';
import { fetchCustomer } from 'api/Customers';

import { Animal } from 'types/Animal';
import { Clinic } from 'types/Clinic';
import { Consult } from 'types/Consult';
import { ConsultDetailsStep, consultDetailsSteps } from 'types/ConsultDetailsStep';
import { Customer } from 'types/Customer';
import { DocumentContextObjects } from 'types/DocumentContextObjects';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import SvgPlay from 'assets/images/SvgPlay';

import { AppointmentSummary } from 'components/AppointmentSummary';
import { Signalment } from 'components/Signalment';

import styles from './Details.module.scss';

import { CommunicationsStep } from './CommunicationsStep';
import { DiagnosticsStep } from './DiagnosticsStep';
import { HealthPlansList } from './HealthPlansList';
import { HealthStatusesStep } from './HealthStatusesStep';
import { MasterProblemsList } from './MasterProblemsList';
import { MedicationsVaccinationsStep } from './MedicationsVaccinationsStep';
import { ProceduresStep } from './ProceduresStep';
import { SOAPStep } from './SOAPStep';
import { SummaryStep } from './SummaryStep';
import { TasksStep } from './TasksStep';
import { TreatmentPlansStep } from './TreatmentPlansStep';

export const sidebarSteps = ['health-plans', 'master-problems'] as const;

export type SidebarStep = (typeof sidebarSteps)[number];

const Details = (): JSX.Element => {
  type DetailsParams = { id: string };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [consult, setConsult] = useState<Consult>();
  const [animal, setAnimal] = useState<Animal>();
  const [contextObjects, setContextObjects] = useState<DocumentContextObjects>({});
  const [customer, setCustomer] = useState<Customer>();
  const [currentStep, setCurrentStep] = useState<ConsultDetailsStep>(consultDetailsSteps[0]);
  const [sidebarStep, setSidebarStep] = useState<SidebarStep>(sidebarSteps[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollRef = createRef<HTMLDivElement>();

  useDocumentTitle('Consult Details');

  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();
  useEffect(() => {
    clinicContext && fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useEffect(() => {
    fetchConsult(id, setConsult);
  }, [id]);

  useEffect(() => {
    let stepFromParams = searchParams.get('step');

    if (!stepFromParams || !consultDetailsSteps.includes(stepFromParams as ConsultDetailsStep)) {
      stepFromParams = consultDetailsSteps[0];
    }

    setCurrentStep(stepFromParams as ConsultDetailsStep);
  }, [searchParams, setSearchParams]);

  const handleStepClick = (step: ConsultDetailsStep) => {
    searchParams.set('step', step);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (consult?.customer_id) {
      fetchCustomer(consult.customer_id, setCustomer);
    }
  }, [consult?.customer_id]);

  useEffect(() => {
    if (consult?.animal_id && customer && clinic) {
      fetchAnimal(consult?.animal_id, (animal) => {
        setAnimal(animal);
        setContextObjects({ ...contextObjects, customer: customer, animal, clinic, consult: consult });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consult?.appointment?.animal_id, customer, clinic]);

  const handleAnimalUpdate = () => {
    fetchConsult(id, setConsult);
    if (consult?.animal_id) fetchAnimal(consult.animal_id, setAnimal);
  };

  const stepLabel = (step: ConsultDetailsStep) => {
    return { 'rx-vacc': 'rx/vacc', soap: 'SOAP' }[step as 'rx-vacc' | 'soap'] || step.split('-').join(' ');
  };

  const scrollUp = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const renderStep = (step: ConsultDetailsStep) => {
    if (!consult) return;

    return {
      'treatment-plans': <TreatmentPlansStep consult={consult} scrollUp={scrollUp} />,
      'health-statuses': (
        <HealthStatusesStep consult={consult} scrollUp={scrollUp} refetchAnimal={handleAnimalUpdate} />
      ),
      soap: <SOAPStep consult={consult} scrollUp={scrollUp} />,
      'rx-vacc': <MedicationsVaccinationsStep consult={consult} scrollUp={scrollUp} />,
      diagnostics: <DiagnosticsStep consult={consult} scrollUp={scrollUp} />,
      procedures: <ProceduresStep consult={consult} scrollUp={scrollUp} />,
      communications: <CommunicationsStep consult={consult} />,
      tasks: <TasksStep consult={consult} />,
      summary: <SummaryStep consult={consult} scrollUp={scrollUp} contextObjects={contextObjects} />
    }[step];
  };

  const renderSidebar = (step: SidebarStep) => {
    if (!consult) return;

    return {
      'master-problems': <MasterProblemsList consult={consult} />,
      'health-plans': <HealthPlansList consult={consult} />
    }[step];
  };

  return (
    <div className="d-flex" style={{ gap: '30px' }}>
      <div className={styles.mainPage}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-0">Consult Details</h1>
          {consult?.appointment &&
            consult.appointment.status !== 'completed' &&
            consult.appointment.telehealth_host_url && (
              <CButton href={consult.appointment.telehealth_host_url} target="_blank" shape="rounded-pill">
                <SvgPlay /> Begin Telehealth
              </CButton>
            )}
        </div>

        {!consult ? (
          <CSpinner color="primary" />
        ) : (
          <>
            <CRow>
              <CCol className="mb-3" xxl>
                <Signalment onUpdateSuccess={handleAnimalUpdate} animal={animal || consult.animal} showPatientNotes />
              </CCol>

              <CCol className="mb-3" xxl>
                {consult.appointment && (
                  <AppointmentSummary animal={consult.animal} appointment={consult.appointment} />
                )}
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <CNav role="list" variant="pills" style={{ marginBottom: '19px' }} ref={scrollRef}>
                  {[
                    consultDetailsSteps.map((step) => (
                      <CNavItem key={step}>
                        <CNavLink
                          key={step}
                          role="button"
                          active={step === currentStep}
                          className="text-capitalize"
                          onClick={() => handleStepClick(step)}
                        >
                          {stepLabel(step)}
                        </CNavLink>
                      </CNavItem>
                    ))
                  ]}
                </CNav>

                {renderStep(currentStep)}
              </CCol>
            </CRow>
          </>
        )}
      </div>

      <CCol className={styles.sidePanel}>
        <div className={styles.panelMainContent}>
          {customer && <OwnerInfoCard customer={customer} />}
          <CNav
            role="list"
            className="d-flex justify-content-between flex-nowrap"
            variant="pills"
            style={{ marginBottom: '15px' }}
          >
            {sidebarSteps.map((step) => (
              <CNavItem key={step}>
                <CNavLink
                  className="text-capitalize text-nowrap"
                  role="button"
                  active={step === sidebarStep}
                  onClick={() => setSidebarStep(step)}
                >
                  {step.split('-').join(' ')}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>

          {renderSidebar(sidebarStep)}
        </div>
      </CCol>
    </div>
  );
};

export default Details;
