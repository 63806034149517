export const reactSelectStyles = {
  menu: (base: object) => ({
    ...base,
    zIndex: 3 // Ensure the select options are above any TinyMCE editor header (z-index 2)
  }),
  control: (base: object) => ({
    ...base,
    borderRadius: '16px',
    borderColor: '#ececec'
  })
};

export const reactSmallSelectStyles = {
  menu: (base: object) => ({
    ...base,
    zIndex: 3 // Ensure the select options are above any TinyMCE editor header (z-index 2)
  }),
  container: (base: object) => ({
    ...base,
    textAlign: 'left' as const,
    width: '-webkit-fill-available'
  }),
  control: (base: object) => ({
    ...base,
    borderRadius: '16px',
    borderColor: '#ececec',
    minHeight: '32px',
    height: '32px',
    fontSize: '0.765625rem',
    fontWeight: 'normal',
    alignContent: 'center'
  }),
  indicatorContainer: (base: object) => ({
    ...base,
    padding: '5px',
    color: 'gray'
  }),
  indicatorSeparator: (base: object) => ({
    ...base,
    display: 'none'
  })
};
