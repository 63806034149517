import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1.5 9.458h12.75"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.25 7.71v5.363c-.023 2.137-.607 2.677-2.835 2.677h-7.08c-2.265 0-2.835-.562-2.835-2.797V7.71c0-2.025.472-2.677 2.25-2.782q.27-.013.585-.015h7.08c2.265 0 2.835.562 2.835 2.797"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 5.048v5.242c0 2.025-.473 2.678-2.25 2.783V7.71c0-2.235-.57-2.798-2.835-2.798h-7.08q-.315.002-.585.016c.022-2.138.608-2.678 2.835-2.678h7.08c2.265 0 2.835.563 2.835 2.798"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M3.938 13.358h1.29M6.832 13.358h2.58"
    />
  </svg>
);
export default SvgCard;
