import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOrderForm = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.375 8.475V5.28c0-3.022-.705-3.78-3.54-3.78h-5.67c-2.835 0-3.54.758-3.54 3.78v8.445c0 1.995 1.095 2.467 2.423 1.043l.007-.008c.615-.652 1.552-.6 2.085.113l.758 1.012M6 5.25h6M6.75 8.25h4.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m13.662 11.078-2.655 2.655a.9.9 0 0 0-.225.442l-.142 1.013c-.053.367.202.622.57.57l1.012-.143a.9.9 0 0 0 .443-.225l2.655-2.655c.457-.457.675-.99 0-1.665-.668-.667-1.2-.45-1.658.008"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M13.281 11.46a2.39 2.39 0 0 0 1.665 1.665"
    />
  </svg>
);
export default SvgOrderForm;
