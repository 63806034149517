import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import * as React from 'react';

import { Animal, compactAge } from 'types/Animal';

import { desexedDisplay } from 'utils/animal';
import { styles } from 'utils/printLabel';

type Props = {
  animal: Animal;
};

export const PatientInfoPrintLabel = ({ animal }: Props): JSX.Element => {
  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`${animal.name}'s Patient Info`}>
        <Page size={{ width: 572, height: 318 }} style={styles.page}>
          <View>
            <Text style={styles.bold}>
              {animal.customer.first_name} {animal.customer.last_name} ({animal.customer.id})
            </Text>
            <Text>{animal.customer.phone}</Text>
          </View>
          <View>
            <Text style={styles.bold}>
              {animal.name} ({animal.id})
            </Text>
            <Text>
              <Text style={styles.bold}>Species: </Text> {animal.species.name}
            </Text>
            <Text>
              <Text style={styles.bold}>Breed: </Text> {animal.breed?.name}
            </Text>
            <Text>
              <Text style={styles.bold}>Sex: </Text>
              {desexedDisplay(animal)}
            </Text>
            <Text>
              <Text style={styles.bold}>DOB: </Text>
              {animal.date_of_birth}
            </Text>
            <Text>
              <Text style={styles.bold}>Weight: </Text>
              {animal.weight} {animal.weight_unit}
            </Text>
            <Text>
              <Text style={styles.bold}>Age: </Text>
              {compactAge(animal)}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
