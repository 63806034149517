type Statusable = {
  id: number;
  status: string;
};

type Props<T extends Statusable> = {
  item: T;
  name: string;
  handleSuccess: (message: string) => void;
  handleError: (error: string) => void;
  updater: (
    id: number,
    params: object,
    { onSuccess, onError }: { onSuccess: (item: T) => void; onError: (error: string) => void }
  ) => void;
};

export const statusAction = ({ item, updater, name, handleSuccess, handleError }: Props<Statusable>) => {
  const handleToggleStatus = () => {
    const newStatus = item.status === 'disabled' ? 'active' : 'disabled';

    updater(
      item.id,
      { status: newStatus },
      {
        onSuccess: () => handleSuccess(`${name} ${newStatus}!`),
        onError: handleError
      }
    );
  };

  return {
    label: item.status === 'disabled' ? 'Activate' : 'Disable',
    onClick: handleToggleStatus
  };
};
