import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CBadge, CModal, CModalBody, CModalHeader, CRow, CSmartTable } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { AnimalHealthPlan, isDue, isPastDue } from 'types/AnimalHealthPlan';
import { importanceColor, statusColor } from 'types/HealthPlan';
import { ListFilter } from 'types/ListFilter';

import { compactDateDisplay } from 'utils/dates';

import SvgPencil from 'assets/images/SvgPencil';
import SvgPlus from 'assets/images/SvgPlus';

import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';

import AddHealthPlanToAnimal from './AddHealthPlanToAnimal';
import EditAnimalHealthPlan from './EditAnimalHealthPlan';

type AnimalHealthPlansListProps = {
  animal: Animal;
  healthPlans?: AnimalHealthPlan[];
  onSuccess: () => void;
  filters: ListFilter[];
  handleFilterChange: (filters: ListFilter[]) => void;
};

export function AnimalHealthPlansList({
  animal,
  healthPlans,
  onSuccess,
  filters,
  handleFilterChange
}: AnimalHealthPlansListProps) {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editingHealthPlan, setEditingHealthPlan] = useState<AnimalHealthPlan>();

  const handleAddSuccess = () => {
    setIsAddModalVisible(false);
    onSuccess();
  };

  const handleEditSuccess = () => {
    setEditingHealthPlan(undefined);
    onSuccess();
  };

  return (
    <CRow className="mb-3">
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <Filters setFilters={handleFilterChange} filters={filters} />
        <IconButton
          className="ms-2"
          onClick={() => setIsAddModalVisible(true)}
          label="Add Health Plan"
          icon={SvgPlus}
        />
      </div>
      {healthPlans && healthPlans.length > 0 ? (
        <>
          <CSmartTable
            items={healthPlans}
            tableHeadProps={{ color: 'dark' }}
            tableProps={{
              'aria-label': 'Animal Health Plans Table'
            }}
            pagination
            itemsPerPage={25}
            paginationProps={{
              align: 'center',
              pages: Math.ceil(healthPlans.length / 25) || 1
            }}
            columns={[
              { key: 'name', _style: { width: '15%' } },
              { key: 'importance', _style: { width: '10%' } },
              { key: 'status', _style: { width: '10%' } },
              { key: 'next_due_date', label: 'Next Due', _style: { width: '20%' } },
              { key: 'fulfilled_at', _style: { width: '5%' } },
              { key: 'fulfilled_by', _style: { width: '5%' } },
              { key: 'patient_notes', _style: { width: '40%' } },
              { key: 'actions', _style: { width: '5%' } }
            ]}
            scopedColumns={{
              name: (item: AnimalHealthPlan) => (
                <td>
                  <Link to={`/health_plans/${item.health_plan.id}`}>{item.health_plan.name}</Link>
                </td>
              ),
              importance: (item: AnimalHealthPlan) => (
                <td>
                  <CBadge shape="rounded-pill" className="ms-2" color={importanceColor(item.health_plan.importance)}>
                    {item.health_plan.importance}
                  </CBadge>
                </td>
              ),
              status: (item: AnimalHealthPlan) => {
                return (
                  <td className="py-2">
                    <CBadge
                      color={statusColor(item.status)}
                      shape="rounded-pill"
                      aria-label="draft-status"
                      aria-hidden={true}
                    >
                      {item.status}
                    </CBadge>
                  </td>
                );
              },
              fulfilled_at: (item: AnimalHealthPlan) => (
                <td>{item.fulfilled_at ? compactDateDisplay(item.fulfilled_at) : null}</td>
              ),
              fulfilled_by: (item: AnimalHealthPlan) => (
                <td>
                  {item.fulfilled_by ? (
                    <Link to={generatePath(paths.productEdit, { id: item.fulfilled_by })}>
                      {item.fulfilled_by.name}
                    </Link>
                  ) : null}
                </td>
              ),
              next_due_date: (item: AnimalHealthPlan) => (
                <td>
                  {item.next_due_date ? compactDateDisplay(item.next_due_date) : null}
                  {isDue(item) ? (
                    <CBadge color="warning" shape="rounded-pill" className="ms-1">
                      Due Soon
                    </CBadge>
                  ) : null}
                  {isPastDue(item) ? (
                    <CBadge color="danger" shape="rounded-pill" className="ms-1">
                      Past Due
                    </CBadge>
                  ) : null}
                </td>
              ),
              internal_notes: (item: AnimalHealthPlan) => <td>{item.internal_notes}</td>,
              patient_notes: (item: AnimalHealthPlan) => <td>{item.patient_notes}</td>,
              actions: (item: AnimalHealthPlan) => (
                <td>
                  <IconButton
                    className="ms-2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: 36,
                      height: 36
                    }}
                    aria-label={`Edit Health Plan ${item.health_plan.name} for ${animal.name}`}
                    icon={SvgPencil}
                    onClick={() => {
                      setEditingHealthPlan(item);
                    }}
                  />
                </td>
              )
            }}
          />
        </>
      ) : (
        <label className="form-label">This animal has no health plans</label>
      )}

      <CModal visible={isAddModalVisible} size="lg" fullscreen="sm" onClose={() => setIsAddModalVisible(false)}>
        <CModalHeader>
          <h4>Add Health Plan to {animal.name}</h4>
        </CModalHeader>
        <CModalBody>
          <AddHealthPlanToAnimal
            animal={animal}
            onSuccess={handleAddSuccess}
            onCancel={() => setIsAddModalVisible(false)}
          />
        </CModalBody>
      </CModal>

      {editingHealthPlan && (
        <CModal
          visible={editingHealthPlan !== undefined}
          size="lg"
          fullscreen="sm"
          onClose={() => setEditingHealthPlan(undefined)}
        >
          <CModalHeader>
            <h4>
              Edit {animal.name}&apos;s {editingHealthPlan?.health_plan.name} Details
            </h4>
          </CModalHeader>
          <CModalBody>
            <EditAnimalHealthPlan
              healthPlan={editingHealthPlan}
              onSuccess={handleEditSuccess}
              onCancel={() => setEditingHealthPlan(undefined)}
            />
          </CModalBody>
        </CModal>
      )}
    </CRow>
  );
}
