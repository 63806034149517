import { Font, StyleSheet } from '@react-pdf/renderer';

import DMSansBold from 'assets/fonts/DMSans-Bold.ttf';
import DMSansItalic from 'assets/fonts/DMSans-Italic.ttf';
import DMSansRegular from 'assets/fonts/DMSans-Regular.ttf';

Font.register({
  family: 'Inter',
  fonts: [{ src: DMSansRegular }, { src: DMSansItalic, fontStyle: 'italic' }, { src: DMSansBold, fontStyle: 'bold' }]
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'
});

export const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh'
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    padding: '30px',
    fontSize: '13px'
  },
  address: {
    textAlign: 'center'
  },
  metaData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divider: {
    borderBottom: '1px solid #505050',
    height: 0
  },
  bold: {
    fontStyle: 'bold'
  },
  em: {
    fontStyle: 'italic'
  }
});

type Label = {
  label: string;
  value: string;
  title: string;
  body?: string;
};

export const labelData: Label[] = [
  { label: 'Patient Info', value: 'patientInfo', title: 'Patient Info' },
  {
    label: 'Prepaid Fecal Keyscreen',
    value: 'prepaidFecalKeyscreen',
    title: 'PREPAID Fecal Keyscreen T991',
    body: 'Please return with fresh fecal sample within 7 days. Sample cannot be processed if received 30 days after payment.'
  },
  {
    label: 'Prepaid Fecal Recheck',
    value: 'prepaidFecalRecheck',
    title: 'PREPAID Fecal Recheck T805',
    body: 'Please return with fresh fecal sample 2-3 days after finishing deworming. Sample cannot be processed if received after 30 days of payment.'
  },
  {
    label: 'Fecal Sample',
    value: 'fecalSample',
    title: 'FECAL SAMPLE'
  },
  {
    label: 'Prepaid Urine',
    value: 'prepaidUrine',
    title: 'URINE PREPAID',
    body: 'Please return with fresh urine sample within 7 days. Sample cannot be processed if received after 30 days of payment.'
  }
];
