import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { CContainer } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';

import { dateDisplay, timeDisplay } from 'utils/dates';

import { AppointmentCaseOwnerSelect } from 'components/AppointmentCaseOwnerSelect';
import { AppointmentRoomSelect } from 'components/AppointmentRoomSelect';
import { AppointmentStatusSelect } from 'components/AppointmentStatusSelect';
import { PossibleParvoBadge } from 'components/PossibleParvoBadge';

import styles from './AppointmentDetailsCard.module.scss';

import { InternalNote } from './InternalNote';

type Props = {
  animal: Animal;
  appointment: Appointment;
  setAppointment: (appointment: Appointment) => void;
};

export const AppointmentDetailsCard: React.FC<Props> = ({ animal, appointment, setAppointment }) => {
  return (
    <div>
      <h2 className={styles.header}>
        Appointment
        <PossibleParvoBadge animal={animal} appointment={appointment} />
      </h2>
      <CContainer className={styles.container}>
        <div className="w-50">
          <div className={styles.infoRow}>
            <div>Date</div>
            <div>{dateDisplay(appointment.start_time)}</div>
          </div>
          <div className={styles.infoRow}>
            <div>Start Time</div>
            <div>{timeDisplay(appointment.start_time)}</div>
          </div>
          <div className={styles.infoRow}>
            <div>End Time</div>
            <div>{timeDisplay(appointment.end_time)}</div>
          </div>
          <div className={styles.infoRow}>
            <div>Appointment Type</div>
            <div>{appointment.appointment_type.name_in_pim}</div>
          </div>
          <div className={styles.infoRow}>
            <div>Employee</div>
            <div>
              <Link to={`/employees/${appointment.employee.id}`}>{appointment.employee.full_name_with_title}</Link>
            </div>
          </div>
          <div className={cn(styles.infoRow, 'align-items-center')}>
            <div>Case Owner</div>
            <div className="w-100">
              <AppointmentCaseOwnerSelect appointment={appointment} />
            </div>
          </div>
          <div className={cn(styles.infoRow, 'align-items-center')}>
            <div>Status</div>
            <div>
              <AppointmentStatusSelect appointment={appointment} />
            </div>
          </div>
          <div className={styles.infoRow}>
            <div>Clinic</div>
            <div>{appointment.clinic.name}</div>
          </div>
          <div className={cn(styles.infoRow, 'align-items-center')}>
            <div>Room</div>
            <div>
              <AppointmentRoomSelect appointment={appointment} />
            </div>
          </div>
        </div>
        <InternalNote appointment={appointment} onNoteSaved={setAppointment} />
      </CContainer>
    </div>
  );
};
