import cn from 'classnames';
import React, { useState } from 'react';

import { CButton, CFormTextarea, CSpinner } from '@coreui/react-pro';

import { updateCustomer } from 'api/Customers';

import { Customer } from 'types/Customer';

import { renderMarkdown } from 'utils/markdown';

import SvgPencil from 'assets/images/SvgPencil';

import styles from './InternalNote.module.scss';

type Props = {
  customer: Customer;
  onNoteSaved: (customer: Customer) => void;
  title?: string;
};

export const InternalNote = ({ customer, onNoteSaved, title }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [customerNotes, setCustomerNotes] = useState(customer.internal_notes);

  const handleCancel = () => {
    setIsEditing(false);
    setCustomerNotes(customer.internal_notes);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    updateCustomer(
      customer.id,
      { ...customer, internal_notes: customerNotes },
      {
        onSuccess: updateSuccess,
        onError: updateError
      }
    );
  };

  const updateSuccess = (updatedCustomer: Customer) => {
    setIsSubmitting(false);
    setIsEditing(false);
    onNoteSaved(updatedCustomer);
  };

  const updateError = (error: string) => {
    setIsSubmitting(false);
  };

  const handleCustomerNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomerNotes(event.target.value);
  };

  return (
    <div className={styles.root}>
      <div className="d-flex align-items-center justify-content-between gap-2 pb-2 h-40">
        <h2>{title || 'Internal Note'}</h2>
        <div>
          {!isEditing && (
            <CButton className={styles.editButton} shape="rounded-pill" onClick={() => setIsEditing(true)}>
              <SvgPencil height={17} />
            </CButton>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className={cn('d-flex flex-column align-items-start h-100', styles.textAreaWrapper)}>
          <CFormTextarea
            id="customer_notes"
            rows={6}
            placeholder="Notes about the customer are only visible to staff"
            value={customerNotes || undefined}
            onChange={handleCustomerNotesChange}
            className={styles.textarea}
          />
          <div className="d-flex align-items-center justify-content-end gap-2 w-100 mt-2">
            <CButton
              color="primary"
              variant="outline"
              shape="rounded-pill"
              size="sm"
              className="px-3"
              onClick={handleCancel}
            >
              Cancel
            </CButton>
            <CButton
              color="primary"
              shape="rounded-pill"
              type="submit"
              size="sm"
              className="px-3"
              onClick={handleSubmit}
            >
              {isSubmitting ? <CSpinner size="sm" /> : 'Save'}
            </CButton>
          </div>
        </div>
      ) : customer.internal_notes ? (
        <div className={styles.customerNotes}>{renderMarkdown(customer.internal_notes)}</div>
      ) : (
        <div>
          <i>None entered</i>
        </div>
      )}
    </div>
  );
};
