import * as React from 'react';
import { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CButton, CRow, CSmartTable } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Consult } from 'types/Consult';
import { EmployeeTask, employeeTaskPresentingMap, isOverdue } from 'types/EmployeeTask';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgPlus from 'assets/images/SvgPlus';

import { IconButton } from 'components/IconButton';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

import { EditTaskModal } from './EditTaskModal';
import { NewTaskModal } from './NewTaskModal';

type Props = {
  employeeTasks: EmployeeTask[];
  pagination: Pagination;
  isLoading: boolean;
  setPagination: (pagination: Pagination) => void;
  refetchTasks?: () => void;
  animal?: Animal;
  consult?: Consult;
};

export const TasksListTable = ({
  employeeTasks,
  pagination,
  isLoading,
  setPagination,
  refetchTasks,
  animal,
  consult
}: Props) => {
  const [editingTask, setEditingTask] = useState<EmployeeTask | null>(null);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handlePageChange = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const editModalClosed = () => {
    setEditTaskModalVisible(false);
    setEditingTask(null);
    if (refetchTasks) refetchTasks();
  };

  const addModalClosed = () => {
    setIsAddModalVisible(false);
    if (refetchTasks) refetchTasks();
  };

  const handleEditClick = (task: EmployeeTask) => () => {
    setEditingTask(task);
    setEditTaskModalVisible(true);
  };

  return (
    <CRow className="mb-3">
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <IconButton onClick={() => setIsAddModalVisible(true)} label="New Task" icon={SvgPlus} />
      </div>
      <CSmartTable
        tableProps={{ 'aria-label': 'Tasks' }}
        items={employeeTasks}
        loading={isLoading}
        tableHeadProps={{ color: 'dark' }}
        itemsPerPage={20}
        pagination={{ external: true }}
        paginationProps={{
          align: 'center',
          activePage: pagination.page,
          pages: calculatePages(pagination)
        }}
        onActivePageChange={(activePage) => handlePageChange(activePage)}
        columns={[
          'updated',
          'due_at',
          'consult',
          'reason',
          'patient',
          'clinic',
          'assigned_to',
          'status',
          { key: 'actions', _style: { width: '10%' } }
        ]}
        scopedColumns={{
          updated: (task: EmployeeTask) => <TableAuditData item={task} handleClick={handleEditClick(task)} />,
          due_at: (task: EmployeeTask) => (
            <td>
              {task.due_at && compactDateTimeDisplay(task.due_at)}{' '}
              {isOverdue(task) && task.status === 'pending' ? <Pill label="overdue"></Pill> : null}
            </td>
          ),
          consult: (task: EmployeeTask) => (
            <td>
              {task.consult_id && <Link to={generatePath(paths.consultDetails, { id: task.consult_id })}>Consult</Link>}
            </td>
          ),
          reason: (task: EmployeeTask) => (
            <td>
              {task.type && employeeTaskPresentingMap[task.type]} {task.context_type ? ` - ${task.context_type}` : null}
            </td>
          ),
          patient: (task: EmployeeTask) => (
            <td>
              {task.animal && (
                <Link to={generatePath(paths.animalDetails, { id: task.animal_id })}>{task.animal.name}</Link>
              )}
            </td>
          ),
          clinic: (task: EmployeeTask) => <td>{task.clinic?.name}</td>,
          assigned_to: (task: EmployeeTask) => (
            <td>{task.assignees && task.assignees.map((employee) => employee.full_name_with_title).join(', ')}</td>
          ),
          status: (task: EmployeeTask) => <td>{task.status && <Pill label={task.status}></Pill>}</td>,
          actions: (task: EmployeeTask) => (
            <td>
              <div className="d-flex flex-wrap gap-2">
                <CButton size="sm" onClick={handleEditClick(task)} shape="rounded-pill">
                  {task.status === 'pending' ? 'Edit' : 'View'}
                </CButton>
              </div>
            </td>
          )
        }}
      />

      {editTaskModalVisible && editingTask ? (
        <EditTaskModal task={editingTask} setTask={setEditingTask} hideModal={editModalClosed} />
      ) : null}

      {isAddModalVisible && <NewTaskModal animal_id={animal?.id} consult_id={consult?.id} hideModal={addModalClosed} />}
    </CRow>
  );
};
