import cn from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import AsyncSelect from 'react-select/async';
import { OwnerInfoCard } from 'views/animals/OwnerInfoCard';

import { CButton, CCol, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchAnimal, fetchAnimalsByQuery } from 'api/Animals';
import { fetchConsultsByAnimal } from 'api/Consults';
import { fetchCustomer, fetchCustomersByQuery } from 'api/Customers';
import { fetchDiagnosticRequestsByAnimal, fetchDiagnosticRequestsForConsult } from 'api/DiagnosticRequests';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';
import { Customer } from 'types/Customer';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DiagnosticResult, DiagnosticStatus } from 'types/DiagnosticResult';
import { Employee } from 'types/Employee';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import {
  animalToOption,
  consultToOption,
  customerToOption,
  diagnosticRequestToOption,
  employeeToOption
} from 'utils/selectOptions';

import SvgChart from 'assets/images/SvgChart';
import SvgClipboard from 'assets/images/SvgClipboard';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPeople from 'assets/images/SvgPeople';
import SvgPlus from 'assets/images/SvgPlus';
import SvgTwoPeople from 'assets/images/SvgTwoPeople';

import { DiagnosticResultAttachments } from 'components/DiagnosticResultAttachments';
import { FormAuditData } from 'components/FormAuditData';
import { IconButton } from 'components/IconButton';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';
import { Signalment } from 'components/Signalment';

import styles from './DiagnosticResultForm.module.scss';

import { DiagnosticResultItemForm } from './DiagnosticResultItemForm';

type Props = {
  appointment?: Appointment;
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  diagnosticResult?: DiagnosticResult;
  employee: Employee | null;
  diagnosticRequest?: DiagnosticRequest;
  showHeader: boolean;
};

const MIN_QUERY_LENGTH = 3;

export const DiagnosticResultForm = ({
  appointment,
  loading,
  hideForm,
  onSubmit,
  diagnosticResult,
  employee,
  diagnosticRequest,
  showHeader
}: Props) => {
  const initialEmployeeOption = () => {
    const initial = diagnosticResult?.employee || appointment?.case_owner || (employee?.vet && employee);
    return initial ? employeeToOption(initial) : null;
  };

  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(initialEmployeeOption);

  const [diagnosticResultItemCount, setDiagnosticResultItemCount] = useState(
    diagnosticResult?.diagnostic_result_items.length || 1
  );
  const [patientNotes, setPatientNotes] = useState(diagnosticResult?.patient_notes || '');
  const [outcome, setOutcome] = useState(diagnosticResult?.outcome || '');
  const [status, setStatus] = useState<DiagnosticStatus>(diagnosticResult?.status || 'complete');
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedAnimalOption, setSelectedAnimalOption] = useState<Option | null>(null);
  const [animalOptions, setAnimalOptions] = useState<Option[]>([]);
  const [selectedAnimal, setSelectedAnimal] = useState<Animal | null>(null);

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<Option | null>(null);
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const [selectedConsultOption, setSelectedConsultOption] = useState<Option | null>(null);
  const [consultOptions, setConsultOptions] = useState<Option[]>([]);
  const [selectedDiagnosticRequestOption, setSelectedDiagnosticRequestOption] = useState<Option | null>(null);
  const [diagnosticRequestOptions, setDiagnosticRequestOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (diagnosticResult?.animal_id) {
      fetchAnimal(diagnosticResult.animal_id, (animal: Animal) => {
        setSelectedAnimal(animal);
        setSelectedAnimalOption(animalToOption(animal));
        fetchConsultsByAnimal(animal.id).then((options) => {
          setConsultOptions(options.map(consultToOption));
        });
        if (animal?.customer) {
          setSelectedCustomer(animal.customer);
        }
      });
    }
  }, [diagnosticResult]);

  const handleAnimalSelectChange = (option: Option | null) => {
    setSelectedAnimalOption(option);

    if (option?.value) {
      fetchAnimal(option.value, (animal: Animal) => {
        if (animal?.customer) {
          const customerOption = customerToOption(animal.customer);
          setSelectedCustomerOption(customerOption);
          setCustomerOptions([customerOption]);
          setSelectedCustomer(animal.customer);
          setSelectedAnimal(animal);
        }
      });
      fetchConsultsByAnimal(option.value).then((options) => {
        setConsultOptions(options.map(consultToOption));
      });
      fetchDiagnosticRequestsByAnimal(option.value, (options) => {
        setDiagnosticRequestOptions(options.map(diagnosticRequestToOption));
      });
    } else {
      setSelectedCustomerOption(null);
      setSelectedCustomer(null);
    }
  };

  const handleCustomerSelectChange = (option: Option | null) => {
    setSelectedCustomerOption(option);

    if (option?.value) {
      fetchCustomer(option.value, (customer: Customer) => {
        setSelectedCustomer(customer);
        if (customer?.animals) {
          const options = customer.animals.map(animalToOption);
          setAnimalOptions(options);
          setSelectedAnimalOption(options[0]);
          setSelectedAnimal(customer.animals[0]);
          fetchConsultsByAnimal(customer.animals[0].id).then((options) => {
            setConsultOptions(options.map(consultToOption));
          });
        }
      });
    } else {
      setSelectedAnimalOption(null);
      setSelectedCustomer(null);
    }
  };

  const handleConsultSelectChange = (option: Option | null) => {
    setSelectedConsultOption(option);
    if (option?.value) {
      fetchDiagnosticRequestsForConsult(option.value, (options) => {
        setDiagnosticRequestOptions(options.map(diagnosticRequestToOption));
      });
    }
  };

  const loadAnimalOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchAnimalsByQuery(inputValue, {}).then((options) => {
      callback(options.map(animalToOption));
    });
  };

  const loadCustomerOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchCustomersByQuery(inputValue, {}).then((options) => {
      callback(options.map(customerToOption));
    });
  };

  const loadConsultOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (!selectedAnimalOption) return;

    fetchConsultsByAnimal(selectedAnimalOption.value).then((options) => {
      callback(options.map(consultToOption));
    });
  };

  const loadDiagnosticRequestOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (!selectedConsultOption) return;

    fetchDiagnosticRequestsForConsult(selectedConsultOption.value, (options) => {
      callback(options.map(diagnosticRequestToOption));
    });
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const animalId = diagnosticResult?.animal_id || diagnosticRequest?.animal_id;
  const customerId = diagnosticResult?.customer_id || diagnosticRequest?.customer_id;
  const consultId = diagnosticResult?.consult_id || diagnosticRequest?.consult_id;
  const diagnosticRequestId = diagnosticResult?.diagnostic_request_id || diagnosticRequest?.id;

  const incrementDiagnosticResults = () => {
    setDiagnosticResultItemCount(diagnosticResultItemCount + 1);
  };

  const handleCompleteAndSubmit = () => {
    flushSync(() => {
      // flushSync is required here because we need to make sure the form field is updated before submitting
      setStatus('released');
    });
    saveButtonRef.current?.click();
  };

  const diagnosticName = diagnosticResult?.diagnostic_name || diagnosticRequest?.diagnostic_name;

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <div className="mb-3">
        {!showHeader && <h2>{diagnosticResult ? 'Edit' : 'New'} Diagnostic Result</h2>}
        {!showHeader && diagnosticName && <h3>{diagnosticName}</h3>}

        {showHeader && (
          <div className="d-inline-flex gap-4 align-items-center">
            {selectedAnimal && <Signalment animal={selectedAnimal} condensed={true} />}
            {selectedCustomer && <OwnerInfoCard customer={selectedCustomer} />}
          </div>
        )}
      </div>

      <CFormInput hidden id="status" name="status" value={status} />

      {animalId && customerId ? (
        <>
          <CFormInput hidden id="animal_id" name="animal_id" value={animalId} />
          <CFormInput hidden id="customer_id" name="customer_id" value={customerId} />
        </>
      ) : (
        <CRow className="mb-3">
          <CCol>
            <CFormInput hidden id="animal_id" name="animal_id" value={selectedAnimalOption?.value ?? ''} />
            <div className="d-flex align-items-center justify-content-between form-label">
              <IconLabel icon={SvgPaw} label="Patient" />
              <div className={styles.required}>Required</div>
            </div>

            <AsyncSelect<Option>
              id="animal"
              aria-label="Patient"
              placeholder="Type to search..."
              value={selectedAnimalOption}
              onChange={handleAnimalSelectChange}
              loadingMessage={loadingMessage}
              defaultOptions={animalOptions}
              loadOptions={loadAnimalOptions}
              styles={reactSelectStyles}
              isClearable
              isSearchable
              required
            />
          </CCol>

          <CCol>
            <CFormInput hidden id="customer_id" name="customer_id" value={selectedCustomerOption?.value ?? ''} />
            <div className="d-flex align-items-center justify-content-between form-label">
              <IconLabel icon={SvgTwoPeople} label="Client" />
              <div className={styles.required}>Required</div>
            </div>

            <AsyncSelect<Option>
              id="client"
              aria-label="Client"
              placeholder="Type to search..."
              value={selectedCustomerOption}
              onChange={handleCustomerSelectChange}
              loadingMessage={loadingMessage}
              styles={reactSelectStyles}
              defaultOptions={customerOptions}
              loadOptions={loadCustomerOptions}
              isClearable
              isSearchable
            />
          </CCol>
        </CRow>
      )}

      <CRow className="mb-3">
        {consultId ? (
          <CFormInput hidden id="consult_id" name="consult_id" value={consultId} />
        ) : (
          <CCol>
            <CFormInput hidden id="consult_id" name="consult_id" value={selectedConsultOption?.value ?? ''} />

            <div className="d-flex align-items-center justify-content-between form-label">
              <IconLabel icon={SvgClipboard} label="Consult" />
              <div className={styles.required}>Required</div>
            </div>

            <AsyncSelect<Option>
              id="consult"
              aria-label="Consult"
              placeholder={selectedAnimalOption ? 'Select Consult' : 'Select a patient first'}
              value={selectedConsultOption}
              onChange={handleConsultSelectChange}
              loadingMessage={loadingMessage}
              defaultOptions={consultOptions}
              loadOptions={loadConsultOptions}
              styles={reactSelectStyles}
              isClearable
              required
              isDisabled={!selectedAnimalOption}
            />
          </CCol>
        )}

        {diagnosticRequestId ? (
          <CFormInput hidden id="diagnostic_request_id" name="diagnostic_request_id" value={diagnosticRequestId} />
        ) : (
          <CCol>
            <CFormInput
              hidden
              id="diagnostic_request_id"
              name="diagnostic_request_id"
              value={selectedDiagnosticRequestOption?.value ?? ''}
            />

            <div className="d-flex align-items-center justify-content-between form-label">
              <IconLabel icon={SvgChart} label="Diagnostic Request" />
            </div>

            <AsyncSelect<Option>
              id="diagnostic_request"
              aria-label="Diagnostic Request"
              placeholder={selectedAnimalOption ? 'Select Diagnostic Request' : 'Select a consult first'}
              value={selectedDiagnosticRequestOption}
              onChange={setSelectedDiagnosticRequestOption}
              loadingMessage={loadingMessage}
              defaultOptions={diagnosticRequestOptions}
              loadOptions={loadDiagnosticRequestOptions}
              styles={reactSelectStyles}
              isClearable
              isDisabled={!selectedAnimalOption}
            />
          </CCol>
        )}

        <CCol className="mb-3">
          <CFormInput hidden id="employee_id" name="employee_id" value={selectedEmployeeOption?.value ?? ''} />

          <div className="d-flex align-items-center justify-content-between form-label">
            <IconLabel icon={SvgPeople} label="Prescribing Vet" />
            <div className={styles.required}>Required</div>
          </div>

          <AsyncSelect<Option>
            aria-label="Prescribing Vet"
            placeholder="Type to search..."
            value={selectedEmployeeOption}
            onChange={handleEmployeeSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={loadEmployeeOptions}
            styles={reactSelectStyles}
            isClearable
            isSearchable
            required
          />
        </CCol>
      </CRow>

      {diagnosticResult?.results_url && (
        <CRow className="mb-3">
          <CCol>
            <CButton target="_blank" shape="rounded-pill" href={diagnosticResult.results_url}>
              View Result
            </CButton>
          </CCol>
        </CRow>
      )}

      <CRow className="mb-3">
        <CCol>
          <CFormInput label="Attachments" type="file" id="formFileMultiple" name="attachments[]" multiple />
          {diagnosticResult?.attachments && <DiagnosticResultAttachments attachments={diagnosticResult.attachments} />}
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="mb-0">Diagnostic Result Items</h3>

          <IconButton icon={SvgPlus} label="Add Item" onClick={incrementDiagnosticResults} />
        </div>

        {Array.from({ length: diagnosticResultItemCount }).map((_, index) => {
          return (
            <div key={index}>
              <DiagnosticResultItemForm index={index} resultItem={diagnosticResult?.diagnostic_result_items[index]} />
            </div>
          );
        })}
      </CRow>

      <CRow className="mb-3">
        <RichTextEditor id="outcome" name="outcome" label="Outcome" value={outcome} onChange={setOutcome} />
      </CRow>

      <CRow className="mb-3">
        <RichTextEditor
          id="patient_notes"
          name="patient_notes"
          label="Client Communications"
          value={patientNotes}
          onChange={setPatientNotes}
        />
      </CRow>

      <div className="d-flex align-items-center justify-content-between">
        {diagnosticResult && <FormAuditData item={diagnosticResult} />}

        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton shape="rounded-pill" loading={loading} color="primary" type="submit" ref={saveButtonRef}>
            {diagnosticResult ? 'Update' : 'Create'}
          </CLoadingButton>
          {diagnosticResult === undefined && (
            <CButton shape="rounded-pill" type="button" onClick={handleCompleteAndSubmit}>
              Create and Release
            </CButton>
          )}
          {diagnosticResult && diagnosticResult.status === 'complete' && (
            <CButton shape="rounded-pill" type="button" onClick={handleCompleteAndSubmit}>
              Update and Release
            </CButton>
          )}
          <CButton shape="rounded-pill" variant="outline" type="button" onClick={hideForm}>
            Cancel
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
