import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCardX = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1.5 6.375h8.625M4.5 12.375H6M7.875 12.375h3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 9.023v3.06c0 2.632-.668 3.292-3.33 3.292H4.83c-2.662 0-3.33-.66-3.33-3.293V5.919c0-2.633.668-3.293 3.33-3.293h5.295"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m12.984 6.142 2.91-2.91M15.894 6.142l-2.91-2.91"
    />
  </svg>
);
export default SvgCardX;
