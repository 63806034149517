/* 
  replacements is an object containing keys/values needed to replace variables. they might include:
   animal, customer, employee, consult, clinic, prescriptions: PrescriptionItem[], vaccinations
*/
import { compactAge } from 'types/Animal';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { Vaccination } from 'types/Vaccination';

import { generateCloudinaryPath } from './cloudinary';
import { compactDateDisplay } from './dates';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceKeywords = (text: string, replacements: any): string => {
  const regex = /\{\{([^{}]+)\}\}/g;
  return text.replace(regex, (match, keyword) => {
    const today = new Date();
    if (keyword === 'today') return today.toLocaleDateString();
    if (keyword === 'today+1year') return new Date(today.setFullYear(today.getFullYear() + 1)).toLocaleDateString();
    if (keyword === 'animalAge' && replacements.animal) return compactAge(replacements.animal);
    if (keyword === 'employeeSignature' && replacements.employee) {
      const avatarSource = generateCloudinaryPath({
        imagePublicId: replacements.employee.signature_photo_provider_id,
        width: 400,
        height: 100
      });
      return `<img
        alt="Veterinarian's signature"
        src=${avatarSource}
      />`;
    }

    if (keyword === 'animalMicrochip') {
      const microchip = replacements.animal?.microchip_number;
      if (microchip) {
        let microchipString =
          '<table style="width:100%; text-align:center; border:none; font-size: 18px; font-weight: 500;"><tr>';
        for (let i = 0; i < microchip.length; i++) {
          microchipString += `<td style="border:none;">${microchip[i]}</td>`;
        }
        microchipString += '</tr></table>';
        return microchipString;
      }
    }

    if (keyword === 'medicationsList' && replacements.prescriptions) {
      return replacements.prescriptions
        .map(
          (prescription: PrescriptionItem) =>
            `<li>${prescription.product?.name}: ${prescription.clean_instructions}</li>`
        )
        .join('<br>');
    }

    if (keyword === 'dispenseMedications' && replacements.prescriptions) {
      return replacements.prescriptions
        .map((prescription: PrescriptionItem) => {
          const showConcentration =
            prescription.product?.concentration &&
            prescription.product?.concentration_unit &&
            prescription.product?.concentration_volume;
          return (
            `<p>${prescription.product?.name}<br>` +
            `${
              showConcentration
                ? `Concentration: ${Number(prescription.product?.concentration)} ${
                    prescription.product?.concentration_unit
                  }/${prescription.product?.concentration_volume}<br>`
                : ''
            }` +
            `Quantity: ${prescription.quantity}<br>` +
            `${prescription.clean_instructions}` +
            `</p>`
          );
        })
        .join('<hr>');
    }

    if (keyword === 'vaccinationsList' && replacements.vaccinations) {
      let vaccinationsTable = `<table style="width:100%"><tr><th>Vaccination</th><th>Last Done</th><th>Next Due</th></tr>`;
      replacements.vaccinations.map((vaccination: Vaccination) => {
        if (vaccination.status === 'active')
          vaccinationsTable +=
            `<tr>` +
            `<td>${vaccination.product.display_name}</td>` +
            `<td>${compactDateDisplay(vaccination.date_of_administration)}</td>` +
            `<td>${vaccination.administration_status === 'past_due' ? '<b>' : ''}${
              vaccination.date_of_next_administration ? compactDateDisplay(vaccination.date_of_next_administration) : ''
            }${vaccination.administration_status === 'past_due' ? '</b>' : ''}</td>` +
            `</tr>`;
        return vaccinationsTable;
      });
      return vaccinationsTable + '</table>';
    }

    const keys = keyword.split('.');
    let value = replacements;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) return match; // return the original placeholder if the key is not found
    }
    return value;
  });
};

export const replaceInputs = (text: string): string => {
  const regex = /(?:#INPUT#(?:\[((?:[^\]]*\|?[^\]]*\/?)*)\])?|#INPUT#)(?:'([^']*)')?/g;

  let inputCount = 0;
  return text
    .replace(regex, (match, keyword) => {
      inputCount++;
      const optionsString = match.split('[')[1];
      if (optionsString) {
        const options = optionsString
          .replace(']', '')
          .split('/')
          .map((option) => option.trim())
          .filter((option) => option !== '');
        let optionCount = 0;
        return options
          .map((option) => {
            optionCount++;
            return `${option}<input type="radio" class="form-check-input" required style="display: inline; margin-left: 5px; margin-right: 10px;" name="radio-${inputCount}" id="radio-${optionCount}" aria-label="radio-${inputCount}" />`;
          })
          .join('');
      }

      return `<input type="text" class="form-control" required style="width: 100px; display: inline" size="100" name="input-${inputCount}" id="input-${inputCount}" aria-label="input-${inputCount}" />`;
    })
    .replace(/#TEXTAREA#/g, (match, keyword) => {
      inputCount++;
      return `<textarea class="form-control" required style="width: 100%" name="textarea-${inputCount}" id="textarea-${inputCount}" aria-label="textarea-${inputCount}" ></textarea>`;
    });
};

export const replaceSignature = (text: string): string => {
  const regex = /#SIGNATURE#/g;

  let sigCount = 0;
  return text.replace(regex, (match, keyword) => {
    sigCount++;
    return `<canvas style="touch-action: none; user-select: none; border: 1px solid lightgray; border-radius: 6px" width="400" height="100" name="signature-${sigCount}" id="signature-${sigCount}" />`;
  });
};
