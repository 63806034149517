import * as React from 'react';
import type { SVGProps } from 'react';

const SvgNewResult = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6 1.5v2.25M12 1.5v2.25M15.75 6.375v3.848a3.9 3.9 0 0 0-2.437-.848c-.923 0-1.786.322-2.46.87a3.89 3.89 0 0 0-1.478 3.068c0 .734.21 1.44.57 2.024.277.458.637.855 1.065 1.163H6c-2.625 0-3.75-1.5-3.75-3.75V6.375c0-2.25 1.125-3.75 3.75-3.75h6c2.625 0 3.75 1.5 3.75 3.75M5.25 8.25h4.5M5.25 12h1.965"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.25 13.313c0 .734-.21 1.44-.57 2.024a3.5 3.5 0 0 1-.78.93 3.86 3.86 0 0 1-2.588.983 3.88 3.88 0 0 1-2.302-.75 3.95 3.95 0 0 1-1.065-1.162 3.9 3.9 0 0 1-.57-2.025c0-1.238.57-2.356 1.477-3.068a3.9 3.9 0 0 1 2.46-.87c.923 0 1.77.315 2.438.848.915.72 1.5 1.837 1.5 3.09"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.313 15.188c0-1.036.84-1.876 1.874-1.876a1.876 1.876 0 0 1-1.874-1.874c0 1.034-.84 1.874-1.876 1.874 1.036 0 1.876.84 1.876 1.876"
    />
  </svg>
);
export default SvgNewResult;
