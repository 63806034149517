import flatMap from 'lodash/flatMap';
import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { createSearchParams, generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import { ExternalDispensePrescriptions } from 'views/prescriptions/ExternalDispensePrescriptions';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchAnimalHealthPlans } from 'api/AnimalHealthPlans';
import { fetchAnimalMasterProblemsByAnimal } from 'api/AnimalMasterProblems';
import { fetchAnimal } from 'api/Animals';
import { fetchAssessmentsByAnimal } from 'api/Assessments';
import { fetchConsultPlansByAnimal } from 'api/ConsultPlans';
import { fetchDiagnosticRequestsByAnimal } from 'api/DiagnosticRequests';
import { fetchDispenseRecord, updateDispenseRecord } from 'api/DispenseRecords';
import { fetchHealthStatusesByAnimal } from 'api/HealthStatuses';
import { fetchHistoriesByAnimal } from 'api/Histories';
import { fetchPhysicalExamsByAnimal } from 'api/PhysicalExams';
import { fetchPrescriptionItemsByAnimal, fetchPrescriptionLabel } from 'api/PrescriptionItems';
import { createPrescription, fetchPrescription, updatePrescription } from 'api/Prescriptions';
import { fetchProceduresByAnimal } from 'api/Procedures';
import { createVaccination, fetchVaccinationsByAnimal, updateVaccination } from 'api/Vaccinations';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Animal } from 'types/Animal';
import { AnimalHealthPlan, isDue, isPastDue } from 'types/AnimalHealthPlan';
import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { Assessment } from 'types/Assessment';
import { ConsultDetailsStep } from 'types/ConsultDetailsStep';
import { ConsultPlan } from 'types/ConsultPlan';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DispenseRecord } from 'types/DispenseRecord';
import { HealthStatus } from 'types/HealthStatus';
import { History } from 'types/History';
import { MedicalHistoryItem } from 'types/MedicalHistoryItem';
import { MedicalHistoryTab, medicalHistoryTabs } from 'types/MedicalHistoryTabs';
import { PhysicalExam } from 'types/PhysicalExam';
import { Prescription } from 'types/Prescription';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { PrescriptionLoadState } from 'types/PrescriptionLoadState';
import { Procedure } from 'types/Procedure';
import { Vaccination } from 'types/Vaccination';

import { useAuth } from 'hooks/useAuth';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useKey } from 'hooks/useKey';

import { compactDateDisplay, compactDateTimeDisplay } from 'utils/dates';
import { isAwaitingLogs } from 'utils/dispenseRecords';
import { filterMetadata } from 'utils/healthStatuses';
import { identity } from 'utils/identity';
import { fulfillmentStatus } from 'utils/prescriptionItems';
import { sanitizeHtml } from 'utils/sanitizeHtml';
import { createNewTab } from 'utils/tabs';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { DiagnosticRequestDetails } from 'components/DiagnosticRequestDetails';
import { IconButton } from 'components/IconButton';
import { List } from 'components/List';
import { MedicalHistoryTable } from 'components/MedicalHistoryTable';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { NewDispenseLogsModal } from 'components/NewDispenseLogsModal';
import { Pill } from 'components/Pill';
import { PrescriptionForm } from 'components/PrescriptionForm';
import { PrescriptionItemLabelPrint } from 'components/print_labels/PrescriptionItemLabelPrint';
import { Signalment } from 'components/Signalment';
import { TableAuditData } from 'components/TableAuditData';
import { VaccinationForm } from 'components/VaccinationForm';

type Form =
  | 'edit-prescription'
  | 'edit-vaccination'
  | 'external-dispense-prescription'
  | 'new-dispense-logs'
  | 'new-prescription'
  | 'new-vaccination';

type LoadState = 'creating-updating-vaccination' | PrescriptionLoadState;

const MedicalHistory = (): JSX.Element => {
  type MedicalHistoryParams = { id: string };

  const { id } = useParams<keyof MedicalHistoryParams>() as MedicalHistoryParams;

  const [animal, setAnimal] = useState<Animal>();
  const [consultPlans, setConsultPlans] = useState<ConsultPlan[]>();
  const [animalMasterProblems, setAnimalMasterProblems] = useState<AnimalMasterProblem[]>();
  const [prescriptionItems, setPrescriptionItems] = useState<PrescriptionItem[]>();
  const [healthPlans, setHealthPlans] = useState<AnimalHealthPlan[]>();
  const [healthStatuses, setHealthStatuses] = useState<HealthStatus[]>();
  const [vaccinations, setVaccinations] = useState<Vaccination[]>();
  const [assessments, setAssessments] = useState<Assessment[]>();
  const [procedures, setProcedures] = useState<Procedure[]>();
  const [physicalExams, setPhysicalExams] = useState<PhysicalExam[]>();
  const [histories, setHistories] = useState<History[]>();
  const [diagnosticRequests, setDiagnosticRequests] = useState<DiagnosticRequest[]>();

  const [visibleForms, setVisibleForms] = useState<Form[]>([]);
  const [loadState, setLoadState] = useState<LoadState | undefined>(undefined);
  const [formKey, remountForm] = useKey();

  const [editingPrescription, setEditingPrescription] = useState<Prescription>();
  const [editingVaccination, setEditingVaccination] = useState<Vaccination>();
  const [editingDispenseRecord, setEditingDispenseRecord] = useState<DispenseRecord>();

  const [enqueuedDispenseRecords, setEnqueuedDispenseRecords] = useState<DispenseRecord[]>();

  useDocumentTitle('Medical History', animal?.name);

  const auth = useAuth();
  const location = useLocation();
  const scrollRef = createRef<HTMLDivElement>();
  const scrollUp = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const scrollTo = (table: MedicalHistoryTab) => {
    document.getElementById(table)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    fetchAnimal(id, setAnimal);
    fetchConsultPlansByAnimal(id, setConsultPlans);
    fetchAnimalMasterProblemsByAnimal(id, setAnimalMasterProblems);
    fetchPrescriptionItemsByAnimal(id, setPrescriptionItems);
    fetchAnimalHealthPlans({ animal_id: id }, setHealthPlans);
    fetchVaccinationsByAnimal(id, setVaccinations);
    fetchAssessmentsByAnimal(id, setAssessments);
    fetchProceduresByAnimal(id, setProcedures);
    fetchPhysicalExamsByAnimal(id, setPhysicalExams);
    fetchHistoriesByAnimal(id, setHistories);
    fetchDiagnosticRequestsByAnimal(id, setDiagnosticRequests);
    fetchHealthStatusesByAnimal(id, setHealthStatuses);
  }, [id]);

  const buildConsultLink = (consult_id: number | null, step?: ConsultDetailsStep) =>
    [consult_id && generatePath(paths.consultDetails, { id: consult_id }), step && `?step=${step}`]
      .filter(identity)
      .join('');

  const handleAnimalUpdate = () => {
    fetchAnimal(id, setAnimal);
  };

  const status = {
    status: (item: MedicalHistoryItem) => (
      <td>
        <Pill label={item.status} />
      </td>
    )
  };

  const evaluateSubmitValue = (event: React.FormEvent<HTMLFormElement>) => {
    const isPrinting = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'print';
    const isDispensing = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'enqueue';
    const isExternalDispensing = (event.nativeEvent as unknown as HTMLFormElement).submitter?.value === 'external';

    return { isPrinting, isDispensing, isExternalDispensing };
  };

  const getPrescriptionItemsAttributes = (formData: FormData) => {
    const nestedName = (index: number, name: string) => `prescription_items_attributes[${index}][${name}]`;
    const nestedDateName = (index: number, name: string) => `${nestedName(index, name)}-date`;

    const result = [];
    let index = 0;

    while (formData.has(nestedName(index, 'product_id'))) {
      const chronic = formData.get(nestedName(index, 'chronic'));
      const current = formData.get(nestedName(index, 'current'));
      const dispense = formData.get(nestedName(index, 'dispense'));
      const dispenseInstructions = formData.get(nestedName(index, 'dispense_instructions'));
      const id = formData.get(nestedName(index, 'id'));
      const instructions = formData.get(nestedName(index, 'instructions'));
      const maxRefills = formData.get(nestedName(index, 'max_refills'));
      const productId = formData.get(nestedName(index, 'product_id'));
      const quantity = formData.get(nestedName(index, 'quantity'));
      const quantityPerRefill = formData.get(nestedName(index, 'quantity_per_refill'));
      const quantityPerUse = formData.get(nestedName(index, 'quantity_per_use'));
      const refillExpiresAt = formData.get(nestedDateName(index, 'refill_expires_at')?.toString());
      const refillTimePeriod = formData.get(nestedName(index, 'refill_time_period'));
      const refillTimeUnit = formData.get(nestedName(index, 'refill_time_unit'));
      const takenWithFood = formData.get(nestedName(index, 'taken_with_food'));
      const timeIntervalInHours = formData.get(nestedName(index, 'time_interval_in_hours'));
      const expiresAt = formData.get(nestedDateName(index, 'expires_at')?.toString());

      const attributes = {
        chronic: Boolean(chronic),
        current: Boolean(current),
        dispense: dispense || null,
        dispense_instructions: dispenseInstructions || null,
        expires_at: expiresAt,
        id: id ? Number(id) : null,
        instructions: instructions,
        max_refills: Number(maxRefills),
        product_id: Number(productId),
        quantity: quantity,
        quantity_per_refill: quantityPerRefill,
        quantity_per_use: quantityPerUse || null,
        refill_expires_at: refillExpiresAt,
        refill_time_period: refillTimePeriod,
        refill_time_unit: Number(refillTimeUnit),
        taken_with_food: Boolean(takenWithFood),
        time_interval_in_hours: timeIntervalInHours ? Number(timeIntervalInHours) : null
      };

      result.push(attributes);

      index++;
    }

    return result;
  };

  const handleCreatePrescription = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!animal) return;
    const form = event.currentTarget;
    const formData = new FormData(form);

    const { isPrinting, isDispensing, isExternalDispensing } = evaluateSubmitValue(event);

    let loadState = 'creating-updating-prescription';
    if (isPrinting) {
      loadState = 'printing-prescription';
    } else if (isDispensing) {
      loadState = 'dispensing-prescription';
    } else if (isExternalDispensing) {
      loadState = 'external-dispensing-prescription';
    }

    setLoadState(loadState as LoadState);

    const prescriptionItemsAttributes = getPrescriptionItemsAttributes(formData).map((attributes) => ({
      ...attributes,
      ...(isExternalDispensing ? { external_prescription: true } : {})
    }));

    const formJson = {
      animal_id: animal.id,
      employee_id: Number(formData.get('employee_id')),
      date_of_prescription: formData.get('date_of_prescription-date')?.toString(),
      prescription_items_attributes: prescriptionItemsAttributes
    };

    createPrescription(
      formJson,
      { enqueue: isPrinting || isDispensing },
      {
        onSuccess: (prescription) => {
          if (isPrinting) {
            handleEnterPrintProcess(prescription);
          } else if (isExternalDispensing) {
            handleExternalDispense(prescription);
          } else {
            const action = isDispensing ? 'enqueued for dispense' : 'created';
            const message = `Medications ${action}!`;
            handlePrescriptionSuccess(message);
          }
        },
        onError: handleError
      }
    );
  };

  const handleUpdatePrescription = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingPrescription) return;

    const form = event.currentTarget;
    const formData = new FormData(form);

    const { isPrinting, isDispensing, isExternalDispensing } = evaluateSubmitValue(event);

    let loadState = 'creating-updating-prescription';
    if (isPrinting) {
      loadState = 'printing-prescription';
    } else if (isDispensing) {
      loadState = 'dispensing-prescription';
    } else if (isExternalDispensing) {
      loadState = 'external-dispensing-prescription';
    }

    setLoadState(loadState as LoadState);

    const prescriptionItemsAttributes = getPrescriptionItemsAttributes(formData).map((attributes) => ({
      ...attributes,
      ...(isExternalDispensing ? { external_prescription: true } : {})
    }));

    const formJson = {
      employee_id: Number(formData.get('employee_id')),
      date_of_prescription: formData.get('date_of_prescription-date')?.toString(),
      prescription_items_attributes: prescriptionItemsAttributes
    };

    updatePrescription(
      editingPrescription.id,
      formJson,
      { enqueue: isPrinting || isDispensing },
      {
        onSuccess: (prescription) => {
          if (isPrinting) {
            handleEnterPrintProcess(prescription);
          } else if (isExternalDispensing) {
            handleExternalDispense(prescription);
          } else {
            const action = isDispensing ? 'enqueued for dispense' : 'updated';
            const message = `Medications ${action}!`;
            handlePrescriptionSuccess(message);
          }
        },
        onError: handleError
      }
    );
  };

  // Note: our domain says this should always be true, but checking for
  // it feels like a good idea.
  const everyPrescriptionDispensed = (prescription: Prescription) => {
    return prescription.prescription_items.every(
      (item) => item?.dispense_records && item?.dispense_records?.length > 0
    );
  };

  const extractLoggableDispenseRecords = (prescription: Prescription) => {
    return flatMap(
      prescription.prescription_items,
      (item: PrescriptionItem) => item.dispense_records?.filter(isAwaitingLogs) || []
    );
  };

  // Note: we need the full serialized dispense record and its associated records to load the dispense logs form. The
  // record returned with the prescription does not have enough data, nor should it.
  const loadDispenseRecord = (dispenseRecordId: number) => {
    fetchDispenseRecord(dispenseRecordId, {
      onSuccess: (dispenseRecord) => {
        setLoadState(undefined);
        setEditingDispenseRecord(dispenseRecord);

        setVisibleForms((prev) => [
          ...prev.filter((form) => form !== 'new-prescription' && form !== 'edit-prescription'),
          'new-dispense-logs'
        ]);

        remountForm();

        remountForm();
      }
    });
  };

  const handleEnterPrintProcess = (prescription: Prescription) => {
    if (!everyPrescriptionDispensed(prescription)) {
      toast.error('All medications must be dispensed.');
      return;
    }

    const loggableDispenseRecords = extractLoggableDispenseRecords(prescription);
    setEnqueuedDispenseRecords(loggableDispenseRecords);

    if (loggableDispenseRecords.length === 0) {
      printPrescription(prescription);
    } else {
      loadDispenseRecord(loggableDispenseRecords[0].id);
    }
  };

  const handleReenterPrintProcess = (dispenseRecord: DispenseRecord) => {
    if (!enqueuedDispenseRecords) return;

    const remainingLoggables = enqueuedDispenseRecords.slice(1);
    setEnqueuedDispenseRecords(remainingLoggables);

    if (remainingLoggables.length === 0) {
      setEditingDispenseRecord(undefined);
      setVisibleForms((prev) => prev.filter((form) => form !== 'new-dispense-logs'));
      fetchPrescription(dispenseRecord.prescription_item.prescription.id, { onSuccess: printPrescription });
    } else {
      loadDispenseRecord(remainingLoggables[0].id);
    }
  };

  const printPrescription = (prescription: Prescription) => {
    prescription.prescription_items.forEach((prescriptionItem) => {
      fetchPrescriptionLabel(prescriptionItem.id, {
        onSuccess: (label) => {
          handlePrescriptionSuccess('Prescription label printed!');
          createNewTab(<PrescriptionItemLabelPrint prescriptionLabel={label} />);
        },
        onError: handleError
      });
    });
  };

  const getDispenseLogsAttributes = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    const nestedName = (index: number, name: string) => `dispense_logs[${index}][${name}]`;

    const result = [];
    let index = 0;

    while (formData.has(nestedName(index, 'expiration_date'))) {
      const id = formData.get(nestedName(index, 'id'));
      const lotNumber = formData.get(nestedName(index, 'lot_number'));
      const expirationDate = formData.get(nestedName(index, 'expiration_date'));

      result.push({
        id: id ? Number(id) : undefined,
        lot_number: lotNumber ? String(lotNumber) : null,
        expiration_date: expirationDate ? String(expirationDate) : null
      });

      index++;
    }

    return result;
  };

  const handleCreateDispenseLogs = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingDispenseRecord) return;
    setLoadState('creating-dispense-logs');

    const dispense_logs_attributes = getDispenseLogsAttributes(event);

    updateDispenseRecord(
      editingDispenseRecord.id,
      { dispense_logs_attributes },
      {
        onSuccess: handleReenterPrintProcess,
        onError: handleError
      }
    );
  };
  const applyAnimalMasterProblemStyles = (item: AnimalMasterProblem) => {
    return { ...item, ...(item.important && { _props: { color: 'info' } }) };
  };

  const applyHealthPlanStyles = (item: AnimalHealthPlan) => {
    return {
      ...item,
      ...(isPastDue(item) && { _props: { color: 'danger' } }),
      ...(isDue(item) && { _props: { color: 'warning' } })
    };
  };

  const applyPrescriptionItemStyles = (item: PrescriptionItem) => {
    return { ...item, ...(item.current && { _props: { color: 'info' } }) };
  };

  const handleCreateVaccination = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadState('creating-updating-vaccination');

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      animal_id: id,
      product_id: Number(formData.get('product_id')),
      date_of_administration: formData.get('date_of_administration')?.toString(),
      date_of_next_administration: formData.get('date_of_next_administration')?.toString(),
      historical: formData.get('historical') === 'on',
      quantity: Number(formData.get('quantity')),
      serial_number: formData.get('serial_number')?.toString(),
      expiration_date: formData.get('expiration_date-date')?.toString()
    };

    createVaccination(formJson, {
      onSuccess: () => handleVaccinationSuccess('Vaccination created!'),
      onError: handleError
    });
  };

  const handleUpdateVaccination = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingVaccination) return;
    setLoadState('creating-updating-vaccination');

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      product_id: Number(formData.get('product_id')),
      date_of_administration: formData.get('date_of_administration')?.toString(),
      date_of_next_administration: formData.get('date_of_next_administration')?.toString(),
      historical: formData.get('historical') ? formData.get('historical') === 'on' : false,
      quantity: Number(formData.get('quantity')),
      serial_number: formData.get('serial_number')?.toString(),
      expiration_date: formData.get('expiration_date-date')?.toString()
    };

    updateVaccination(editingVaccination.id, formJson, {
      onSuccess: () => handleVaccinationSuccess('Vaccination updated!'),
      onError: handleError
    });
  };

  const handleVaccinationSuccess = (message: string) => {
    fetchVaccinationsByAnimal(id, setVaccinations);
    toast.success(message);
    hideVaccinationForms();
    setLoadState(undefined);
  };

  const handlePrescriptionSuccess = (message: string) => {
    fetchPrescriptionItemsByAnimal(id, setPrescriptionItems);
    toast.success(message);
    hidePrescriptionForms();
    setLoadState(undefined);
  };

  const handleExternalDispense = (prescription: Prescription) => {
    fetchPrescriptionItemsByAnimal(id, setPrescriptionItems);
    setLoadState(undefined);
    setEditingPrescription(prescription);

    setVisibleForms((prev) => [
      ...prev.filter((form) => form !== 'new-prescription' && form !== 'edit-prescription'),
      'external-dispense-prescription'
    ]);
  };

  const handleError = (error: string) => {
    setLoadState(undefined);
  };

  const hideVaccinationForms = () => {
    setVisibleForms((prev) => prev.filter((form) => form !== 'new-vaccination' && form !== 'edit-vaccination'));
    setEditingVaccination(undefined);
  };

  const hidePrescriptionForms = () => {
    setVisibleForms((prev) => prev.filter((form) => form !== 'new-prescription' && form !== 'edit-prescription'));
    setEditingVaccination(undefined);
  };

  const handleNewVaccinationClick = () => {
    remountForm();
    scrollTo('vaccinations');
    setVisibleForms((prev) => [...prev.filter((form) => form !== 'edit-vaccination'), 'new-vaccination']);
  };

  const handleNewPrescriptionClick = () => {
    remountForm();
    scrollTo('medications');
    setVisibleForms([...visibleForms.filter((form) => form !== 'new-prescription'), 'new-prescription']);
  };

  const handleEditVaccinationClick = (item: Vaccination) => () => {
    remountForm();
    scrollTo('vaccinations');
    setVisibleForms((prev) => [...prev.filter((form) => form !== 'new-vaccination'), 'edit-vaccination']);
    setEditingVaccination(item);
  };

  const handleEditPrescriptionItemClick = (item: PrescriptionItem) => () => {
    fetchPrescription(item.prescription.id, {
      onSuccess: (prescription) => {
        remountForm();
        scrollTo('medications');
        setEditingPrescription(prescription);

        setVisibleForms((prev) => [
          ...prev.filter((form) => form !== 'new-prescription' && form !== 'edit-prescription'),
          'edit-prescription'
        ]);
      }
    });
  };

  const renderVaccinationForms = (visibleForms?: Form[]) => {
    let result = null;
    if (visibleForms?.includes('new-vaccination')) {
      result = (
        <VaccinationForm
          key={formKey}
          hideForm={hideVaccinationForms}
          loading={loadState === 'creating-updating-vaccination'}
          onSubmit={handleCreateVaccination}
          formDefaults={{ historical: true }}
        />
      );
    } else if (visibleForms?.includes('edit-vaccination') && editingVaccination) {
      result = (
        <VaccinationForm
          key={formKey}
          vaccination={editingVaccination}
          hideForm={hideVaccinationForms}
          loading={loadState === 'creating-updating-vaccination'}
          onSubmit={handleUpdateVaccination}
        />
      );
    }

    return result;
  };

  const renderPrescriptionForms = (visibleForms?: Form[]) => {
    let result = null;
    if (visibleForms?.includes('new-prescription')) {
      if (!animal) return null;
      result = (
        <PrescriptionForm
          key={formKey}
          employee={auth.employee}
          animal={animal}
          hideForm={hidePrescriptionForms}
          loadState={loadState as PrescriptionLoadState}
          onSubmit={handleCreatePrescription}
        />
      );
    } else if (visibleForms?.includes('edit-prescription') && editingPrescription) {
      if (!animal) return null;
      result = (
        <PrescriptionForm
          key={formKey}
          employee={auth.employee}
          prescription={editingPrescription}
          animal={animal}
          hideForm={hidePrescriptionForms}
          loadState={loadState as PrescriptionLoadState}
          onSubmit={handleUpdatePrescription}
        />
      );
    }

    if (visibleForms?.includes('external-dispense-prescription') && editingPrescription) {
      if (!animal) return null;
      return (
        <ExternalDispensePrescriptions
          animal={animal}
          prescription={editingPrescription}
          hideModal={() => {
            setVisibleForms((prev) => prev.filter((form) => form !== 'external-dispense-prescription'));
            setEditingPrescription(undefined);
          }}
        />
      );
    }

    if (visibleForms?.includes('new-dispense-logs') && editingDispenseRecord) {
      return (
        <NewDispenseLogsModal
          hideModal={() => {
            setVisibleForms((prev) => prev.filter((form) => form !== 'new-dispense-logs'));
            setEditingDispenseRecord(undefined);
          }}
          loading={loadState === 'creating-dispense-logs'}
          key={formKey}
          onSubmit={handleCreateDispenseLogs}
          dispenseRecord={editingDispenseRecord}
        />
      );
    }

    return result;
  };

  const ToTopButton = ({ id }: { id: MedicalHistoryTab }) => (
    <CButton id={id} onClick={scrollUp} color="link" variant="ghost" size="sm" className="ps-0 fw-medium mb-5">
      {'Back to top'}
    </CButton>
  );

  const isLoading =
    !animal ||
    !consultPlans ||
    !animalMasterProblems ||
    !prescriptionItems ||
    !healthPlans ||
    !assessments ||
    !vaccinations ||
    !procedures ||
    !physicalExams ||
    !histories ||
    !diagnosticRequests ||
    !healthStatuses;

  return (
    <CCard>
      <CCardHeader ref={scrollRef}>
        <h1>Medical History</h1>
      </CCardHeader>

      <CCardBody>
        <CContainer fluid>
          {isLoading ? (
            <CSpinner color="primary" />
          ) : (
            <>
              <CRow className="mb-3">
                <CCol>
                  <Signalment onUpdateSuccess={handleAnimalUpdate} animal={animal} showPatientNotes />
                </CCol>

                <CCol>
                  <div>
                    <div className="mb-3 h5 d-flex align-items-center">
                      <h2>Client</h2>
                    </div>

                    <CTable aria-label="Client" small borderless>
                      <CTableBody>
                        {[animal.customer.first_name, animal.customer.last_name, animal.customer.id].every(
                          (attr) => attr
                        ) && (
                          <CTableRow>
                            <CTableHeaderCell>Name</CTableHeaderCell>
                            <CTableDataCell>
                              <Link to={`/customers/${animal.customer.id}`}>
                                {[animal.customer.first_name, animal.customer.last_name].join(' ')}
                              </Link>
                            </CTableDataCell>
                          </CTableRow>
                        )}

                        {animal.customer.phone && (
                          <CTableRow>
                            <CTableHeaderCell>Phone</CTableHeaderCell>
                            <CTableDataCell>{animal.customer.phone}</CTableDataCell>
                          </CTableRow>
                        )}

                        {animal.customer.email && (
                          <CTableRow>
                            <CTableHeaderCell>Email</CTableHeaderCell>
                            <CTableDataCell>{animal.customer.email}</CTableDataCell>
                          </CTableRow>
                        )}
                      </CTableBody>
                    </CTable>
                  </div>
                </CCol>
              </CRow>

              <CNav variant="pills" className="mt-3" style={{ marginBottom: '19px' }}>
                {[
                  medicalHistoryTabs.map((table) => (
                    <CNavItem key={table}>
                      <CNavLink
                        role="button"
                        className="text-capitalize"
                        href={`#${table}`}
                        active={location.hash === `#${table}`}
                      >
                        {table.split('-').join(' ')}
                      </CNavLink>
                    </CNavItem>
                  ))
                ]}
              </CNav>

              <CRow className="mb-4">
                <CCol>
                  <div onClick={scrollUp} className="mb-5" id="master-problems" />
                  <MedicalHistoryTable
                    name="Master Problems"
                    items={animalMasterProblems.map(applyAnimalMasterProblemStyles)}
                    caption="Master problems, with flagged problems in blue"
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '5%' } },
                      { key: 'started_on', label: 'Started', _style: { width: '10%' } },
                      'name',
                      'specifics',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      started_on: (item: AnimalMasterProblem) => (
                        <td>{item.started_on && compactDateDisplay(item.started_on)}</td>
                      ),
                      name: (item: AnimalMasterProblem) => <td>{item.master_problem.name}</td>,
                      specifics: (item: AnimalMasterProblem) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.specifics ?? '') }} />
                      ),
                      internal_notes: (item: AnimalMasterProblem) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes ?? '') }} />
                      ),
                      updated_at: (item: AnimalMasterProblem) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id)} />
                      ),
                      ...status
                    }}
                  />

                  <ToTopButton id="health-statuses" />
                  <MedicalHistoryTable
                    name="Health Statuses"
                    items={healthStatuses}
                    columns={[{ key: 'updated', label: 'Updated' }, { key: 'status' }, { key: 'statuses' }]}
                    scopedColumns={{
                      updated: (item: HealthStatus) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'health-statuses')} />
                      ),
                      statuses: (item: HealthStatus) => (
                        <td>
                          <List data={filterMetadata(item)} />
                        </td>
                      ),
                      status: (item: HealthStatus) => (
                        <td>
                          <Pill label={item.status} />
                        </td>
                      )
                    }}
                  />

                  <ToTopButton id="health-plans" />
                  <MedicalHistoryTable
                    name="Health Plans"
                    items={healthPlans.map(applyHealthPlanStyles)}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '5%' } },
                      'name',
                      'importance',
                      'fulfilled_at',
                      'next_due_date'
                    ]}
                    scopedColumns={{
                      name: (item: AnimalHealthPlan) => <td>{item.health_plan.name}</td>,
                      importance: (item: AnimalHealthPlan) => (
                        <td>
                          <Pill label={item.health_plan.importance} />
                        </td>
                      ),
                      fulfilled_at: (item: AnimalHealthPlan) => (
                        <td>{item.fulfilled_at && compactDateDisplay(item.fulfilled_at)}</td>
                      ),
                      next_due_date: (item: AnimalHealthPlan) => (
                        <td>{item.next_due_date && compactDateDisplay(item.next_due_date)}</td>
                      ),
                      ...status,
                      updated_at: (item: AnimalHealthPlan) => (
                        <td>
                          <div>{item.updated_by_employee?.full_name_with_title}</div>
                          {item.updated_at && compactDateTimeDisplay(item.updated_at)}
                        </td>
                      )
                    }}
                  />

                  <ToTopButton id="histories" />
                  <MedicalHistoryTable
                    name="Histories"
                    items={histories}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '5%' } },
                      { key: 'history' },
                      { key: 'internal_notes' }
                    ]}
                    scopedColumns={{
                      updated_at: (item: History) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'soap')} />
                      ),
                      case_owner: (item: History) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'soap')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      history: (item: History) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.history ?? '') }} />
                      ),
                      internal_notes: (item: History) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes) }} />
                      ),
                      ...status
                    }}
                  />

                  <ToTopButton id="physical-exams" />
                  <MedicalHistoryTable
                    name="Physical Exams"
                    items={physicalExams}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '10%' } },
                      'exam',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      updated_at: (item: PhysicalExam) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'soap')} />
                      ),
                      case_owner: (item: PhysicalExam) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'soap')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      exam: (item: PhysicalExam) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.exam ?? '') }} />
                      ),
                      internal_notes: (item: PhysicalExam) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes) }} />
                      ),
                      ...status
                    }}
                  />

                  <ToTopButton id="assessments" />
                  <MedicalHistoryTable
                    name="Assessments"
                    items={assessments}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '10%' } },
                      'evaluation'
                    ]}
                    scopedColumns={{
                      updated_at: (item: Assessment) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'soap')} />
                      ),
                      case_owner: (item: Assessment) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'soap')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      ...status,
                      evaluation: (item: Assessment) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.evaluation) }} />
                      )
                    }}
                  />

                  <ToTopButton id="consult-plans" />
                  <MedicalHistoryTable
                    name="Consult Plans"
                    items={consultPlans}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '10%' } },
                      'plan',
                      'customer_discussion',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      updated_at: (item: ConsultPlan) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'soap')} />
                      ),
                      case_owner: (item: ConsultPlan) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'soap')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      plan: (item: ConsultPlan) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.plan ?? '') }} />
                      ),
                      customer_discussion: (item: ConsultPlan) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.customer_discussion ?? '') }} />
                      ),
                      internal_notes: (item: ConsultPlan) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes ?? '') }} />
                      ),
                      ...status
                    }}
                  />

                  <ToTopButton id="vaccinations" />
                  <MedicalHistoryTableV2
                    name="Vaccinations"
                    title="Vaccinations"
                    form={renderVaccinationForms(visibleForms)}
                    newButton={
                      <IconButton icon={SvgPlus} label="New Vaccination" onClick={handleNewVaccinationClick} />
                    }
                    items={vaccinations}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'date_of_administration', _style: { width: '10%' } },
                      { key: 'date_of_next_administration', _style: { width: '10%' } },
                      { key: 'administration_status', label: 'Administration Status', _style: { width: '10%' } },
                      'product_name',
                      'quantity',
                      'historical',
                      'internal_notes',
                      'actions'
                    ]}
                    scopedColumns={{
                      updated_at: (item: Vaccination) => (
                        <TableAuditData item={item} handleClick={handleEditVaccinationClick(item)} />
                      ),
                      case_owner: (item: Vaccination) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'rx-vacc')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      internal_notes: (item: Vaccination) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes ?? '') }} />
                      ),
                      date_of_administration: (item: Vaccination) => (
                        <td>{item.date_of_administration && compactDateDisplay(item.date_of_administration)}</td>
                      ),
                      date_of_next_administration: (item: Vaccination) => (
                        <td>
                          {item.date_of_next_administration && compactDateDisplay(item.date_of_next_administration)}
                        </td>
                      ),
                      product_name: (item: Vaccination) => <td>{item.product?.name}</td>,
                      administration_status: (item: Vaccination) => (
                        <td>
                          <Pill label={item.administration_status} />
                        </td>
                      ),
                      historical: (item: Vaccination) => <td>{item.historical ? 'true' : 'false'}</td>,
                      quantity: (item: Vaccination) => <td>{item.quantity && item.quantity}</td>,
                      ...status,
                      actions: (item: Vaccination) => (
                        <td>
                          <ActionsMenu
                            items={
                              [
                                item.consult_id && {
                                  label: 'View in Consult',
                                  href: [
                                    generatePath(paths.consultDetails, { id: item.consult_id }),
                                    '?',
                                    createSearchParams({ step: 'rx-vacc' })
                                  ].join('')
                                },
                                {
                                  label: 'Edit',
                                  onClick: handleEditVaccinationClick(item)
                                }
                              ].filter(identity) as ActionsMenuItem[]
                            }
                          />
                        </td>
                      )
                    }}
                  />

                  <ToTopButton id="medications" />
                  <MedicalHistoryTableV2
                    name="Medications"
                    title="Medications"
                    items={prescriptionItems.map(applyPrescriptionItemStyles)}
                    caption="Medications, with currently taken medications in blue"
                    form={renderPrescriptionForms(visibleForms)}
                    newButton={
                      <IconButton icon={SvgPlus} label="New Medication" onClick={handleNewPrescriptionClick} />
                    }
                    columns={[
                      { key: 'updated_at', label: 'Updated' },
                      { key: 'case_owner' },
                      { key: 'date_of_prescription', label: 'Date of Prescription' },
                      'fulfillment_status',
                      'current',
                      'chronic',
                      'name',
                      'quantity',
                      'instructions'
                    ]}
                    scopedColumns={{
                      case_owner: (item: PrescriptionItem) => (
                        <td>
                          <Link to={buildConsultLink(item.prescription.consult_id, 'rx-vacc')}>
                            {item.prescription.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      date_of_prescription: (item: PrescriptionItem) => (
                        <td>{compactDateDisplay(item.prescription.date_of_prescription)}</td>
                      ),
                      instructions: (item: PrescriptionItem) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.instructions) }} />
                      ),
                      fulfillment_status: (item: PrescriptionItem) => (
                        <td>
                          <Pill label={fulfillmentStatus(item)} />
                        </td>
                      ),
                      name: (item: PrescriptionItem) => <td>{item.product?.name}</td>,
                      current: (item: PrescriptionItem) => <td>{item.current ? 'Yes' : 'No'}</td>,
                      chronic: (item: PrescriptionItem) => <td>{item.chronic ? 'Yes' : 'No'}</td>,
                      updated_at: (item: PrescriptionItem) => (
                        <TableAuditData item={item} handleClick={handleEditPrescriptionItemClick(item)} />
                      )
                    }}
                  />

                  <ToTopButton id="diagnostics" />
                  <MedicalHistoryTable
                    name="Diagnostics"
                    items={diagnosticRequests}
                    columns={['updated', 'case_owner', 'employee', 'summaries']}
                    scopedColumns={{
                      updated: (item: DiagnosticRequest) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'diagnostics')} />
                      ),
                      case_owner: (item: DiagnosticRequest) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'diagnostics')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      employee: (item: DiagnosticRequest) => <td>{item.employee.full_name_with_title}</td>,
                      summaries: (item: DiagnosticRequest) => (
                        <td>
                          <DiagnosticRequestDetails item={item} />
                        </td>
                      )
                    }}
                  />

                  <ToTopButton id="procedures" />
                  <MedicalHistoryTable
                    name="Procedures"
                    items={procedures}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'case_owner' },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'procedure_name', _style: { width: '10%' } },
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      updated_at: (item: Procedure) => (
                        <TableAuditData item={item} href={buildConsultLink(item.consult_id, 'procedures')} />
                      ),
                      case_owner: (item: Procedure) => (
                        <td>
                          <Link to={buildConsultLink(item.consult_id, 'procedures')}>
                            {item.case_owner?.full_name_with_title}
                          </Link>
                        </td>
                      ),
                      procedure_name: (item: Procedure) => <td>{item.product.name}</td>,
                      internal_notes: (item: Procedure) => (
                        <td dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.internal_notes) }} />
                      ),
                      ...status
                    }}
                  />
                </CCol>
              </CRow>
            </>
          )}
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default MedicalHistory;
