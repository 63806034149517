import cn from 'classnames';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CCol, CContainer, CRow } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';

import { dateDisplay, timeDisplay } from 'utils/dates';
import { renderMarkdown } from 'utils/markdown';
import { formatPhoneNumber } from 'utils/phoneNumbers';

import { ReactComponent as CallIcon } from 'assets/images/call.svg';
import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import { ReactComponent as EmailIcon } from 'assets/images/sms.svg';

import { AnimalPhoto } from 'components/AnimalPhoto';
import { Pill } from 'components/Pill';

import styles from './PatientClientAppointment.module.scss';

type Props = {
  appointment: Appointment;
  animal: Animal;
  upgradeButton?: React.ReactNode;
};

export const PatientClientAppointmentDetails: React.FC<Props> = ({ appointment, animal, upgradeButton }) => {
  return (
    <div className="d-flex flex-row gap-4">
      <CContainer className={cn(styles.container, styles.clientAnimalSection)}>
        <CRow>
          <CCol sm={6}>
            <h2 className={cn('mb-1', styles.header)}>Patient</h2>
          </CCol>
          <CCol sm={6}>
            <h2 className={cn('mb-1', styles.header)}>Client</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm={6}>
            <div className={cn(styles.animalSection, styles.infoGroup)}>
              <Link
                aria-label={animal.name || 'Patient details'}
                to={generatePath(paths.animalDetails, { id: animal.id })}
              >
                <AnimalPhoto height={80} width={80} animal={animal} />
              </Link>
              <div>
                <Link className={styles.name} to={generatePath(paths.animalDetails, { id: animal.id })}>
                  {animal.name}
                </Link>
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex gap-1 flex-wrap">
                    <CrownIcon /> {animal.membership?.plan.name}
                  </div>
                  {upgradeButton}
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm={6}>
            <div className={styles.infoGroup}>
              <div className={styles.customerRow}>
                <Link to={`/customers/${animal.customer.id}`}>
                  {appointment.customer?.first_name} {appointment.customer?.last_name}
                </Link>
              </div>

              <div className={styles.customerRow}>
                <EmailIcon />
                <div className={styles.blueText}>{appointment.customer?.email}</div>
              </div>

              <div className={styles.customerRow}>
                <CallIcon />
                <div className={styles.value}>{formatPhoneNumber(appointment.customer?.phone)}</div>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm={6} className="d-flex flex-column">
            <h3 className="mb-0 mt-2">Internal Note</h3>
            <div className={styles.internalNotes}>
              <div className={styles.scrollableContent}>
                {animal.internal_notes ? renderMarkdown(animal.internal_notes) : <i>No internal notes</i>}
              </div>
            </div>
          </CCol>
          <CCol sm={6} className="d-flex flex-column">
            <h3 className="mb-0 mt-2">Internal Note</h3>
            <div className={styles.internalNotes}>
              <div className={styles.scrollableContent}>
                {appointment.customer?.internal_notes ? (
                  renderMarkdown(appointment.customer.internal_notes)
                ) : (
                  <i>No internal notes</i>
                )}
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <CContainer className={styles.container}>
        <h2 className={cn('mb-1 mt-3', styles.header)}>Appointment</h2>
        <div className={styles.infoRow}>
          <div>Details</div>
          <div>
            <Link to={`/appointments/${appointment.id}`}>View</Link>
          </div>
        </div>
        <div className={styles.infoRow}>
          <div>Date</div>
          <div>{dateDisplay(appointment.start_time)}</div>
        </div>
        <div className={styles.infoRow}>
          <div>Time</div>
          <div>
            {timeDisplay(appointment.start_time)}-{timeDisplay(appointment.end_time)}
          </div>
        </div>
        <div className={styles.infoRow}>
          <div>Appointment Type</div>
          <div>{appointment.appointment_type.name_in_pim}</div>
        </div>
        <div className={styles.infoRow}>
          <div>With</div>
          <div>
            <Link to={`/employees/${appointment.employee.id}`}>{appointment.employee.full_name_with_title}</Link>
          </div>
        </div>
        <div className={styles.infoRow}>
          <div>Status</div>
          <div>
            <Pill label={appointment.status} />
          </div>
        </div>
      </CContainer>
    </div>
  );
};
