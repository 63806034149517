import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import * as React from 'react';

import { Animal } from 'types/Animal';

import { desexedDisplay } from 'utils/animal';
import { styles } from 'utils/printLabel';

type Props = {
  animal: Animal;
  title: string;
  body?: string;
};

export const SamplePrintLabel = ({ animal, title, body }: Props): JSX.Element => {
  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`${title} for ${animal.name}`}>
        <Page size={{ width: 572, height: 318 }} style={styles.page}>
          <View>
            <Text style={styles.bold}>{title}</Text>
          </View>
          <View>
            <Text style={styles.bold}>
              Client: {animal.customer.first_name} {animal.customer.last_name} ({animal.customer.id})
            </Text>
            <Text style={styles.bold}>
              {animal.name} ({animal.id})
            </Text>
            <Text>
              <Text style={styles.bold}>Species: </Text> {animal.species.name}
            </Text>
            <Text>
              <Text style={styles.bold}>Sex: </Text>
              {desexedDisplay(animal)}
            </Text>
            <Text>
              <Text style={styles.bold}>DOB: </Text>
              {animal.date_of_birth}
            </Text>
          </View>
          <View>
            <Text style={styles.bold}>{body}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
