import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { paths } from 'routes';

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable,
  CSpinner
} from '@coreui/react-pro';

import { fetchProducts } from 'api/Products';

import { Option } from 'types/Option';
import { calculatePages, Pagination } from 'types/Pagination';
import { Product } from 'types/Product';
import { ProductType, productTypes } from 'types/ProductType';
import { Status } from 'types/Status';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { reactSelectStyles } from 'utils/reactSelect';
import { pluralize } from 'utils/strings';

const List = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [productList, setProductList] = useState<Product[]>();
  const [searchText, setSearchText] = useState<string>('');
  const [productType, setProductType] = useState<Option>();
  const [productStatus, setProductStatus] = useState<Option>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  useDocumentTitle('Products List');

  const updateProductList = useCallback((products: Product[]) => {
    setIsLoading(false);
    setProductList(products);
  }, []);

  useEffect(() => {
    const search = searchParams.get('search');
    if (search) setSearchText(search);

    const type = searchParams.get('type') as ProductType;
    if (type && productTypes.indexOf(type) > -1) setProductType({ value: type, label: type });

    const status = searchParams.get('status') as Status;
    if (status) setProductStatus({ value: status.toLowerCase(), label: status });
    else setProductStatus({ value: 'active', label: 'Active' });

    const page = searchParams.get('page');
    if (page) setPagination((prev) => ({ ...prev, page: parseInt(page) }));

    fetchProducts(
      search || '',
      setProductList,
      setPagination,
      type || undefined,
      status || 'active',
      page ? parseInt(page) : 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchByProductType(productFilter: Option | undefined) {
    if (productFilter?.value !== productType?.value) {
      if (productFilter) searchParams.set('type', productFilter.value);
      else searchParams.delete('type');
      setSearchParams(searchParams);

      setProductType(productFilter);
      if (productFilter)
        setSearchParams({
          ...searchParams,
          search: searchText,
          status: productStatus?.value,
          type: productFilter?.value
        });
      else setSearchParams({ ...searchParams, type: '', status: productStatus?.value, search: searchText });

      setIsLoading(true);
      fetchProducts(searchText, updateProductList, setPagination, productFilter?.value, productStatus?.value);
    }
  }

  function searchByStatus(statusFilter: Option) {
    if (statusFilter) searchParams.set('status', statusFilter.label);
    else searchParams.delete('status');
    setSearchParams(searchParams);

    setProductStatus(statusFilter);
    setSearchParams({ ...searchParams, search: searchText, status: statusFilter.label, type: productType?.value });

    setIsLoading(true);
    fetchProducts(searchText, updateProductList, setPagination, productType?.value, statusFilter?.value);
  }

  const setSearch = (query: string) => {
    setSearchText(query);
    if (!query) {
      searchParams.delete('search');
      setSearchParams(searchParams);
      fetchProductList();
    }
  };

  const updatePage = (page: number) => {
    if (pagination.page !== page) {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
      fetchProducts(searchText, updateProductList, setPagination, productType?.value, productStatus?.value, page);
    }
  };

  const fetchProductList = useCallback(() => {
    setIsLoading(true);
    fetchProducts(searchText, updateProductList, setPagination, productType?.value, productStatus?.value);
  }, [searchText, updateProductList, productType?.value, productStatus?.value]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    searchParams.set('search', searchText);
    setSearchParams(searchParams);
    fetchProductList();
  };

  const handleClearFilters = () => {
    setSearchParams();
    setSearchText('');
    setProductType(undefined);
    setProductStatus({ value: 'active', label: 'Active' });
    setIsLoading(true);
    fetchProducts('', updateProductList, setPagination, undefined, 'active');
  };

  return (
    <div>
      <CRow className="mb-2 d-flex align-items-center">
        <CCol xs={11}>
          <h1 className="mb-0">Products</h1>
        </CCol>
        <CCol xs={1} className="p-0">
          <CButton color="primary" href={'/products/new'} shape="rounded-pill">
            New
          </CButton>
        </CCol>
      </CRow>
      <CForm
        className="p-3"
        style={{
          background: 'var(--background-blue',
          boxShadow: 'none',
          border: '1px solid var(--primary-blue',
          borderRadius: '20px'
        }}
        onSubmit={handleSubmit}
      >
        <CRow className="mb-3">
          <CCol xs={10}>
            <CFormInput
              type="text"
              id="searchInput"
              label="Search Products"
              placeholder="Search by name"
              value={searchText}
              onChange={(event) => setSearch(event.target.value)}
            />
          </CCol>
          <CCol className="d-flex justify-content-end align-self-end" xs={2}>
            <CLoadingButton
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={!searchText}
              shape="rounded-pill"
            >
              Search
            </CLoadingButton>
          </CCol>
        </CRow>

        <CRow className="mb-0">
          <CCol xs={3}>
            <CFormLabel>
              Filter
              {isLoading ? <CSpinner color="dark" size="sm" className="ms-2" /> : null}
            </CFormLabel>
          </CCol>
          <CCol xs={9} className="d-flex justify-content-end">
            <CButton onClick={handleClearFilters} size="sm" variant="ghost" className="mb-0 pt-0">
              Clear
            </CButton>
          </CCol>
        </CRow>

        <CRow>
          <CCol xs={6}>
            <Select<Option>
              id="type"
              aria-label="Filter by type"
              placeholder="Filter by type"
              styles={reactSelectStyles}
              onChange={(selected) => searchByProductType((selected as Option) || undefined)}
              options={productTypes.map((type) => ({ value: type, label: type }))}
              isClearable
              isSearchable
              value={productType}
            />
          </CCol>
          <CCol xs={6}>
            <Select<Option>
              id="status"
              aria-label="Filter by status"
              placeholder="Filter by status"
              styles={reactSelectStyles}
              onChange={(selected) => searchByStatus((selected as Option) || undefined)}
              options={[
                { value: 'active', label: 'Active' },
                { value: 'disabled', label: 'Disabled' },
                { value: 'all', label: 'All' }
              ]}
              isSearchable
              value={productStatus}
            />
          </CCol>
        </CRow>
      </CForm>

      {!isLoading && (
        <CFormLabel className="mt-4 text-body-secondary" color="light">
          {pagination.total} {pluralize('product', pagination.total)} found
        </CFormLabel>
      )}
      {productList && productList.length > 0 && (
        <CSmartTable
          items={productList}
          itemsPerPage={25}
          pagination={{
            external: true
          }}
          paginationProps={{
            activePage: pagination.page,
            pages: calculatePages(pagination),
            align: 'center'
          }}
          onActivePageChange={(activePage) => updatePage(activePage)}
          columns={[
            'name',
            {
              key: 'display_name',
              label: 'Display Name'
            },
            'description',
            'product_type',
            {
              key: 'sheet_group_name',
              label: 'Group Name'
            },
            'controlled',
            'preventative',
            'status'
          ]}
          scopedColumns={{
            name: (product: Product) => {
              return (
                <td className="py-2">
                  <Link to={generatePath(paths.productEdit, { id: product.id })}>{product.name}</Link>
                </td>
              );
            },
            display_name: (product: Product) => {
              return (
                <td className="py-2">
                  <Link to={generatePath(paths.productEdit, { id: product.id })}>{product.display_name}</Link>
                </td>
              );
            },
            sheet_group_name: (product: Product) => {
              return <td className="py-2">{product.sheet_group_name || ''}</td>;
            },
            controlled: (product: Product) => {
              return <td className="py-2">{product.controlled ? 'Yes' : 'No'}</td>;
            },
            preventative: (product: Product) => {
              return <td className="py-2">{product.preventative ? 'Yes' : 'No'}</td>;
            },
            status: (product: Product) => {
              return (
                <td className="py-2" style={{ textTransform: 'capitalize' }}>
                  {product.status}
                </td>
              );
            }
          }}
        ></CSmartTable>
      )}
    </div>
  );
};

export default List;
