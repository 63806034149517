import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSolidX = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M8 0C3.592 0 0 3.592 0 8s3.592 8 8 8 8-3.592 8-8-3.592-8-8-8m2.688 9.84a.604.604 0 0 1 0 .848.6.6 0 0 1-.424.176.6.6 0 0 1-.424-.176L8 8.848l-1.84 1.84a.6.6 0 0 1-.424.176.6.6 0 0 1-.424-.176.604.604 0 0 1 0-.848L7.152 8l-1.84-1.84a.604.604 0 0 1 0-.848.604.604 0 0 1 .848 0L8 7.152l1.84-1.84a.604.604 0 0 1 .848 0 .604.604 0 0 1 0 .848L8.848 8z"
    />
  </svg>
);
export default SvgSolidX;
