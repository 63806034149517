import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { CModal, CModalBody } from '@coreui/react-pro';

import {
  createDiagnosticRequest,
  fetchDiagnosticRequestsForConsult,
  refreshDiagnosticRequest,
  sendDiagnosticRequestOrder,
  updateDiagnosticRequest
} from 'api/DiagnosticRequests';
import { createDiagnosticResult, updateDiagnosticResult } from 'api/DiagnosticResults';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Consult } from 'types/Consult';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DiagnosticRequestItem } from 'types/DiagnosticRequestItem';
import { DiagnosticRequestStatus } from 'types/DiagnosticRequestStatus';
import { DiagnosticResult, DiagnosticStatus } from 'types/DiagnosticResult';
import { DiagnosticResultItem } from 'types/DiagnosticResultItem';
import { MedicalHistoryItem } from 'types/MedicalHistoryItem';

import { useAuth } from 'hooks/useAuth';
import { usePoll } from 'hooks/usePoll';

import { appendToFormData, nestFormData } from 'utils/formData';
import { diagnosticRequestToOption } from 'utils/selectOptions';
import { statusAction } from 'utils/status';
import { pluralize } from 'utils/strings';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { DiagnosticRequestDetails } from 'components/DiagnosticRequestDetails';
import { IconButton } from 'components/IconButton';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';

import { DiagnosticRequestForm } from './DiagnosticRequestForm';
import { DiagnosticResultForm } from './DiagnosticResultForm';

type Form = 'new-diagnostic-request' | 'edit-diagnostic-request' | 'new-diagnostic-result' | 'edit-diagnostic-result';

type Props = {
  consult: Consult;
  scrollUp: () => void;
};

export const DiagnosticsStep = ({ consult, scrollUp }: Props) => {
  const [diagnosticRequests, setDiagnosticRequests] = useState<DiagnosticRequest[]>([]);

  const [visibleForm, setVisibleForm] = useState<Form>();
  const [editingItem, setEditingItem] = useState<DiagnosticRequest | DiagnosticResult>();
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showIframe, setShowIframe] = useState(false);
  const [showResultsViewer, setShowResultsViewer] = useState(false);
  const [resultsIframeUrl, setResultsIframeUrl] = useState<string>();

  const auth = useAuth();

  const fetch = useCallback(
    () =>
      fetchDiagnosticRequestsForConsult(consult.id, (requests) => {
        if (requests.length === 0) {
          setVisibleForm('new-diagnostic-request');
        }

        setDiagnosticRequests(requests);
      }),
    [consult]
  );
  usePoll(fetch);

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (typeof event.data === 'string' && event.data.indexOf('ui/done') === 0) {
        if (editingItem) {
          refreshDiagnosticRequest(editingItem.id, (dr: DiagnosticRequest) => {
            setDiagnosticRequests((prev) => prev.map((item) => (item.id === dr.id ? dr : item)));
            setShowIframe(false);
            setEditingItem(undefined);
            if (dr.pdf_url) {
              window.open(dr.pdf_url, '_blank', 'noopener, noreferrer');
            }
          });
        }
      }
    };

    window.addEventListener('message', messageListener);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [editingItem]);

  const hideForm = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  const remountForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleNewDiagnosticRequestClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-diagnostic-request');
  };

  const handleNewDiagnosticResultClick = (item: DiagnosticRequest) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('new-diagnostic-result');
  };

  const handleEditDiagnosticRequest = (item: DiagnosticRequest) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-diagnostic-request');
  };

  const handleEditDiagnosticResult = (item: DiagnosticResult) => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-diagnostic-result');
  };

  const handleSuccess = (message: string) => {
    hideForm();
    toast.success(message);
    fetch();

    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const formDataToDiagnosticRequestItems = (formData: FormData) => {
    const nestedName = (index: number, name: string) => `diagnostic_request_items[${index}][${name}]`;
    let index = 0;
    const diagnosticItems = [];
    while (formData.has(nestedName(index, 'diagnostic'))) {
      const item: Partial<DiagnosticRequestItem> = {
        diagnostic_id: Number(formData.get(nestedName(index, 'diagnostic')))
      };
      const id = formData.get(nestedName(index, 'id'));
      if (id) {
        item.id = Number(id);
      }
      const code = formData.get(nestedName(index, 'product'));
      if (code) {
        item.code = code.toString();
      }
      diagnosticItems.push(item);
      index++;
    }
    return diagnosticItems;
  };

  const handleCreateDiagnosticRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const diagnosticItems = formDataToDiagnosticRequestItems(formData);

    const formJson = {
      consult_id: consult.id,
      animal_id: consult.animal_id,
      employee_id: Number(formData.get('employee_id')),
      requested_at: formData.get('date_of_request-date')?.toString(),
      specifics: formData.get('specifics')?.toString(),
      status: formData.get('status') as DiagnosticRequestStatus,
      diagnostic_request_items_attributes: diagnosticItems
    };

    if (formJson.requested_at) {
      // the backend type is a DateTime, but there is no time selector on the form
      const requestedAtDate = new Date(`${formJson.requested_at} 00:00:00`);
      formJson.requested_at = requestedAtDate.toISOString();
    }

    createDiagnosticRequest(
      formJson,
      () => {
        const message = `${pluralize('Diagnostic', diagnosticItems.length)} requested!`;
        return handleSuccess(message);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleUpdateDiagnosticRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const diagnosticItems = formDataToDiagnosticRequestItems(formData);

    const formJson = {
      employee_id: Number(formData.get('employee_id')),
      requested_at: formData.get('date_of_request-date')?.toString(),
      specifics: formData.get('specifics')?.toString(),
      status: formData.get('status') as DiagnosticRequestStatus,
      diagnostic_request_items_attributes: diagnosticItems
    };

    if (formJson.requested_at) {
      // the backend type is a DateTime, but there is no time selector on the form
      const requestedAtDate = new Date(`${formJson.requested_at} 00:00:00`);
      formJson.requested_at = requestedAtDate.toISOString();
    }

    updateDiagnosticRequest(editingItem.id, formJson, {
      onSuccess: () => {
        const message = `${pluralize('Diagnostic', diagnosticItems.length)} updated!`;
        return handleSuccess(message);
      },
      onError: () => setLoading(false)
    });
  };

  const formDataToDiagnosticResultItems = (formData: FormData) => {
    const nestedName = (index: number, name: string) => `diagnostic_result_items_attributes[${index}][${name}]`;
    let index = 0;
    const diagnosticItems = [];
    while (formData.has(nestedName(index, 'name'))) {
      const item: Partial<DiagnosticResultItem & { _destroy: boolean }> = {
        name: formData.get(nestedName(index, 'name'))?.toString(),
        value: formData.get(nestedName(index, 'value'))?.toString(),
        unit: formData.get(nestedName(index, 'unit'))?.toString(),
        qualifier: formData.get(nestedName(index, 'qualifier'))?.toString(),
        range_low: formData.get(nestedName(index, 'range_low'))?.toString(),
        range_high: formData.get(nestedName(index, 'range_high'))?.toString(),
        _destroy: formData.get(nestedName(index, '_destroy')) === '1'
      };

      const id = formData.get(nestedName(index, 'id'));
      if (id) {
        item.id = Number(id);
      }
      if (item.name?.length) {
        diagnosticItems.push(item);
      }
      index++;
    }
    return diagnosticItems;
  };

  const handleCreateDiagnosticResult = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const diagnosticItems = formDataToDiagnosticResultItems(formData);

    const data = {
      consult_id: consult.id,
      animal_id: consult.animal_id,
      customer_id: consult.customer_id,
      diagnostic_request_id: editingItem.id,
      employee_id: Number(formData.get('employee_id')),
      status: formData.get('status')?.toString() as DiagnosticStatus,
      requested_at: formData.get('date_of_request-date')?.toString(),
      specifics: formData.get('specifics')?.toString(),
      attachments: formData.get('attachments[]'),
      outcome: formData.get('outcome')?.toString(),
      patient_notes: formData.get('patient_notes')?.toString(),
      diagnostic_result_items_attributes: diagnosticItems
    };

    appendToFormData(formData, 'diagnostic_result', data);

    createDiagnosticResult(nestFormData(formData, 'diagnostic_result'), {
      onSuccess: (diagnosticResult: DiagnosticResult) => {
        setDiagnosticRequests((prev) =>
          prev.map((item) => {
            if (item.diagnostic_results && item.diagnostic_results.length > 0) {
              const resultIndex = item.diagnostic_results.findIndex((result) => result.id === diagnosticResult.id);
              if (resultIndex !== -1) {
                item.diagnostic_results[resultIndex] = diagnosticResult;
              }
            } else {
              item.diagnostic_results = [diagnosticResult];
            }
            return item;
          })
        );
        const message = `${pluralize('Diagnostic result', diagnosticItems.length)} created!`;
        handleSuccess(message);
      },
      onError: () => handleError
    });
  };

  const handleUpdateDiagnosticResult = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const diagnosticItems = formDataToDiagnosticResultItems(formData);

    const data = {
      employee_id: Number(formData.get('employee_id')),
      status: formData.get('status')?.toString() as DiagnosticStatus,
      requested_at: formData.get('date_of_request-date')?.toString(),
      specifics: formData.get('specifics')?.toString(),
      attachments: formData.get('attachments[]'),
      outcome: formData.get('outcome')?.toString(),
      patient_notes: formData.get('patient_notes')?.toString(),
      diagnostic_result_items_attributes: diagnosticItems
    };

    appendToFormData(formData, 'diagnostic_result', data);

    updateDiagnosticResult(editingItem.id, nestFormData(formData, 'diagnostic_result'), {
      onSuccess: (diagnosticResult: DiagnosticResult) => {
        setDiagnosticRequests((prev) =>
          prev.map((item) => {
            if (item.diagnostic_results) {
              const resultIndex = item.diagnostic_results.findIndex((result) => result.id === diagnosticResult.id);
              if (resultIndex !== -1) {
                item.diagnostic_results[resultIndex] = diagnosticResult;
              }
            }
            return item;
          })
        );
        const message = `${pluralize('Diagnostic result', diagnosticItems.length)} updated!`;
        handleSuccess(message);
      },
      onError: () => handleError
    });
  };

  const handleRefreshDiagnosticRequest = (item: DiagnosticRequest) => {
    refreshDiagnosticRequest(item.id, (dr: DiagnosticRequest) => {
      setDiagnosticRequests((prev) => prev.map((item) => (item.id === dr.id ? dr : item)));
      toast.success('Diagnostic order refreshed.');
    });
  };

  const canPreOrder = (item: DiagnosticRequest) => {
    return item.status !== 'disabled' && !item.reference_number && item.diagnostic_provider;
  };

  const canSendOrder = (item: DiagnosticRequest) => {
    return item.status === 'pending' && item.ordering_url;
  };

  const handleSendDiagnosticRequestOrder = (item: DiagnosticRequest) => {
    if (!consult.animal.breed) {
      toast.error('Please set the breed of the patient before sending the order.');
      return;
    }
    sendDiagnosticRequestOrder(item.id, (updatedRequest) => {
      setDiagnosticRequests((prev) =>
        prev.map((request) => (request.id === updatedRequest.id ? updatedRequest : request))
      );
      if (canSendOrder(updatedRequest)) {
        openOrderingIframe(updatedRequest);
      } else {
        toast.success('Diagnostic order sent!');
      }
    });
  };

  function openOrderingIframe(item: DiagnosticRequest) {
    setEditingItem(item);
    setShowIframe(true);
  }

  const actionsMenuItems = (item: DiagnosticRequest) => {
    let actions;

    if (item.diagnostic_results && item.diagnostic_results.length > 0) {
      actions = [
        item.status !== 'disabled' &&
          item.reference_number && {
            label: 'Refresh',
            onClick: () => handleRefreshDiagnosticRequest(item)
          },
        statusAction({ item, name: 'Diagnostic Request', handleSuccess, handleError, updater: updateDiagnosticRequest })
      ];
    } else {
      actions = [
        {
          label: 'Edit',
          onClick: handleEditDiagnosticRequest(item)
        },
        canPreOrder(item) && {
          label: 'Send Order',
          onClick: () => handleSendDiagnosticRequestOrder(item)
        },
        item.status !== 'disabled' && {
          label: 'New Result',
          onClick: handleNewDiagnosticResultClick(item)
        },
        canSendOrder(item) && {
          label: 'Send Order',
          onClick: () => {
            openOrderingIframe(item);
          }
        },
        item.pdf_url && {
          label: 'Order Form',
          href: item.pdf_url
        },
        item.status !== 'disabled' &&
          item.reference_number && {
            label: 'Refresh',
            onClick: () => handleRefreshDiagnosticRequest(item)
          },
        statusAction({ item, name: 'Diagnostic Request', handleSuccess, handleError, updater: updateDiagnosticRequest })
      ];
    }

    return [...actions].filter(Boolean) as ActionsMenuItem[];
  };

  const isDiagnosticRequest = (editingItem?: MedicalHistoryItem): editingItem is DiagnosticRequest => !!editingItem;
  const isDiagnosticResult = (editingItem?: MedicalHistoryItem): editingItem is DiagnosticResult => !!editingItem;

  const getEmployeeForRequest = () => {
    if (consult.appointment?.case_owner?.vet) {
      return consult.appointment.case_owner;
    }
    if (auth.employee?.vet) {
      return auth.employee;
    }
    return null;
  };

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'new-diagnostic-request':
        return (
          <DiagnosticRequestForm
            key={formKey}
            hideForm={hideForm}
            loading={loading}
            onSubmit={handleCreateDiagnosticRequest}
            employee={getEmployeeForRequest()}
            consult={consult}
          />
        );
      case 'edit-diagnostic-request':
        if (isDiagnosticRequest(editingItem)) {
          return (
            <DiagnosticRequestForm
              key={formKey}
              diagnosticRequest={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateDiagnosticRequest}
              employee={editingItem.employee}
              consult={consult}
            />
          );
        }
        break;
      case 'new-diagnostic-result':
        if (isDiagnosticRequest(editingItem)) {
          return (
            <DiagnosticResultForm
              key={formKey}
              appointment={consult.appointment}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleCreateDiagnosticResult}
              employee={auth.employee}
              diagnosticRequest={editingItem}
              showHeader={false}
            />
          );
        }
        break;
      case 'edit-diagnostic-result':
        if (isDiagnosticResult(editingItem)) {
          return (
            <DiagnosticResultForm
              key={formKey}
              appointment={consult.appointment}
              diagnosticResult={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateDiagnosticResult}
              employee={auth.employee}
              showHeader={false}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  const getOrderingHandler = (item: DiagnosticRequest) => {
    if (canPreOrder(item)) {
      return handleSendDiagnosticRequestOrder;
    } else if (canSendOrder(item)) {
      return openOrderingIframe;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {showIframe && isDiagnosticRequest(editingItem) && (
        <CModal
          className="show d-block"
          visible
          size="xl"
          fullscreen="sm"
          onClose={() => {
            if (editingItem) {
              refreshDiagnosticRequest(editingItem.id, (dr: DiagnosticRequest) => {
                setDiagnosticRequests((prev) => prev.map((item) => (item.id === dr.id ? dr : item)));
                setShowIframe(false);
                setEditingItem(undefined);
              });
            }
          }}
        >
          <CModalBody>
            <iframe
              title="Order Form"
              src={editingItem.ordering_url}
              style={{ width: '100%', height: '1060px' }}
              onLoad={(event) => {
                const iframeElement = event.target as HTMLIFrameElement;
                const iframeUrl = iframeElement.contentWindow?.location.href;
                if (iframeUrl?.includes('ui/done')) {
                  setShowIframe(false);
                }
              }}
            ></iframe>
          </CModalBody>
        </CModal>
      )}

      {showResultsViewer && (
        <CModal
          className="show d-block"
          visible
          size="xl"
          fullscreen="sm"
          onClose={() => {
            setResultsIframeUrl(undefined);
            setShowResultsViewer(false);
          }}
        >
          <CModalBody>
            <iframe title="Results Viewer" src={resultsIframeUrl} style={{ width: '100%', height: '1060px' }}></iframe>
          </CModalBody>
        </CModal>
      )}

      <MedicalHistoryTableV2
        name="Diagnostics"
        items={diagnosticRequests}
        form={renderForm(visibleForm)}
        columns={[{ key: 'diagnostics' }, { key: 'actions' }]}
        scopedColumns={{
          diagnostics: (item: DiagnosticRequest) => (
            <td>
              <DiagnosticRequestDetails
                item={item}
                handleEditDiagnosticResult={handleEditDiagnosticResult}
                handleSendOrder={getOrderingHandler(item)}
              />
            </td>
          ),
          actions: (item: DiagnosticRequest) => (
            <td>
              <ActionsMenu items={actionsMenuItems(item)} />
            </td>
          )
        }}
        newButton={
          <IconButton icon={SvgPlus} label="New Diagnostic Request" onClick={handleNewDiagnosticRequestClick} />
        }
        secondaryButton={
          <NewTaskButton
            buttonText="New Task"
            contextOptions={diagnosticRequests
              .filter((request) => request.status !== 'disabled')
              .map(diagnosticRequestToOption)}
            contextType={
              diagnosticRequests.filter((request) => request.status !== 'disabled').length > 0
                ? 'DiagnosticRequest'
                : undefined
            }
            consult_id={consult.id}
            animal_id={consult.animal_id}
            disablePresets
          />
        }
      />
    </>
  );
};
