import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchPresentingProblems } from 'api/PresentingProblems';

import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import SvgClipboard from 'assets/images/SvgClipboard';

import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './ConsultPresentingProblemForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  consultPresentingProblem?: ConsultPresentingProblem;
};

const MIN_QUERY_LENGTH = 3;

export const ConsultPresentingProblemForm = ({ loading, hideForm, onSubmit, consultPresentingProblem }: Props) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    consultPresentingProblem ? toOption(consultPresentingProblem.presenting_problem) : null
  );
  const [specifics, setSpecifics] = useState(consultPresentingProblem?.specifics ?? '');

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{consultPresentingProblem ? 'Edit' : 'New'} Presenting Problem</h2>

      <CRow className="mb-3">
        <CFormInput
          hidden
          id="presenting_problem_id"
          name="presenting_problem_id"
          value={selectedOption?.value ?? ''}
        />
        <div className="d-flex align-items-center justify-content-between form-label">
          <label htmlFor="presenting_problem">
            <IconLabel icon={SvgClipboard} label="Presenting Problem" />
          </label>
          <div className={styles.required}>Required</div>
        </div>
        <AsyncSelect<Option>
          id="presenting_problem"
          aria-label="Presenting Problem"
          placeholder="Type to search..."
          value={selectedOption}
          onChange={handleSelectChange}
          loadingMessage={loadingMessage}
          styles={reactSelectStyles}
          loadOptions={(inputValue, callback) => {
            if (inputValue.length < MIN_QUERY_LENGTH) return;

            fetchPresentingProblems(inputValue, (options) => {
              callback(options.map(toOption));
            });
          }}
          isClearable
          required
          isSearchable
        />
      </CRow>

      <CRow className="mb-3">
        <RichTextEditor name="specifics" id="specifics" label="Specifics" value={specifics} onChange={setSpecifics} />
      </CRow>

      <div className="d-flex align-items-center justify-content-between">
        {consultPresentingProblem && <FormAuditData item={consultPresentingProblem} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            className={styles.button}
            shape="rounded-pill"
            color="primary"
            type="submit"
          >
            {consultPresentingProblem ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton variant="outline" className={styles.button} shape="rounded-pill" type="button" onClick={hideForm}>
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
