import { DateSelectArg, DatesSetArg, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin from '@fullcalendar/luxon2';
import FullCalendar from '@fullcalendar/react';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CButton,
  CCloseButton,
  CCol,
  CContainer,
  CDatePicker,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CRow,
  CSidebar,
  CSidebarHeader
} from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchPimResources } from 'api/PimResources';

import { PimResource } from 'types/PimResource';

import { ClinicContext } from 'contexts/ClinicContext';

import {
  createPlanningGuide,
  deletePlanningGuide,
  fetchPlanningGuides,
  updatePlanningGuide
} from '../../api/PlanningGuides';
import { fetchSchedulingCategories } from '../../api/SchedulingCategories';
import EmployeePhoto from '../../components/EmployeePhoto';
import { CalendarEvent } from '../../types/CalendarEvent';
import { Clinic } from '../../types/Clinic';
import { PlanningGuide } from '../../types/PlanningGuide';
import { SchedulingCategory } from '../../types/SchedulingCategory';
import { compactDateTimeDisplay } from '../../utils/dates';

const PlanningGuideIndex = (): JSX.Element => {
  type Resource = {
    id: string;
    title: string;
  };

  const [currentClinic, setCurrentClinic] = useState<Clinic | undefined>(undefined);
  const [editingItem, setEditingItem] = useState<PlanningGuide | undefined>(undefined);
  const [planningGuides, setPlanningGuides] = useState<PlanningGuide[]>([]);
  const [pimResources, setPimResources] = useState<PimResource[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);

  const [schedulingCategories, setSchedulingCategories] = React.useState<SchedulingCategory[]>([]);
  const [selectedSchedulingCategory, setSelectedSchedulingCategory] = React.useState<SchedulingCategory | undefined>(
    undefined
  );

  const calendarRef = React.useRef<FullCalendar>(null);
  const navigate = useNavigate();

  const planningGuideToEvent = (planningGuide: PlanningGuide) => ({
    id: planningGuide.id.toString(),
    title: planningGuide.scheduling_category.name,
    backgroundColor: planningGuide.scheduling_category.color,
    start: planningGuide.start_at,
    end: planningGuide.end_at,
    resourceId: planningGuide.pim_resource_id.toString(),
    extendedProps: {
      schedulingCategoryId: planningGuide.scheduling_category_id
    }
  });

  useEffect(() => {
    fetchSchedulingCategories(setSchedulingCategories);
  }, []);

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    if (clinicContext) fetchClinic(Number(clinicContext), setCurrentClinic);
  }, [clinicContext]);

  const events = useMemo(() => {
    return planningGuides.map((planningGuide: PlanningGuide) => {
      return planningGuideToEvent(planningGuide);
    });
  }, [planningGuides]);

  useEffect(() => {
    if (currentClinic) {
      fetchPimResources(currentClinic.id, setPimResources);
    }
  }, [currentClinic]);

  const handleResourceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const resourceId = event.target.value;
    const checked = event.target.checked;
    if (checked) {
      const planningResource = pimResources.find((pimResource: PimResource) => {
        return pimResource.id.toString() === resourceId;
      });
      if (planningResource) {
        const newResource = {
          id: planningResource.id.toString(),
          title: planningResource.display_name
        };
        setResources([...resources, newResource]);
      }
    } else {
      if (editingItem && editingItem.pim_resource_id.toString() === resourceId) {
        closeSidebar();
      }
      setResources(resources.filter((resource: Resource) => resource.id !== resourceId));
    }
  };

  useEffect(() => {
    const resourceIds = new Set();
    events.forEach((event: CalendarEvent) => {
      resourceIds.add(event.resourceId);
    });
    const activeResources = pimResources.filter((pimResource: PimResource) => {
      return resourceIds.has(pimResource.id.toString());
    });
    const calendarResources = activeResources.map((pimResource: PimResource) => {
      return {
        id: pimResource.id.toString(),
        title: pimResource.display_name
      };
    });
    setResources(calendarResources);
  }, [events, pimResources]);

  const createPlanningGuideSuccess = (planningGuide: PlanningGuide) => {
    setPlanningGuides(planningGuides.concat([planningGuide]));
  };

  const handleSelect = (event: DateSelectArg) => {
    closeSidebar();
    if (selectedSchedulingCategory && event.resource) {
      createPlanningGuide(
        {
          scheduling_category_id: selectedSchedulingCategory.id,
          start_at: event.startStr,
          end_at: event.endStr,
          pim_resource_id: event.resource.id
        },
        createPlanningGuideSuccess
      );
    }
  };

  const handleDatesSet = (dateInfo: DatesSetArg) => {
    closeSidebar();
    if (currentClinic) {
      fetchPlanningGuides(dateInfo.start, dateInfo.end, currentClinic.id, setPlanningGuides);
      if (dateInfo.view.type === 'resourceTimeGridDay') {
        // FullCalendar is in the clinic's time zone, but the date picker is in the user's time zone
        // So we need to strip out the time and tell the date picker to just select a date
        // First, get the date in the clinic's time zone, removing the time part:
        const dt = DateTime.fromJSDate(dateInfo.start).setZone(currentClinic.time_zone).toFormat('yyyy/MM/dd');
        // Then tell the date picker to select that date
        setSelectedDay(dt);
      }
    }
  };

  const handleEventClick = (clickArg: EventClickArg) => {
    const selected = planningGuides.find((planningGuide: PlanningGuide) => {
      return planningGuide.id.toString() === clickArg.event.id;
    });
    if (selected) {
      setEditingItem(selected);
    }
  };

  const closeSidebar = () => {
    setEditingItem(undefined);
  };

  const updatePlanningGuideSuccess = (pg: PlanningGuide) => {
    setPlanningGuides(
      planningGuides.map((planningGuide: PlanningGuide) => (planningGuide.id === pg.id ? pg : planningGuide))
    );
  };

  const handleChangeEvent = (event: EventChangeArg) => {
    const eventObj = event.event.toPlainObject();
    const resourceId = event.event.getResources()[0].id;

    updatePlanningGuide(
      Number(eventObj.id),
      {
        scheduling_category_id: eventObj.extendedProps.schedulingCategoryId,
        start_at: eventObj.start,
        end_at: eventObj.end,
        pim_resource_id: resourceId
      },
      updatePlanningGuideSuccess,
      event.revert
    );
  };

  const handleSchedulingCategoryChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const schedulingCategory = schedulingCategories.find((schedulingCategory: SchedulingCategory) => {
      return schedulingCategory.id.toString() === event.target.value;
    });
    setSelectedSchedulingCategory(schedulingCategory);
  };

  const handleDelete = () => {
    if (editingItem) {
      deletePlanningGuide(editingItem.id, deleteSuccess);
    }
  };

  const deleteSuccess = (deletedId: number) => {
    setPlanningGuides(planningGuides.filter((planningGuide: PlanningGuide) => planningGuide.id !== deletedId));
    closeSidebar();
  };

  function getFullNameWithTitle(pg: PlanningGuide) {
    return pimResources.find((pimResource: PimResource) => pimResource.id === pg.pim_resource_id)?.employee
      .full_name_with_title;
  }

  function renderSidebar(item: PlanningGuide) {
    return (
      <CSidebar
        colorScheme="light"
        overlaid
        placement="end"
        visible={!!editingItem}
        style={{ width: 'auto', overflowY: 'scroll' }}
      >
        <CSidebarHeader className="bg-transparent">
          <CCloseButton className="float-end" onClick={closeSidebar} />
        </CSidebarHeader>
        <ul className="ps-0 ms-3 me-3">
          <li className="d-flex justify-content-between gap-3 mb-1">
            <div className="text-secondary">Employee:</div>
            <div>{getFullNameWithTitle(item)}</div>
          </li>
          <li className="d-flex justify-content-between gap-3 mb-1">
            <div className="text-secondary">Scheduling Category:</div>
            <div>{item.scheduling_category.name}</div>
          </li>
          <li className="d-flex justify-content-between gap-3 mb-1">
            <div className="text-secondary">Start:</div>
            <div>{compactDateTimeDisplay(item.start_at)}</div>
          </li>
          <li className="d-flex justify-content-between gap-3 mb-1">
            <div className="text-secondary">End:</div>
            <div>{compactDateTimeDisplay(item.end_at)}</div>
          </li>
        </ul>
        <CButton color="danger" onClick={handleDelete} className="m-4">
          Delete
        </CButton>
      </CSidebar>
    );
  }

  return (
    <>
      {editingItem && renderSidebar(editingItem)}
      <CContainer className="mb-3">
        <CRow>
          <CCol xs={3}>
            <CFormSelect onChange={handleSchedulingCategoryChanged}>
              <option>Select Scheduling Category</option>
              {schedulingCategories.map((schedulingCategory: SchedulingCategory) => {
                return (
                  <option key={schedulingCategory.id} value={schedulingCategory.id}>
                    {schedulingCategory.name}
                  </option>
                );
              })}
            </CFormSelect>
          </CCol>
          <CCol>
            <CButton
              color="link"
              onClick={() => {
                navigate('/scheduling_categories');
              }}
            >
              Manage Scheduling Categories
            </CButton>
          </CCol>
        </CRow>
      </CContainer>

      <CContainer className="mw-100">
        <CRow>
          <CCol xs="auto">
            {currentClinic ? (
              <CDatePicker
                date={selectedDay}
                locale="en-US"
                visible
                closeOnSelect={false}
                cleaner={false}
                container={'inline'}
                firstDayOfWeek={0}
                inputReadOnly
                onDateChange={(date: Date | null) => {
                  if (date) {
                    if (calendarRef.current) {
                      const calendarApi = calendarRef.current.getApi();
                      // get the date component of the selected date, removing the time part
                      const dt = DateTime.fromJSDate(date).toISODate();
                      // tell the FullCalendar to go to that date
                      calendarApi.gotoDate(dt);
                    }
                  }
                }}
                onHide={() => {
                  return false;
                }}
                size={'sm'}
                weekdayFormat={'short'}
              />
            ) : null}
            {pimResources.map((pimResource: PimResource) => {
              return (
                <div key={pimResource.employee_id} className="mb-3 d-flex align-items-center">
                  <CFormCheck
                    id={pimResource.id.toString()}
                    style={{ marginTop: 0 }} // className="mt-0" doesn't work
                    name={pimResource.display_name}
                    value={pimResource.id}
                    onChange={handleResourceChange}
                    checked={resources.map((r) => r.id).includes(pimResource.id.toString())}
                  />
                  <CFormLabel className="mb-0 d-flex align-items-center" htmlFor={pimResource.id.toString()}>
                    <span className="mx-2">
                      <EmployeePhoto employee={pimResource.employee} />
                    </span>
                    <span className="mb-0">{pimResource.display_name}</span>
                  </CFormLabel>
                </div>
              );
            })}
          </CCol>
          <CCol>
            <FullCalendar
              schedulerLicenseKey="0956875066-fcs-1680552874"
              plugins={[interactionPlugin, resourceTimeGridPlugin, resourceDayGridPlugin, luxon2Plugin]}
              ref={calendarRef}
              initialDate={selectedDay}
              headerToolbar={{
                start: 'prev,next today',
                center: 'title',
                end: 'resourceTimeGridDay,resourceTimeGridWeek'
              }}
              buttonText={{}}
              initialView="resourceTimeGridDay"
              titleFormat={{ month: 'long', year: 'numeric', day: 'numeric', weekday: 'long' }}
              nowIndicator={true}
              editable={true}
              selectable={true}
              select={handleSelect}
              eventChange={handleChangeEvent}
              navLinks={true}
              events={events}
              resources={resources}
              eventTextColor={'#000000'}
              eventBorderColor={'#000000'}
              timeZone={currentClinic?.time_zone}
              allDaySlot={false}
              height={'auto'}
              slotDuration={'00:15:00'}
              slotMinTime={'07:30:00'}
              slotMaxTime={'18:30:00'}
              businessHours={{
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                startTime: '08:30',
                endTime: '17:30'
              }}
              datesSet={handleDatesSet}
              eventClick={handleEventClick}
            />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default PlanningGuideIndex;
