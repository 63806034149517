import React from 'react';

import { CCol, CForm, CFormInput, CFormSwitch, CLoadingButton, CRow } from '@coreui/react-pro';

import { ProductSupplier } from 'types/ProductSupplier';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgPencil from 'assets/images/SvgPencil';

import { IconLabel } from 'components/IconLabel';

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  supplier: ProductSupplier;
  isLoading: boolean;
};

const Form = ({ handleSubmit, handleCancel, supplier, handleChange, isLoading }: Props): JSX.Element => {
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmit(event);
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol sm={12}>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="name">
              <IconLabel icon={SvgPencil} label="Name" />
            </label>
            <div className="primaryColor">Required</div>
          </div>
          <CFormInput type="text" id="name" value={supplier.name || ''} required onChange={handleChange} />
        </CCol>
      </CRow>

      <CRow className="mb-3 align-items-center">
        <CCol sm={12}>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="status">
              <IconLabel icon={SvgPencil} label="Is Active" />
            </label>
            <div className="primaryColor">Required</div>
          </div>
          <CFormSwitch
            id="status"
            checked={supplier.status === 'active'}
            onChange={(event) =>
              handleChange({
                ...event,
                target: {
                  ...event.target,
                  value: event.target.checked ? 'active' : 'disabled',
                  id: 'status'
                }
              })
            }
          />
        </CCol>
      </CRow>

      <div className="d-flex justify-content-between align-items-center">
        {supplier.id && (
          <div className="text-muted w-100">
            Last updated: {compactDateTimeDisplay(supplier.updated_at)} by{' '}
            {supplier.updated_by_employee?.full_name_with_title}
          </div>
        )}
        <div className="d-grid gap-4 d-sm-flex w-100 justify-content-end">
          <CLoadingButton
            loading={isLoading}
            color="primary"
            variant="outline"
            shape="rounded-pill"
            onClick={handleCancel}
          >
            Cancel
          </CLoadingButton>
          <CLoadingButton color="primary" shape="rounded-pill" type="submit" loading={isLoading}>
            Save
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default Form;
