import React, { useState } from 'react';

import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { PresentingProblem } from 'types/PresentingProblem';

type FormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  presentingProblem: PresentingProblem;
};

const Form = ({ handleSubmit, handleCancel, presentingProblem, handleChange }: FormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>();
  const [nameError, setNameError] = useState<string | undefined>();

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setApiError(undefined);
    if (!presentingProblem.name) {
      setNameError('Name is required');
    } else {
      setNameError(undefined);
      setIsSubmitting(true);
      handleSubmit(event, errorCallback);
    }
  };

  function errorCallback(error: string) {
    setApiError(error);
    setIsSubmitting(false);
  }

  return (
    <CForm onSubmit={submit}>
      {apiError && (
        <CAlert color="danger" dismissible>
          Error: {apiError}
        </CAlert>
      )}
      <CRow className="mb-3">
        <CFormLabel htmlFor="name" className="col-sm-4 col-form-label">
          Name
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput
            type="text"
            id="name"
            value={presentingProblem.name || ''}
            onChange={handleChange}
            feedbackInvalid={nameError}
            invalid={nameError !== undefined}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="status" className="col-sm-4 col-form-label">
          Is Active
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch id="status" checked={presentingProblem.status === 'active'} onChange={handleChange} />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
