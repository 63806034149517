import * as React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CRow
} from '@coreui/react-pro';

import { fetchContextsForAnimal, fetchContextsForConsult } from 'api/Contexts';

import { Animal } from 'types/Animal';
import { CommunicationLog } from 'types/CommunicationLog';
import { CommunicationType } from 'types/CommunicationType';
import { Context } from 'types/Context';
import { Option } from 'types/Option';
import { Topic, topics } from 'types/Topic';

import { getPaddedTime } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import { contextToOption, OptionWithTopic, toOption } from 'utils/selectOptions';

type Props = {
  animals?: Animal[];
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  communicationLog: CommunicationLog;
};

export const EditLogModal = ({ animals, hideModal, onSubmit, loading, communicationLog }: Props) => {
  const [contexts, setContexts] = useState<Context[]>([]);
  const [contextOptions, setContextOptions] = useState<OptionWithTopic[]>([]);
  const [selectedContextOption, setSelectedContextOption] = useState<Option>(
    communicationLog.context_id
      ? {
          label: communicationLog.context_display_name,
          value: `${communicationLog.context_type}-${communicationLog.context_id.toString()}`
        }
      : { label: '', value: '' }
  );
  const selectedContext = contexts.find((context) => contextToOption(context).value === selectedContextOption?.value);
  const [selectedTopic, setSelectedTopic] = useState(communicationLog.topic);

  const [time, setTime] = useState(getPaddedTime(new Date(communicationLog.sent_at)));
  const [date, setDate] = useState<Date | null>(new Date(communicationLog.sent_at));

  const [selectedCommunicationType, setSelectedCommunicationType] = useState(communicationLog.communication_type);
  const [body, setBody] = useState(communicationLog.body);
  const [selectedAnimals, setSelectedAnimals] = useState<Option[]>(
    communicationLog.animals.map((animal) => toOption(animal))
  );

  useEffect(() => {
    if (communicationLog.consult_id) {
      fetchContextsForConsult(communicationLog.consult_id, setContexts);
    } else {
      fetchContextsForAnimal(communicationLog.animals[0].id, setContexts);
    }
  }, [communicationLog.consult_id, communicationLog.animals]);

  useEffect(() => {
    const currentContextIncluded = contexts.some(
      (context) =>
        context.context_id === communicationLog.context_id && context.context_type === communicationLog.context_type
    );
    setContextOptions([
      { label: '', value: '', topic: '' },
      ...(currentContextIncluded
        ? []
        : [
            {
              label: communicationLog.context_display_name,
              value: `${communicationLog.context_type}-${communicationLog.context_id?.toString()}`,
              topic: communicationLog.topic
            }
          ]),
      ...contexts.map(contextToOption)
    ]);
  }, [contexts, communicationLog]);

  const handleContextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = contextOptions.find((c) => c.value === event.target.value);

    if (selected?.topic) {
      setSelectedContextOption(selected);
      setSelectedTopic(selected.topic as Topic);
    }
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setTime(newTime);

    if (date) {
      const newDate = new Date(date);
      const [hours, minutes] = newTime.split(':');

      newDate.setHours(Number(hours));
      newDate.setMinutes(Number(minutes));

      setDate(newDate);
    }
  };

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(event.target.value as Topic);
  };

  return (
    <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h4>Edit Log</h4>
      </CModalHeader>
      <CModalBody>
        <CForm className="g-3" onSubmit={onSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormInput hidden name="sent_at" value={date?.toISOString()} />
              <CDatePicker
                label="Date"
                firstDayOfWeek={0}
                onDateChange={setDate}
                date={date}
                text="Required"
                cleaner={false}
                required
              />
            </CCol>
            <CCol>
              <CFormInput label="Time" type="time" onChange={handleTimeChange} value={time} text="Required" required />
            </CCol>
          </CRow>

          {animals && (
            <div className="mb-2">
              <label className="form-label">Animal</label>
              <Select
                name="animal_ids"
                isMulti
                styles={reactSelectStyles}
                options={animals.map(toOption)}
                onChange={(selected) => setSelectedAnimals(selected as Option[])}
                value={selectedAnimals}
                delimiter=","
                required
              />
            </div>
          )}

          <div className="mb-2">
            <CFormInput type="hidden" name="topic" value={selectedTopic} />
            <CFormSelect
              id="topic"
              label="Topic"
              onChange={handleTopicChange}
              options={['', ...topics]}
              text="Required"
              value={selectedTopic}
              required
            />
          </div>

          <div className="mb-2">
            <CFormInput type="hidden" name="context_id" value={selectedContext?.context_id ?? ''} />
            <CFormInput type="hidden" name="context_type" value={selectedContext?.context_type ?? ''} />
            <CFormSelect
              id="context"
              label="Context"
              value={selectedContextOption.value}
              onChange={handleContextChange}
            >
              {contextOptions.map((option: Option) => (
                <option key={`${option.value}-${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </CFormSelect>
          </div>

          <div className="mb-2">
            <CFormSelect
              name="communication_type"
              id="communication_type"
              label="Communication Type"
              options={[
                { label: '', value: '' },
                { label: 'In-Person', value: 'in_person' },
                { label: 'Phone', value: 'phone' }
              ]}
              required
              value={selectedCommunicationType}
              onChange={(e) => setSelectedCommunicationType(e.target.value as CommunicationType)}
              text="Required"
            />
          </div>

          <div className="mb-2">
            <CFormTextarea
              id="body"
              label="Body"
              name="body"
              rows={3}
              text="Required"
              required
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>

          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <CLoadingButton loading={loading} color="primary" shape="rounded-pill" type="submit">
              Log
            </CLoadingButton>
            <CButton variant="outline" type="button" shape="rounded-pill" onClick={hideModal}>
              Cancel
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    </CModal>
  );
};
