import axios from 'axios';

import { CommunicationLog } from 'types/CommunicationLog';
import { Pagination } from 'types/Pagination';
import { PrimaryKey } from 'types/PrimaryKey';

export type CommunicationFilterParams = {
  animal_id?: string | number;
  topic?: string;
  date_start?: string;
  date_end?: string;
  page?: number;
};

const fetchCommunicationLogsForConsult = (
  consultId: number | string,
  onSuccess: (communicatonLogs: CommunicationLog[]) => void
) => {
  const url = `/pim/communication_logs`;

  axios.get<CommunicationLog[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const fetchCommunicationLogsByCustomer = (
  customerId: number | string,
  params: CommunicationFilterParams,
  onSuccess: (communicatonLogs: CommunicationLog[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/communication_logs`;

  axios
    .get<CommunicationLog[]>(url, {
      params: {
        customer_id: customerId,
        ...params
      }
    })
    .then((data) => {
      onSuccess(data.data.record);

      setPagination({
        page: params.page || 1,
        perPage: Number(data.headers['per-page']),
        total: Number(data.headers.total)
      });
    });
};

const createCommunicationLog = (
  communicationLog: Partial<CommunicationLog>,
  { onSuccess, onError }: { onSuccess: (communicationLog: CommunicationLog) => void; onError: (error: Error) => void }
) => {
  const url = `/pim/communication_logs`;

  axios
    .post<CommunicationLog>(url, { communication_log: communicationLog })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      onError(error.response.data.message);
    });
};

const updateCommunicationLog = (
  communicationLogId: PrimaryKey,
  communicationLog: Partial<CommunicationLog>,
  { onSuccess, onError }: { onSuccess: (communicationLog: CommunicationLog) => void; onError: (error: Error) => void }
) => {
  const url = `/pim/communication_logs/${communicationLogId}`;

  axios
    .put<CommunicationLog>(url, { communication_log: communicationLog })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      onError(error.response.data.message);
    });
};

export {
  fetchCommunicationLogsByCustomer,
  fetchCommunicationLogsForConsult,
  createCommunicationLog,
  updateCommunicationLog
};
