import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CSmartTable
} from '@coreui/react-pro';

import { fetchDocumentTemplates } from 'api/ConsultTemplates';

import { ConsultTemplate } from 'types/ConsultTemplate';
import { calculatePages, Pagination } from 'types/Pagination';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateTimeDisplay } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { Pill } from 'components/Pill';

const List = (): JSX.Element => {
  const [templatesList, setTemplatesList] = useState<ConsultTemplate[]>();
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  useDocumentTitle('Document Templates');

  useEffect(() => {
    if (searchText.length === 0) {
      fetchDocumentTemplates('', setTemplatesList, setPagination);
    }
  }, [searchText]);

  const updateTemplatesList = useCallback((templates: ConsultTemplate[]) => {
    setIsLoading(false);
    setTemplatesList(templates);
  }, []);

  const updatePage = useCallback(
    (page: number) => {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      fetchDocumentTemplates(searchText, updateTemplatesList, setPagination, page);
    },
    [searchText, updateTemplatesList]
  );

  const fetchConsultTemplatesList = useCallback(() => {
    setIsLoading(true);
    fetchDocumentTemplates(searchText, updateTemplatesList, setPagination);
  }, [searchText, updateTemplatesList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchConsultTemplatesList();
  };

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <CForm className="row g-3" onSubmit={handleSubmit}>
          <CCol>
            <CFormInput
              type="text"
              id="searchInput"
              label="Search Document Templates"
              placeholder="Search by name"
              onChange={(event) => setSearchText(event.target.value)}
            />
          </CCol>
          <CCol className="align-self-end" xs={2}>
            <CLoadingButton
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={!searchText}
              shape="rounded-pill"
            >
              Search
            </CLoadingButton>
          </CCol>
          <CCol className="align-self-end text-end" xs={2}>
            <CButton color="primary" href={'/document_templates/new'} shape="rounded-pill">
              New
            </CButton>
          </CCol>
        </CForm>
      </CCardHeader>
      <CFormLabel className="ms-3 mt-2 text-body-secondary" color="light">
        {pagination.total} {pluralize('document', pagination.total)}{' '}
      </CFormLabel>
      <CCardBody role="listitem">
        <CSmartTable
          items={templatesList}
          itemsPerPage={25}
          pagination={{
            external: true
          }}
          paginationProps={{
            activePage: pagination.page,
            pages: calculatePages(pagination),
            align: 'center'
          }}
          onActivePageChange={(activePage) => updatePage(activePage)}
          columns={['name', 'status', 'description']}
          scopedColumns={{
            name: (template: ConsultTemplate) => {
              return (
                <td>
                  <Link to={`/document_templates/${template.id}`}>{template.name}</Link>
                </td>
              );
            },
            status: (template: ConsultTemplate) => {
              return (
                <td>
                  <Pill label={template.status} />
                </td>
              );
            },
            last_updated: (template: ConsultTemplate) => {
              return <td>{template.updated_at && compactDateTimeDisplay(template.updated_at)}</td>;
            }
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default List;
