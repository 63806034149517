import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHourglass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.429 1.5h-4.86c-2.82 0-3.038 2.535-1.515 3.915l7.89 7.17c1.522 1.38 1.305 3.915-1.515 3.915h-4.86c-2.82 0-3.038-2.535-1.515-3.915l7.89-7.17c1.522-1.38 1.305-3.915-1.515-3.915"
    />
  </svg>
);
export default SvgHourglass;
