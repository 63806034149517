import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CFormSelect, CSpinner } from '@coreui/react-pro';

import { updateAppointment } from 'api/Appointments';
import { fetchRooms } from 'api/Rooms';

import { Appointment } from 'types/Appointment';
import { Option } from 'types/Option';

import { toOption } from 'utils/selectOptions';

type Props = {
  appointment: Appointment;
};

export const AppointmentRoomSelect = ({ appointment }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [roomOptions, setRoomOptions] = useState<Option[]>();
  const [selectedRoomOption, setSelectedRoomOption] = useState<number | string | null>(appointment.room_id);

  useEffect(() => {
    fetchRooms((rooms) => {
      setRoomOptions([{ label: 'Unassigned', value: '' }, ...rooms.map(toOption)]);
    });
  }, []);

  const handleRoomSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = roomOptions?.find((option: Option) => option.value === event.target.value);
    if (!selected) return;
    setSelectedRoomOption(selected.value);

    let room_id;
    if (selected.value === '') {
      room_id = null;
    } else {
      room_id = Number(selected?.value);
    }

    setIsUpdating(true);
    updateAppointment(appointment.id, { room_id }, { onSuccess: handleUpdateSuccess, onError: handleUpdateError });
  };

  const handleUpdateSuccess = () => {
    setIsUpdating(false);
    toast.success('Room updated!');
  };

  const handleUpdateError = (error: string) => {
    setIsUpdating(false);
    toast.error(error);
  };

  return (
    <div className="d-flex align-items-center gap-2" style={{ position: 'relative' }}>
      <CFormSelect
        id="appointment_room"
        size="sm"
        aria-label="Room"
        className="text-truncate"
        disabled={isUpdating}
        options={roomOptions}
        value={selectedRoomOption ?? ''}
        onChange={handleRoomSelectChange}
      />
      {isUpdating && (
        <CSpinner size="sm" color="primary" style={{ background: 'white', position: 'absolute', right: '13px' }} />
      )}
    </div>
  );
};
