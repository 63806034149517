import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { CSpinner } from '@coreui/react-pro';

import { fetchProductSupplier, updateProductSupplier } from 'api/ProductSuppliers';

import { ProductSupplier } from 'types/ProductSupplier';

import Form from './Form';

const Edit = (): JSX.Element => {
  type ProductSupplierEditParams = {
    id: string;
  };
  const { id } = useParams<keyof ProductSupplierEditParams>() as ProductSupplierEditParams;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProductSupplier, setIsLoadingProductSupplier] = useState<boolean>(true);
  const [productSupplier, setProductSupplier] = useState<ProductSupplier>({} as ProductSupplier);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setProductSupplier({ ...productSupplier, [event.target.id]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateProductSupplier(id, productSupplier, updateSuccess, updateError);
  };

  useEffect(() => {
    fetchProductSupplier(id, (supplier: ProductSupplier) => {
      setProductSupplier(supplier);
      setIsLoadingProductSupplier(false);
    });
  }, [id]);

  const updateError = () => {
    setIsLoading(false);
    toast.error('Error updating product supplier');
  };

  const updateSuccess = (supplier: ProductSupplier) => {
    toast.success('Product supplier updated!');
    setIsLoading(false);
    handleGoBack();
  };

  const handleCancel = () => {
    handleGoBack();
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back if there's history
    } else {
      // Handle the case where there's no previous page
      navigate(generatePath(paths.productSupplierList));
    }
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <div style={{ borderRadius: '20px', boxShadow: '0px 4px 30px 0px rgba(26, 41, 60, 0.1)', padding: '21px' }}>
        <h1 className="mb-3">Edit Product Supplier</h1>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          supplier={productSupplier}
          isLoading={isLoading}
        />
      </div>
    );
  }

  return isLoadingProductSupplier ? loadingState() : loadedState();
};

export default Edit;
