import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { HealthStatus } from 'types/HealthStatus';

import { stringToFractionOption } from 'utils/selectOptions';

import SvgBrokenBone from 'assets/images/SvgBrokenBone';
import SvgDentalTools from 'assets/images/SvgDentalTools';
import SvgHeartbeat from 'assets/images/SvgHeartbeat';
import SvgNose from 'assets/images/SvgNose';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPawCross from 'assets/images/SvgPawCross';
import SvgScale from 'assets/images/SvgScale';
import SvgThermometer from 'assets/images/SvgThermometer';
import SvgWindyHeart from 'assets/images/SvgWindyHeart';

import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';

import styles from './HealthStatusForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  healthStatus?: HealthStatus;
};

export const HealthStatusForm = ({ loading, hideForm, onSubmit, healthStatus }: Props) => {
  const [weight, setWeight] = useState(healthStatus?.weight ?? '');
  const [temp, setTemp] = useState(healthStatus?.temperature ?? '');
  const [heartRate, setHeartRate] = useState(healthStatus?.heart_rate ?? '');
  const [respRate, setRespRate] = useState(healthStatus?.respiratory_rate ?? '');
  const [attitude, setAttitude] = useState(healthStatus?.attitude ?? '');
  const [bcs, setBCS] = useState(healthStatus?.bcs ?? '');
  const [fasScore, setFASScore] = useState(healthStatus?.fas_score ?? '');
  const [maxBCS, setMaxBCS] = useState(healthStatus?.max_bcs ?? '9');
  const [dentalScore, setDentalScore] = useState(healthStatus?.dental_score ?? '');
  const [maxDental, setMaxDental] = useState(healthStatus?.max_dental_score ?? '4');
  const [demeanor, setDemeanor] = useState(healthStatus?.demeanor ?? '');
  const [pain, setPain] = useState(healthStatus?.pain_score ?? '');
  const [maxPain, setMaxPain] = useState(healthStatus?.max_pain_score ?? '5');
  const [crt, setCRT] = useState(healthStatus?.crt ?? '');
  const [mm, setMM] = useState(healthStatus?.mm ?? '');
  const [pulseQuality, setPulseQuality] = useState(healthStatus?.pulse_quality ?? '');
  const [respEffort, setRespEffort] = useState(healthStatus?.respiratory_effort ?? '');
  const [bloodPressure, setBloodPressure] = useState(healthStatus?.blood_pressure ?? '');

  // Non-standard items
  const [cervixCyto, setCervixCyto] = useState(healthStatus?.cervix_cytology ?? '');
  const [cervixTone, setCervixTone] = useState(healthStatus?.cervix_tone ?? '');
  const [loSize, setLoSize] = useState(healthStatus?.left_ovary_size ?? '');
  const [loStage, setLoStage] = useState(healthStatus?.left_ovary_stage ?? '');
  const [roSize, setRoSize] = useState(healthStatus?.right_ovary_size ?? '');
  const [roStage, setRoStage] = useState(healthStatus?.right_ovary_stage ?? '');
  const [pregnant, setPregnant] = useState(healthStatus?.pregnant ?? false);
  const [uterineCyst, setUterineCyst] = useState(healthStatus?.uterine_cyst ?? '');
  const [uterineCyto, setUterineCyto] = useState(healthStatus?.uterine_cytology ?? '');
  const [uterineEdema, setUterineEdema] = useState(healthStatus?.uterine_edema ?? '');
  const [uterineFluid, setUterineFluid] = useState(healthStatus?.uterine_fluid ?? '');
  const [uterineTone, setUterineTone] = useState(healthStatus?.uterine_tone ?? '');

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{healthStatus ? 'Edit' : 'New'} Health Status</h2>

      <div className="mb-3">
        {/* First row of form inputs */}
        <CRow className="mb-3">
          <CCol>
            <CFormInput
              type="number"
              id="weight"
              name="weight"
              label={<IconLabel icon={SvgScale} label="Weight (Kg)" />}
              autoFocus={!healthStatus}
              value={weight}
              onChange={(event) => setWeight(event.target.value)}
              min={0}
              step={0.01}
            />
          </CCol>
          <CCol>
            <CFormInput
              type="number"
              id="temperature"
              name="temperature"
              label={<IconLabel icon={SvgThermometer} label="Temp (&#176;F)" />}
              value={temp}
              onChange={(event) => setTemp(event.target.value)}
              min={0}
              step={0.1}
            />
          </CCol>
          <CCol>
            <CFormInput
              type="number"
              id="heart_rate"
              name="heart_rate"
              label={<IconLabel icon={SvgHeartbeat} label="HR (bpm)" />}
              value={heartRate}
              onChange={(event) => setHeartRate(event.target.value)}
              min={0}
            />
          </CCol>
          <CCol>
            <CFormInput
              type="number"
              id="respiratory_rate"
              name="respiratory_rate"
              label={<IconLabel icon={SvgHeartbeat} label="RR (bpm)" />}
              value={respRate}
              onChange={(event) => setRespRate(event.target.value)}
              min={0}
            />
          </CCol>

          <CCol>
            <CFormInput
              type="number"
              id="blood_pressure"
              name="blood_pressure"
              label={<IconLabel icon={SvgHeartbeat} label="BP (mm Hg)" />}
              value={bloodPressure}
              onChange={(event) => setBloodPressure(event.target.value)}
              min={0}
            />
          </CCol>
        </CRow>

        {/* Second row of form inputs */}
        <CRow className="mb-3">
          <CCol>
            <CFormSelect
              id="attitude"
              name="attitude"
              label={<IconLabel icon={SvgPaw} label="Attitude" />}
              options={['', 'BAR', 'QAR', 'Comatose', 'Sleeping', 'Vocalising', 'Sedate']}
              value={attitude}
              onChange={(event) => setAttitude(event.target.value)}
            />
          </CCol>

          <CCol>
            <CFormSelect
              id="crt"
              name="crt"
              label={<IconLabel icon={SvgPaw} label="CRT" />}
              options={['', '<1 sec', '1-2 sec', '>2 sec']}
              value={crt}
              onChange={(event) => setCRT(event.target.value)}
            />
          </CCol>

          <CCol>
            <CFormSelect
              id="mm"
              name="mm"
              options={[
                '',
                'Blue',
                'Grey',
                'White',
                'Pale',
                'Yellow',
                'Pigmented',
                'Tacky',
                'Pale Pink',
                'Pink',
                'Injected',
                'Red'
              ]}
              value={mm}
              label={<IconLabel icon={SvgNose} label="MM" />}
              onChange={(event) => setMM(event.target.value)}
            />
          </CCol>

          <CCol>
            <CFormSelect
              id="pulse_quality"
              name="pulse_quality"
              label={<IconLabel icon={SvgHeartbeat} label="Pulse Quality" />}
              options={[
                '',
                'Bounding',
                'Normal',
                'Fair',
                'Difficult to Palpate',
                'Weak',
                'No Pulse Detected',
                'Dropped Pulse'
              ]}
              value={pulseQuality}
              onChange={(event) => setPulseQuality(event.target.value)}
            />
          </CCol>

          <CCol>
            <CFormSelect
              id="respiratory_effort"
              name="respiratory_effort"
              label={<IconLabel icon={SvgWindyHeart} label="Resp Effort" />}
              options={['', 'Normal', 'Laboured Breathing', 'Shallow']}
              value={respEffort}
              onChange={(event) => setRespEffort(event.target.value)}
            />
          </CCol>
        </CRow>

        {/* Third row of form inputs */}
        <CRow className="mb-3">
          <CCol>
            <CFormLabel htmlFor="demeanor">
              <IconLabel icon={SvgPaw} label="Demeanor" />
            </CFormLabel>
            <CFormSelect
              id="demeanor"
              name="demeanor"
              options={[
                '',
                'Friendly',
                'Unfriendly',
                'Anxious',
                'Caution',
                'Bites',
                'Dog aggressive',
                'Cat aggressive',
                'Muzzle necessary'
              ]}
              value={demeanor ?? ''}
              onChange={(event) => setDemeanor(event.target.value)}
            />
          </CCol>
          <CCol>
            <CFormLabel htmlFor="bcs">
              <IconLabel icon={SvgPawCross} label="BCS" />
            </CFormLabel>
            <CInputGroup>
              <CFormInput
                type="number"
                id="bcs"
                className={styles.blue}
                name="bcs"
                value={bcs}
                onChange={(event) => setBCS(event.target.value)}
                min={0}
                step={0.1}
                max={maxBCS}
              />
              <CFormSelect
                id="max_bcs"
                aria-label="Max BCS"
                name="max_bcs"
                options={['5', '9'].map(stringToFractionOption)}
                className="fw-bold"
                value={maxBCS}
                onChange={(event) => setMaxBCS(event.target.value)}
              />
            </CInputGroup>
          </CCol>

          <CCol>
            <CFormLabel htmlFor="fas_score">
              <IconLabel icon={SvgPawCross} label="FAS Score" />
            </CFormLabel>
            <CFormSelect
              id="fas_score"
              name="fas_score"
              options={['', '1', '2', '3', '4', '5']}
              value={fasScore ?? ''}
              onChange={(event) => setFASScore(event.target.value)}
            />
          </CCol>

          <CCol>
            <CFormLabel htmlFor="pain_score">
              <IconLabel icon={SvgBrokenBone} label="Pain Score" />
            </CFormLabel>
            <CInputGroup>
              <CFormInput
                type="number"
                id="pain_score"
                name="pain_score"
                value={pain}
                className={styles.blue}
                onChange={(event) => setPain(event.target.value)}
                min={0}
                max={maxPain}
              />
              <CFormSelect
                id="max_pain_score"
                name="max_pain_score"
                aria-label="Max Pain Score"
                className="fw-bold"
                options={['4', '5', '9', '23', '24'].map(stringToFractionOption)}
                value={maxPain}
                onChange={(event) => setMaxPain(event.target.value)}
              />
            </CInputGroup>
          </CCol>
          <CCol>
            <CFormLabel htmlFor="dental_score">
              <IconLabel icon={SvgDentalTools} label="Dental Score" />
            </CFormLabel>
            <CInputGroup>
              <CFormInput
                type="number"
                id="dental_score"
                name="dental_score"
                value={dentalScore}
                className={styles.blue}
                onChange={(event) => setDentalScore(event.target.value)}
                min={0}
                max={maxDental}
              />
              <CFormSelect
                id="max_dental_score"
                name="max_dental_score"
                aria-label="Max Dental Score"
                className="fw-bold"
                options={['4'].map(stringToFractionOption)}
                value={maxDental}
                onChange={(event) => setMaxDental(event.target.value)}
              />
            </CInputGroup>
          </CCol>
        </CRow>

        {/* Non-standard form inputs */}
        <CAccordion className="mt-3">
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>Non-Standard Items</CAccordionHeader>
            <CAccordionBody>
              <CRow className="mb-3">
                <CCol>
                  <CFormInput
                    type="number"
                    id="left_ovary_size"
                    name="left_ovary_size"
                    label="Left Ovary Size (mm)"
                    value={loSize}
                    onChange={(event) => setLoSize(event.target.value)}
                    min={0}
                  />
                </CCol>
                <CCol>
                  <CFormSelect
                    id="left_ovary_stage"
                    name="left_ovary_stage"
                    label="Left Ovary Stage"
                    options={['', 'CL', 'CH', 'ANOV']}
                    value={loStage}
                    onChange={(event) => setLoStage(event.target.value)}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    type="number"
                    id="right_ovary_size"
                    name="right_ovary_size"
                    label="Right Ovary Size (mm)"
                    value={roSize}
                    onChange={(event) => setRoSize(event.target.value)}
                    min={0}
                  />
                </CCol>
                <CCol>
                  <CFormSelect
                    id="right_ovary_stage"
                    name="right_ovary_stage"
                    label="Right Ovary Stage"
                    options={['', 'CL', 'CH', 'ANOV']}
                    value={roStage}
                    onChange={(event) => setRoStage(event.target.value)}
                  />
                </CCol>
              </CRow>

              <CRow className="mb-3">
                <CCol>
                  <CFormInput
                    id="uterine_cyst"
                    name="uterine_cyst"
                    label="Uterine Cyst"
                    value={uterineCyst}
                    onChange={(event) => setUterineCyst(event.target.value)}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    id="uterine_cytology"
                    name="uterine_cytology"
                    label="Uterine Cytology"
                    value={uterineCyto}
                    onChange={(event) => setUterineCyto(event.target.value)}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    id="uterine_edema"
                    name="uterine_edema"
                    label="Uterine Edema"
                    value={uterineEdema}
                    onChange={(event) => setUterineEdema(event.target.value)}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    id="uterine_fluid"
                    name="uterine_fluid"
                    label="Uterine Fluid"
                    value={uterineFluid}
                    onChange={(event) => setUterineFluid(event.target.value)}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    id="uterine_tone"
                    name="uterine_tone"
                    label="Uterine Tone"
                    value={uterineTone}
                    onChange={(event) => setUterineTone(event.target.value)}
                  />
                </CCol>
              </CRow>

              <CRow className="mb-3">
                <CCol>
                  <CFormInput
                    type="number"
                    id="cervix_cytology"
                    label="Cervix Cytology"
                    name="cervix_cytology"
                    value={cervixCyto}
                    onChange={(event) => setCervixCyto(event.target.value)}
                    min={0}
                  />
                </CCol>
                <CCol>
                  <CFormInput
                    type="number"
                    id="cervix_tone"
                    label="Cervix Tone"
                    name="cervix_tone"
                    value={cervixTone}
                    onChange={(event) => setCervixTone(event.target.value)}
                    min={0}
                  />
                </CCol>

                <CCol>
                  <CFormLabel htmlFor="pregnant">Is Pregnant</CFormLabel>
                  <CFormSwitch
                    id="pregnant"
                    name="pregnant"
                    checked={pregnant}
                    onChange={(event) => setPregnant(event.target.checked)}
                  />
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        {healthStatus && <FormAuditData item={healthStatus} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            className={styles.button}
            shape="rounded-pill"
            color="primary"
            type="submit"
          >
            {healthStatus ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
