import { PrescriptionItem } from 'types/PrescriptionItem';

import { compactDateDisplay } from 'utils/dates';
import { identity } from 'utils/identity';
import { pluralize } from 'utils/strings';

export const buildRefillInstructions = ({
  isRefillable,
  maxRefills,
  quantityPerRefill,
  refillTimeUnit,
  refillTimePeriod,
  refillExpiresAt
}: {
  isRefillable?: boolean;
  maxRefills?: string;
  quantityPerRefill?: string;
  refillTimeUnit?: string;
  refillTimePeriod?: string;
  refillExpiresAt?: string;
}) => {
  const instructions = [];

  const isRefillInstructable =
    isRefillable && refillExpiresAt && maxRefills && quantityPerRefill && refillTimeUnit && refillTimePeriod;

  if (isRefillInstructable) {
    instructions.push(
      `You have ${maxRefills} ${pluralize('refill', Number(maxRefills))}`,
      `with a quantity of ${quantityPerRefill}`,
      `available every ${refillTimeUnit} ${pluralize(refillTimePeriod.toLowerCase(), Number(refillTimeUnit))}`,
      `before ${compactDateDisplay(refillExpiresAt)}.`
    );
  }

  return instructions.filter(identity).join(' ');
};

export const fulfillmentStatus = (item: PrescriptionItem): string => {
  let result = 'unfulfilled';
  if (item.status === 'disabled') {
    result = 'disabled';
  } else if (item.external_prescription) {
    result = 'external';
  } else if (item.dispense_records && item.dispense_records.length > 0) {
    const newestDispenseRecord = item.dispense_records.sort(
      (first, second) => new Date(second.created_at).getTime() - new Date(first.created_at).getTime()
    )[0];

    result = newestDispenseRecord.status;
  }

  return result;
};
