import * as React from 'react';
import Select from 'react-select';

import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Option } from 'types/Option';

import { labelData } from 'utils/printLabel';
import { reactSelectStyles } from 'utils/reactSelect';
import { createNewTab } from 'utils/tabs';

import SvgPrint from 'assets/images/SvgPrint';

import { IconLabel } from 'components/IconLabel';

import { PatientInfoPrintLabel } from './PatientInfoPrintLabel';
import { SamplePrintLabel } from './SamplePrintLabel';

type Props = {
  animal: Animal;
  hideModal: () => void;
};

export const PrintLabelSelectorModal = ({ animal, hideModal }: Props) => {
  const handleLabelSelected = (selectedOption: Option | null) => {
    if (!selectedOption) return;
    if (selectedOption.value === 'patientInfo') {
      createNewTab(<PatientInfoPrintLabel animal={animal} />);
    } else {
      const label = labelData.find((l) => l.value === selectedOption.value);
      if (label) createNewTab(<SamplePrintLabel animal={animal} title={label.title} body={label.body} />);
    }
  };

  return (
    <CModal alignment="center" visible onClose={hideModal}>
      <CModalHeader closeButton>
        <CModalTitle data-testid="modal-header">Select a print label</CModalTitle>
      </CModalHeader>
      <CModalBody data-testid="modal-body">
        <label htmlFor="template" className="form-label">
          <IconLabel icon={SvgPrint} label="Select Print Label" />
        </label>
        <Select<Option>
          id="print_label"
          name="print_label"
          aria-label="Select Print Label"
          options={labelData}
          onChange={handleLabelSelected}
          styles={reactSelectStyles}
        />
      </CModalBody>
    </CModal>
  );
};
