import * as React from 'react';
import type { SVGProps } from 'react';

const SvgWalletX = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.53 10.163c-.315.307-.495.75-.45 1.222.068.81.81 1.403 1.62 1.403h1.425v.892a2.826 2.826 0 0 1-2.82 2.82H5.723c.232-.195.434-.435.592-.705a2.94 2.94 0 0 0 .435-1.545 3 3 0 0 0-4.875-2.34V8.633a2.826 2.826 0 0 1 2.82-2.82h8.61a2.826 2.826 0 0 1 2.82 2.82v1.08H14.61c-.42 0-.803.165-1.08.45"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.875 9.308V5.88c0-.892.547-1.687 1.38-2.002l5.955-2.25a1.425 1.425 0 0 1 1.927 1.335v2.85M16.92 10.478v1.545a.77.77 0 0 1-.75.765H14.7c-.81 0-1.553-.593-1.62-1.403a1.5 1.5 0 0 1 .45-1.222c.277-.285.66-.45 1.08-.45h1.56a.77.77 0 0 1 .75.765M5.25 9h5.25"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.75 14.25a2.94 2.94 0 0 1-.435 1.545c-.158.27-.36.51-.593.705a2.93 2.93 0 0 1-1.972.75 2.98 2.98 0 0 1-2.565-1.455A2.94 2.94 0 0 1 .75 14.25c0-.945.435-1.793 1.125-2.34a3 3 0 0 1 4.875 2.34M4.557 15.03l-1.59-1.583M4.54 13.47l-1.59 1.582"
    />
  </svg>
);
export default SvgWalletX;
